import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { useUserAuth } from "../../context/userAuthContext";

const Comments = ({ productId }) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const commentsRef = collection(db, "Products", productId, "Comments");
    const q = query(commentsRef, orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const commentsList = await Promise.all(
        snapshot.docs.map(async (commentDoc) => {
          const commentData = commentDoc.data();
          const userRef = doc(db, "Users", commentData.uid);
          const userSnapshot = await getDoc(userRef);
          const userData = userSnapshot.exists() ? userSnapshot.data() : {};
          return {
            id: commentDoc.id,
            ...commentData,
            userName: userData.name,
            userPhoto: userData.photoUrl,
          };
        })
      );
      setComments(commentsList);
    });

    return () => unsubscribe();
  }, [productId]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (comment.trim() === "" || !user) return;

    try {
      await addDoc(collection(db, "Products", productId, "Comments"), {
        text: comment,
        createdAt: new Date(),
        uid: user.uid,
      });
      setComment("");
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  const handleDeleteClick = (commentId) => {
    setCommentToDelete(commentId);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(
        doc(db, "Products", productId, "Comments", commentToDelete)
      );
      setCommentToDelete(null);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting comment: ", error);
    }
  };

  const handleDeleteCancel = () => {
    setCommentToDelete(null);
    setOpenDialog(false);
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <Card elevation={0} sx={{ bgcolor: "background.default" }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Comentarios ({comments.length})
        </Typography>

        {!user ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              my: 4,
            }}
          >
            <Typography variant="body1" color="textSecondary">
              Inicia sesión para comentar en este anuncio.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginRedirect}
              sx={{ borderRadius: 20 }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <TextField
              variant="outlined"
              placeholder="Escribe un comentario..."
              fullWidth
              multiline
              rows={isMobile ? 2 : 1}
              value={comment}
              onChange={handleCommentChange}
              sx={{ mr: 2 }}
            />
            <IconButton
              color="primary"
              onClick={handleCommentSubmit}
              disabled={comment.trim() === ""}
              sx={{
                bgcolor: "primary.main",
                color: "white",
                "&:hover": {
                  bgcolor: "primary.dark",
                },
                "&:disabled": {
                  bgcolor: "action.disabledBackground",
                },
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        )}

        <List>
          {comments.map((comment, index) => (
            <React.Fragment key={comment.id}>
              {index !== 0 && <Divider variant="inset" component="li" />}
              <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                <ListItemAvatar>
                  <Avatar alt={comment.userName} src={comment.userPhoto} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{ fontWeight: "medium", mr: 1 }}
                      >
                        {comment.userName}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(comment.createdAt.toDate()).toLocaleString()}
                      </Typography>
                      {comment.uid === user?.uid && (
                        <IconButton
                          onClick={() => handleDeleteClick(comment.id)}
                          size="small"
                          sx={{ ml: "auto" }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ mt: 1, whiteSpace: "pre-wrap" }}
                    >
                      {comment.text}
                    </Typography>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleDeleteCancel}
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar este comentario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Comments;
