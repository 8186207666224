import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, LoadScript, KmlLayer } from "@react-google-maps/api";
import { db } from "../../firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

const containerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "15px",
  overflow: "hidden",
};

const ParkMap = () => {
  const { uid } = useParams();
  const [kmlData, setKmlData] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: -34.397, lng: 150.644 });
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchParkLocation = async () => {
      const parkRef = doc(db, "Parks", uid);
      const parkDoc = await getDoc(parkRef);
      if (parkDoc.exists()) {
        const parkData = parkDoc.data();
        if (parkData.Location) {
          setMapCenter({
            lat: parkData.Location.latitude,
            lng: parkData.Location.longitude,
          });
        }
      }
    };

    const fetchTracks = async () => {
      const tracksRef = collection(db, "Parks", uid, "Tracks");
      const snapshot = await getDocs(tracksRef);
      const allKmlData = [];
      snapshot.forEach((doc) => {
        if (doc.exists()) {
          const trackData = doc.data();
          if (trackData.KML) {
            allKmlData.push({
              url: trackData.KML,
              status: trackData.Status,
            });
          }
        }
      });
      setKmlData(allKmlData);
    };

    fetchParkLocation();
    fetchTracks();
  }, [uid]);

  const onLoad = (map) => {
    mapRef.current = map;

    kmlData.forEach((track) => {
      const kmlLayer = new window.google.maps.KmlLayer({
        url: track.url,
        map: map,
        preserveViewport: true,
      });

      kmlLayer.addListener("defaultviewport_changed", () => {
        if (mapRef.current) {
          const bounds = kmlLayer.getDefaultViewport();
          mapRef.current.fitBounds(bounds);
        }
      });

      kmlLayer.addListener("status_changed", () => {
        const color = getColorBasedOnStatus(track.status);
        kmlLayer.setOptions({
          suppressInfoWindows: true,
          preserveViewport: true,
        });
        mapRef.current.data.setStyle({
          strokeColor: color,
          fillColor: color,
        });
      });
    });
  };

  const getColorBasedOnStatus = (status) => {
    switch (status) {
      case "Abierta":
        return "green";
      case "Mantenimiento":
        return "yellow";
      case "Cerrada":
        return "red";
      default:
        return "blue";
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={14}
        onLoad={onLoad}
        mapTypeId="terrain"
      >
        {kmlData.map((track, index) => (
          <KmlLayer
            key={index}
            url={track.url}
            options={{ preserveViewport: true }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(ParkMap);
