import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  AccessTime,
  Groups,
  LocationOn,
  Edit,
  Delete,
} from "@mui/icons-material";

const formatDateTime = (timestamp) => {
  if (!timestamp) {
    return "Invalid Date";
  }
  const date = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
};

const MyRides = () => {
  const [rides, setRides] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRideId, setSelectedRideId] = useState(null);
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRides = async () => {
      const ridesRef = collection(db, "Rides");
      const q = query(ridesRef, where("creator", "==", user.uid));
      onSnapshot(q, (snapshot) => {
        const ridesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRides(ridesList);
      });
    };

    fetchRides();
  }, [user.uid]);

  const handleEditClick = (rideId) => {
    navigate(`/createride/${rideId}`);
  };

  const handleDeleteClick = (rideId) => {
    setSelectedRideId(rideId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRideId(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedRideId) {
      try {
        await deleteDoc(doc(db, "Rides", selectedRideId));
        setRides(rides.filter((ride) => ride.id !== selectedRideId));
        handleCloseDialog();
      } catch (error) {
        console.error("Error deleting ride: ", error);
      }
    }
  };

  const handleCardClick = (rideId) => {
    navigate(`/ride/${rideId}`);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom component="div">
        My Rides
      </Typography>
      <Grid container spacing={3}>
        {rides.map((ride, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => handleCardClick(ride.id)}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {ride.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={2}>
                    Hora: {formatDateTime(ride.time)}
                  </Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Groups color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      {ride.riders.length} riders
                    </Typography>
                  </Box>
                  {ride.location && (
                    <Box display="flex" alignItems="center" mt={1}>
                      <LocationOn color="primary" />
                      <Typography variant="body2" color="textSecondary" ml={1}>
                        {`${ride.location.latitude}, ${ride.location.longitude}`}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(ride.id);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Delete />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(ride.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this ride? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyRides;
