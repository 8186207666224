import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import ControlLinks from "./ControlLinks"; // Importa el componente ControlLinks

const userData = [
  { name: "Jan", users: 400 },
  { name: "Feb", users: 300 },
  { name: "Mar", users: 200 },
  { name: "Apr", users: 278 },
  { name: "May", users: 189 },
  { name: "Jun", users: 239 },
  { name: "Jul", users: 349 },
  { name: "Aug", users: 200 },
  { name: "Sep", users: 278 },
  { name: "Oct", users: 189 },
  { name: "Nov", users: 349 },
  { name: "Dec", users: 400 },
];

const pieData = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const ControlPanel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      p={isMobile ? 1 : 3}
      sx={{ backgroundColor: "#f5f5f5", minHeight: "100vh" }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#333",
          fontWeight: "bold",
          mb: isMobile ? 2 : 4,
          textTransform: "uppercase",
        }}
      >
        Dashboard
      </Typography>

      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              color: "#333",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                User Growth
              </Typography>
              <Box sx={{ overflowX: "auto" }}>
                <LineChart
                  width={isMobile ? 300 : 500}
                  height={300}
                  data={userData}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" stroke="#333" />
                  <YAxis stroke="#333" />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              color: "#333",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                User Distribution
              </Typography>
              <Box sx={{ overflowX: "auto" }}>
                <PieChart width={isMobile ? 300 : 400} height={300}>
                  <Pie
                    data={pieData}
                    cx={isMobile ? 150 : 200}
                    cy={150}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              color: "#333",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Monthly Active Users
              </Typography>
              <Box sx={{ overflowX: "auto" }}>
                <BarChart
                  width={isMobile ? 300 : 500}
                  height={300}
                  data={userData}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" stroke="#333" />
                  <YAxis stroke="#333" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="users" fill="#8884d8" />
                </BarChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#fff",
              color: "#333",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                User Retention
              </Typography>
              <Box sx={{ overflowX: "auto" }}>
                <LineChart
                  width={isMobile ? 300 : 500}
                  height={300}
                  data={userData}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" stroke="#333" />
                  <YAxis stroke="#333" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="users" stroke="#8884d8" />
                </LineChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4, borderColor: "#ccc" }} />

      <ControlLinks />
    </Box>
  );
};

export default ControlPanel;
