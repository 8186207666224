import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

function MyEventsContainer() {
  return (
    <div>
      <Container>
        <Outlet />
      </Container>
    </div>
  );
}

export default MyEventsContainer;
