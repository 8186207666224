import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Typography,
  Container,
  Box,
  Button, // Se importó Button
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import { OpenInNew, Facebook, Twitter, WhatsApp } from "@mui/icons-material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import RideChat from "./RideChat";
import { useUserAuth } from "../../context/userAuthContext";
import ParticipantsList from "./ParticipantsList"; // Importando el componente ParticipantsList
import RideDetails from "./RideInfo/RideDetails"; // Importando el nuevo componente RideDetails

const mapContainerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "16px",
  marginTop: "20px",
  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
};

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: "12px 24px",
  textTransform: "none",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  backgroundColor: "black",
  color: "white",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

// Definición de la función formatDateTime
const formatDateTime = (timestamp) => {
  if (!timestamp) {
    return "Invalid Date";
  }
  const date = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  return date.toLocaleString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const RideInfo = () => {
  const { rideId } = useParams();
  const { user } = useUserAuth();
  const [ride, setRide] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  });

  useEffect(() => {
    const fetchRide = async () => {
      const rideRef = doc(db, "Rides", rideId);
      const rideSnap = await getDoc(rideRef);
      if (rideSnap.exists()) {
        const data = rideSnap.data();
        const creator = await getUser(data.creator);
        const riders = await getRiders(data.riders);
        setRide({ ...data, creator, riders });
      } else {
        console.log("No such document!");
      }
    };

    fetchRide();
  }, [rideId]);

  const getUser = async (userId) => {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      return { ...userData, id: userSnap.id };
    } else {
      return null;
    }
  };

  const getRiders = async (ridersIds) => {
    const ridersList = [];
    for (const riderId of ridersIds) {
      const rider = await getUser(riderId);
      if (rider) {
        ridersList.push(rider);
      }
    }
    return ridersList;
  };

  const handleOpenInMaps = () => {
    if (ride.location) {
      const { latitude, longitude } = ride.location;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const handleShare = (platform) => {
    const { name, time, bikeType, creator } = ride;
    const text = `*Únete a la ruta* ${name} con ${
      creator.name
    } *Fecha:* ${formatDateTime(time)} Tipo de bicicleta: ${bikeType}`;

    let url;
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${
          window.location.href
        }&quote=${encodeURIComponent(text)}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          text
        )}&url=${window.location.href}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          text + " " + window.location.href
        )}`;
        break;
      default:
        return;
    }
    window.open(url, "_blank");
  };

  const handleJoinOrLeaveRide = async () => {
    const rideRef = doc(db, "Rides", rideId);
    const newRiders = ride.riders.map((rider) => rider.id);

    if (newRiders.includes(user.uid)) {
      await updateDoc(rideRef, {
        riders: arrayRemove(user.uid),
      });
      setRide((prevState) => ({
        ...prevState,
        riders: prevState.riders.filter((rider) => rider.id !== user.uid),
      }));
    } else {
      await updateDoc(rideRef, {
        riders: arrayUnion(user.uid),
      });
      const newUser = await getUser(user.uid);
      setRide((prevState) => ({
        ...prevState,
        riders: [...prevState.riders, newUser],
      }));
    }
  };

  if (!ride || !isLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "black",
        }}
      >
        Loading...
      </Box>
    );
  }

  const isUserRiding = ride.riders.some((rider) => rider.id === user.uid);

  return (
    <Container maxWidth="md" sx={{ py: 3 }}>
      {/* Usando el nuevo componente RideDetails */}
      <RideDetails ride={ride} user={user} rideId={rideId} />

      <Divider sx={{ my: 3 }} />

      <Typography
        variant="h6"
        sx={{ mb: 2, fontWeight: "bold", color: "black" }}
      >
        Participantes:
      </Typography>
      {/* Usando el componente ParticipantsList */}
      <ParticipantsList riders={ride.riders} />

      {user && (
        <StyledButton
          variant="contained"
          fullWidth
          onClick={handleJoinOrLeaveRide}
          sx={{ mt: 3 }}
        >
          {isUserRiding ? "Abandonar" : "Unirme"}
        </StyledButton>
      )}

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 3, fontStyle: "italic" }}
      >
        Publicado el: {formatDateTime(ride.postdate)}
      </Typography>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{
          lat: ride.location.latitude,
          lng: ride.location.longitude,
        }}
        zoom={14}
        options={{
          mapTypeId: "terrain",
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        <Marker
          position={{
            lat: ride.location.latitude,
            lng: ride.location.longitude,
          }}
        />
      </GoogleMap>

      <StyledButton
        variant="contained"
        fullWidth
        onClick={handleOpenInMaps}
        startIcon={<OpenInNew />}
        sx={{ mt: 3 }}
      >
        Abrir Ubicación
      </StyledButton>

      <Box
        mt={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledIconButton
          onClick={() => handleShare("facebook")}
          aria-label="share on Facebook"
        >
          <Facebook sx={{ color: "black" }} />
        </StyledIconButton>
        <StyledIconButton
          onClick={() => handleShare("twitter")}
          aria-label="share on Twitter"
        >
          <Twitter sx={{ color: "black" }} />
        </StyledIconButton>
        <StyledIconButton
          onClick={() => handleShare("whatsapp")}
          aria-label="share on WhatsApp"
        >
          <WhatsApp sx={{ color: "black" }} />
        </StyledIconButton>
      </Box>

      <Box mt={5}>
        <RideChat uid={rideId} />
      </Box>
    </Container>
  );
};

export default RideInfo;
