import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
  Box,
  Button,
  Rating,
  TextField,
  IconButton,
} from "@mui/material";
import { Phone as PhoneIcon, Search as SearchIcon } from "@mui/icons-material";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "200px",
  borderRadius: "10px",
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

const AllStores = () => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [nearestStore, setNearestStore] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchStores = async () => {
      const firestore = getFirestore();
      const storesCollection = collection(firestore, "stores");
      const storesSnapshot = await getDocs(storesCollection);
      const storesList = storesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStores(storesList);
      setLoading(false);
    };

    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error fetching user location:", error);
            setLoading(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLoading(false);
      }
    };

    fetchStores();
    fetchUserLocation();
  }, []);

  useEffect(() => {
    if (userLocation && stores.length > 0) {
      let nearest = stores[0];
      let minDistance = calculateDistance(
        userLocation.lat,
        userLocation.lng,
        stores[0].location.lat,
        stores[0].location.lng
      );

      stores.forEach((store) => {
        const distance = calculateDistance(
          userLocation.lat,
          userLocation.lng,
          store.location.lat,
          store.location.lng
        );
        if (distance < minDistance) {
          nearest = store;
          minDistance = distance;
        }
      });

      setNearestStore(nearest);
      setStores((prevStores) =>
        prevStores.filter((store) => store.id !== nearest.id)
      );
    }
  }, [userLocation, stores]);

  const filteredStores = stores.filter((store) =>
    store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" mb={3}>
        <TextField
          variant="outlined"
          placeholder="Buscar Tienda"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Typography variant="h4" gutterBottom>
        Todas las Tiendas
      </Typography>
      {userLocation && nearestStore && (
        <Box mb={3}>
          <Typography variant="h5" gutterBottom>
            Tienda Más Cercana:
          </Typography>
          <Card sx={{ mb: 3, borderRadius: 2 }}>
            <CardMedia
              component="img"
              height="200"
              image={nearestStore.storePhoto}
              alt={nearestStore.storeName}
              sx={{ borderRadius: "10px 10px 0 0" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <CardMedia
                    component="img"
                    height="80"
                    image={nearestStore.storeLogo}
                    alt={nearestStore.storeName}
                    sx={{ borderRadius: "10px", objectFit: "cover" }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6">{nearestStore.storeName}</Typography>
                  <Typography>{nearestStore.storeAddress}</Typography>
                  <Typography>{nearestStore.phoneNumber}</Typography>
                  <Rating
                    name="read-only"
                    value={nearestStore.rating || 4}
                    readOnly
                  />
                  <Box mt={2}>
                    <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={nearestStore.location}
                        zoom={15}
                        options={{
                          disableDefaultUI: true,
                          gestureHandling: "none",
                        }}
                      >
                        <Marker position={nearestStore.location} />
                      </GoogleMap>
                    </LoadScript>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    startIcon={<PhoneIcon />}
                    href={`tel:${nearestStore.phoneNumber}`}
                  >
                    Llamar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      <Grid container spacing={3}>
        {filteredStores.map((store) => (
          <Grid item xs={12} sm={6} key={store.id}>
            <Card sx={{ mb: 3, borderRadius: 2 }}>
              <CardMedia
                component="img"
                height="200"
                image={store.storePhoto}
                alt={store.storeName}
                sx={{ borderRadius: "10px 10px 0 0" }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <CardMedia
                      component="img"
                      height="80"
                      image={store.storeLogo}
                      alt={store.storeName}
                      sx={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h6">{store.storeName}</Typography>
                    <Typography>{store.storeAddress}</Typography>
                    <Typography>{store.phoneNumber}</Typography>
                    <Rating
                      name="read-only"
                      value={store.rating || 4}
                      readOnly
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      startIcon={<PhoneIcon />}
                      href={`tel:${store.phoneNumber}`}
                    >
                      Llamar
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AllStores;
