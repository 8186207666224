import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import DeleteIcon from "@mui/icons-material/Delete";

const MemberManager = () => {
  const { uid } = useParams();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const teamDocRef = doc(db, "Teams", uid);

    const unsubscribe = onSnapshot(teamDocRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const teamData = docSnapshot.data();
        if (teamData.members && teamData.members.length > 0) {
          const memberPromises = teamData.members.map(async (userUid) => {
            const userDocRef = doc(db, "Users", userUid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              return { uid: userUid, ...userDocSnap.data() };
            } else {
              console.error("No such user:", userUid);
              return null;
            }
          });
          const membersData = await Promise.all(memberPromises);
          setMembers(membersData.filter((member) => member !== null));
        } else {
          setMembers([]);
        }
      } else {
        console.error("No such team!");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  const handleOpenDialog = (member) => {
    setSelectedMember(member);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedMember(null);
    setDialogOpen(false);
  };

  const handleRemoveMember = async () => {
    if (!selectedMember) return;

    try {
      const teamRef = doc(db, "Teams", uid);
      await updateDoc(teamRef, {
        members: arrayRemove(selectedMember.uid),
      });
      setMembers((prevMembers) =>
        prevMembers.filter((member) => member.uid !== selectedMember.uid),
      );
      handleCloseDialog();
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Miembros
        </Typography>
        <List>
          {members.length > 0 ? (
            members.map((member) => (
              <ListItem
                key={member.uid}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f9f9f9",
                  borderRadius: 2,
                  mb: 1,
                  boxShadow: 1,
                }}
              >
                <ListItemAvatar>
                  <Avatar alt={member.name} src={member.photoUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={member.name}
                  secondary={member.nickName}
                  sx={{ mr: 2 }}
                />
                <Tooltip title="Eliminar">
                  <IconButton
                    color="secondary"
                    onClick={() => handleOpenDialog(member)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">
              No hay miembros en el equipo.
            </Typography>
          )}
        </List>
      </Paper>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{"Confirmar eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar a {selectedMember?.name} del
            equipo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemoveMember} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MemberManager;
