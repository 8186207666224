import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  useMediaQuery,
  useTheme,
  Divider,
  CardMedia,
} from "@mui/material";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import GroupsIcon from "@mui/icons-material/Groups";
import ChatIcon from "@mui/icons-material/Chat";

const formatDateTime = (timestamp) => {
  if (!timestamp) return "Invalid Date";
  const date = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const ProfileRides = ({ uid }) => {
  const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRides = async () => {
      setLoading(true);
      try {
        const ridesRef = collection(db, "Rides");
        const q = query(ridesRef, where("riders", "array-contains", uid));
        const querySnapshot = await getDocs(q);

        const userRides = await Promise.all(
          querySnapshot.docs.map(async (rideDoc) => {
            const rideData = rideDoc.data();

            // Obtener el documento del creador del ride
            const creatorDocRef = doc(db, "Users", rideData.creator);
            const creatorDoc = await getDoc(creatorDocRef);
            const creatorData = creatorDoc.exists() ? creatorDoc.data() : {};

            // Contar mensajes en la subcolección chat
            const chatRef = collection(db, "Rides", rideDoc.id, "chat");
            const chatSnapshot = await getDocs(chatRef);
            const messagesCount = chatSnapshot.size;

            return {
              id: rideDoc.id,
              ...rideData,
              messagesCount,
              creator: {
                name: creatorData.name || "Unknown",
                nickName: creatorData.nickName || "Unknown",
                photoUrl:
                  creatorData.photoUrl || "https://via.placeholder.com/150",
              },
            };
          })
        );

        setRides(userRides);
      } catch (error) {
        console.error("Error fetching user rides:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRides();
  }, [uid]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (rides.length === 0) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", mt: 4, color: "text.secondary" }}
      >
        No se encontraron rides en los que hayas participado.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        gap: isMobile ? 2 : isTablet ? 2.5 : 3,
        justifyContent: "center",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        },
        padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
      }}
    >
      {rides.map((ride, index) => (
        <Card
          key={index}
          elevation={0}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            overflow: "hidden",
            transition:
              "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Añadir sombra
            "&:hover": {
              transform: "translateY(-4px)",
              boxShadow: "0 12px 24px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <CardActionArea onClick={() => navigate(`/ride/${ride.id}`)}>
            <Box
              sx={{
                padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f8f9fa",
              }}
            >
              <Box display="flex" alignItems="center" mb={isMobile ? 2 : 3}>
                <Avatar
                  sx={{
                    width: isMobile ? 48 : 56,
                    height: isMobile ? 48 : 56,
                  }}
                  src={ride.creator.photoUrl}
                  alt={ride.creator.name}
                />
                <Box ml={2}>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    component="div"
                    noWrap
                    sx={{ fontWeight: 600 }}
                  >
                    {ride.creator.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    @{ride.creator.nickName}
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant={isMobile ? "h5" : "h4"}
                component="div"
                gutterBottom
                noWrap
                sx={{ fontWeight: 700, color: "#000" }}
              >
                {ride.name}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <DirectionsBikeIcon fontSize="small" color="action" />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    {ride.bikeType}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body2" color="text.secondary">
                    {formatDateTime(ride.time)}
                  </Typography>
                </Box>
              </Box>
              {ride.imageUrl && (
                <CardMedia
                  component="img"
                  sx={{
                    marginTop: 2,
                    borderRadius: 2,
                    objectFit: "cover",
                    height: isMobile ? 140 : isTablet ? 180 : 220,
                  }}
                  image={ride.imageUrl}
                  alt={ride.name}
                />
              )}
            </Box>
            <CardContent
              sx={{
                padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
                backgroundColor: "#f8f9fa",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <GroupsIcon fontSize="medium" color="action" />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {ride.riders.length}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <ChatIcon fontSize="medium" color="action" />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {ride.messagesCount}
                </Typography>
              </Box>
            </CardContent>
            <Divider
              sx={{ borderColor: "rgba(0, 0, 0, 0.1)", borderWidth: "1px" }}
            />
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
};

export default ProfileRides;
