import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";

const Failure = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center">
        Pago Fallido
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Hubo un problema al procesar tu pago. Por favor, intenta nuevamente.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/home")}
        fullWidth
      >
        Volver al Inicio
      </Button>
    </Container>
  );
};

export default Failure;
