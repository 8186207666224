import React from "react";
import { Typography, Box } from "@mui/material";

const SpeedCoins = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Qué son los SpeedCoins?
      </Typography>
      <Typography variant="body1" paragraph>
        Los SpeedCoins son la moneda virtual en Livespeed. Aquí aprenderás cómo
        ganarlos y utilizarlos.
      </Typography>
      {/* Aquí puedes agregar más contenido detallado sobre los SpeedCoins */}
    </Box>
  );
};

export default SpeedCoins;
