import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Avatar, Button } from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const Upgrades = () => {
  const { user } = useUserAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserInfo(userSnap.data());
        } else {
          setUserInfo(null);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [user]);

  if (loading) {
    return <Typography variant="body1">Cargando...</Typography>;
  }

  const isCompleteProfile =
    userInfo &&
    userInfo.name &&
    userInfo.nickName &&
    userInfo.phoneNumber &&
    userInfo.country &&
    userInfo.state;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: "white",
        color: "black",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Informacion de tu perfil:
      </Typography>
      {userInfo && (
        <Box sx={{ mt: 2 }}>
          <Avatar
            src={userInfo.photoUrl}
            alt={userInfo.name}
            sx={{
              width: 100,
              height: 100,
              mb: 2,
              mx: "auto",
            }}
          />
          <Typography variant="h6">{userInfo.name}</Typography>
          <Typography variant="body2">@{userInfo.nickName}</Typography>
          <Typography variant="body2">{userInfo.phoneNumber}</Typography>
          <Typography variant="body2">
            {userInfo.country}, {userInfo.state}
          </Typography>
        </Box>
      )}
      {!isCompleteProfile && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          Completa tus datos para poder continuar.
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        disabled={!isCompleteProfile}
      >
        Comenzar a explorar Livespeed
      </Button>
    </Paper>
  );
};

export default Upgrades;
