export const riderss = [
  {
    tag: 1,
    name: "ISAAC MORALES DAVILA",
    category: "AVANZADOS",
    team: "SAVAGE BLOOD",
  },
  {
    tag: 38,
    name: "JOSE SERGIO GIL SAMANIEGO GUTIERREZ",
    category: "INTERMEDIOS",
    team: "MASTER BIKES",
  },
  {
    tag: 0,
    name: "MIGUEL ZATARAIN",
    category: "40+",
    team: "SOLOVINOS MTB",
  },
  {
    tag: 39,
    name: "REYNALDO TRUJILLO",
    category: "INTERMEDIOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 2,
    name: "JORGE EDUARDO CASTRO ARANDA",
    category: "AVANZADOS",
    team: "GALAXY",
  },
  {
    tag: 15,
    name: "APOLINAR GALVAN",
    category: "E-BIKE",
    team: "SOLOVINOS MTB",
  },
  {
    tag: 40,
    name: "GERMAN EDUARDO GARCIA GUEVARA",
    category: "INTERMEDIOS",
    team: "SIN EQUIPO",
  },
  {
    tag: 28,
    name: "MOISES FRANCISCO LARRAIAGA ALVARES",
    category: "40+",
    team: "HUATSONBIKE",
  },
  {
    tag: 34,
    name: "MAGDA LUZ MENDOZA DIOSDADO",
    category: "FEMENIL",
    team: "SIN MIEDO",
  },
  {
    tag: 67,
    name: "HUGO GAMBOA",
    category: "PRINCIPIANTE",
    team: "APSOM MTB",
  },
  {
    tag: 41,
    name: "OTHONIEL CAZARES",
    category: "INTERMEDIOS",
    team: "ENDURO & DOWNHILL MAFIA",
  },
  {
    tag: 3,
    name: "ALAN FERNANDO MARISCAL MONGE",
    category: "AVANZADOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 16,
    name: "FERNANDO VILLALOBOS MENDOZA",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 17,
    name: "AQUILINO FUERTE",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 29,
    name: "DANIEL ROSAS MANRIQUEZ",
    category: "40+",
    team: "INDEPENDIENTE",
  },
  {
    tag: 42,
    name: "GUSTAVO ASTOLFO MENDIVIL LAGARGA",
    category: "INTERMEDIOS",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 18,
    name: "ALEJANDRO URREA MEXIA",
    category: "E-BIKE",
    team: "OXIGENADOS",
  },
  {
    tag: 43,
    name: "ROBERTO ROSS",
    category: "INTERMEDIOS",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 44,
    name: "FRANCISCO JAVIER ZAZUETA",
    category: "INTERMEDIOS",
    team: "SIN EQUIPO",
  },
  {
    tag: 4,
    name: "TADEO YEOMANS VEJAR",
    category: "AVANZADOS",
    team: "STONERS MTB",
  },
  {
    tag: 19,
    name: "WILL GRANA",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 20,
    name: "FERNANDO SANTINI",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 45,
    name: "LUIS CARLOS ROSS MOLINA",
    category: "INTERMEDIOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 46,
    name: "ALEJANDRO SAMANIEGO",
    category: "INTERMEDIOS",
    team: "BAINKERST",
  },
  {
    tag: 35,
    name: "GRECIA GARCIA",
    category: "FEMENIL",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 5,
    name: "PEDRO VALENZUELA",
    category: "AVANZADOS",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 36,
    name: "SOFIA RUY SANCHEZ",
    category: "FEMENIL",
    team: "CAPITAL FLOW",
  },
  {
    tag: 47,
    name: "MARCOS FEUCHTER",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 48,
    name: "GILDARDO FLORES SUROVOV",
    category: "INTERMEDIOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 49,
    name: "ANDRES AGUILERA",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 6,
    name: "ANDRES BOUVET",
    category: "AVANZADOS",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 7,
    name: "MAURICIO BOUVET",
    category: "AVANZADOS",
    team: "LOBO AVENTURISMO",
  },
  {
    tag: 50,
    name: "MALAYO PAZ",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 68,
    name: "LUIS ALVAREZ",
    category: "PRINCIPIANTE",
    team: "PATOS",
  },
  {
    tag: 30,
    name: "ALONSO GALAVIZ",
    category: "40+",
    team: "SIN MIEDO",
  },
  {
    tag: 51,
    name: "ERNESTO BOUVET",
    category: "INTERMEDIOS",
    team: "NADECI",
  },
  {
    tag: 8,
    name: "CESAR LEAL DIAZ",
    category: "AVANZADOS",
    team: "GALAXY",
  },
  {
    tag: 9,
    name: "JESUS ACUÑA",
    category: "AVANZADOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 69,
    name: "KOKE OLIVARRIA",
    category: "PRINCIPIANTE",
    team: "PATOS",
  },
  {
    tag: 52,
    name: "LUIS MANUEL DEL GRANDE",
    category: "INTERMEDIOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 53,
    name: "FRANK ROMO",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 10,
    name: "JUAN LUIS GUTIERREZ",
    category: "AVANZADOS",
    team: "HUATSONBIKE",
  },
  {
    tag: 54,
    name: "FERNANDO MUGUERZA",
    category: "INTERMEDIOS",
    team: "PAPOVSKYS",
  },
  {
    tag: 11,
    name: "JULIO CESAR GONZALEZ LOPEZ",
    category: "AVANZADOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 21,
    name: "ALAN FERNANDO GONZALEZ LOPEZ",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 70,
    name: "JUAN CAMILO GONZALEZ LOPEZ",
    category: "PRINCIPIANTE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 55,
    name: "JAVIER ARREOLA",
    category: "INTERMEDIOS",
    team: "SPEED BIKES",
  },
  {
    tag: 22,
    name: "CESAR TORRES",
    category: "E-BIKE",
    team: "STONERS MTB",
  },
  {
    tag: 71,
    name: "REYES JESUS LEYVA VERDUZCO",
    category: "PRINCIPIANTE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 23,
    name: "JORGE MARIO LOPEZ",
    category: "E-BIKE",
    team: "OXIGENADOS",
  },
  {
    tag: 72,
    name: "ARON VERDUZCO",
    category: "PRINCIPIANTE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 56,
    name: "HIRAM VERDUZCO",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 57,
    name: "BRAULIO MORALES",
    category: "INTERMEDIOS",
    team: "OSMAR PRINT",
  },
  {
    tag: 12,
    name: "HECTOR MALDONADO",
    category: "AVANZADOS",
    team: "STONERS MTB",
  },
  {
    tag: 73,
    name: "JORGE MARIO LOPEZ JR",
    category: "PRINCIPIANTE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 74,
    name: "HUMBERTO HERNADEZ",
    category: "PRINCIPIANTE",
    team: "SONORABIKES",
  },
  {
    tag: 75,
    name: "ALVARO HERNANDEZ HDZ",
    category: "PRINCIPIANTE",
    team: "SONORABIKES",
  },
  {
    tag: 58,
    name: "MIGUEL ANTONIO VALENZUELA",
    category: "INTERMEDIOS",
    team: "WORKSHOP",
  },
  {
    tag: 24,
    name: "OSCAR VElDERRAIN",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 59,
    name: "RODRIGO ARZAC",
    category: "INTERMEDIOS",
    team: "NUEVO ORDEN",
  },
  {
    tag: 60,
    name: "JONATHAN GARCIA",
    category: "INTERMEDIOS",
    team: "NUEVO ORDEN",
  },
  {
    tag: 61,
    name: "IVAN JIMENEZ",
    category: "INTERMEDIOS",
    team: "NUEVO ORDEN",
  },
  {
    tag: 62,
    name: "ALFONSO CELIS",
    category: "INTERMEDIOS",
    team: "NUEVO ORDEN",
  },
  {
    tag: 63,
    name: "VICENTE TIRADO",
    category: "INTERMEDIOS",
    team: "NUEVO ORDEN",
  },
  {
    tag: 25,
    name: "RODOLFO MORENO",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 64,
    name: "JUAN  JOSE GUTIERREZ SALIDO",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 65,
    name: "HUGO MENDIVIL",
    category: "INTERMEDIOS",
    team: "CAPITAL FLOW",
  },
  {
    tag: 31,
    name: "JOSE LUIS BOJORQUEZ CANTORAL",
    category: "40+",
    team: "DBW",
  },
  {
    tag: 26,
    name: "CARLOS MIRANDA",
    category: "E-BIKE",
    team: "SIN EQUIPO",
  },
  {
    tag: 66,
    name: "OSIRIS ALMANZA GARCIA",
    category: "INTERMEDIOS",
    team: "EDHM",
  },
  {
    tag: 37,
    name: "CECY OSUNA",
    category: "FEMENIL",
    team: "CAPITAL FLOW",
  },
  {
    tag: 27,
    name: "ADRIAN CUEVAS",
    category: "E-BIKE",
    team: "CAPITAL FLOW",
  },
  {
    tag: 13,
    name: "VICTOR TERREROS",
    category: "AVANZADOS",
    team: "Alamos Adventure",
  },
  {
    tag: 14,
    name: "ANGEL TIRADO",
    category: "AVANZADOS",
    team: "SI",
  },
  {
    tag: 32,
    name: "JORGE ORTEGA",
    category: "40+",
    team: "PEM",
  },
  {
    tag: 33,
    name: "FERNANDO ORTEGA",
    category: "40+",
    team: "PEM",
  },
  {
    tag: 76,
    name: "ARTURO ACEVEZ",
    category: "E-BIKE",
    team: "SPEED BIKES",
  },
  {
    tag: 77,
    name: "SEBASTIAN CORRAL",
    category: "PRINCIPIANTE",
    team: "HUATSONBIKE",
  },
  {
    tag: 78,
    name: "RODRIGO PEREZ TAMAYO",
    category: "AVANZADOS",
    team: "STONERS MTB",
  },
  {
    tag: 79,
    name: "ANGEL ALBERTO TIRADO GRACIA",
    category: "AVANZADOS",
    team: "SAVAGE BLOOD",
  },
  {
    tag: 80,
    name: "JOSE ANGEL MARTINEZ",
    category: "AVANZADOS",
    team: "GREEN HOUSE",
  },
  {
    tag: 81,
    name: "ORLANDO GARCIA",
    category: "AVANZADOS",
    team: "RIPPER GANG",
  },
];
