import React, { useState, useEffect } from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase"; // Ruta correcta

// Estilo para el contenedor del mapa
const mapContainerStyle = {
  width: "100%",
  height: "60vh", // Altura adecuada para pantallas grandes
  borderRadius: "20px", // Bordes redondeados
};

// Estilo personalizado del mapa
const customMapStyle = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [{ saturation: -100 }, { lightness: 50 }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ visibility: "on" }, { lightness: 20 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { lightness: 20 }],
  },
];

const ReceiverMapLocation = () => {
  const [receivers, setReceivers] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [satelliteView, setSatelliteView] = useState(false);

  // Cargar la API de Google Maps
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g", // Reemplaza con tu API Key
    libraries: ["places"],
  });

  const navigate = useNavigate();

  // Obtener los receivers de Firebase y calcular el centro del mapa
  useEffect(() => {
    const fetchReceivers = async () => {
      try {
        const receiversCollection = collection(db, "Receivers");
        const receiverSnapshot = await getDocs(receiversCollection);
        const receiversData = receiverSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReceivers(receiversData);

        // Calcular el centro entre los receivers
        if (receiversData.length >= 2) {
          const totalLat = receiversData.reduce(
            (sum, receiver) => sum + (receiver?.Location?.lat || 0),
            0
          );
          const totalLng = receiversData.reduce(
            (sum, receiver) => sum + (receiver?.Location?.lng || 0),
            0
          );
          const avgLat = totalLat / receiversData.length;
          const avgLng = totalLng / receiversData.length;
          setMapCenter({ lat: avgLat, lng: avgLng });
        } else if (receiversData.length === 1) {
          setMapCenter({
            lat: receiversData[0]?.Location?.lat || 0,
            lng: receiversData[0]?.Location?.lng || 0,
          });
        }
      } catch (error) {
        console.error("Error fetching receivers: ", error);
      }
    };

    fetchReceivers();
  }, []);

  // Navegar a la página del receiver al hacer clic en un marcador
  const handleMarkerClick = (uid) => {
    navigate(`/receiver/${uid}`);
  };

  // Cambiar entre vista satélite y mapa normal
  const handleSatelliteToggle = () => {
    setSatelliteView((prev) => !prev);
  };

  // Mostrar errores de carga del mapa
  if (loadError) {
    return <Typography>Error al cargar el mapa.</Typography>;
  }

  // Mostrar mensaje de carga si el mapa no está listo
  if (!isLoaded) {
    return <Typography>Cargando mapa...</Typography>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      {/* Alternador para vista satelital */}
      <FormControlLabel
        control={
          <Switch
            checked={satelliteView}
            onChange={handleSatelliteToggle}
            color="primary"
          />
        }
        label="Vista Satélite"
        sx={{ mb: 2 }}
      />

      {/* Mapa de Google */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={mapCenter}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          mapTypeId: satelliteView ? "satellite" : "roadmap",
          styles: !satelliteView ? customMapStyle : null,
        }}
      >
        {/* Colocar marcadores en el mapa */}
        {receivers.map((receiver) => {
          if (!receiver?.Location) return null;

          const markerColor = receiver.Type === "0" ? "green" : "red";
          const position = {
            lat: receiver.Location.lat,
            lng: receiver.Location.lng,
          };

          return (
            <Marker
              key={receiver.id}
              position={position}
              onClick={() => handleMarkerClick(receiver.id)}
              title={receiver.id}
              label={{
                text: receiver.id,
                color: "#000",
                fontSize: "10px",
                fontWeight: "bold",
              }}
              icon={{
                path: "M10 0 C5 0 0 5 0 10 C0 15 5 20 10 20 C15 20 20 15 20 10 C20 5 15 0 10 0 z",
                scale: 0.6,
                fillColor: markerColor,
                fillOpacity: 1,
                strokeColor: "#000",
                strokeWeight: 2,
              }}
            />
          );
        })}
      </GoogleMap>

      {/* Lista de receivers debajo del mapa */}
      <Box
        sx={{
          width: "100%",
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", textTransform: "uppercase", mb: 2 }}
        >
          Lista de Receivers
        </Typography>

        {receivers.map((receiver) => (
          <Box
            key={receiver.id}
            sx={{
              width: "80%",
              padding: "16px",
              marginBottom: "10px",
              backgroundColor: "#f5f5f5",
              borderRadius: "12px",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={() => handleMarkerClick(receiver.id)}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {receiver.id} - {receiver.Type === "0" ? "Start" : "Finish"}
            </Typography>
            <Typography variant="body1">
              {receiver?.Location
                ? `Coordenadas: ${receiver.Location.lat}, ${receiver.Location.lng}`
                : "Sin ubicación"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReceiverMapLocation;
