import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { collection, addDoc, doc } from "firebase/firestore";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  pic: Yup.string().url("Debe ser una URL válida").required("Requerido"),
  price: Yup.number()
    .required("Requerido")
    .positive("Debe ser un número positivo"),
  size: Yup.array().of(Yup.string()).required("Requerido"),
  type: Yup.string().required("Requerido"),
  stock: Yup.number()
    .required("Requerido")
    .positive("Debe ser un número positivo")
    .integer("Debe ser un número entero"),
  description: Yup.string().required("Requerido"),
});

const CreateAddon = ({ eventId }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const eventDocRef = doc(db, "events", eventId);
      const addonsCollectionRef = collection(eventDocRef, "addons");
      await addDoc(addonsCollectionRef, values);
      setSubmitting(false);
      handleClose();
    } catch (error) {
      console.error("Error adding document: ", error);
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Crear Addon
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Crear Addon al evento
          </Typography>
          <Formik
            initialValues={{
              pic: "",
              price: "",
              size: [],
              type: "",
              stock: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label="URL de la Imagen"
                    name="pic"
                    value={values.pic}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Precio"
                    name="price"
                    type="number"
                    value={values.price}
                    onChange={handleChange}
                  />
                  <InputLabel>Tamaño</InputLabel>
                  <Select
                    fullWidth
                    multiple
                    name="size"
                    value={values.size}
                    onChange={handleChange}
                  >
                    {["S", "M", "L", "XL"].map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    fullWidth
                    label="Tipo"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Stock"
                    name="stock"
                    type="number"
                    value={values.stock}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Descripción"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Crear Addon"
                    )}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Paper>
      </Modal>
    </Box>
  );
};

export default CreateAddon;
