import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  CircularProgress,
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import PeopleIcon from "@mui/icons-material/People";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import EventIcon from "@mui/icons-material/Event";

const ParkDashboard = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [park, setPark] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPark = async () => {
      const parkRef = doc(db, "Parks", uid);
      try {
        const parkSnap = await getDoc(parkRef);
        if (parkSnap.exists()) {
          setPark(parkSnap.data());
        } else {
          console.log("No such park!");
        }
      } catch (error) {
        console.error("Error fetching park:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPark();
  }, [uid]);

  const menuItems = [
    { name: "Editar Parque", icon: <EditIcon />, link: `/editpark/${uid}` },
    {
      name: "Crear Pistas",
      icon: <AddRoadIcon />,
      link: `/createtrack/${uid}`,
    },
    {
      name: "Administradores",
      icon: <PeopleIcon />,
      link: `/manageAdmins/${uid}`,
    },
    {
      name: "Anuncios a la Comunidad",
      icon: <AnnouncementIcon />,
      link: `/communityAnnouncements/${uid}`,
    },
    { name: "Eventos", icon: <EventIcon />, link: `/events/${uid}` },
  ];

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!park) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom>
          Parque no encontrado
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom align="center">
        {park.ParkName}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {menuItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.name}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: 150,
                backgroundColor: "#000",
                borderRadius: "5%",
                boxShadow: 3,
                transition: "0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              onClick={() => navigate(item.link)}
            >
              <IconButton
                sx={{
                  color: "#fff",
                  "& .MuiSvgIcon-root": {
                    fontSize: 40,
                  },
                }}
              >
                {item.icon}
              </IconButton>
              <Typography
                variant="button"
                display="block"
                sx={{ color: "#fff" }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ParkDashboard;
