import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Avatar,
  IconButton,
  Paper,
  Button,
  CircularProgress,
  useTheme,
  styled,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  DirectionsBike as DirectionsBikeIcon,
  LocalOffer as LocalOfferIcon,
  Groups as GroupsIcon,
  Build as BuildIcon,
  Navigation as NavigationIcon,
  CalendarToday as CalendarTodayIcon,
  Notifications as NotificationsIcon,
  CardGiftcard,
  GroupAdd,
  HelpOutline,
  Security,
  Translate,
  QrCode2,
} from "@mui/icons-material";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import ParkIcon from "@mui/icons-material/Park";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  padding: theme.spacing(1.5, 3),
  textTransform: "none",
  fontWeight: 600,
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  width: "100%",
  textAlign: "left",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const routes = [
  { label: "Editar Profile", path: "/addProfile", icon: <AccountCircleIcon /> },
  { label: "BikeGarage", path: "/MyBikes", icon: <DirectionsBikeIcon /> },
  { label: "Mis Tags", path: "/MyTags", icon: <LocalOfferIcon /> },
  { label: "Mis Equipos", path: "/MyTeams", icon: <GroupsIcon /> },
  { label: "Mis WorkShops", path: "/myworkshop", icon: <BuildIcon /> },
  { label: "Live Location", path: "/livelocation", icon: <NavigationIcon /> },
  { label: "Mis eventos", path: "/my-events", icon: <CalendarTodayIcon /> },
  { label: "Mis Tickets", path: "/tickets", icon: <QrCode2 /> },
];

const UserMenu = () => {
  const { user, loading } = useUserAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (!loading && !user) {
      navigate("/login");
    } else if (user) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });

      return () => unsubscribe();
    }
  }, [user, loading, navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <StyledPaper elevation={0}>
        <Box sx={{ p: 3, bgcolor: "#bcbcbc", color: "white" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Link
                to={`/user/${user?.uid}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Avatar
                  src={userInfo?.photoUrl || "https://via.placeholder.com/40"}
                  alt={userInfo?.name || "User Name"}
                  sx={{
                    width: 80,
                    height: 80,
                    mr: 2,
                    border: "3px solid white",
                  }}
                />
              </Link>
              <Box>
                <Link
                  to={`/user/${user?.uid}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {userInfo?.name || "User Name"}
                  </Typography>
                </Link>
                <Typography variant="body2">
                  {userInfo?.verified
                    ? "Verified User"
                    : "Complete your profile"}
                </Typography>
              </Box>
            </Box>
            <IconButton sx={{ color: "white" }}>
              <NotificationsIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ p: 3 }}>
          {routes.map((route, index) => (
            <MenuButton
              key={index}
              component={Link}
              to={route.path}
              startIcon={route.icon}
              color="inherit"
            >
              {route.label}
            </MenuButton>
          ))}
        </Box>
      </StyledPaper>

      <StyledPaper elevation={0}>
        <Typography
          variant="h6"
          sx={{ p: 2, bgcolor: theme.palette.grey[100] }}
        >
          Creditos
        </Typography>
        <Box sx={{ p: 2 }}>
          <MenuButton
            onClick={() => handleNavigation("/wallet")}
            startIcon={<CardGiftcard />}
            color="inherit"
          >
            Wallet
          </MenuButton>
          <MenuButton
            onClick={() => handleNavigation("/invite-friend")}
            startIcon={<GroupAdd />}
            color="inherit"
          >
            Invita a un amigo
          </MenuButton>
        </Box>
      </StyledPaper>
      <StyledPaper elevation={0}>
        <Typography
          variant="h6"
          sx={{ p: 2, bgcolor: theme.palette.grey[100] }}
        >
          Managment
        </Typography>
        <Box sx={{ p: 2 }}>
          <MenuButton
            onClick={() => handleNavigation("/misreceivers")}
            startIcon={<TapAndPlayIcon />}
            color="inherit"
          >
            Mis Receivers
          </MenuButton>
          <MenuButton
            onClick={() => handleNavigation("/security-help")}
            startIcon={<ParkIcon />}
            color="inherit"
          >
            Mis Parques
          </MenuButton>
        </Box>
      </StyledPaper>

      <StyledPaper elevation={0}>
        <Typography
          variant="h6"
          sx={{ p: 2, bgcolor: theme.palette.grey[100] }}
        >
          Asistencia
        </Typography>
        <Box sx={{ p: 2 }}>
          <MenuButton
            onClick={() => handleNavigation("/documentacion")}
            startIcon={<HelpOutline />}
            color="inherit"
          >
            Documetacion
          </MenuButton>
          <MenuButton
            onClick={() => handleNavigation("/documentacion")}
            startIcon={<Security />}
            color="inherit"
          >
            Verificaciones
          </MenuButton>
        </Box>
      </StyledPaper>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StyledButton startIcon={<Translate />} variant="outlined">
          Español (MX)
        </StyledButton>
        <StyledButton variant="outlined">$ MXN</StyledButton>
      </Box>
    </Container>
  );
};

export default UserMenu;
