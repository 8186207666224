import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 10,
        mx: 6,
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        Contactanos para vivir una nueva experiencia.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "sm",
          mx: 0,
          textAlign: "center",
          py: 3,
          color: "#7b7b7b",
        }}
      >
        Nos comprometemos a ofrecerte una experiencia profesional y
        satisfactoria para mejorar tu rendimiento y disfrutar de tu pasión. Si
        deseas obtener más información sobre nuestros productos y servicios, haz
        clic en el botón de abajo.
      </Typography>
      <Button
        component={Link}
        to="/contact"
        variant="contained"
        type="submit"
        size="medium"
        sx={{
          fontSize: "0.9rem",
          textTransform: "capitalize",
          py: 2,
          px: 4,
          mt: 3,
          mb: 2,
          borderRadius: 0,
          backgroundColor: "#14192d",
          "&:hover": {
            backgroundColor: "#1e2a5a",
          },
        }}
      >
        Enviar Mensaje.
      </Button>
    </Stack>
  );
};

export default GetInTouch;
