import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import GroupsIcon from "@mui/icons-material/Groups";
import ChatIcon from "@mui/icons-material/Chat";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const mapContainerStyle = {
  width: "100%",
  height: "200px", // Incrementar altura del mapa para ordenadores
  overflow: "hidden",
};

const mapOptions = {
  styles: [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ],
  disableDefaultUI: true,
  draggable: false,
  zoomControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
};

const formatDateTime = (timestamp) => {
  if (!timestamp) return "Invalid Date";
  const date = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const RideCard = ({ ride, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "16px",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Añadir sombra
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <CardActionArea onClick={onClick}>
        <Box
          sx={{
            padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f8f9fa",
          }}
        >
          <Box display="flex" alignItems="center" mb={isMobile ? 2 : 3}>
            <Avatar
              sx={{
                width: isMobile ? 48 : 56,
                height: isMobile ? 48 : 56,
              }}
              src={ride.creator?.photoUrl}
              alt={ride.creator?.name}
            />
            <Box ml={2}>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                component="div"
                noWrap
                sx={{ fontWeight: 600 }}
              >
                {ride.creator?.name || "Unknown"}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                @{ride.creator?.nickName || "Unknown"}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            component="div"
            gutterBottom
            noWrap
            sx={{ fontWeight: 700, color: "#000" }}
          >
            {ride.name}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <AccessTimeIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {formatDateTime(ride.time)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#757575",
                borderRadius: "12px",
                padding: "4px 8px",
                color: "white",
                fontSize: isMobile ? "0.75rem" : "0.875rem",
                fontWeight: "600",
              }}
            >
              <DirectionsBikeIcon
                sx={{ fontSize: "1rem", marginRight: "4px" }}
              />
              <Typography
                variant="caption"
                sx={{ fontFamily: "'Roboto', sans-serif" }}
              >
                {ride.bikeType}
              </Typography>
            </Box>
          </Box>
        </Box>
        {ride.location && (
          <Box
            sx={{
              width: "100%",
              height: isMobile ? "180px" : isTablet ? "200px" : "220px",
            }}
          >
            <GoogleMap
              mapContainerStyle={{
                ...mapContainerStyle,
                height: isMobile ? "180px" : isTablet ? "200px" : "220px",
              }}
              center={{
                lat: ride.location.latitude,
                lng: ride.location.longitude,
              }}
              zoom={11}
              options={mapOptions}
            >
              <Marker
                position={{
                  lat: ride.location.latitude,
                  lng: ride.location.longitude,
                }}
                icon={{
                  url: "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/icons%2Fubicacion.png?alt=media&token=3d9979cf-7f87-43ee-b74f-bf6bc65fda74",
                  scaledSize: new window.google.maps.Size(
                    isMobile ? 36 : 48,
                    isMobile ? 36 : 48
                  ),
                }}
              />
            </GoogleMap>
          </Box>
        )}
        <CardContent
          sx={{
            padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
            backgroundColor: "#f8f9fa",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <GroupsIcon fontSize="medium" color="action" />
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontWeight: 600 }}
            >
              {ride.riders.length}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <ChatIcon fontSize="medium" color="action" />
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontWeight: 600 }}
            >
              {ride.messagesCount}
            </Typography>
          </Box>
          {ride.distance !== null && (
            <Box display="flex" alignItems="center" gap={1}>
              <LocationOnIcon fontSize="medium" color="action" />
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ fontWeight: 600 }}
              >
                {ride.distance.toFixed(1)} km
              </Typography>
            </Box>
          )}
        </CardContent>
        <Divider
          sx={{ borderColor: "rgba(0, 0, 0, 0.1)", borderWidth: "1px" }}
        />
      </CardActionArea>
    </Card>
  );
};

const RidesList = ({ rides, handleRideClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Box
      sx={{
        display: "grid",
        gap: isMobile ? 2 : isTablet ? 2.5 : 3,
        justifyContent: "center",
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        },
        padding: isMobile ? "16px" : isTablet ? "20px" : "24px",
      }}
    >
      {rides.map((ride, index) => (
        <RideCard
          key={index}
          ride={ride}
          onClick={() => handleRideClick(ride.id)}
        />
      ))}
    </Box>
  );
};

export default RidesList;
