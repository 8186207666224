import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Box,
  Divider,
} from "@mui/material";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import QRCode from "qrcode.react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const AllReceivers = () => {
  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState({});
  const [ownerNames, setOwnerNames] = useState({});

  const mapContainerStyle = {
    height: "300px",
    width: "100%",
  };

  const fetchParks = async () => {
    const parksQuery = await getDocs(collection(db, "Parks"));
    let parksData = {};
    parksQuery.forEach((doc) => {
      parksData[doc.id] = doc.data();
    });
    setParks(parksData);
  };

  const fetchOwnerNames = async (owners) => {
    let ownerNamesTemp = {};
    for (const ownerUid of owners) {
      const userDoc = await getDoc(doc(db, "Users", ownerUid));
      if (userDoc.exists()) {
        ownerNamesTemp[ownerUid] =
          userDoc.data().name || "Nombre no disponible";
      } else {
        ownerNamesTemp[ownerUid] = "Nombre no disponible";
      }
    }
    return ownerNamesTemp;
  };

  useEffect(() => {
    const fetchReceivers = async () => {
      const receiversQuery = await getDocs(collection(db, "Receivers"));
      const receiversData = receiversQuery.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Buscar los nombres de los owners para cada receiver
      for (const receiver of receiversData) {
        if (receiver.Owners && receiver.Owners.length > 0) {
          const names = await fetchOwnerNames(receiver.Owners);
          setOwnerNames((prev) => ({ ...prev, ...names }));
        }
      }

      setReceivers(receiversData);
      setLoading(false);
    };

    fetchReceivers();
    fetchParks();
  }, []);

  const downloadQRCode = (receiverId) => {
    const canvas = document.getElementById(`qr-${receiverId}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${receiverId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Lista de Receivers
      </Typography>
      <Grid container spacing={4}>
        {receivers.map((receiver) => {
          const parkName =
            parks[receiver.ParkID]?.ParkName || "Parque desconocido";

          return (
            <Grid item xs={12} key={receiver.id}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Receiver UID: {receiver.id}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Typography variant="body1">
                      <strong>Tipo:</strong>{" "}
                      {receiver.Type === "0" ? "StartTime" : "FinishTime"}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Parque:</strong> {parkName}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Owners:</strong>
                    </Typography>
                    <ul>
                      {receiver.Owners?.map((ownerUid) => (
                        <li key={ownerUid}>
                          {ownerNames[ownerUid]
                            ? `${ownerNames[ownerUid]} (${ownerUid})`
                            : "Cargando..."}
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      Código QR del UID:
                    </Typography>
                    <QRCode
                      id={`qr-${receiver.id}`}
                      value={receiver.id}
                      size={150}
                      level={"H"}
                      includeMargin={true}
                    />
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() => downloadQRCode(receiver.id)}
                    >
                      Descargar QR
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">Ubicación del Receiver</Typography>
                {receiver.Location ? (
                  <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: receiver.Location.lat,
                        lng: receiver.Location.lng,
                      }}
                      zoom={15}
                    >
                      <Marker
                        position={{
                          lat: receiver.Location.lat,
                          lng: receiver.Location.lng,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                ) : (
                  <Typography variant="body1">
                    Ubicación no disponible
                  </Typography>
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default AllReceivers;
