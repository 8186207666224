import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Avatar,
  Typography,
  CardMedia,
  Stack,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  LocationOn,
  AccessTime,
  Edit,
  DirectionsBike,
  Public,
  Lock,
} from "@mui/icons-material";

const RideDetails = ({ ride, user, rideId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formatDateTime = (timestamp) => {
    if (!timestamp) {
      return "Invalid Date";
    }
    const date = new Date(
      timestamp.seconds ? timestamp.seconds * 1000 : timestamp
    );
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardMedia
        component="img"
        height={isMobile ? "200px" : "400px"}
        image={ride.imageUrl || "/logo512.png"}
        alt={ride.name}
        sx={{
          objectFit: "cover",
          width: "100%",
          borderBottom: "5px solid #000",
        }}
      />
      <Box sx={{ p: isMobile ? 2 : 4 }}>
        <Typography
          variant={isMobile ? "h5" : "h3"}
          gutterBottom
          sx={{ fontWeight: "bold", color: "#333" }}
        >
          {ride.name}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box
            display="flex"
            alignItems="center"
            component={Link}
            to={`/user/${ride.creator.id}`}
            sx={{ textDecoration: "none", color: "inherit" }}
          >
            <Avatar
              src={ride.creator ? ride.creator.photoUrl : undefined}
              alt={ride.creator ? ride.creator.name : "Unknown"}
              sx={{
                width: 56,
                height: 56,
                mr: 2,
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease-in-out",
                },
              }}
            />
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#333",
                }}
              >
                {ride.creator ? ride.creator.name : "Unknown"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                @{ride.creator ? ride.creator.nickName : "Unknown"}
              </Typography>
            </Box>
          </Box>
          {user && user.uid === ride.creator.id && (
            <IconButton
              component={Link}
              to={`/createride/${rideId}`}
              color="primary"
              aria-label="edit ride"
            >
              <Edit sx={{ color: "black" }} />
            </IconButton>
          )}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 2 : 4}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <DirectionsBike sx={{ mr: 1, color: "black" }} />
            <Typography variant="body1" sx={{ color: "#333" }}>
              {ride.bikeType}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LocationOn sx={{ mr: 1, color: "black" }} />
            <Typography variant="body1" sx={{ color: "#333" }}>
              {ride.location
                ? `${ride.location.latitude.toFixed(
                    4
                  )}, ${ride.location.longitude.toFixed(4)}`
                : "No location"}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <AccessTime sx={{ mr: 1, color: "black" }} />
            <Typography variant="body1" sx={{ color: "#333" }}>
              {formatDateTime(ride.time)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {ride.private ? (
              <Lock sx={{ mr: 1, color: "black" }} />
            ) : (
              <Public sx={{ mr: 1, color: "black" }} />
            )}
            <Typography variant="body1" sx={{ color: "#333" }}>
              {ride.private ? "Privada" : "Pública"}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default RideDetails;
