import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TagIcon from "@mui/icons-material/Tag";
import NfcIcon from "@mui/icons-material/Nfc";
import ParkIcon from "@mui/icons-material/Park";
import PeopleIcon from "@mui/icons-material/People";
import CameraIcon from "@mui/icons-material/Camera";
import MapIcon from "@mui/icons-material/Map";
import UploadIcon from "@mui/icons-material/Upload";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import RouterIcon from "@mui/icons-material/Router";

const routes = [
  {
    label: "Receiver Manager",
    path: "/ReceiverManager",
    icon: <RouterIcon />,
  },
  {
    label: "Crear Rodada",
    path: "/create-event",
    icon: <EventIcon />,
  },
  {
    label: "Rodadas",
    path: "/event-list",
    icon: <EventIcon />,
  },
  {
    label: "Leaderboards",
    path: "/Leaderboards",
    icon: <LeaderboardIcon />,
  },
  { label: "Mis Tags", path: "/MyTags", icon: <TagIcon /> },
  { label: "Start NFC", path: "/StartNFC", icon: <NfcIcon /> },
  {
    label: "Finish NFC",
    path: "/finishNFC",
    icon: <NfcIcon />,
  },
  {
    label: "CreatePark",
    path: "/CreatePark",
    icon: <ParkIcon />,
  },
  {
    label: "Usuarios",
    path: "/UsersControl",
    icon: <PeopleIcon />,
  },
  {
    label: "TagControl",
    path: "/TagControl",
    icon: <TagIcon />,
  },
  {
    label: "FinishCam",
    path: "/FinishCam",
    icon: <CameraIcon />,
  },
  {
    label: "Active Riders",
    path: "/activeriders",
    icon: <DirectionsBikeIcon />,
  },
  { label: "MapCam", path: "/MapCam", icon: <MapIcon /> },
  { label: "NfcCam", path: "/NfcCam", icon: <CameraIcon /> },
  {
    label: "CapitaLeaderboard",
    path: "/capitanleaderboard",
    icon: <LeaderboardIcon />,
  },
  {
    label: "uploadTags",
    path: "/uploadTags",
    icon: <UploadIcon />,
  },
  {
    label: "Riders Tags",
    path: "/riderstags",
    icon: <TagIcon />,
  },
  {
    label: "Manual Start",
    path: "/manualstart",
    icon: <EventIcon />,
  },
  {
    label: "Manual Live Rider",
    path: "/ManualLiveRider",
    icon: <DirectionsBikeIcon />,
  },
];

const ControlLinks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      p={3}
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        color: "#333",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          mb: 3,
          textTransform: "uppercase",
        }}
      >
        Control Panel
      </Typography>
      <List
        sx={{
          width: isMobile ? "100%" : "50%",
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {routes.map((route, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              component={Link}
              to={route.path}
              sx={{
                color: "#333",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
              <ListItemIcon sx={{ color: "#333" }}>{route.icon}</ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItem>
            {index < routes.length - 1 && (
              <Divider sx={{ borderColor: "#ddd" }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ControlLinks;
