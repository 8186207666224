import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebase"; // Asegúrate de que la ruta sea correcta para tu configuración de Firebase
import { useUserAuth } from "./context/userAuthContext";

const CheckNewUser = ({ children }) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkNewUserStatus = async () => {
      if (user && user.uid) {
        const storedUserStatus = localStorage.getItem(
          `newuser-status-${user.uid}`
        );

        if (storedUserStatus === "false") {
          setLoading(false);
          return;
        }

        try {
          console.log("Checking new user status for:", user.uid);
          const userDocRef = doc(db, "Users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log("User data:", userData);
            if (userData.newuser === false) {
              // Guardar el estado en localStorage para evitar futuras verificaciones
              localStorage.setItem(`newuser-status-${user.uid}`, "false");
              setLoading(false);
              return;
            } else {
              navigate("/welcomeaboard");
            }
          } else {
            // Si el documento no existe, créalo
            console.log("Creating new user document for:", user.uid);
            await setDoc(userDocRef, {
              uid: user.uid,
              email: user.email,
              type: "user",
              newuser: true,
            });
            navigate("/welcomeaboard");
          }
        } catch (error) {
          console.error("Error checking new user status:", error);
        }
      }
      setLoading(false);
    };

    checkNewUserStatus();
  }, [user, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Puedes personalizar esto con un spinner o cualquier otro indicador de carga
  }

  return <>{children}</>;
};

export default CheckNewUser;
