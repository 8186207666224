import React, { useEffect, useState, useRef } from "react";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage, auth } from "../../firebase";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  TextField,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Container,
  useTheme,
  CircularProgress,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { styled } from "@mui/system";

const LargeImage = styled("img")({
  width: "100%",
  height: "auto",
  cursor: "pointer",
});

const ChatBubble = styled(Paper)(({ theme, own }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: own ? theme.palette.primary.main : theme.palette.grey[200],
  color: own ? "#FFFFFF" : theme.palette.text.primary,
  alignSelf: own ? "flex-end" : "flex-start",
  maxWidth: "70%",
  boxShadow: own ? "0 3px 10px rgba(0,0,0,0.2)" : "none", // Mejor sombra para mensajes del usuario
  wordBreak: "break-word", // Manejo mejor de textos largos
}));

const ChatContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  overflow: "hidden",
  padding: "0",
  border: "1px solid #ccc", // Mejor separación con el borde
  borderRadius: "8px", // Bordes redondeados para el contenedor
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)", // Sombra para el contenedor
  backgroundColor: "#f9f9f9", // Fondo suave para el contenedor
});

const ChatList = styled(List)({
  flexGrow: 1,
  overflowY: "auto",
  padding: "10px 20px",
});

const MessageInputContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderTop: "1px solid #ccc",
  backgroundColor: "#fff", // Fondo blanco para el input
});

const RideChat = ({ uid }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");
  const [userId, setUserId] = useState(null);
  const [typing, setTyping] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true); // Inicia el estado de carga
      const chatRef = collection(db, "Rides", uid, "chat");
      const q = query(chatRef, orderBy("timestamp", "asc"));
      onSnapshot(q, async (snapshot) => {
        const updatedMessages = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const userInfo = await fetchUserInfo(data.senderId);
            return { id: doc.id, ...data, userInfo };
          })
        );
        setMessages(updatedMessages);
        scrollToBottom();
        setLoading(false); // Finaliza el estado de carga
      });
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    const listenToTypingStatus = () => {
      const typingRef = doc(db, "Rides", uid, "typing", "status");
      onSnapshot(typingRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsUserTyping(data.isTyping && data.userId !== userId);
        }
      });
    };

    fetchMessages();
    fetchUserId();
    listenToTypingStatus();
  }, [uid, userId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !selectedImage) return;

    let imageUrl = "";
    if (selectedImage) {
      const imageRef = ref(storage, `chat_images/${uid}/${selectedImage.name}`);
      const snapshot = await uploadBytes(imageRef, selectedImage);
      imageUrl = await getDownloadURL(snapshot.ref);
      setSelectedImage(null);
      setPreviewImage(null);
    }

    const chatRef = collection(db, "Rides", uid, "chat");
    const docRef = await addDoc(chatRef, {
      message: newMessage,
      senderId: userId,
      timestamp: serverTimestamp(),
      imageUrl,
    });

    await updateDoc(docRef, { sent: true });

    setNewMessage("");
    setTyping(false);

    const typingRef = doc(db, "Rides", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleTyping = async (e) => {
    setNewMessage(e.target.value);
    setTyping(true);

    const typingRef = doc(db, "Rides", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: true, userId });
  };

  const handleStopTyping = async () => {
    setTyping(false);

    const typingRef = doc(db, "Rides", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleDeleteMessage = async () => {
    if (messageToDelete.imageUrl) {
      const imageRef = ref(storage, messageToDelete.imageUrl);
      await deleteObject(imageRef);
    }

    const messageRef = doc(db, "Rides", uid, "chat", messageToDelete.id);
    await deleteDoc(messageRef);

    setOpenDialog(false);
    setMessageToDelete(null);
  };

  const fetchUserInfo = async (userId) => {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      return { name: "Desconocido", photoUrl: "" };
    }
  };

  const handleOpenDialog = (message) => {
    setMessageToDelete(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessageToDelete(null);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleImageClick = (url) => {
    setLargeImageUrl(url);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setLargeImageUrl("");
  };

  return (
    <ChatContainer>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          padding: "20px",
          backgroundColor: theme.palette.primary.main,
          color: "#FFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        Chat
      </Typography>
      <ChatList>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {messages.map((message) => (
              <Box
                key={message.id}
                display="flex"
                flexDirection="column"
                alignItems={
                  message.senderId === userId ? "flex-end" : "flex-start"
                }
              >
                <ListItem
                  alignItems="flex-start"
                  onClick={() =>
                    message.senderId === userId && handleOpenDialog(message)
                  }
                  style={{
                    cursor: message.senderId === userId ? "pointer" : "default",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={message.userInfo?.photoUrl} />
                  </ListItemAvatar>
                  <ChatBubble theme={theme} own={message.senderId === userId}>
                    <ListItemText
                      primary={message.userInfo?.name}
                      primaryTypographyProps={{
                        color:
                          message.senderId === userId
                            ? "#FFFFFF"
                            : theme.palette.text.primary,
                      }}
                      secondary={
                        <span>
                          <Typography
                            style={{
                              color:
                                message.senderId === userId
                                  ? "#ececec"
                                  : theme.palette.text.secondary,
                            }}
                          >
                            {message.message}
                          </Typography>
                          <br />
                          {message.imageUrl && (
                            <img
                              src={message.imageUrl}
                              alt="chat"
                              style={{
                                maxWidth: "100px",
                                marginTop: "5px",
                                cursor: "pointer",
                                borderRadius: "8px",
                              }}
                              onClick={() => handleImageClick(message.imageUrl)}
                            />
                          )}
                          <br />
                          {new Date(
                            message.timestamp?.seconds * 1000
                          ).toLocaleString()}
                          {message.senderId === userId && message.sent && (
                            <span
                              style={{
                                color: "theme.palette.success.main",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              ✔ Sent
                            </span>
                          )}
                        </span>
                      }
                    />
                  </ChatBubble>
                </ListItem>
                <Divider variant="inset" component="li" />
              </Box>
            ))}
            {isUserTyping && (
              <Typography
                variant="caption"
                style={{ paddingLeft: "20px", color: theme.palette.grey[600] }}
              >
                Someone is typing...
              </Typography>
            )}
            <div ref={messagesEndRef} />
          </>
        )}
      </ChatList>
      {previewImage && (
        <Box display="flex" justifyContent="center" mb={2}>
          <img
            src={previewImage}
            alt="Preview"
            style={{
              maxWidth: "100px",
              marginTop: "10px",
              borderRadius: "8px",
            }}
          />
        </Box>
      )}
      <MessageInputContainer>
        <TextField
          label="Escribe un mensaje"
          variant="outlined"
          fullWidth
          value={newMessage}
          onChange={handleTyping}
          onBlur={handleStopTyping}
          sx={{ mr: 2 }}
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          sx={{
            ml: 2,
            backgroundColor: theme.palette.primary.main,
            "&:hover": { backgroundColor: theme.palette.primary.dark },
          }}
        >
          Enviar
        </Button>
      </MessageInputContainer>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Eliminar Mensaje"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar este mensaje?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteMessage} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Imagen</DialogTitle>
        <DialogContent>
          <LargeImage
            src={largeImageUrl}
            alt="Vista ampliada"
            onClick={handleCloseImageDialog}
          />
        </DialogContent>
      </Dialog>
    </ChatContainer>
  );
};

export default RideChat;
