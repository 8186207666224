import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import qrCode from "qrcode";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ServiceRequestCard = ({ serviceRequest, workshopName }) => {
  const [qrDataURL, setQRDataURL] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const auth = getAuth();

  useEffect(() => {
    const getUserPhoneNumber = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          console.log("User UID:", user.uid);
          const userDocRef = doc(db, "Users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            if (userData.phoneNumber) {
              setUserPhoneNumber(userData.phoneNumber.toString());
              console.log("User's phone number:", userData.phoneNumber);
            } else {
              console.log("Phone number not found for the user.");
            }
          } else {
            console.log("User document not found in Firestore.");
          }
        } else {
          console.log("No user logged in.");
        }
      } catch (error) {
        console.error("Error getting user's phone number:", error);
      }
    };
    getUserPhoneNumber();
  }, [auth.currentUser]);

  useEffect(() => {
    const sendToWhatsApp = async () => {
      try {
        if (!userPhoneNumber) {
          console.log("User's phone number is empty.");
          return;
        }

        const qrText = `Folio: ${serviceRequest.id}`;
        const url = await generateQRCode(qrText);
        setQRDataURL(url);

        const phoneNumber = userPhoneNumber.startsWith("521")
          ? userPhoneNumber
          : `521${userPhoneNumber}`;
        const serviceDetails = {
          number: phoneNumber,
          folio: serviceRequest.id,
          fechaEnvio: new Date(serviceRequest.createdAt).toLocaleDateString(),
          bicicleta: serviceRequest.bikeId,
          estadoSolicitud: serviceRequest.status,
          detallesServicio: serviceRequest.serviceDetails,
          codigoQR: url,
          nombreTaller: workshopName,
        };

        const response = await fetch(
          "https://pd87xz-3001.csb.app/send-service-details",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(serviceDetails),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Response from server:", result);
      } catch (error) {
        console.error("Error sending to WhatsApp:", error);
      }
    };
    sendToWhatsApp();
  }, [serviceRequest, workshopName, userPhoneNumber]);

  const generateQRCode = async (qrText) => {
    try {
      return await qrCode.toDataURL(qrText);
    } catch (error) {
      console.error("Error generating QR code:", error);
      return "";
    }
  };

  return (
    <Card sx={{ marginTop: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div" gutterBottom>
              Solicitud Enviada con éxito
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Número de Folio:</strong> {serviceRequest.id}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Fecha de envío:</strong>{" "}
              {new Date(serviceRequest.createdAt).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Bicicleta:</strong> {serviceRequest.bikeId}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Estado de la Solicitud:</strong> {serviceRequest.status}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Detalles del Servicio:</strong>{" "}
              {serviceRequest.serviceDetails}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom>
              <strong>Código QR:</strong>
            </Typography>
            {qrDataURL && <img src={qrDataURL} alt="QR Code" />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServiceRequestCard;
