import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Divider,
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SelectBikeCard from "./SelectBikeCard";
import ServiceRequestCard from "./ServiceRequestCard";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import PhoneIcon from "@mui/icons-material/Phone";

const WorkShop = () => {
  const { id } = useParams();
  const [workshop, setWorkshop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSelectBikeCard, setShowSelectBikeCard] = useState(false);
  const [serviceDetails, setServiceDetails] = useState("");
  const [selectedBike, setSelectedBike] = useState(null);
  const [userInfo, setUserInfo] = useState({ name: "", uid: "" });
  const [serviceRequest, setServiceRequest] = useState(null);

  useEffect(() => {
    const fetchWorkshop = async () => {
      const firestore = getFirestore();
      const workshopRef = doc(firestore, "workshops", id);
      const workshopSnapshot = await getDoc(workshopRef);
      if (workshopSnapshot.exists()) {
        setWorkshop({ id: workshopSnapshot.id, ...workshopSnapshot.data() });
        console.log("UID del Workshop:", workshopSnapshot.id); // Imprimir UID del workshop
      } else {
        console.log("No workshop found with the provided ID");
      }
      setLoading(false);
    };

    const fetchUserInfo = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const { uid } = auth.currentUser;
        const firestore = getFirestore();
        const userRef = doc(firestore, "Users", uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserInfo({ name: userSnap.data().name, uid });
        } else {
          console.log("No user information found");
        }
      } else {
        console.log("User not logged in");
      }
    };

    fetchWorkshop();
    fetchUserInfo();
  }, [id]);

  const handleAgendarServicio = () => {
    setShowSelectBikeCard(true);
  };

  const handleServiceDetailsChange = (event) => {
    setServiceDetails(event.target.value);
  };

  const handleSolicitarServicio = async () => {
    if (!selectedBike || serviceDetails.trim() === "") {
      console.log("Debe seleccionar una bicicleta y detallar el servicio.");
      return;
    }

    const firestore = getFirestore();
    const bikeServiceCollection = collection(firestore, "bikeService");

    const newService = {
      workshopId: id, // UID del WorkShop
      userId: userInfo.uid,
      userName: userInfo.name,
      bikeId: selectedBike.id,
      serviceDetails,
      status: "pendiente",
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(bikeServiceCollection, newService);
      console.log("Servicio solicitado correctamente");
      setServiceRequest({ ...newService, id: docRef.id });
      setSelectedBike(null);
      setServiceDetails("");
      setShowSelectBikeCard(false);
    } catch (error) {
      console.error("Error al solicitar el servicio:", error);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        sx={{ borderRadius: "16px", mt: 3, overflow: "hidden" }}
      >
        {loading ? (
          <CircularProgress />
        ) : workshop ? (
          <>
            <Box sx={{ position: "relative" }}>
              <img
                src={workshop.workshopPhoto}
                alt={workshop.workshopName}
                style={{
                  width: "100%",
                  height: "140px",
                  objectFit: "cover",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  bgcolor: "green",
                  color: "white",
                  borderRadius: "4px",
                  p: "2px 8px",
                }}
              >
                <Typography variant="caption">Abierto</Typography>
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom variant="h5" component="div">
                {workshop.workshopName}
              </Typography>
              <Typography variant="body2" ml={1}>
                {workshop.reviews} Reseñas
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <LocationOnIcon color="action" />
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ ml: 1 }}
                >
                  {workshop.workshopAddress}
                </Typography>
              </Box>
              {/* Nueva sección para mostrar el número de teléfono y el ícono de llamada */}
              {workshop.phoneNumber && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  {/* Opcional: Link para iniciar una llamada */}
                  <a
                    href={`tel:${workshop.phoneNumber}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PhoneIcon />}
                      sx={{ ml: 0 }}
                    >
                      Llamar
                    </Button>
                  </a>
                </Box>
              )}
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography variant="body2" gutterBottom>
                Descripción del taller:
              </Typography>
              <Typography>{workshop.workshopDescription}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgendarServicio}
                sx={{ mt: 2 }}
              >
                Agendar Servicio
              </Button>
              {showSelectBikeCard && (
                <Box sx={{ mt: 2 }}>
                  <SelectBikeCard setSelectedBike={setSelectedBike} />
                  <TextField
                    label="Detalles del Servicio"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={serviceDetails}
                    onChange={handleServiceDetailsChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSolicitarServicio}
                    sx={{ mt: 2 }}
                  >
                    Solicitar Servicio
                  </Button>
                </Box>
              )}
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography variant="body2" gutterBottom>
                Tipos de servicio:
              </Typography>
              <Grid container spacing={1}>
                {workshop.serviceTypes &&
                  Object.entries(workshop.serviceTypes).map(
                    ([serviceType, checked]) =>
                      checked && (
                        <Accordion key={serviceType}>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="body2">
                              {serviceType}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {workshop.subServices[serviceType] &&
                              Object.entries(
                                workshop.subServices[serviceType],
                              ).map(
                                ([subService, checked]) =>
                                  checked && (
                                    <Typography
                                      key={subService}
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      - {subService}
                                    </Typography>
                                  ),
                              )}
                          </AccordionDetails>
                        </Accordion>
                      ),
                  )}
              </Grid>
            </Box>
            {serviceRequest && (
              <ServiceRequestCard
                serviceRequest={serviceRequest}
                workshopName={workshop ? workshop.workshopName : ""}
              />
            )}
          </>
        ) : (
          <Typography variant="body1" gutterBottom>
            No se encontró información del taller.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default WorkShop;
