import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Typography, Grid } from "@mui/material";

const EventParticipants = ({ participants }) => {
  const navigate = useNavigate();
  const handleParticipantClick = (uid) => {
    navigate(`/user/${uid}`);
  };

  return (
    <Box mt={2}>
      <Typography variant="h5">Participantes:</Typography>
      <Grid container spacing={2} mt={1}>
        {participants.map((participant, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            onClick={() => handleParticipantClick(participant.user.uid)}
            sx={{ cursor: "pointer" }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={participant.user.photoUrl}
                alt={`Avatar de ${participant.user.name}`}
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {participant.user.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  @{participant.user.nickName}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventParticipants;
