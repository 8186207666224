import React from "react";
import { Typography, Box } from "@mui/material";

const OrganizacionEventos = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Organización de Eventos
      </Typography>
      <Typography variant="body1" paragraph>
        Aprende cómo organizar eventos en Livespeed. Esta guía cubre desde la
        creación hasta la gestión de los eventos.
      </Typography>
      {/* Aquí puedes agregar más contenido detallado sobre la organización de eventos */}
    </Box>
  );
};

export default OrganizacionEventos;
