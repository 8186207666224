import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const Stats = () => {
  const [services, setServices] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchServices = async () => {
      const servicesRef = collection(firestore, "bikeService");
      const q = query(servicesRef);
      const querySnapshot = await getDocs(q);
      const fetchedServices = querySnapshot.docs.map((doc) => doc.data());
      setServices(fetchedServices);
    };

    fetchServices();
  }, [firestore]);

  const statusCounts = services.reduce((acc, service) => {
    acc[service.status] = (acc[service.status] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(statusCounts).map(
      (status) => `${status} (${statusCounts[status]})`,
    ),
    datasets: [
      {
        data: Object.values(statusCounts),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <h2 style={{ marginBottom: "16px" }}>Estadísticas</h2>
      <div style={{ width: "100%", height: "200px" }}>
        {/* Limitar el tamaño del gráfico */}
        <Pie data={chartData} options={{ maintainAspectRatio: false }} />
      </div>
    </div>
  );
};

export default Stats;
