import React from "react";
import { Typography, Button, Stack } from "@mui/material";
import { riderss } from "./ridersData";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

const UploadRiders = () => {
  const uplolad = async (tagInfo) => {
    try {
      const tagsCollectionRef = collection(db, "Tags");
      const tagData = {
        NFCID: tagInfo.tag.toString(),
        Owner: tagInfo.name,
        Category: tagInfo.category,
        Team: tagInfo.team,
      };
      await addDoc(tagsCollectionRef, tagData);
      console.log("Tag registered successfully.");
    } catch (error) {
      const errorMessage = "Error registering run: " + error.message;
      console.error(errorMessage);
    }
  };

  const uploadAll = () => {
    riderss.forEach(async (rider) => await uplolad(rider));
  };

  return (
    <Stack>
      <Typography>Upload Riders</Typography>
      <Button onClick={uploadAll} variant="contained">
        Upload All Tags
      </Button>
    </Stack>
  );
};

export default UploadRiders;
