import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { Typography } from "@mui/material";
import scanSound from "../../../sounds/bip.mp3";

const FinishReceiver = () => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [scanResult, setScanResult] = useState(null);

  const playScanSound = () => {
    const audio = new Audio(scanSound);
    audio.play();
  };

  const handleReadingError = () => {
    const errorMessage =
      "Argh! Cannot read data from the NFC tag. Try another one?";
    console.error(errorMessage);
    setError(errorMessage);
  };

  const handleReading = async ({ serialNumber }) => {
    console.log("> Serial Number: ", serialNumber);
    setScanResult(serialNumber);
    playScanSound();
    setError(null);
    await handleFinish(serialNumber);
  };

  const handleNFCScan = async () => {
    try {
      const ndef = new window.NDEFReader();

      ndef.addEventListener("readingerror", handleReadingError);

      ndef.addEventListener("reading", async (event) => {
        await handleReading(event);
      });

      await ndef.scan();
      console.log("> Scan started");
    } catch (error) {
      const errorMessage = "Error during NFC scanning: " + error.message;
      console.error(errorMessage);
      setError(errorMessage);
    }
  };

  const handleFinish = async (NFCIdentifier) => {
    try {
      const runsCollectionRef = collection(db, "Runs");
      const q = query(runsCollectionRef, where("ActiveRun", "==", true));
      const querySnapshot = await getDocs(q);

      let found = false;
      querySnapshot.forEach(async (doc) => {
        if (!found && doc.data().NFCIdentifier === NFCIdentifier) {
          await updateDoc(doc.ref, {
            FinishTime: new Date().toISOString(),
            ActiveRun: false,
          });
          found = true;
          setSuccessMessage("Finish time updated successfully.");
        }
      });

      if (!found) {
        setError(
          "No se encontró una carrera activa con el NFCIdentifier especificado.",
        );
      }
    } catch (error) {
      console.error("Error updating finish time:", error);
      setError("Error updating finish time: " + error.message);
    }
  };

  useEffect(() => {
    handleNFCScan();
  }, []);

  return (
    <div>
      <Typography variant="h4">Finish Receiver</Typography>
      {scanResult && (
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          NFC Identifier: {scanResult}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="subtitle1" sx={{ mt: 2, color: "green" }}>
          {successMessage}
        </Typography>
      )}
      {error && (
        <Typography variant="subtitle1" sx={{ mt: 2, color: "red" }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default FinishReceiver;
