import React from "react";
import Enduro from "./Enduro"; // Asegúrate de proporcionar la ruta correcta al componente Enduro.js
import KingHill from "./KingHill";

const Stats = () => {
  return (
    <div>
      <Enduro /> {/* Aquí se inserta el componente Enduro */}
      <KingHill /> {/* Aquí se inserta el componente KingHill */}
    </div>
  );
};

export default Stats;
