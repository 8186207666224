import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Paper,
  Fade,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

const MarketplaceActions = ({ searchTerm, onSearchChange }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fade in={true} timeout={1000}>
      <Box
        mb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth="800px"
        mx="auto"
      >
        <Paper
          component="form"
          elevation={3}
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 3,
            borderRadius: 4,
            transition: "box-shadow 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <TextField
            placeholder="Buscar productos..."
            variant="outlined"
            value={searchTerm}
            onChange={onSearchChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              sx: { borderRadius: 4 },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />
        </Paper>

        <Box
          display="flex"
          justifyContent="center"
          gap={2}
          flexWrap="wrap"
          width="100%"
        >
          {[
            {
              text: "Mis anuncios",
              icon: <Inventory2OutlinedIcon />,
              action: () => navigate("/myproducts"),
              color: "primary",
            },
            {
              text: "Publicar anuncio",
              icon: <AddCircleOutlineIcon />,
              action: () => navigate("/createproduct"),
              color: "success",
            },
          ].map((button, index) => (
            <Button
              key={index}
              variant="contained"
              color={button.color}
              startIcon={button.icon}
              onClick={button.action}
              sx={{
                "&:hover": {
                  transform: "translateY(-2px)",
                },
                borderRadius: 3,
                px: isMobile ? 2 : 4,
                py: isMobile ? 1.5 : 2,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "bold",
                minWidth: isMobile ? "100%" : "200px",
                flex: isMobile ? "1 0 100%" : "0 1 auto",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              {button.text}
            </Button>
          ))}
        </Box>
      </Box>
    </Fade>
  );
};

export default MarketplaceActions;
