import React, { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Typography,
  Container,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Categories = ({ onSelectCategory }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderRef = React.useRef(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const categoriesRef = collection(db, "Categories");
    onSnapshot(categoriesRef, (snapshot) => {
      const categoriesList = [];
      snapshot.docs.forEach((doc) => {
        const { type, catPic } = doc.data();
        categoriesList.push({ id: doc.id, type, catPic });
      });
      setCategories(categoriesList);
    });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
    onSelectCategory(category.id);
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 10,
    arrows: false,
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ position: "relative" }}>
        <IconButton
          onClick={previous}
          sx={{
            position: "absolute",
            top: "50%",
            left: -30,
            transform: "translateY(-50%)",
            zIndex: 1,
            color: "black",
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Slider ref={sliderRef} {...settings}>
          {categories.map((category) => (
            <Box key={category.id} sx={{ padding: "5px" }}>
              <Button
                onClick={() => handleCategoryClick(category)}
                sx={{
                  width: isMobile ? "90px" : "110px",
                  height: isMobile ? "90px" : "110px",
                  borderRadius: "10px",
                  color: selectedCategory === category.id ? "#fff" : "#000",
                  backgroundColor:
                    selectedCategory === category.id ? "#1976d2" : "#f5f5f5",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor:
                      selectedCategory === category.id ? "#1565c0" : "#e0e0e0",
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <img
                  src={category.catPic}
                  alt={category.type}
                  style={{
                    width: isMobile ? "50px" : "60px",
                    height: isMobile ? "50px" : "60px",
                    marginBottom: "5px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "bold",
                    fontSize: isMobile ? "0.6rem" : "0.8rem",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  {category.type}
                </Typography>
              </Button>
            </Box>
          ))}
        </Slider>
        <IconButton
          onClick={next}
          sx={{
            position: "absolute",
            top: "50%",
            right: -30,
            transform: "translateY(-50%)",
            zIndex: 1,
            color: "black",
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Categories;
