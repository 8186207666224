// Notifications.js
import React, { useEffect, useState } from "react";
import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import { db, auth } from "./firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const [uid, setUid] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUid(currentUser.uid);
        await fetchUserName(currentUser.uid);
        setOpen(true);
      } else {
        console.error("No user is currently logged in");
      }
    };

    fetchCurrentUser();

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Handle the message when the app is in the foreground
    });
  }, []);

  const fetchUserName = async (uid) => {
    try {
      const userDocRef = doc(db, "Users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setName(userData.name);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };

  const handleActivateNotifications = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BMIe5pn_pEC-s20ZdTb0DYXHA0FE2JDwPs18dJ4z9ocmwnOZdy-kC-65d8S-Qkk7fY852pk1xp4MKb1_O3PzI4g",
        serviceWorkerRegistration: await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        ),
      });

      if (token) {
        console.log("FCM Token:", token);
        const userDocRef = doc(db, "Users", uid);
        await updateDoc(userDocRef, {
          fcmToken: token,
        });
        console.log("Token saved to Firestore");
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
    } finally {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Hola!</DialogTitle>
      <DialogContent>
        <DialogContentText>Tu UID es: {uid}</DialogContentText>
        {name && <DialogContentText>Tu nombre es: {name}</DialogContentText>}
        <DialogContentText>
          ¿Deseas activar las notificaciones?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleActivateNotifications} color="primary">
          Sí
        </Button>
        <Button onClick={handleClose} color="secondary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notifications;
