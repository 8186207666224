import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";

const WorkShopServices = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [bikePics, setBikePics] = useState({});
  const { workshopId } = useParams();
  const navigate = useNavigate();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchServices = async () => {
      const servicesRef = collection(firestore, "bikeService");
      const q = query(
        servicesRef,
        where("workshopId", "==", workshopId),
        orderBy("createdAt", "desc"),
      );
      const querySnapshot = await getDocs(q);

      const fetchedServices = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setServices(fetchedServices);
      setFilteredServices(fetchedServices);

      const statuses = fetchedServices.map((service) => service.status);
      setUniqueStatuses([...new Set(statuses)]);
    };

    fetchServices();
  }, [workshopId, firestore]);

  useEffect(() => {
    if (statusFilter || searchTerm) {
      const filtered = services.filter(
        (service) =>
          (statusFilter === "" || service.status === statusFilter) &&
          (searchTerm === "" ||
            service.userName.toLowerCase().includes(searchTerm.toLowerCase())),
      );
      setFilteredServices(filtered);
    } else {
      setFilteredServices(services);
    }
  }, [statusFilter, searchTerm, services]);

  useEffect(() => {
    const fetchBikePics = async () => {
      const bikePicsData = {};

      for (const service of services) {
        const bikeId = service.bikeId;
        const bikeRef = doc(firestore, "Bikes", bikeId);
        const bikeDoc = await getDoc(bikeRef);

        if (bikeDoc.exists()) {
          const bikeData = bikeDoc.data();
          bikePicsData[bikeId] = bikeData.bikePic;
        }
      }

      setBikePics(bikePicsData);
    };

    fetchBikePics();
  }, [services, firestore]);

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleServiceClick = (serviceId) => {
    navigate(`/service/${serviceId}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Servicios del Taller
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Buscar por Nombre"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <FormControl fullWidth sx={{ ml: 2 }}>
            <InputLabel id="status-filter-label">Filtrar por Estado</InputLabel>
            <Select
              labelId="status-filter-label"
              value={statusFilter}
              label="Filtrar por Estado"
              onChange={handleStatusChange}
            >
              <MenuItem value="">Todos</MenuItem>
              {uniqueStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      {filteredServices.length > 0 ? (
        filteredServices.map((service) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={service.id}
            onClick={() => handleServiceClick(service.id)}
          >
            <Card elevation={3} sx={{ cursor: "pointer" }}>
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Nombre del Cliente"
                      secondary={service.userName}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="ID del Servicio"
                      secondary={service.bikeId}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Detalles"
                      secondary={service.serviceDetails}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Estado" secondary={service.status} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Fecha de Creación"
                      secondary={service.createdAt.toDate().toLocaleString()}
                    />
                  </ListItem>
                </List>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  overflow="hidden"
                  mt={2}
                >
                  <img
                    src={bikePics[service.bikeId] || ""}
                    alt="Bike"
                    style={{
                      width: "auto",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography>No se encontraron servicios para este taller</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default WorkShopServices;
