import React, { useState, useRef } from "react";
import {
  Box,
  Paper,
  CardMedia,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  IconButton,
  Fade,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

const ProductHeader = ({ allImages }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(allImages[0]);
  const [open, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          left: 10,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          color: "white",
          backgroundColor: "rgba(0,0,0,0.3)",
          "&:hover": { backgroundColor: "rgba(0,0,0,0.5)" },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          right: 10,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          color: "white",
          backgroundColor: "rgba(0,0,0,0.3)",
          "&:hover": { backgroundColor: "rgba(0,0,0,0.5)" },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{ borderRadius: 4, overflow: "hidden", bgcolor: "transparent" }}
      >
        <Slider {...settings} ref={sliderRef}>
          {allImages.map((image, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                cursor: "pointer",
                height: isMobile ? "auto" : 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
              onClick={() => handleImageClick(image)}
            >
              <CardMedia
                component="img"
                image={image}
                alt={`Product ${index}`}
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: 4,
                }}
              />
            </Box>
          ))}
        </Slider>
      </Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          overflowX: "auto",
          overflowY: "hidden",
          "&::-webkit-scrollbar": {
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.2)",
            borderRadius: "3px",
          },
        }}
      >
        {allImages.map((image, index) => (
          <Fade in={true} key={index}>
            <Box
              component="img"
              src={image}
              alt={`Thumbnail ${index}`}
              sx={{
                width: 60,
                height: 60,
                objectFit: "cover",
                cursor: "pointer",
                mx: 0.5,
                borderRadius: 2,
                transition: "all 0.3s ease",
                opacity: currentSlide === index ? 1 : 0.6,
                transform: currentSlide === index ? "scale(1.1)" : "scale(1)",
                boxShadow:
                  currentSlide === index ? "0 0 0 2px #1976d2" : "none",
                "&:hover": {
                  opacity: 1,
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => sliderRef.current.slickGoTo(index)}
            />
          </Fade>
        ))}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "90vw",
            maxWidth: "90vw",
            maxHeight: "90vh",
            bgcolor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent sx={{ p: 0, position: "relative" }}>
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxWidth: "100%",
              maxHeight: "85vh",
              objectFit: "contain",
              display: "block",
              margin: "0 auto",
            }}
            onClick={handleClose}
          />
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              bgcolor: "rgba(0,0,0,0.5)",
              "&:hover": { bgcolor: "rgba(0,0,0,0.7)" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductHeader;
