import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Typography,
  IconButton,
  Avatar,
  Box,
  Container,
  Snackbar,
  Alert,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  setDoc,
  getFirestore,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import EmergencyContacts from "./EmergencyContacts";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import LocationForm from "./LocationForm";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  margin: theme.spacing(2, 0),
  cursor: "pointer",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        content: "'✓'",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: 18,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#fff",
    width: 32,
    height: 32,
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    boxSizing: "border-box",
  },
}));

const UserForm = ({ onComplete }) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    profileImage: null,
    profileImageUrl: "",
    nickName: "",
    emergencyContacts: [],
    country: null,
    state: null,
    city: null,
    private: false,
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [profileExists, setProfileExists] = useState(true);
  const [nicknameError, setNicknameError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid) {
        try {
          const firestore = getFirestore();
          const usersDocRef = doc(firestore, "Users", user.uid);
          const docSnap = await getDoc(usersDocRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            const countryOption = data.country
              ? { value: data.country, label: data.country }
              : null;
            const stateOption = data.state
              ? { value: data.state, label: data.state }
              : null;
            const cityOption = data.city
              ? { value: data.city, label: data.city }
              : null;

            setUserData((prev) => ({
              ...prev,
              ...data,
              country: countryOption,
              state: stateOption,
              city: cityOption,
              profileImage: null,
              profileImageUrl: data.photoUrl || "",
            }));
          } else {
            setProfileExists(false);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setSnackbar({
            open: true,
            message: "Error al cargar los datos del usuario",
            severity: "error",
          });
        }
      }
    };

    fetchData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "nickName") {
      validateNickname(value);
    }
  };

  const handleCountryChange = (selectedCountry) => {
    setUserData((prev) => ({
      ...prev,
      country: selectedCountry,
      state: null,
      city: null,
    }));
  };

  const handleStateChange = (selectedState) => {
    setUserData((prev) => ({
      ...prev,
      state: selectedState,
      city: null,
    }));
  };

  const handleCityChange = (selectedCity) => {
    setUserData((prev) => ({
      ...prev,
      city: selectedCity,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUserData((prev) => ({ ...prev, profileImage: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserData((prev) => ({ ...prev, profileImageUrl: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateNickname = async (nickName) => {
    const nicknameRegex = /^[a-z0-9]+$/;
    if (!nicknameRegex.test(nickName)) {
      setNicknameError("El nickname solo puede contener minúsculas y números.");
      return;
    }

    if (nickName !== userData.nickName) {
      const firestore = getFirestore();
      const usersCollectionRef = collection(firestore, "Users");
      const q = query(usersCollectionRef, where("nickName", "==", nickName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setNicknameError("Este nickname ya está en uso.");
      } else {
        setNicknameError("");
      }
    } else {
      setNicknameError("");
    }
  };

  const handlePhoneChange = (value) => {
    setUserData((prev) => ({ ...prev, phoneNumber: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nicknameError) {
      return;
    }
    setLoading(true);

    try {
      const firestore = getFirestore();
      const usersDocRef = doc(firestore, "Users", user.uid);
      let profileImageUrl = userData.profileImageUrl;

      if (userData.profileImage) {
        profileImageUrl = await uploadImage(userData.profileImage, "profile");
      }

      const dataToSend = {
        uid: user.uid,
        email: user.email,
        name: userData.name,
        photoUrl: profileImageUrl,
        type: "",
        phoneNumber: userData.phoneNumber,
        nickName: userData.nickName,
        emergencyContacts: userData.emergencyContacts,
        country: userData.country?.label,
        state: userData.state?.label,
        city: userData.city?.value,
        private: userData.private,
        newuser: false, // Asegurarse de establecer el campo newuser a false
      };

      await setDoc(usersDocRef, dataToSend);

      setSnackbar({
        open: true,
        message: "Perfil actualizado correctamente",
        severity: "success",
      });

      if (onComplete) {
        onComplete(); // Llama a onComplete cuando el formulario se complete
      }

      setTimeout(() => {
        if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
        navigate("/home");
      }, 1500);
    } catch (error) {
      console.error("Error saving user data:", error.message);
      setSnackbar({
        open: true,
        message: "Error al guardar los datos",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (image, type) => {
    if (!image) return null;
    const storageRef = ref(storage, `${user.uid}/${type}_${image.name}`);
    await uploadBytes(storageRef, image);
    return await getDownloadURL(storageRef);
  };

  const handleAddEmergencyContact = () => {
    const name = prompt("Nombre del contacto de emergencia:");
    const phoneNumber = prompt(
      "Número de teléfono del contacto de emergencia:"
    );
    if (name && phoneNumber) {
      setUserData((prev) => ({
        ...prev,
        emergencyContacts: [...prev.emergencyContacts, { name, phoneNumber }],
      }));
    }
  };

  const handleRemoveEmergencyContact = (index) => {
    setUserData((prev) => ({
      ...prev,
      emergencyContacts: prev.emergencyContacts.filter((_, i) => i !== index),
    }));
  };

  return (
    <StyledContainer ref={formRef}>
      <Typography variant="h5" gutterBottom>
        Perfil de usuario
      </Typography>
      {!profileExists && (
        <Typography variant="subtitle2" color="error" gutterBottom>
          Necesitas crear un perfil para continuar utilizando la aplicación
        </Typography>
      )}
      <StyledForm onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="profileImage"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="profileImage">
            <IconButton component="span">
              <StyledAvatar src={userData.profileImageUrl}>
                {!userData.profileImageUrl && (
                  <AddAPhotoIcon fontSize="large" />
                )}
              </StyledAvatar>
            </IconButton>
          </label>
        </Box>
        <TextField
          label="Nombre y Apellido"
          variant="outlined"
          fullWidth
          name="name"
          value={userData.name}
          onChange={handleChange}
          required
        />
        <PhoneInput
          country={"us"}
          value={userData.phoneNumber}
          onChange={handlePhoneChange}
        />
        <TextField
          label="Nickname"
          variant="outlined"
          fullWidth
          name="nickName"
          value={userData.nickName}
          onChange={handleChange}
          required
          error={!!nicknameError}
          helperText={nicknameError}
        />
        <LocationForm
          country={userData.country}
          state={userData.state}
          city={userData.city}
          handleCountryChange={handleCountryChange}
          handleStateChange={handleStateChange}
          handleCityChange={handleCityChange}
        />
        <FormControlLabel
          control={
            <CustomSwitch
              checked={userData.private}
              onChange={handleChange}
              name="private"
              color="primary"
            />
          }
          label="Perfil Privado"
        />
        <EmergencyContacts
          emergencyContacts={userData.emergencyContacts}
          handleAddEmergencyContact={handleAddEmergencyContact}
          handleRemoveEmergencyContact={handleRemoveEmergencyContact}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          fullWidth
          sx={{ mt: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Guardar Datos"
          )}
        </Button>
      </StyledForm>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default UserForm;
