import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Button,
  Box,
  CardActionArea,
} from "@mui/material";

const TeamManager = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.uid) {
      const fetchTeams = () => {
        const teamsRef = collection(db, "Teams");
        const q = query(teamsRef, where("admin", "array-contains", user.uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const teamsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTeams(teamsData);
          setLoading(false);
        });

        return () => unsubscribe();
      };

      fetchTeams();
    } else {
      setLoading(false);
    }
  }, [user]);

  const handleCreateTeam = () => {
    navigate("/createteam");
  };

  const handleTeamClick = (teamId) => {
    navigate(`/teamadmin/${teamId}`);
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!teams.length) {
    return (
      <Container sx={{ mt: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            Team Manager
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateTeam}
          >
            Crear un equipo
          </Button>
        </Box>
        <Typography variant="body1">
          No has creado ningún equipo todavía.
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Team Manager
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateTeam}>
          Crear un equipo
        </Button>
      </Box>
      <Grid container spacing={2}>
        {teams.map((team) => (
          <Grid item xs={12} sm={6} md={4} key={team.id}>
            <Card elevation={3}>
              <CardActionArea onClick={() => handleTeamClick(team.id)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={team.logo}
                  alt={team.name}
                />
                <CardContent>
                  <Typography variant="h6">{team.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Nacionalidad: {team.nationality}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Estado: {team.state}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Municipio: {team.municipality}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Privacidad: {team.private ? "Privado" : "Público"}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeamManager;
