import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collectionGroup,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Paper,
  Badge,
  CircularProgress,
} from "@mui/material";

const Leaderboards = () => {
  const [track, setTrack] = useState(null);
  const [runs, setRuns] = useState([]);
  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  const specificParkId = "a1tGkXljSiNf4Z7blsE0";
  const specificTrackId = "mB5B2PeabinuwogCGKtL";

  useEffect(() => {
    const fetchTrackAndData = async () => {
      const trackDocRef = doc(
        db,
        "Parks",
        specificParkId,
        "Tracks",
        specificTrackId,
      );
      const trackSnapshot = await getDoc(trackDocRef);
      if (trackSnapshot.exists()) {
        setTrack({ id: trackSnapshot.id, ...trackSnapshot.data() });

        const tagsCollectionRef = collection(db, "Tags");
        const tagsSnapshot = await getDocs(tagsCollectionRef);
        const tagsData = tagsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(tagsData);

        const usersCollectionRef = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);

        const runsCollectionRef = collectionGroup(db, "Runs");
        const q = query(
          runsCollectionRef,
          where("TrackID", "==", specificTrackId),
        );
        const runsSnapshot = await getDocs(q);
        const runsData = runsSnapshot.docs.map((doc) => {
          const runData = doc.data();
          if (runData.StartTime && runData.FinishTime) {
            const startTime = new Date(runData.StartTime.seconds * 1000);
            const finishTime = new Date(runData.FinishTime.seconds * 1000);
            const timeDifference = finishTime - startTime;
            const riderTag = tagsData.find(
              (tag) => tag.NFCID === runData.NFCIdentifier,
            );
            const rider = riderTag
              ? usersData.find((user) => user.id === riderTag.Owner)
              : null;
            return {
              id: doc.id,
              riderName: rider ? rider.name : "Unknown",
              photoUrl:
                rider && rider.photoUrl ? rider.photoUrl : "default_photo_url",
              date: startTime.toLocaleString(),
              time: timeDifference,
            };
          } else {
            return {
              id: doc.id,
              riderName: "Unknown",
              photoUrl: "default_photo_url",
              date: "N/A",
              time: "N/A",
            };
          }
        });
        setRuns(runsData);
        setLoading(false);
      }
    };

    fetchTrackAndData();
  }, []);

  useEffect(() => {
    const findBestTimes = () => {
      const bestTimesMap = new Map();
      runs.forEach((run) => {
        if (!bestTimesMap.has(run.riderName)) {
          bestTimesMap.set(run.riderName, run);
        } else {
          if (run.time < bestTimesMap.get(run.riderName).time) {
            bestTimesMap.set(run.riderName, run);
          }
        }
      });
      const bestTimesArray = Array.from(bestTimesMap.values());
      const sortedBestTimes = bestTimesArray.sort((a, b) => a.time - b.time);
      setBestTimes(sortedBestTimes);
    };
    findBestTimes();
  }, [runs]);

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        {track ? track.TrackName : "Loading..."}
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <Typography variant="h5" align="center">
                Mejores Tiempos
              </Typography>
              <List>
                {bestTimes.map((run, index) => (
                  <ListItem
                    key={run.id}
                    style={{
                      backgroundColor:
                        index === 0
                          ? "gold"
                          : index === 1
                            ? "silver"
                            : index === 2
                              ? "#cd7f32"
                              : "none",
                    }}
                  >
                    <ListItemAvatar>
                      <Badge badgeContent={index + 1} color="primary">
                        <Avatar src={run.photoUrl} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={run.riderName}
                      secondary={`Tiempo: ${Math.floor(run.time / 60000)} min ${Math.floor(
                        (run.time / 1000) % 60,
                      )} sec, Fecha: ${run.date}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Leaderboards;
