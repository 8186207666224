import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayRemove,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import {
  Button,
  Avatar,
  CircularProgress,
  Typography,
  Box,
  Paper,
  useTheme,
  Slide,
  Snackbar,
  Container,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const FriendRequests = () => {
  const [friendRequests, setFriendRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = subscribeToFriendRequests();
    return () => unsubscribe();
  }, []);

  const subscribeToFriendRequests = () => {
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const userDocRef = doc(db, "Users", uid);
      return onSnapshot(userDocRef, (docSnapshot) => {
        const userData = docSnapshot.data();
        if (
          userData &&
          userData.friendRequests &&
          userData.friendRequests.length > 0
        ) {
          const friendDetailsPromises = userData.friendRequests.map(
            async (friendUid) => {
              const friendDocRef = doc(db, "Users", friendUid);
              const friendDocSnap = await getDoc(friendDocRef);
              if (friendDocSnap.exists()) {
                const friendData = friendDocSnap.data();
                return {
                  uid: friendUid,
                  name: friendData.name,
                  photoUrl: friendData.photoUrl,
                  nickName: friendData.nickName,
                };
              } else {
                console.error("Friend details not found for UID:", friendUid);
                return null;
              }
            }
          );
          Promise.all(friendDetailsPromises).then((resolvedFriendDetails) => {
            setFriendRequests(resolvedFriendDetails.filter(Boolean));
            setLoading(false);
          });
        } else {
          setFriendRequests([]);
          setLoading(false);
        }
      });
    }
    return () => {}; // No-op unsubscribe function
  };

  const handleAcceptFriendRequest = async (friendUid) => {
    try {
      const userUid = auth.currentUser.uid;
      const userRef = doc(db, "Users", userUid);
      const friendRef = doc(db, "Users", friendUid);

      await updateDoc(userRef, {
        friendRequests: arrayRemove(friendUid),
        friends: arrayUnion(friendUid),
      });

      await updateDoc(friendRef, {
        friends: arrayUnion(userUid),
      });

      setSnackbar({ open: true, message: "Solicitud de amistad aceptada" });
    } catch (error) {
      console.error("Error al aceptar la solicitud de amistad:", error);
      setSnackbar({ open: true, message: "Error al aceptar la solicitud" });
    }
  };

  const handleDenyFriendRequest = async (friendUid) => {
    try {
      const userRef = doc(db, "Users", auth.currentUser.uid);
      await updateDoc(userRef, {
        friendRequests: arrayRemove(friendUid),
      });
      setSnackbar({ open: true, message: "Solicitud de amistad rechazada" });
    } catch (error) {
      console.error("Error al rechazar la solicitud de amistad:", error);
      setSnackbar({ open: true, message: "Error al rechazar la solicitud" });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom align="center">
        Solicitudes de Amistad
      </Typography>
      {friendRequests.length > 0 ? (
        <Grid container spacing={3}>
          {friendRequests.map((friend, index) => (
            <Grid item xs={12} sm={6} md={4} key={friend.uid}>
              <Slide direction="up" in={true} timeout={300 * (index + 1)}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 4,
                    height: "100%",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <Avatar
                    alt={friend.name}
                    src={friend.photoUrl}
                    sx={{ width: 100, height: 100, mb: 2 }}
                  />
                  <Typography variant="h6" fontWeight="bold" align="center">
                    {friend.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    mb={2}
                    align="center"
                  >
                    {friend.nickName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CheckCircleIcon />}
                      onClick={() => handleAcceptFriendRequest(friend.uid)}
                      sx={{ mr: 1, flexGrow: 1 }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<CancelIcon />}
                      onClick={() => handleDenyFriendRequest(friend.uid)}
                      sx={{ flexGrow: 1 }}
                    >
                      Rechazar
                    </Button>
                  </Box>
                </Paper>
              </Slide>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 4,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <PersonAddIcon
            sx={{ fontSize: 80, color: theme.palette.text.secondary, mb: 2 }}
          />
          <Typography variant="h6" color="textSecondary" align="center">
            No tienes solicitudes de amistad disponibles.
          </Typography>
        </Paper>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Container>
  );
};

export default FriendRequests;
