import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Divider,
  Box,
  Stack,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import { storage } from "../../firebase";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const CreateStore = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { storeId } = useParams();
  const firestore = getFirestore();
  const [storeData, setStoreData] = useState({
    storeName: "",
    location: { lat: 29.110262, lng: -110.940463 },
    storeLogo: null,
    storePhoto: null,
    creationDate: "",
    phoneNumber: "",
    storeAddress: "",
    storeDescription: "",
    openingHours: {
      lunes: { open: "", close: "", closed: false },
      martes: { open: "", close: "", closed: false },
      miércoles: { open: "", close: "", closed: false },
      jueves: { open: "", close: "", closed: false },
      viernes: { open: "", close: "", closed: false },
      sábado: { open: "", close: "", closed: false },
      domingo: { open: "", close: "", closed: false },
    },
    productCategories: {
      Bicicletas: false,
      Accesorios: false,
      Ropa: false,
      Componentes: false,
    },
    subProducts: {
      Bicicletas: {
        Montaña: false,
        Ruta: false,
        Eléctricas: false,
      },
      Accesorios: {
        Cascos: false,
        Guantes: false,
        Gafas: false,
      },
      Ropa: {
        Jerseys: false,
        Pantalones: false,
        Chaquetas: false,
      },
      Componentes: {
        Transmisión: false,
        Frenos: false,
        Suspensión: false,
      },
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (storeId) {
      const fetchStore = async () => {
        const docRef = doc(firestore, "stores", storeId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setStoreData({ ...docSnap.data(), storeId: docSnap.id });
        } else {
          console.log("No such store!");
        }
      };

      fetchStore();
    }
  }, [storeId, firestore]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData({ ...storeData, [name]: value });
  };

  const handleLocationChange = (e) => {
    const { latLng } = e;
    setStoreData((prevData) => ({
      ...prevData,
      location: { lat: latLng.lat(), lng: latLng.lng() },
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, storeLogo: file }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, storePhoto: file }));
  };

  const handleOpeningHoursChange = (day, time, value) => {
    setStoreData((prevData) => ({
      ...prevData,
      openingHours: {
        ...prevData.openingHours,
        [day]: {
          ...prevData.openingHours[day],
          [time]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (section, category) => {
    if (section === "productCategories") {
      setStoreData((prevData) => ({
        ...prevData,
        productCategories: {
          ...prevData.productCategories,
          [category]: !prevData.productCategories[category],
        },
      }));
    } else {
      setStoreData((prevData) => ({
        ...prevData,
        subProducts: {
          ...prevData.subProducts,
          [section]: {
            ...prevData.subProducts[section],
            [category]: !prevData.subProducts[section][category],
          },
        },
      }));
    }
  };

  const handleDayCheckboxChange = (day) => {
    setStoreData((prevData) => ({
      ...prevData,
      openingHours: {
        ...prevData.openingHours,
        [day]: {
          ...prevData.openingHours[day],
          closed: !prevData.openingHours[day].closed,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let logoUrl = storeData.storeLogo;
      if (storeData.storeLogo instanceof File) {
        const logoRef = storageRef(
          storage,
          `store_logos/${user.uid}/${storeData.storeLogo.name}`
        );
        const uploadLogoTaskSnapshot = await uploadBytesResumable(
          logoRef,
          storeData.storeLogo
        );
        logoUrl = await getDownloadURL(uploadLogoTaskSnapshot.ref);
      }

      let photoUrl = storeData.storePhoto;
      if (storeData.storePhoto instanceof File) {
        const photoRef = storageRef(
          storage,
          `store_images/${user.uid}/${storeData.storePhoto.name}`
        );
        const uploadPhotoTaskSnapshot = await uploadBytesResumable(
          photoRef,
          storeData.storePhoto
        );
        photoUrl = await getDownloadURL(uploadPhotoTaskSnapshot.ref);
      }

      const storeDataToSave = {
        ...storeData,
        storeLogo: logoUrl,
        storePhoto: photoUrl,
      };

      if (storeId) {
        const storeRef = doc(firestore, "stores", storeId);
        await updateDoc(storeRef, storeDataToSave);
        console.log("Store updated with ID: ", storeId);
      } else {
        const storesCollectionRef = collection(firestore, "stores");
        const newStoreRef = await addDoc(storesCollectionRef, storeDataToSave);
        console.log("New store created with ID: ", newStoreRef.id);
      }

      navigate("/home");
    } catch (error) {
      console.error("Error creating store:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          {storeId ? "Editar Tienda" : "Crear Tienda"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre de la Tienda"
                type="text"
                name="storeName"
                value={storeData.storeName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha de Creación"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="creationDate"
                value={storeData.creationDate}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={storeData.location}
                  zoom={10}
                  onClick={handleLocationChange}
                >
                  <Marker position={storeData.location} />
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="storeLogo">
                Agrega Logo de la Tienda:
              </InputLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <input
                  accept="image/*"
                  id="storeLogo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleLogoChange}
                />
                <label htmlFor="storeLogo">
                  <IconButton color="primary" component="span">
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
                {storeData.storeLogo && (
                  <Box
                    component="img"
                    sx={{ width: 100, height: "auto" }}
                    alt="Store logo preview"
                    src={
                      storeData.storeLogo instanceof File
                        ? URL.createObjectURL(storeData.storeLogo)
                        : storeData.storeLogo
                    }
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="storePhoto">
                Agrega Fotos de la Tienda:
              </InputLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <input
                  accept="image/*"
                  id="storePhoto"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
                <label htmlFor="storePhoto">
                  <IconButton color="primary" component="span">
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
                {storeData.storePhoto && (
                  <Box
                    component="img"
                    sx={{ width: 100, height: "auto" }}
                    alt="Store preview"
                    src={
                      storeData.storePhoto instanceof File
                        ? URL.createObjectURL(storeData.storePhoto)
                        : storeData.storePhoto
                    }
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Número de Teléfono"
                type="tel"
                name="phoneNumber"
                value={storeData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección de la Tienda"
                type="text"
                name="storeAddress"
                value={storeData.storeAddress}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción de la Tienda"
                multiline
                rows={4}
                name="storeDescription"
                value={storeData.storeDescription}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Horario de Apertura:
              </Typography>
              <Divider />
              {Object.entries(storeData.openingHours).map(([day, hours]) => (
                <Grid container spacing={2} key={day} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      <strong>
                        {day.charAt(0).toUpperCase() + day.slice(1)}:
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hours.closed}
                          onChange={() => handleDayCheckboxChange(day)}
                        />
                      }
                      label="Cerrado"
                    />
                    {!hours.closed && (
                      <Stack direction="row" spacing={1}>
                        <TextField
                          type="time"
                          value={hours.open}
                          onChange={(e) =>
                            handleOpeningHoursChange(
                              day,
                              "open",
                              e.target.value
                            )
                          }
                        />
                        <Typography variant="body1">a</Typography>
                        <TextField
                          type="time"
                          value={hours.close}
                          onChange={(e) =>
                            handleOpeningHoursChange(
                              day,
                              "close",
                              e.target.value
                            )
                          }
                        />
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Categorías de Productos:
              </Typography>
              <Divider />
              {Object.entries(storeData.productCategories).map(
                ([category, checked]) => (
                  <React.Fragment key={category}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() =>
                            handleCheckboxChange("productCategories", category)
                          }
                        />
                      }
                      label={category}
                    />
                    {checked && (
                      <Grid container>
                        {Object.entries(storeData.subProducts[category]).map(
                          ([subCategory, subChecked]) => (
                            <Grid item key={subCategory} xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={subChecked}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        category,
                                        subCategory
                                      )
                                    }
                                  />
                                }
                                label={subCategory}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : storeId ? (
                  "Actualizar Tienda"
                ) : (
                  "Crear Tienda"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateStore;
