import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Container,
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Button,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/system";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import TerrainIcon from "@mui/icons-material/Terrain";
import PaidIcon from "@mui/icons-material/Paid";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TimerIcon from "@mui/icons-material/Timer";

// Estilo del Card modernizado con fondo blanco y efectos mejorados
const ModernCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  height: "100%",
  transition: "all 0.3s ease",
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
  },
}));

// Botón modernizado con un estilo limpio
const ModernButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  textTransform: "none",
  padding: theme.spacing(1.5, 3),
  fontWeight: "bold",
  fontSize: "1rem",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
  "&:hover": {
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
  },
}));

// Chip para cuenta regresiva con mejoras
const CountdownChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: "rgba(0, 0, 0, 0.9)",
  color: "#fff",
  fontWeight: "bold",
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
}));

const EventHome = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));

        const sortedEvents = eventsData.sort((a, b) => b.dateTime - a.dateTime);
        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleResultsClick = (eventId) => {
    navigate(`/leaderboard/${eventId}`);
  };

  const isEventPast = (eventDate) => {
    const today = new Date();
    return eventDate < today;
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const getCountdown = (eventDate) => {
    const now = new Date();
    const diff = eventDate - now;

    if (diff <= 0) return null;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const filteredEvents = events.filter((event) =>
    event.eventName.toLowerCase().includes(searchTerm)
  );

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3, mb: 6, bgcolor: "#fff" }}>
        <Grid container spacing={4}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton
                variant="rectangular"
                height={400}
                sx={{ bgcolor: "grey.200" }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 3, mb: 6, bgcolor: "#fff", color: "#000" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold", color: "#000", mb: 4 }}
      >
        Eventos
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          variant="outlined"
          placeholder="Buscar eventos por título"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            width: "100%",
            maxWidth: "500px",
            "& .MuiOutlinedInput-root": {
              borderRadius: 4,
              backgroundColor: "#000",
              color: "#fff",
              "& fieldset": {
                borderColor: "#000",
              },
              "&:hover fieldset": {
                borderColor: "#000",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#000",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#fff" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Grid container spacing={4}>
        {filteredEvents.map((event) => {
          const isPast = isEventPast(event.dateTime);
          const countdown = getCountdown(event.dateTime);

          return (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <ModernCard>
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    image={event.photo}
                    alt={`Foto del evento ${event.eventName}`}
                    sx={{
                      height: 250,
                      objectFit: "cover",
                      width: "100%",
                    }}
                  />
                  {isPast ? (
                    <CountdownChip label="Evento finalizado" color="primary" />
                  ) : (
                    countdown && (
                      <CountdownChip icon={<TimerIcon />} label={countdown} />
                    )
                  )}
                </Box>
                <CardContent sx={{ p: 3, flex: "1 0 auto" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    {event.eventName}
                  </Typography>
                  <Stack spacing={2}>
                    <Stack direction="row" alignItems="center">
                      <EventIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        {formatDate(event.dateTime)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <PersonIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        {event.creatorName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <TerrainIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        Nivel: {event.skillLevel}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <AccessTimeIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        Duración: {event.duration || "No especificada"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Chip
                      icon={
                        event.price > 0 ? (
                          <PaidIcon sx={{ color: "#fff" }} />
                        ) : (
                          <FreeBreakfastIcon sx={{ color: "#fff" }} />
                        )
                      }
                      label={event.price > 0 ? `$${event.price}` : "Gratuito"}
                      sx={{
                        mb: 2,
                        backgroundColor: event.price > 0 ? "#000" : "#4caf50",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    />
                    <Box>
                      {isPast && (
                        <ModernButton
                          variant="outlined"
                          startIcon={<EmojiEventsIcon />}
                          onClick={() => handleResultsClick(event.id)}
                          sx={{
                            borderColor: "#000",
                            color: "#000",
                            mr: 2,
                            mb: isMobile ? 2 : 0,
                          }}
                        >
                          Resultados
                        </ModernButton>
                      )}
                      <ModernButton
                        variant="contained"
                        onClick={() => handleEventClick(event.id)}
                        sx={{
                          backgroundColor: isPast ? "#e0e0e0" : "#000",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: isPast ? "#e0e0e0" : "#000",
                          },
                        }}
                      >
                        {isPast ? "Ver detalles" : "Registrarte"}
                      </ModernButton>
                    </Box>
                  </Box>
                </CardContent>
              </ModernCard>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default EventHome;
