import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";

const mapContainerStyle = {
  height: "300px",
  width: "100%",
  borderRadius: "16px", // Orillas redondeadas
  overflow: "hidden", // Para que las esquinas no se salgan del contenedor
};

const ReceiverLocation = ({ location }) => {
  if (!location || !location.lat || !location.lng) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="body2" sx={{ color: "red" }}>
          Coordenadas no disponibles.
        </Typography>
      </Box>
    );
  }

  const center = {
    lat: location.lat,
    lng: location.lng,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default ReceiverLocation;
