import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Rating,
  Avatar,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const WorkShopCard = ({ workshop, onClick }) => {
  const ratingValue = workshop.rating || 4; // Asume un valor por defecto o usa el rating del taller

  return (
    <Card sx={{ borderRadius: "16px", position: "relative" }}>
      <CardMedia
        component="img"
        height="140"
        image={workshop.workshopPhoto}
        alt={workshop.workshopName}
        sx={{ objectFit: "cover", borderRadius: "16px 16px 0 0" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          bgcolor: "green",
          color: "white",
          borderRadius: "4px",
          p: "2px 8px",
        }}
      >
        <Typography variant="caption">Abierto</Typography>
      </Box>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Avatar
            src={workshop.workshopLogo}
            alt={workshop.workshopName}
            sx={{
              width: 64,
              height: 64,
              marginRight: 1,
              border: "2px solid white",
            }}
          />
          <Typography gutterBottom variant="h5" component="div">
            {workshop.workshopName}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Rating name="read-only" value={ratingValue} readOnly />
          <Typography variant="body2" ml={1}>
            {workshop.reviews} Reseñas
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <LocationOnIcon color="action" />
          <Typography variant="subtitle1" color="text.secondary" sx={{ ml: 1 }}>
            {workshop.workshopAddress}
          </Typography>
        </Box>
      </CardContent>
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => onClick(workshop.id)}
        >
          Agendar Servicio
        </Button>
      </Box>
    </Card>
  );
};

export default WorkShopCard;
