import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  IconButton,
  Stack,
  Avatar,
  Box,
  LinearProgress,
} from "@mui/material";
import { PhotoCamera as PhotoCameraIcon } from "@mui/icons-material";
import { getFirestore, doc, collection, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const ServiceNotes = ({ serviceId }) => {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [comments, setComments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const storage = getStorage();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const previews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFilePreviews(previews);
    setFiles(selectedFiles);
    setComments(Array(selectedFiles.length).fill(""));
    setUploadProgress({});
  };

  const handleCommentChange = (index, event) => {
    const newComments = [...comments];
    newComments[index] = event.target.value;
    setComments(newComments);
  };

  const handleUpload = async () => {
    const db = getFirestore();

    files.forEach((file, index) => {
      const timestamp = new Date().toISOString();
      const storageRefItem = storageRef(
        storage,
        `serviceNotes/${serviceId}/${timestamp}_${file.name}`,
      );
      const metadata = { contentType: file.type };
      const uploadTask = uploadBytesResumable(storageRefItem, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prev) => ({ ...prev, [index]: progress }));
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const serviceDocRef = doc(db, `bikeService/${serviceId}`);
          const notesRef = collection(serviceDocRef, "ServiceNotes");
          await addDoc(notesRef, {
            url: downloadURL,
            comments: comments[index],
            timestamp,
          });
          setUploadProgress((prev) => ({ ...prev, [index]: 100 }));
        },
      );
    });

    // Resetear los estados después de un tiempo para permitir la carga de nuevos archivos
    setTimeout(() => {
      setFiles([]);
      setFilePreviews([]);
      setComments([]);
      setUploadProgress({});
    }, 3000);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Notas de Servicio</Typography>
        <input
          accept="image/*,video/*"
          style={{ display: "none" }}
          id="upload-file-input"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="upload-file-input">
          <IconButton component="span" color="primary" aria-label="add file">
            <PhotoCameraIcon />
          </IconButton>
        </label>
        <Stack spacing={2}>
          {filePreviews.map((preview, index) => (
            <Box key={index} sx={{ mt: 2 }}>
              <Avatar
                variant="rounded"
                src={preview}
                sx={{ width: 100, height: 100 }}
              />
              <LinearProgress
                variant="determinate"
                value={uploadProgress[index] || 0}
              />
              <TextField
                label={`Comentario para ${files[index]?.name}`}
                value={comments[index] || ""}
                onChange={(event) => handleCommentChange(index, event)}
                multiline
                rows={4}
                fullWidth
                sx={{ mt: 1 }}
              />
            </Box>
          ))}
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={files.length === 0}
          >
            Subir Notas
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ServiceNotes;
