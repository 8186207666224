import React, { useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import axios from "axios";

const FloatingSupportButton = () => {
  const [openChat, setOpenChat] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null); // Track threadId for ongoing conversations

  const ASSISTANT_ID = "asst_eB1q69urCumOsI20qgh6BG6N"; // Your personalized assistant ID

  const handleSendMessage = async () => {
    if (message.trim() === "") {
      console.log("Message is empty, not sending.");
      return;
    }

    setIsLoading(true);

    const newMessage = {
      role: "user",
      content: message,
      name: "Usuario",
      avatar: "/path_to_user_avatar.jpg",
    };

    // Append user message to chat history
    setChatHistory((prev) => [...prev, newMessage]);
    setMessage(""); // Reset the message input

    try {
      // Make request to the Firebase Function to send the message
      const response = await axios.post(
        "https://us-central1-mtbking-aa657.cloudfunctions.net/sendMessageToAssistant",
        {
          message,
          threadId, // Pass the threadId for ongoing conversation
          assistantId: ASSISTANT_ID,
        }
      );

      // Retrieve and append the assistant's response to the chat
      const assistantMessages = response.data.messages.map((msg) => ({
        role: "assistant",
        content: msg.content,
        name: "Soporte",
        avatar: "/path_to_bot_avatar.jpg",
      }));

      setChatHistory((prev) => [...prev, ...assistantMessages]);

      // Update the threadId for future messages if it's a new thread
      if (!threadId) {
        setThreadId(response.data.threadId);
      }
    } catch (error) {
      console.error("Error communicating with the Assistant:", error);
      const errorMessage = {
        role: "assistant",
        content: "Error communicating with the assistant. Please try again.",
        name: "Error",
        avatar: "/path_to_error_avatar.jpg",
      };
      setChatHistory((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 100,
          left: 20,
          zIndex: 1000,
        }}
      >
        <Tooltip title="Soporte" arrow>
          <IconButton
            onClick={() => setOpenChat(true)}
            sx={{
              backgroundColor: "#000000",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#333333",
              },
              boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              width: 56,
              height: 56,
              borderRadius: "50%",
            }}
          >
            <SupportAgentIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog open={openChat} onClose={() => setOpenChat(false)}>
        <DialogContent
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "12px",
            padding: "16px",
            minWidth: "400px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#000", textAlign: "center" }}
          >
            Chat de Soporte
          </Typography>
          <Box
            sx={{
              height: "300px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "8px",
              marginBottom: "16px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
          >
            {chatHistory.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: msg.role === "user" ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.75rem", color: "#666" }}
                >
                  {msg.name}
                </Typography>
                <Box
                  sx={{
                    maxWidth: "80%",
                    padding: "8px 12px",
                    backgroundColor:
                      msg.role === "user" ? "#000000" : "#e0e0e0",
                    color: msg.role === "user" ? "#ffffff" : "#000000",
                    borderRadius: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  <Avatar
                    src={msg.avatar}
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: "8px",
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  />
                  {msg.content}
                </Box>
              </Box>
            ))}
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Escribe tu mensaje..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !isLoading) {
                e.preventDefault(); // Prevent multiple submissions while loading
                handleSendMessage();
              }
            }}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
            disabled={isLoading}
          />
          <Button
            onClick={handleSendMessage}
            variant="contained"
            sx={{
              marginTop: "16px",
              backgroundColor: "#000000",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#333333",
              },
              display: "block",
              marginLeft: "auto",
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Enviar"
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingSupportButton;
