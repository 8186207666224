import React, { useRef, useEffect, useState } from "react";
import { db, storage } from "../../../firebase";
import { collection, addDoc, doc, getDocs, getDoc } from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { Typography, Select, MenuItem, Alert } from "@mui/material";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";

const CamReceiver = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [selectedPark, setSelectedPark] = useState("");
  const [model, setModel] = useState(null);
  const [parkOptions, setParkOptions] = useState([]);
  const [lineCrossed, setLineCrossed] = useState(false);
  const [parkSelected, setParkSelected] = useState(false); // Añadido estado faltante
  const [captureNotification, setCaptureNotification] = useState(null);

  useEffect(() => {
    cocoSsd.load().then((loadedModel) => {
      setModel(loadedModel);
      console.log("Model loaded.");
    });
  }, []);

  useEffect(() => {
    const fetchParks = async () => {
      const parksCollectionRef = collection(db, "Parks");
      const parksSnapshot = await getDocs(parksCollectionRef);
      const parks = parksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParkOptions(parks);
    };

    fetchParks();
  }, []);

  useEffect(() => {
    if (selectedPark) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            videoRef.current.srcObject = stream;
            setParkSelected(true); // Asegurar que se establece el estado parkSelected
          })
          .catch((error) => {
            console.error("Error accessing the camera", error);
            setError("Error accessing the camera: " + error.message);
          });
      }
    }
  }, [selectedPark]);

  useEffect(() => {
    const detectObjects = async () => {
      if (videoRef.current && model && !lineCrossed && parkSelected) {
        const predictions = await model.detect(videoRef.current);
        console.log(predictions);
        const bicycleDetected = predictions.some(
          (prediction) => prediction.class === "bicycle",
        );
        const crossingDetected = predictions.some((prediction) =>
          isCrossingCenter(prediction.bbox, videoRef.current),
        );
        if (bicycleDetected || crossingDetected) {
          takeScreenshot();
          setLineCrossed(true);
          setTimeout(() => setLineCrossed(false), 500);
        }
      }
    };

    const interval = setInterval(detectObjects, 100);
    return () => clearInterval(interval);
  }, [model, lineCrossed, parkSelected]);

  const isCrossingCenter = (bbox, video) => {
    const centerX = video.videoWidth / 2;
    const centerY = video.videoHeight / 2;
    const [x, y, width, height] = bbox;
    const xMid = x + width / 2;
    const yMid = y + height / 2;
    return (
      Math.abs(xMid - centerX) < width / 2 &&
      Math.abs(yMid - centerY) < height / 2
    );
  };

  const takeScreenshot = async () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(async (blob) => {
        const parkName = await getParkName(selectedPark);
        const imageStorageRef = storageRef(
          storage,
          `captures/${Date.now()}_${parkName}.png`,
        );
        uploadBytes(imageStorageRef, blob).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            addCaptureToFirestore(downloadURL, parkName);
            const captureTime = new Date().toISOString();
            setCaptureNotification(`Capture taken at: ${captureTime}`);
          });
        });
      }, "image/png");
    }
  };

  const getParkName = async (parkId) => {
    const parkDocRef = doc(db, "Parks", parkId);
    const parkDoc = await getDoc(parkDocRef);
    if (parkDoc.exists()) {
      return parkDoc.data().ParkName;
    } else {
      console.error("Park does not exist!");
      return "";
    }
  };

  const addCaptureToFirestore = async (captureDataUrl, parkName) => {
    try {
      await addDoc(collection(db, "CamReceiver"), {
        CaptureTime: new Date().toISOString(),
        Capture: captureDataUrl,
        ParkName: parkName,
        Linked: false,
      });
      console.log("Capture data added to Firestore.");
    } catch (error) {
      console.error("Error adding capture to Firestore:", error);
      setError("Error adding capture to Firestore: " + error.message);
    }
  };

  const handleParkChange = (event) => {
    setSelectedPark(event.target.value);
  };

  return (
    <div>
      <Typography variant="h4">Cam Receiver</Typography>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        width="600"
        height="400"
        style={{ transform: "scale(-1, 1)" }}
      />
      <Typography variant="body1" sx={{ mt: 2 }}>
        Select Park:
      </Typography>
      <Select
        value={selectedPark}
        onChange={handleParkChange}
        sx={{ mt: 1, mb: 2, width: 200 }}
      >
        {parkOptions.map((park) => (
          <MenuItem key={park.id} value={park.id}>
            {park.ParkName}
          </MenuItem>
        ))}
      </Select>
      {captureNotification && (
        <Alert severity="success">{captureNotification}</Alert>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default CamReceiver;
