import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Chip,
  Grid,
  Avatar,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import PaymentIcon from "@mui/icons-material/Payment";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(id, {
        categories: true,
        creator: true,
        participants: true,
      });

      if (user && eventData.participants) {
        const userFound = eventData.participants.find(
          (p) => p.user.uid === user.uid
        );
        if (userFound) setUserIsParticipant(true);
      }

      setEvent(eventData);
    };

    fetchEventData();
  }, [id, userIsParticipant, user]);

  const handleJoinEvent = async () => {
    try {
      if (!user) {
        setShowRegisterWarning(true);
        return;
      }
      await eventsApi.addParticipantToEvent(id, user.uid, selectedCategory);
      setUserIsParticipant(true);
      setSelectedCategory("");
      console.log("Te has unido al evento correctamente.");
    } catch (error) {
      console.error("Error al unirse al evento:", error);
    }
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
      console.log("Has salido del evento correctamente.");
    } catch (error) {
      console.error("Error al salir del evento:", error);
    }
  };

  const navigateToLocation = (event) => {
    window.open(
      `https://www.google.com/maps?q=${event.location.lat},${event.location.lng}`
    );
  };

  const shareEvent = () => {
    if (navigator.share) {
      const eventUrl = `${window.location.origin}/event/${id}`;
      navigator
        .share({ url: eventUrl })
        .then(() => console.log("Evento compartido correctamente"))
        .catch((error) => console.error("Error al compartir evento:", error));
    } else {
      console.log(
        "La función de compartir no está disponible en este navegador."
      );
    }
  };

  const handleCloseRegisterWarning = () => {
    setShowRegisterWarning(false);
  };

  const handlePaymentClick = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleDateString("es-ES", options);
  };

  if (!event) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={4} justifyContent="center" sx={{ py: 4 }}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {event.eventName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card
            sx={{ borderRadius: 2, boxShadow: "0px 4px 12px rgba(0,0,0,0.1)" }}
          >
            <CardMedia
              component="img"
              height="500"
              image={event.photo}
              alt={`Foto del evento ${event.id}`}
              sx={{ borderRadius: 2, objectFit: "cover" }}
            />

            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                mt={3}
                p={2}
                sx={{ backgroundColor: "#f5f5f5", borderRadius: 1 }}
              >
                <Avatar
                  src={event.creator?.photoUrl}
                  alt={event.creator?.name}
                  sx={{ width: 40, height: 40, mr: 2 }}
                />
                <Typography variant="h6">
                  {event.creator
                    ? ` ${event.creator.name}`
                    : "Creador del evento"}
                </Typography>
              </Box>
              <Stack spacing={1} sx={{ mt: 2 }}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Fecha:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {formatDateTime(event.dateTime)}
                  </Typography>
                </Box>
                {event.price > 0 && (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Precio:
                    </Typography>
                    <Chip
                      label={`$${event.price}`}
                      color="primary"
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  </Box>
                )}
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Descripción:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {event.description}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Categorías disponibles
                  </Typography>
                  <FormControl
                    disabled={userIsParticipant}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    <InputLabel id="category-select-label">
                      Categoría
                    </InputLabel>
                    <Select
                      labelId="category-select-label"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      label="Categoría"
                    >
                      {event.categories &&
                        event.categories.map((c) => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Box
                mt={3}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap={1}
              >
                {event.price > 0 ? (
                  <Button
                    disabled={selectedCategory === ""}
                    variant="contained"
                    sx={{
                      backgroundColor: "#4caf50",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#45a049",
                      },
                      flex: 1,
                    }}
                    onClick={handlePaymentClick}
                    size="large"
                    startIcon={<PaymentIcon />}
                  >
                    Pagar Inscripción
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color={userIsParticipant ? "secondary" : "primary"}
                    startIcon={
                      userIsParticipant ? (
                        <CancelIcon />
                      ) : (
                        <CheckCircleOutlineIcon />
                      )
                    }
                    onClick={() => {
                      if (userIsParticipant) {
                        handleLeaveEvent();
                      } else {
                        handleJoinEvent();
                      }
                    }}
                    size="large"
                    sx={{ flex: 1 }}
                  >
                    {userIsParticipant
                      ? "Salir del Evento"
                      : "Unirse al Evento"}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() => navigateToLocation(event)}
                  size="large"
                  startIcon={<LocationOnIcon />}
                  sx={{ flex: 1 }}
                >
                  Ir a la Ubicación
                </Button>
                <Button
                  variant="outlined"
                  onClick={shareEvent}
                  size="large"
                  startIcon={<ShareIcon />}
                  sx={{ flex: 1 }}
                >
                  Compartir Evento
                </Button>
              </Box>
              <EventParticipants participants={event.participants} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={showRegisterWarning}
        onClose={handleCloseRegisterWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¡Inicia Sesion!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Debes tener una cuenta para unirte al evento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseRegisterWarning();
              navigate("/login");
            }}
            color="primary"
          >
            Iniciar Sesión
          </Button>
          <Button
            onClick={() => {
              handleCloseRegisterWarning();
              navigate("/signup");
            }}
            color="primary"
          >
            Registrarse
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EventDetail;
