import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Stack,
  Avatar,
  Skeleton,
  Box,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";
import TimerIcon from "@mui/icons-material/Timer";
import StartIcon from "@mui/icons-material/PlayArrow";
import FlagIcon from "@mui/icons-material/Flag";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

const medalColors = {
  gold: "linear-gradient(45deg, #FFD700, #FFA500)",
  silver: "linear-gradient(45deg, #C0C0C0, #A9A9A9)",
  bronze: "linear-gradient(45deg, #CD7F32, #8B4513)",
  default: "linear-gradient(45deg, #FFFFFF, #F0F0F0)",
};

const LeaderboardCard = styled(Card)(({ theme, bgcolor }) => ({
  borderRadius: theme.spacing(3),
  overflow: "hidden",
  transition: "all 0.3s ease",
  background: bgcolor || medalColors.default,
  color: "#000",
  cursor: "pointer",
  position: "relative",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
  },
}));

const TimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  color: "#fff",
  fontWeight: "bold",
  padding: theme.spacing(0.5, 1.5),
  fontSize: "0.9rem",
}));

const PositionCircle = styled(Box)(({ theme, position }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  fontSize: "1.2rem",
  border: "2px solid #000",
  zIndex: 1,
}));

const GlassomorphicBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.25)",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
}));

const EventLeaderboard = () => {
  const { uid } = useParams();
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ownerData, setOwnerData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const runsQuery = query(
          collection(db, "Runs"),
          where("EventID", "==", uid)
        );
        const runsSnapshot = await getDocs(runsQuery);
        const runsData = runsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedRuns = runsData.sort(
          (a, b) =>
            parseFloat(a.ElapsedTime.replace(/[:.]/g, "")) -
            parseFloat(b.ElapsedTime.replace(/[:.]/g, ""))
        );

        const bestTimesByUser = sortedRuns.reduce((acc, run) => {
          if (
            !acc[run.NFCIdentifier] ||
            parseFloat(run.ElapsedTime.replace(/[:.]/g, "")) <
              parseFloat(
                acc[run.NFCIdentifier].ElapsedTime.replace(/[:.]/g, "")
              )
          ) {
            acc[run.NFCIdentifier] = run;
          }
          return acc;
        }, {});

        const uniqueRuns = Object.values(bestTimesByUser);

        const ownerPromises = uniqueRuns.map(async (run) => {
          const tagsQuery = query(
            collection(db, "Tags"),
            where("NFCID", "==", run.NFCIdentifier)
          );
          const tagsSnapshot = await getDocs(tagsQuery);

          if (!tagsSnapshot.empty) {
            const ownerUID = tagsSnapshot.docs[0].data().Owner;
            const userQuery = query(
              collection(db, "Users"),
              where("uid", "==", ownerUID)
            );
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
              const userData = userSnapshot.docs[0].data();
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: userData.name,
                PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
              };
            }
          }
          return {
            NFCIdentifier: run.NFCIdentifier,
            Owner: "Desconocido",
            PhotoUrl: "/default-avatar.jpg",
          };
        });

        const ownerResults = await Promise.all(ownerPromises);
        const ownerDataMap = ownerResults.reduce((acc, ownerResult) => {
          acc[ownerResult.NFCIdentifier] = {
            Owner: ownerResult.Owner,
            PhotoUrl: ownerResult.PhotoUrl,
          };
          return acc;
        }, {});

        setOwnerData(ownerDataMap);
        setRuns(uniqueRuns);
      } catch (error) {
        console.error("Error fetching runs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRuns();
  }, [uid]);

  const getTimeDifference = (firstTime, currentTime) => {
    const firstInMs = timeToMilliseconds(firstTime);
    const currentInMs = timeToMilliseconds(currentTime);
    const diffInMs = currentInMs - firstInMs;

    const diffSeconds = Math.floor(diffInMs / 1000) % 60;
    const diffMinutes = Math.floor(diffInMs / (1000 * 60)) % 60;
    const diffHours = Math.floor(diffInMs / (1000 * 60 * 60));

    return `${
      diffHours > 0 ? `${diffHours}h ` : ""
    }${diffMinutes}m ${diffSeconds}s`;
  };

  const timeToMilliseconds = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const [secs, ms] = seconds.split(".");
    return (
      parseInt(hours) * 3600000 +
      parseInt(minutes) * 60000 +
      parseInt(secs) * 1000 +
      parseInt(ms)
    );
  };

  const handleCardClick = (runId) => {
    navigate(`/run/${runId}`);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3, mb: 6 }}>
        <Grid container spacing={4}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton
                variant="rectangular"
                height={250}
                sx={{ bgcolor: "grey.200", borderRadius: 3 }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 6 }}>
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold", color: "#000", mb: 4 }}
      >
        Leaderboard del Evento
      </Typography>
      <Grid container spacing={4}>
        {runs.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ width: "100%" }}>
            No hay resultados disponibles para este evento.
          </Typography>
        ) : (
          runs.map((run, index) => {
            const position = index + 1;
            const backgroundColor =
              position <= 3
                ? medalColors[Object.keys(medalColors)[position - 1]]
                : medalColors.default;
            const timeDifference =
              position > 1
                ? getTimeDifference(runs[0].ElapsedTime, run.ElapsedTime)
                : null;
            const owner = ownerData[run.NFCIdentifier] || {};

            return (
              <Grid item xs={12} sm={6} md={4} key={run.id}>
                <LeaderboardCard
                  bgcolor={backgroundColor}
                  onClick={() => handleCardClick(run.id)}
                >
                  <CardContent sx={{ p: 3 }}>
                    <PositionCircle position={position}>
                      {position}
                    </PositionCircle>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Avatar
                        src={owner.PhotoUrl}
                        alt={owner.Owner || "Desconocido"}
                        sx={{
                          width: 60,
                          height: 60,
                          mr: 2,
                          border: "2px solid #FFF",
                        }}
                      />
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {owner.Owner || "Desconocido"}
                        </Typography>
                      </Box>
                    </Box>
                    <GlassomorphicBox>
                      <Stack spacing={2}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <TimerIcon sx={{ mr: 1, color: "#000" }} />
                            <Typography variant="body2" fontWeight="bold">
                              Tiempo:
                            </Typography>
                          </Box>
                          <TimeChip label={run.ElapsedTime} />
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <StartIcon sx={{ mr: 1, color: "#000" }} />
                            <Typography variant="body2" fontWeight="bold">
                              Inicio:
                            </Typography>
                          </Box>
                          <Typography variant="body2">
                            {run.StartTime}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <TrackChangesIcon sx={{ mr: 1, color: "#000" }} />
                            <Typography variant="body2" fontWeight="bold">
                              Track ID:
                            </Typography>
                          </Box>
                          <Typography variant="body2">{run.TrackID}</Typography>
                        </Stack>
                        {timeDifference && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              textAlign: "right",
                            }}
                          >{`+ ${timeDifference} del 1er lugar`}</Typography>
                        )}
                      </Stack>
                    </GlassomorphicBox>
                  </CardContent>
                </LeaderboardCard>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container>
  );
};

export default EventLeaderboard;
