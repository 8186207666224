import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
  Alert,
  CircularProgress,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Html5Qrcode } from "html5-qrcode";

const SendSpeedCoins = ({ onClose, user }) => {
  const [recipientUID, setRecipientUID] = useState("");
  const [amount, setAmount] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [balance, setBalance] = useState(0);
  const [showScanner, setShowScanner] = useState(false);
  const [validRecipient, setValidRecipient] = useState(false);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const qrCodeRegionId = "qr-code-region";

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const senderDocRef = doc(db, "Users", user.uid);
        const senderDoc = await getDoc(senderDocRef);
        if (senderDoc.exists()) {
          setBalance(parseFloat(senderDoc.data().balance) || 0);
        }
      } catch (error) {
        console.error("Error al obtener el balance del usuario:", error);
        setError("Error al obtener el balance del usuario.");
      }
    };

    fetchBalance();
  }, [user.uid]);

  const handleSendCoins = async () => {
    if (!recipientUID || !amount || amount <= 0 || recipientUID === user.uid) {
      setError("No puedes enviarte SpeedCoins a ti mismo.");
      return;
    }
    const amountNum = parseFloat(amount);
    if (amountNum > balance) {
      setError("Fondos insuficientes");
      return;
    }
    setLoading(true);
    try {
      const senderDocRef = doc(db, "Users", user.uid);
      const senderDoc = await getDoc(senderDocRef);

      if (senderDoc.exists() && senderDoc.data().balance >= amountNum) {
        const recipientDocRef = doc(db, "Users", recipientUID);
        const recipientDoc = await getDoc(recipientDocRef);

        if (recipientDoc.exists()) {
          const currentBalance = (await getDoc(senderDocRef)).data().balance;
          if (currentBalance < amountNum) {
            setError("Fondos insuficientes al momento de la transferencia");
            setLoading(false);
            return;
          }

          await updateDoc(senderDocRef, {
            balance: currentBalance - amountNum,
          });
          await updateDoc(recipientDocRef, {
            balance: (recipientDoc.data().balance || 0) + amountNum,
          });

          await addDoc(collection(db, "coinstransactions"), {
            senderUID: user.uid,
            receiverUID: recipientUID,
            amount: amountNum,
            date: new Date(),
            comment: comment.trim(),
          });

          setSuccess("SpeedCoins enviados exitosamente");
          setBalance(balance - amountNum);
          setRecipientUID("");
          setAmount("");
          setComment("");
          setRemainingBalance(0);

          // Animación de éxito y cierre del modal
          setTimeout(() => {
            setAnimationComplete(true);
          }, 2000);
        } else {
          setError("Usuario destinatario no encontrado");
        }
      } else {
        setError("Fondos insuficientes o usuario no encontrado");
      }
    } catch (error) {
      console.error("Error al enviar SpeedCoins:", error);
      setError("Error al enviar SpeedCoins. Intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleScan = (decodedText) => {
    setRecipientUID(decodedText);
    setShowScanner(false);
    checkRecipient(decodedText);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const checkRecipient = async (uid) => {
    try {
      const recipientDocRef = doc(db, "Users", uid);
      const recipientDoc = await getDoc(recipientDocRef);
      setValidRecipient(recipientDoc.exists());
    } catch (error) {
      console.error("Error al verificar el UID del destinatario:", error);
      setValidRecipient(false);
    }
  };

  useEffect(() => {
    if (recipientUID) {
      checkRecipient(recipientUID);
    }
  }, [recipientUID]);

  useEffect(() => {
    const amountNum = parseFloat(amount);
    if (amountNum > 0 && amountNum <= balance) {
      setRemainingBalance(balance - amountNum);
    } else {
      setRemainingBalance(balance);
    }
  }, [amount, balance]);

  useEffect(() => {
    if (showScanner) {
      const html5QrCode = new Html5Qrcode(qrCodeRegionId);
      html5QrCode.start(
        { facingMode: "environment" },
        {
          fps: 10,
          qrbox: 250,
        },
        handleScan,
        handleError
      );

      return () => {
        html5QrCode.stop().catch((err) => console.error(err));
      };
    }
  }, [showScanner]);

  useEffect(() => {
    if (animationComplete) {
      onClose();
    }
  }, [animationComplete, onClose]);

  return (
    <Modal
      open={true}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={true}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: 4,
              maxWidth: 600,
              width: "100%",
              position: "relative",
              boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
            }}
          >
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Enviar SpeedCoins
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box display="flex" flexDirection="column" mb={2}>
              <TextField
                label="UID del Destinatario"
                variant="outlined"
                fullWidth
                value={recipientUID}
                onChange={(e) => setRecipientUID(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowScanner(!showScanner)}>
                        <AccountBalanceWalletIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showScanner && (
                <Box mb={2} id={qrCodeRegionId} sx={{ width: "100%" }} />
              )}
              <TextField
                label="Cantidad"
                variant="outlined"
                fullWidth
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Comentario"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Typography variant="body2" sx={{ mb: 2 }}>
                Balance Disponible: ${balance.toFixed(2)}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Balance Restante: ${remainingBalance.toFixed(2)}
              </Typography>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              )}
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={handleSendCoins}
                fullWidth
                disabled={loading || !validRecipient || amount <= 0}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                  background: "linear-gradient(45deg, #2196F3, #21CBF3)",
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Enviar SpeedCoins"}
              </Button>
            </Box>
          </Paper>
        </Container>
      </Fade>
    </Modal>
  );
};

export default SendSpeedCoins;
