import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  useTheme,
} from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import BuildIcon from "@mui/icons-material/Build";
import StraightenIcon from "@mui/icons-material/Straighten";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const ProfileBikes = ({ uid }) => {
  const [bikes, setBikes] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchBikes = async () => {
      const firestore = getFirestore();
      const bikesCollection = collection(firestore, "BikeGarage");
      const q = query(bikesCollection, where("userId", "==", uid));
      const querySnapshot = await getDocs(q);
      const bikesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBikes(bikesList);
      setLoading(false);
    };

    fetchBikes();
  }, [uid]);

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (bikes.length === 0) {
    return (
      <Container sx={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h6">
          No se encontraron bicicletas para este usuario.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Mis Bicicletas
      </Typography>
      <Grid container spacing={3}>
        {bikes.map((bike) => (
          <Grid item xs={12} sm={6} md={4} key={bike.id}>
            <Card sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                height="200"
                image={bike.images || "default_image_url"} // Coloca una URL de imagen predeterminada si no hay imagen
                alt={bike.name}
              />
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {bike.name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <DirectionsBikeIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Marca: {bike.brand}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <BuildIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Tipo: {bike.type}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <StraightenIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Tamaño: {bike.size}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RadioButtonCheckedIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="textSecondary">
                    Tamaño de Rueda: {bike.wheelSize}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProfileBikes;
