import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const EmergencyContacts = ({
  emergencyContacts,
  handleAddEmergencyContact,
  handleRemoveEmergencyContact,
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        <strong>Contactos de Emergencia</strong>
      </Typography>
      {emergencyContacts.map((contact, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <Box>
            <Typography>Nombre: {contact.name}</Typography>
            <Typography>Teléfono: {contact.phoneNumber}</Typography>
          </Box>
          <IconButton onClick={() => handleRemoveEmergencyContact(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={handleAddEmergencyContact}
        fullWidth
        sx={{ mb: 2 }}
      >
        Agregar Contacto de Emergencia
      </Button>
    </Box>
  );
};

export default EmergencyContacts;
