import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  IconButton,
  TextField,
  MenuItem,
  Paper,
  Divider,
} from "@mui/material";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useUserAuth } from "../../../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

const MyReceivers = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState({});
  const [ownerDetails, setOwnerDetails] = useState({});
  const [searchUid, setSearchUid] = useState(""); // Search state for UID
  const [selectedPark, setSelectedPark] = useState(""); // Filter state for Park
  const [selectedTrack, setSelectedTrack] = useState(""); // Filter state for Track

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
  }, [user, navigate]);

  const mapContainerStyle = {
    height: "100px",
    width: "100%",
    borderRadius: "8px",
  };

  const fetchParks = async () => {
    const parksQuery = await getDocs(collection(db, "Parks"));
    let parksData = {};
    parksQuery.forEach((doc) => {
      parksData[doc.id] = doc.data();
    });
    setParks(parksData);
  };

  const fetchOwnerDetails = async (owners) => {
    let ownerDetailsTemp = {};
    for (const ownerUid of owners) {
      const userDoc = await getDoc(doc(db, "Users", ownerUid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        ownerDetailsTemp[ownerUid] = {
          name: data.name || "Nombre no disponible",
          photoUrl: data.photoUrl || "",
        };
      } else {
        ownerDetailsTemp[ownerUid] = {
          name: "Nombre no disponible",
          photoUrl: "",
        };
      }
    }
    return ownerDetailsTemp;
  };

  useEffect(() => {
    const fetchReceivers = async () => {
      const receiversQuery = await getDocs(collection(db, "Receivers"));
      const receiversData = receiversQuery.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(
          (receiver) =>
            Array.isArray(receiver.Owners) && receiver.Owners.includes(user.uid)
        );

      for (const receiver of receiversData) {
        if (Array.isArray(receiver.Owners) && receiver.Owners.length > 0) {
          const details = await fetchOwnerDetails(receiver.Owners);
          setOwnerDetails((prev) => ({ ...prev, ...details }));
        }
      }

      setReceivers(receiversData);
      setLoading(false);
    };

    if (user) {
      fetchReceivers();
      fetchParks(); // Aquí corregimos la referencia de fetchParks, eliminando la función errónea.
    }
  }, [user]);

  const handleSearch = (e) => {
    setSearchUid(e.target.value);
  };

  const handleParkFilter = (e) => {
    setSelectedPark(e.target.value);
    setSelectedTrack(""); // Clear track filter when park is changed
  };

  const handleTrackFilter = (e) => {
    setSelectedTrack(e.target.value);
  };

  const filteredReceivers = receivers.filter((receiver) => {
    return (
      (searchUid === "" || receiver.id.includes(searchUid)) &&
      (selectedPark === "" || receiver.ParkID === selectedPark) &&
      (selectedTrack === "" || receiver.TrackID === selectedTrack)
    );
  });

  if (!user || loading) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" align="center">
          Cargando...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Mis Receivers
      </Typography>

      {/* Search and Filter Section */}
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <TextField
          label="Buscar por UID"
          variant="outlined"
          fullWidth
          value={searchUid}
          onChange={handleSearch}
        />
        <TextField
          select
          label="Filtrar por Parque"
          variant="outlined"
          fullWidth
          value={selectedPark}
          onChange={handleParkFilter}
        >
          <MenuItem value="">Todos los Parques</MenuItem>
          {Object.keys(parks).map((parkId) => (
            <MenuItem key={parkId} value={parkId}>
              {parks[parkId].ParkName}
            </MenuItem>
          ))}
        </TextField>
        {selectedPark && (
          <TextField
            select
            label="Filtrar por Pista"
            variant="outlined"
            fullWidth
            value={selectedTrack}
            onChange={handleTrackFilter}
          >
            <MenuItem value="">Todas las Pistas</MenuItem>
            {/* Aquí podrías cargar las pistas relacionadas a selectedPark */}
            {/* Ejemplo: */}
            <MenuItem value="Track1">Pista 1</MenuItem>
            <MenuItem value="Track2">Pista 2</MenuItem>
          </TextField>
        )}
      </Box>

      <Grid container spacing={2}>
        {filteredReceivers.map((receiver) => {
          const parkName =
            parks[receiver.ParkID]?.ParkName || "Parque desconocido";
          const isStartTime = receiver.Type === "0";
          const backgroundColor = isStartTime ? "#e0f7e0" : "#fce4e4"; // Verde claro para StartTime, rojo claro para FinishTime
          const iconUrl = isStartTime
            ? "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/receivericonos%2Fbandera-de-carreras.png?alt=media&token=c2cd9b0c-fd84-4214-9959-75d637db0a64"
            : "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/receivericonos%2Fpunto-de-partida.png?alt=media&token=621c68e1-4c07-4e1d-8f72-7bc154121fdc";
          const label = isStartTime ? "Inicio" : "Llegada";

          return (
            <Grid item xs={12} key={receiver.id}>
              <Paper
                sx={{
                  backgroundColor: backgroundColor,
                  borderRadius: 4,
                  padding: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  boxShadow: 3,
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <img
                    src={iconUrl}
                    alt={label}
                    style={{ width: 50, height: 50 }}
                  />
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {label}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#555" }}>
                      <strong>UID:</strong> {receiver.id}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Parque:</strong> {parkName}
                    </Typography>
                    <Typography variant="body2">
                      <strong>TrackID:</strong> {receiver.TrackID || "N/A"}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Owners:</strong>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {receiver.Owners?.map((ownerUid) => (
                        <Box
                          key={ownerUid}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <img
                            src={ownerDetails[ownerUid]?.photoUrl}
                            alt={ownerDetails[ownerUid]?.name}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                          <Typography variant="body2">
                            {ownerDetails[ownerUid]?.name || "Cargando..."}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: receiver.Location.lat,
                        lng: receiver.Location.lng,
                      }}
                      zoom={14}
                    >
                      <Marker
                        position={{
                          lat: receiver.Location.lat,
                          lng: receiver.Location.lng,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                  <IconButton
                    onClick={() => navigate(`/receiver/${receiver.id}`)}
                    aria-label="settings"
                    sx={{
                      alignSelf: "flex-end",
                      mt: 2,
                      color: "#000",
                      backgroundColor: "#fff",
                      borderRadius: 2,
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default MyReceivers;
