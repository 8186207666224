import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useUserAuth } from "../../../context/userAuthContext";

const ReceiverForm = () => {
  const { uid } = useParams(); // Obtener el UID del receiver si se está editando
  const { user } = useUserAuth(); // Obtener el usuario logueado
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState({
    Type: "",
    Location: null,
    ParkID: "",
    TrackID: "",
    EventID: "",
    Owners: [user?.uid], // Incluir el UID del usuario actual como dueño
  });
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [events, setEvents] = useState([]); // Guardar los eventos
  const [newOwner, setNewOwner] = useState("");
  const [location, setLocation] = useState(null); // Latitud y longitud

  const mapContainerStyle = {
    height: "300px",
    width: "100%",
  };

  // Función para cargar datos del receiver (si se está editando)
  const fetchReceiver = async () => {
    if (uid) {
      const receiverDoc = await getDoc(doc(db, "Receivers", uid));
      if (receiverDoc.exists()) {
        const receiverData = receiverDoc.data();
        setReceiver(receiverData);
        setLocation(receiverData.Location); // Inicializar la ubicación con la actual
      } else {
        console.error("Receiver no encontrado");
        navigate("/"); // Redirigir si no se encuentra el receiver
      }
    }
    setLoading(false);
  };

  const fetchParks = async () => {
    const parksQuery = await getDocs(collection(db, "Parks"));
    const parksData = parksQuery.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setParks(parksData);
  };

  const fetchTracks = async (parkId) => {
    const tracksQuery = await getDocs(collection(db, `Parks/${parkId}/Tracks`));
    const tracksData = tracksQuery.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTracks(tracksData);
  };

  const fetchEvents = async () => {
    const eventsQuery = await getDocs(collection(db, "events"));
    const eventsData = eventsQuery.docs.map((doc) => ({
      id: doc.id, // Almacenar EventID
      eventName: doc.data().eventName, // Obtener eventName
    }));
    setEvents(eventsData);
  };

  const getUserLocation = () => {
    // Pedir permisos de geolocalización
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error obteniendo ubicación del usuario:", error);
        }
      );
    } else {
      console.error("Geolocalización no es soportada por el navegador");
    }
  };

  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setLocation({ lat, lng });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReceiver((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddOwner = () => {
    if (newOwner) {
      setReceiver((prev) => ({
        ...prev,
        Owners: [...prev.Owners, newOwner],
      }));
      setNewOwner("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (uid) {
        // Si estamos editando, actualizamos el receiver existente
        await updateDoc(doc(db, "Receivers", uid), {
          Type: receiver.Type,
          Location: location,
          ParkID: receiver.ParkID,
          TrackID: receiver.TrackID,
          EventID: receiver.EventID, // Agregar EventID
          Owners: receiver.Owners,
        });
      } else {
        // Si estamos creando, añadimos un nuevo receiver
        await addDoc(collection(db, "Receivers"), {
          ...receiver,
          Location: location,
          createdAt: new Date(),
        });
      }
      setLoading(false);
      navigate(`/receivers`);
    } catch (error) {
      console.error("Error guardando receiver:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParks();
    fetchEvents(); // Obtener eventos al montar el componente
    getUserLocation(); // Obtener ubicación del usuario
    if (uid) fetchReceiver(); // Solo cargar datos si estamos en modo edición
  }, [uid]);

  useEffect(() => {
    if (receiver?.ParkID) {
      fetchTracks(receiver.ParkID);
    }
  }, [receiver?.ParkID]);

  if (loading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          {uid ? "Editar Receiver" : "Crear Receiver"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo</InputLabel>
                <Select
                  name="Type"
                  value={receiver?.Type || ""}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="0">StartTime</MenuItem>
                  <MenuItem value="1">FinishTime</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={location || { lat: 19.4326, lng: -99.1332 }} // Default to Mexico City if location is not set
                  zoom={12}
                  onClick={handleMapClick}
                >
                  {location && <Marker position={location} />}
                </GoogleMap>
              </LoadScript>
              {location && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Ubicación seleccionada: {location.lat}, {location.lng}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Parque</InputLabel>
                <Select
                  name="ParkID"
                  value={receiver?.ParkID || ""}
                  onChange={(e) => {
                    handleChange(e);
                    fetchTracks(e.target.value); // Actualizar las pistas al cambiar de parque
                  }}
                  required
                >
                  {parks.map((park) => (
                    <MenuItem key={park.id} value={park.id}>
                      {park.ParkName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Pista</InputLabel>
                <Select
                  name="TrackID"
                  value={receiver?.TrackID || ""}
                  onChange={handleChange}
                  required
                >
                  {tracks.map((track) => (
                    <MenuItem key={track.id} value={track.id}>
                      {track.TrackName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Evento</InputLabel>
                <Select
                  name="EventID"
                  value={receiver?.EventID || ""}
                  onChange={handleChange}
                  required
                >
                  {events.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.eventName}{" "}
                      {/* Mostrar eventName pero guardar EventID */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Owners actuales:</strong>
              </Typography>
              <ul>
                {receiver?.Owners.map((ownerUid) => (
                  <li key={ownerUid}>{ownerUid}</li>
                ))}
              </ul>
              <TextField
                label="Agregar nuevo Owner UID"
                value={newOwner}
                onChange={(e) => setNewOwner(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleAddOwner}
                sx={{ mt: 2 }}
              >
                Agregar Owner
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{ mt: 2 }}
              >
                {uid ? "Guardar cambios" : "Crear Receiver"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ReceiverForm;
