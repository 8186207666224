import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import {
  Button,
  Card,
  CardMedia,
  Typography,
  Container,
  Grid,
  Box,
  CardContent,
  CardActionArea,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext";

const MyParks = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [parks, setParks] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user && user.uid) {
      const q = query(
        collection(db, "Parks"),
        where("Owners", "array-contains", user.uid),
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const parksData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParks(parksData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const redirectToPark = (parkId) => {
    navigate(`/parkpanel/${parkId}`);
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center">
        Mis Parques
      </Typography>
      <Grid container spacing={2}>
        {parks.map((park) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={park.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <CardActionArea onClick={() => redirectToPark(park.id)}>
                <CardMedia
                  component="img"
                  height={isMobile ? "200" : "300"}
                  image={park.Cover}
                  alt={`Foto del parque ${park.ParkName}`}
                  style={{ objectFit: "cover" }}
                />
                <CardContent sx={{ p: 2 }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      src={park.Logo}
                      alt={`${park.ParkName} logo`}
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="h6" component="div">
                      {park.ParkName}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Ubicación: {park.city}, {park.state}, {park.country}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Seguidores:{" "}
                    {Array.isArray(park.Followers) ? park.Followers.length : 0}
                  </Typography>
                  {park.location && (
                    <Typography variant="body2" color="textSecondary">
                      Latitud: {park.location.latitude}, Longitud:{" "}
                      {park.location.longitude}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
              <Box p={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => redirectToPark(park.id)}
                  sx={{ borderRadius: 50 }}
                >
                  Ver Detalles
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MyParks;
