import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BatteryChart = ({ chartData }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      title: {
        display: true,
        text: "Relación Tiempo - Batería",
        font: {
          size: 20,
          weight: "bold",
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        padding: 12,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Tiempo",
          font: {
            size: 16,
            weight: "bold",
          },
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          maxTicksLimit: 8,
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Porcentaje de Batería (%)",
          font: {
            size: 16,
            weight: "bold",
          },
        },
        ticks: {
          maxTicksLimit: 6,
          callback: function (value) {
            return value + "%";
          },
          font: {
            size: 12,
          },
        },
        grid: {
          color: "rgba(0, 0, 0, 0.3)",
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 2,
        borderColor: "rgba(76, 175, 80, 0.8)", // Green color
        backgroundColor: "rgba(76, 175, 80, 0.1)", // Light green fill
        fill: true, // To create an area chart effect
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 6,
      },
    },
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default BatteryChart;
