import React from "react";
import { Typography, Box } from "@mui/material";

const CreacionRodadas = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Creación de Rodadas
      </Typography>
      <Typography variant="body1" paragraph>
        Aprende a crear rodadas en Livespeed y gestionar a los participantes.
      </Typography>
      {/* Aquí puedes agregar más contenido detallado sobre la creación de rodadas */}
    </Box>
  );
};

export default CreacionRodadas;
