import React, { useState } from "react";
import {
  Box,
  Fab,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ViewListIcon from "@mui/icons-material/ViewList";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const FloatingMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreateRideClick = () => {
    navigate("/CreateRide");
    handleClose();
  };

  const handleMyRidesClick = () => {
    navigate("/MyRides");
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 80,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Fab
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            borderRadius: "20px",
            padding: "40px 30px 30px",
            maxWidth: "360px",
            width: "90%",
            position: "relative",
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "#000",
            color: "#fff",
            "&:hover": { backgroundColor: "#333" },
            zIndex: 1,
            width: "36px",
            height: "36px",
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
        <DialogContent sx={{ padding: "0 !important" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Fab
              variant="extended"
              sx={{
                width: "100%",
                height: "70px",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              onClick={handleCreateRideClick}
            >
              <DirectionsBikeIcon sx={{ mr: 2, fontSize: "1.5rem" }} />
              <Typography variant="button" sx={{ fontSize: "1.1rem" }}>
                Create Ride
              </Typography>
            </Fab>
            <Fab
              variant="extended"
              sx={{
                width: "100%",
                height: "70px",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              onClick={handleMyRidesClick}
            >
              <ViewListIcon sx={{ mr: 2, fontSize: "1.5rem" }} />
              <Typography variant="button" sx={{ fontSize: "1.1rem" }}>
                My Rides
              </Typography>
            </Fab>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingMenu;
