import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";

const TeamActionButton = ({ teamId }) => {
  const { user } = useUserAuth();
  const [teamData, setTeamData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const [joining, setJoining] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  useEffect(() => {
    const teamRef = doc(db, "Teams", teamId);
    const unsubscribe = onSnapshot(teamRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setTeamData(data);
        if (user) {
          setIsMember(data.members.includes(user.uid));
          setHasRequested(data.requests.includes(user.uid));
        }
      }
    });

    return () => unsubscribe();
  }, [teamId, user]);

  const handleJoinTeam = async () => {
    if (!user) return;
    setJoining(true);

    try {
      const teamRef = doc(db, "Teams", teamId);
      if (teamData.private) {
        await updateDoc(teamRef, {
          requests: arrayUnion(user.uid),
        });
      } else {
        await updateDoc(teamRef, {
          members: arrayUnion(user.uid),
        });
      }
    } catch (error) {
      console.error("Error joining team:", error);
    } finally {
      setJoining(false);
    }
  };

  const handleLeaveTeam = async () => {
    if (!user) return;

    try {
      const teamRef = doc(db, "Teams", teamId);
      await updateDoc(teamRef, {
        members: arrayRemove(user.uid),
      });
    } catch (error) {
      console.error("Error leaving team:", error);
    } finally {
      setOpenLeaveDialog(false);
    }
  };

  const handleCancelRequest = async () => {
    if (!user) return;

    try {
      const teamRef = doc(db, "Teams", teamId);
      await updateDoc(teamRef, {
        requests: arrayRemove(user.uid),
      });
    } catch (error) {
      console.error("Error cancelling request:", error);
    } finally {
      setOpenCancelDialog(false);
    }
  };

  if (!teamData) {
    return <CircularProgress />;
  }

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {isMember ? (
        <>
          <Button variant="contained" color="success" disabled>
            Eres Miembro
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenLeaveDialog(true)}
            sx={{ ml: 2 }}
          >
            Salir del Equipo
          </Button>
        </>
      ) : hasRequested ? (
        <>
          <Button variant="contained" color="warning" disabled>
            Solicitud Enviada
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenCancelDialog(true)}
            sx={{ ml: 2 }}
          >
            Cancelar Solicitud
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleJoinTeam}
          disabled={joining}
        >
          {teamData.private ? "Solicitar Unirse" : "Unirse al Equipo"}
        </Button>
      )}

      {/* Dialog para salir del equipo */}
      <Dialog open={openLeaveDialog} onClose={() => setOpenLeaveDialog(false)}>
        <DialogTitle>Confirmar Salida</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres salir del equipo {teamData.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLeaveDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleLeaveTeam} color="error">
            Salir
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para cancelar solicitud */}
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogTitle>Cancelar Solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres cancelar tu solicitud para unirte al
            equipo {teamData.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={handleCancelRequest} color="error">
            Sí, cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeamActionButton;
