import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputBase,
  Box,
  Button,
  Container,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { useNavigate } from "react-router-dom";
import FloatingMenu from "./FloatingMenu";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f7f7f7",
  "&:hover": {
    backgroundColor: "#eaeaea",
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
    marginLeft: theme.spacing(2),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const RidesFiltersAndNavigation = ({
  filterDate,
  setFilterDate,
  filterBikeType,
  setFilterBikeType,
  availableBikeTypes,
  searchTerm,
  setSearchTerm,
  resetFilters,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleCreateRideClick = () => navigate("/CreateRide");
  const handleMyRidesClick = () => navigate("/MyRides");

  const handleResetFilters = () => {
    resetFilters();
    setFiltersOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search rides…"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              edge="end"
              onClick={() => setFiltersOpen(!filtersOpen)}
              color="inherit"
            >
              <FilterListIcon />
            </IconButton>
            {!isMobile && (
              <>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCreateRideClick}
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                    ml: 2,
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                >
                  Create Ride
                </Button>
                <Button
                  startIcon={<DirectionsBikeIcon />}
                  onClick={handleMyRidesClick}
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                    ml: 2,
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                >
                  My Rides
                </Button>
              </>
            )}
          </Toolbar>
          <FloatingMenu />
        </Container>
      </AppBar>

      {filtersOpen && (
        <Box sx={{ p: 2, backgroundColor: "#f7f7f7", pb: 4 }}>
          <Container maxWidth="lg">
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Filter by date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
                fullWidth
              />
              <Select
                value={filterBikeType}
                onChange={(e) => setFilterBikeType(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="">
                  <em>All bike types</em>
                </MenuItem>
                {availableBikeTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="outlined"
                onClick={handleResetFilters}
                fullWidth
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#333",
                  },
                }}
              >
                Reset Filters
              </Button>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default RidesFiltersAndNavigation;
