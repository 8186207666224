import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  authDomain: "mtbking-aa657.firebaseapp.com",
  projectId: "mtbking-aa657",
  storageBucket: "mtbking-aa657.appspot.com",
  messagingSenderId: "460951187112",
  appId: "1:460951187112:web:d0487b2a2b8c32fba81c9d",
  measurementId: "G-699BYTV4Z9",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { auth, db, storage, messaging, analytics, app as default };
