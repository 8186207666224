import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";

const ProductDetailsForm = ({
  productData,
  handleChange,
  categories,
  subCategories,
  errors,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nombre del Producto"
          type="text"
          name="name"
          value={productData.name}
          onChange={handleChange}
          required
          error={!!errors.name}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          label="Categoría"
          name="category"
          value={productData.category}
          onChange={handleChange}
          required
          error={!!errors.category}
          helperText={errors.category}
        >
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.type}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          label="Subcategoría"
          name="subCategory"
          value={productData.subCategory}
          onChange={handleChange}
          required
          error={!!errors.subCategory}
          helperText={errors.subCategory}
        >
          {subCategories.map((subCategory) => (
            <MenuItem key={subCategory.id} value={subCategory.id}>
              {subCategory.type}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Descripción"
          type="text"
          name="description"
          value={productData.description}
          onChange={handleChange}
          required
          multiline
          rows={4}
          error={!!errors.description}
          helperText={errors.description}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Precio"
          type="number"
          name="price"
          value={productData.price}
          onChange={handleChange}
          required
          error={!!errors.price}
          helperText={errors.price}
        />
      </Grid>
    </Grid>
  );
};

export default ProductDetailsForm;
