import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
  addDoc,
  getDocs,
} from "firebase/firestore";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Alert,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./MyTags.css";

const MyTags = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [tagData, setTagData] = useState({ NFCID: "", TagType: "" });
  const [userTags, setUserTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user && user.uid) {
      const firestore = getFirestore();
      const q = query(
        collection(firestore, "Tags"),
        where("Owner", "==", user.uid)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tags = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserTags(tags);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.hasProfile) {
      navigate("/MyTags");
    }
  }, [user, navigate]);

  const handleScanNFC = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      ndef.onreading = (event) => {
        const serialNumber = event.serialNumber || "Unknown";
        setTagData((prevData) => ({ ...prevData, NFCID: serialNumber }));
      };

      ndef.onreadingerror = () => {
        console.log("Error al leer el tag NFC");
      };
    } catch (error) {
      console.error("Error al iniciar el escaneo NFC: " + error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTagData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const firestore = getFirestore();
      const tagsCollectionRef = collection(firestore, "Tags");

      const nfcQuery = query(
        tagsCollectionRef,
        where("NFCID", "==", tagData.NFCID)
      );
      const querySnapshot = await getDocs(nfcQuery);

      const isTagRegisteredElsewhere = querySnapshot.docs.some(
        (doc) => doc.data().Owner !== user.uid
      );
      const isTagAlreadyRegistered = querySnapshot.docs.some(
        (doc) => doc.data().Owner === user.uid
      );

      if (isTagRegisteredElsewhere) {
        setError("Este NFC ID ya está registrado en otra cuenta.");
        setLoading(false);
        return;
      } else if (isTagAlreadyRegistered) {
        setError("Este NFC ID ya está agregado a tu cuenta.");
        setLoading(false);
        return;
      }

      await addDoc(tagsCollectionRef, {
        NFCID: tagData.NFCID,
        TagType: tagData.TagType,
        Owner: user.uid,
      });

      setTagData({ NFCID: "", TagType: "" });
      setError(null);
      setLoading(false);
    } catch (error) {
      console.error("Error al agregar tag:", error);
      setError("Error al agregar el tag.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const firestore = getFirestore();
      await deleteDoc(doc(firestore, "Tags", id));
    } catch (error) {
      console.error("Error al eliminar el tag:", error);
    }
  };

  return (
    <div className="my-tags-container">
      <h3>Agrega tus tags!</h3>
      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Agrega tu NFCID"
            variant="outlined"
            name="NFCID"
            value={tagData.NFCID}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={handleScanNFC}>
            Escanear Tag NFC
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tag-type-label">
              Selecciona un tipo de Tag
            </InputLabel>
            <Select
              labelId="tag-type-label"
              id="TagType"
              name="TagType"
              value={tagData.TagType}
              label="Selecciona un tipo de Tag"
              onChange={handleChange}
              required
            >
              <MenuItem value="Pulsera NFC">Pulsera NFC</MenuItem>
              <MenuItem value="Llavero NFC">Llavero NFC</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Agregar Tag
          </Button>
        </Grid>
      </Grid>

      {error && <Alert severity="error">{error}</Alert>}

      <List>
        <h3>Mis Tags</h3>
        {userTags.map((tag) => (
          <ListItem
            key={tag.id}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(tag.id)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={`NFCID: ${tag.NFCID}`}
              secondary={`Tipo: ${tag.TagType}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MyTags;
