import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { eventsApi } from "../../lib/client";
import EventInfoCard from "./EventInfoCard";

function EventManagerDashboard() {
  const nav = useNavigate();
  const { user } = useUserAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [myEvents, setMyEvents] = useState(null);

  useEffect(() => {
    const fetchMyEvents = async () => {
      const events = await eventsApi.getEventsByEventManagerUid(user.uid);
      setMyEvents(events);
      setIsLoading(false);
    };
    fetchMyEvents();
  }, [user]);

  const handleCreateEventClick = () => {
    nav("create-event");
  };

  if (isLoading || !user) return <>Loading...</>;

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">Mis eventos</Typography>
        <Button variant="contained" onClick={handleCreateEventClick}>
          Crear evento
        </Button>
      </Stack>
      <Stack spacing={2} py={2}>
        {myEvents && myEvents.length > 0 ? (
          myEvents.map((e) => (
            <EventInfoCard
              key={e.id}
              event={e}
              actionButtons={
                <Button
                  variant="outlined"
                  onClick={() => {
                    nav(e.id);
                  }}
                >
                  Ver evento
                </Button>
              }
            />
          ))
        ) : (
          <Typography>Parece que no hay eventos aun!</Typography>
        )}
      </Stack>
    </Box>
  );
}

export default EventManagerDashboard;
