import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import {
  Typography,
  Container,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import moment from "moment";
import Categories from "./Categories";
import MarketplaceActions from "./MarketplaceActions";
import MiniProduct from "./MiniProduct";

const Marketplace = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const observer = useRef();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      fetchProducts();
      logEvent(analytics, "page_view", {
        page_path: window.location.pathname,
        page_title: "Marketplace",
        page_location: window.location.href,
      });
    }
  }, [user, selectedCategory]);

  const fetchCategoryName = async (categoryId) => {
    const categoryDoc = await getDoc(doc(db, "Categories", categoryId));
    return categoryDoc.exists() ? categoryDoc.data().type : "Unknown Category";
  };

  const fetchProducts = async () => {
    setLoading(true);
    let productsRef = collection(db, "Products");
    if (selectedCategory) {
      productsRef = query(
        productsRef,
        where("category", "==", selectedCategory),
        orderBy("postDate", "desc"),
        limit(5)
      );
    } else {
      productsRef = query(productsRef, orderBy("postDate", "desc"), limit(5));
    }
    onSnapshot(productsRef, async (snapshot) => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setLastVisible(lastVisible);

      const loadedProducts = await Promise.all(
        snapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();
          if (productData.active === false) return null;
          const userDoc = await getDoc(doc(db, "Users", productData.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};
          const categoryName = await fetchCategoryName(productData.category);
          return {
            id: productDoc.id,
            ...productData,
            user: userData,
            categoryName,
          };
        })
      );

      const activeProducts = loadedProducts.filter(
        (product) => product !== null
      );

      const sortedProducts = activeProducts.sort((a, b) => {
        if (a.sponsored && !b.sponsored) return -1;
        if (!a.sponsored && b.sponsored) return 1;
        return new Date(b.postDate) - new Date(a.postDate);
      });

      setProducts((prevProducts) => [...prevProducts, ...sortedProducts]);
      setLoading(false);
    });
  };

  const fetchMoreProducts = async () => {
    if (!lastVisible) return;

    let productsRef = collection(db, "Products");
    if (selectedCategory) {
      productsRef = query(
        productsRef,
        where("category", "==", selectedCategory),
        orderBy("postDate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );
    } else {
      productsRef = query(
        productsRef,
        orderBy("postDate", "desc"),
        startAfter(lastVisible),
        limit(5)
      );
    }
    onSnapshot(productsRef, async (snapshot) => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setLastVisible(lastVisible);

      const loadedProducts = await Promise.all(
        snapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();
          if (productData.active === false) return null;
          const userDoc = await getDoc(doc(db, "Users", productData.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};
          const categoryName = await fetchCategoryName(productData.category);
          return {
            id: productDoc.id,
            ...productData,
            user: userData,
            categoryName,
          };
        })
      );

      const activeProducts = loadedProducts.filter(
        (product) => product !== null
      );

      const sortedProducts = activeProducts.sort((a, b) => {
        if (a.sponsored && !b.sponsored) return -1;
        if (!a.sponsored && b.sponsored) return 1;
        return new Date(b.postDate) - new Date(a.postDate);
      });

      setProducts((prevProducts) => [...prevProducts, ...sortedProducts]);
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    logEvent(analytics, "search", {
      search_term: event.target.value,
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setProducts([]);
    setLastVisible(null);
    logEvent(analytics, "select_category", {
      category_name: category,
    });
  };

  const handleFavorite = (productId) => {
    // Logic to handle adding/removing favorite products
    logEvent(analytics, "add_to_favorites", {
      product_id: productId,
    });
  };

  const filteredProducts = useMemo(
    () =>
      products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [products, searchTerm]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreProducts();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  return (
    <Container>
      <MarketplaceActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
      />
      <Box mb={3}>
        <Categories onSelectCategory={handleCategoryChange} />
      </Box>
      <Grid container spacing={3}>
        {filteredProducts.map((product, index) => {
          if (filteredProducts.length === index + 1) {
            return (
              <Grid
                item
                key={product.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                ref={lastProductElementRef}
              >
                <MiniProduct
                  product={product}
                  handleCategoryChange={handleCategoryChange}
                  handleFavorite={handleFavorite}
                />
              </Grid>
            );
          } else {
            return (
              <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                <MiniProduct
                  product={product}
                  handleCategoryChange={handleCategoryChange}
                  handleFavorite={handleFavorite}
                />
              </Grid>
            );
          }
        })}
        {loading &&
          Array.from(new Array(5)).map((_, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Skeleton variant="rectangular" height={200} />
              <Box pt={0.5}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default Marketplace;
