import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import { Paid, CheckCircleOutline } from "@mui/icons-material";

const AddonCard = ({ addon, selected, onChange, size, onSizeChange }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        cursor: "pointer",
        border: selected ? "2px solid #1976d2" : "1px solid #e0e0e0",
        boxShadow: selected ? "0 0 10px rgba(25, 118, 210, 0.5)" : "none",
        transition: "all 0.3s ease-in-out",
      }}
      onClick={() => onChange(addon, !selected)}
    >
      <CardMedia
        component="img"
        height="200px"
        image={addon.pic}
        alt={`Addon ${addon.type}`}
        sx={{ width: "100%", objectFit: "cover" }}
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{addon.type}</Typography>
          <Checkbox
            checked={selected}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircleOutline color="primary" />}
          />
        </Box>
        {addon.description && (
          <Typography variant="body2" color="text.secondary">
            {addon.description}
          </Typography>
        )}
        {addon.size && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Tamaño</InputLabel>
            <Select
              value={size || ""}
              onChange={(e) => onSizeChange(addon.id, e.target.value)}
            >
              {addon.size.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {addon.stock && (
          <Typography variant="body2" color="text.secondary">
            Stock: {addon.stock}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          ${addon.price}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AddonCard;
