import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotAuthorizedScreen = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
      <Typography variant="h4" textAlign="center">
        No tienes permisos para entrar a este recurso!
      </Typography>
      <Typography textAlign="center">
        Si crees que esto es un error, contactate con Livespeed
      </Typography>
      <Button sx={{ alignSelf: "center" }} variant="contained" onClick={goBack}>
        Regresar
      </Button>
    </Box>
  );
};

export default NotAuthorizedScreen;
