import { ChevronLeft, PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventsApi } from "../../lib/client";
import { Form, Formik } from "formik";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import ViewAddons from "./EventManager/ViewAddons";

const EditEvent = () => {
  const nav = useNavigate();
  const params = useParams();
  const eventId = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const handleIsEditing = () => {
    setIsEditing((prev) => !prev);
  };

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation((prev) => !prev);
  };

  useEffect(() => {
    const fetchEventInfo = async () => {
      const eventData = await eventsApi.getEventById(eventId);
      setEventInfo(eventData);
      setIsLoading(false);
    };
    fetchEventInfo();
  }, [eventId]);

  if (isLoading) return <Box>Loading...</Box>;

  const handleSubmit = async (values) => {
    try {
      if (values.photo === eventInfo.photo) {
        delete values.photo;
      }
      await eventsApi.updateEvent(eventId, values);
      nav(-1);
    } catch (error) {
      console.log("Error =>", error);
    }
  };

  return (
    <Box>
      <Box>
        <Button startIcon={<ChevronLeft />} onClick={() => nav(-1)}>
          Regresar
        </Button>
      </Box>
      <Formik initialValues={eventInfo} onSubmit={handleSubmit}>
        {({ values, handleChange, setFieldValue, isSubmitting }) => (
          <Form style={{ margin: "16px 0px" }}>
            <Stack spacing={2}>
              <TextField
                disabled={!isEditing}
                onChange={handleChange}
                label="Nombre del evento"
                name="eventName"
                value={values.eventName}
              />
              <TextField
                disabled={!isEditing}
                onChange={handleChange}
                label="Descripción"
                name="description"
                value={values.description}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                name="dateTime"
                disabled={!isEditing}
                onChange={handleChange}
                label="Fecha y hora"
                value={values.dateTime}
              />
              <TextField
                type="number"
                name="price"
                disabled={!isEditing}
                onChange={handleChange}
                label="Precio"
                value={values.price}
              />
              <Box>
                <InputLabel htmlFor="photo">Foto del Spot:</InputLabel>
                <input
                  disabled={!isEditing}
                  type="file"
                  accept="image/*"
                  id="photo"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFieldValue("photo", file);
                  }}
                />
                <label htmlFor="photo">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
                {values.photo && (
                  <img
                    src={
                      values.photo !== eventInfo.photo
                        ? URL.createObjectURL(values.photo)
                        : values.photo
                    }
                    alt="Vista Previa del Evento"
                    style={{ marginTop: "10px", maxWidth: "100%" }}
                  />
                )}
              </Box>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={values.location}
                  zoom={15}
                  onClick={(e) => {
                    const { latLng } = e;
                    setFieldValue("location", {
                      lat: latLng.lat(),
                      lng: latLng.lng(),
                    });
                  }}
                >
                  <Marker position={values.location} />
                </GoogleMap>
              </LoadScript>
              {isEditing ? (
                <Stack spacing={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isEditing}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Guardar cambios"
                    )}
                  </Button>
                  <Button variant="outlined" onClick={handleIsEditing}>
                    Cancelar
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleOpenDeleteConfirmation}
                  >
                    Eliminar evento
                  </Button>
                </Stack>
              ) : (
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={handleIsEditing}
                >
                  Editar información del evento
                </Button>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
      <Modal
        open={openDeleteConfirmation}
        onClose={handleOpenDeleteConfirmation}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¿Estás seguro de eliminar este evento?
          </Typography>
          <Stack
            mt={2}
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Button
              color="info"
              variant="contained"
              onClick={handleOpenDeleteConfirmation}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await eventsApi.deleteEvent(eventId);
                nav(-1);
              }}
            >
              Aceptar
            </Button>
          </Stack>
        </Paper>
      </Modal>
      <ViewAddons eventId={eventId} />
    </Box>
  );
};

export default EditEvent;

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};
