import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PaymentIcon from "@mui/icons-material/Payment";

const MyTickets = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [openQR, setOpenQR] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "orders"),
          where("UserUID", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const ticketsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          Status: doc.data().payment_id ? "Pagado" : "No Pagado",
        }));
        setTickets(ticketsData);
      } catch (error) {
        console.error("Error al obtener los tickets:", error);
        setError("Error al obtener los tickets. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchTickets();
    }
  }, [user]);

  const handlePayEvent = (id) => {
    navigate(`/checkout/${id}`);
  };

  const handleQRClick = (ticket) => {
    setSelectedTicket(ticket);
    setOpenQR(true);
  };

  const handleCloseQR = () => {
    setOpenQR(false);
    setSelectedTicket(null);
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  if (tickets.length === 0) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          No tienes tickets
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 3, mb: 6 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold", color: "black", mb: 4 }}
      >
        Mis Entradas
      </Typography>
      <Grid container spacing={3}>
        {tickets.map((ticket) => (
          <Grid item xs={12} sm={6} md={4} key={ticket.id}>
            <Card
              sx={{
                borderRadius: 4,
                overflow: "hidden",
                position: "relative",
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                background: "white",
                color: "black",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <DirectionsBikeIcon fontSize="large" />
                  <ConfirmationNumberIcon fontSize="large" />
                </Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Evento
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Orden ID: {ticket.id}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Total: ${ticket.Total}
                </Typography>
                <Chip
                  icon={
                    ticket.Status === "Pagado" ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <CancelOutlinedIcon />
                    )
                  }
                  label={ticket.Status}
                  sx={{
                    bgcolor:
                      ticket.Status === "Pagado"
                        ? "rgba(0, 128, 0, 0.1)"
                        : "rgba(255, 0, 0, 0.1)",
                    color: ticket.Status === "Pagado" ? "green" : "red",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                />
                <Divider sx={{ my: 2, bgcolor: "rgba(0,0,0,0.1)" }} />
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Addons:
                </Typography>
                <List
                  sx={{
                    bgcolor: "rgba(0,0,0,0.05)",
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  {ticket.Addons.map((addon, index) => (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={addon.type}
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{ color: "rgba(0,0,0,0.7)" }}
                          >
                            {addon.price && `Precio: $${addon.price}`}
                            {addon.size && ` - Tamaño: ${addon.size}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                {ticket.Status === "Pagado" ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "fit-content",
                      margin: "auto",
                      bgcolor: "white",
                      p: 0,
                      borderRadius: 1,
                      overflow: "hidden",
                    }}
                    onClick={() => handleQRClick(ticket)}
                  >
                    <QRCode value={`Ticket ID: ${ticket.id}`} size={128} />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<PaymentIcon />}
                    onClick={() => handlePayEvent(ticket.id)}
                    sx={{ bgcolor: "black", color: "white" }}
                  >
                    Pagar
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          {selectedTicket && (
            <QRCode value={`Ticket ID: ${selectedTicket.id}`} size={256} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default MyTickets;
