import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

class Users {
  usersCollectionRef = collection(db, "Users");

  async getUserByUid(uid) {
    try {
      const userDocRef = doc(this.usersCollectionRef, uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  }
}

export const userApi = new Users();

class Events {
  eventsCollectionName = "events";
  categoriesSubCollectionName = "categories";
  participantsSubCollectionName = "participants";
  eventsCollectionRef = collection(db, this.eventsCollectionName);

  async getEventsByEventManagerUid(uid) {
    try {
      if (!uid) return;
      const q = query(this.eventsCollectionRef, where("creatorUID", "==", uid));
      const querySnapshot = await getDocs(q);
      const events = [];
      querySnapshot.forEach((doc) => {
        events.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return events;
    } catch (error) {
      console.error("Error getting events:", error);
      throw error;
    }
  }

  async createEvent(user, eventData) {
    try {
      // Subir la foto a Storage
      const storageRef = ref(
        storage,
        `event_images/${eventData.id}/${eventData.photo?.name}`
      );
      if (eventData.photo) {
        await uploadBytesResumable(storageRef, eventData.photo);
      }
      const photoUrl = eventData.photo ? await getDownloadURL(storageRef) : "";

      const eventRef = await addDoc(this.eventsCollectionRef, {
        creatorUID: eventData.creatorUID,
        eventName: eventData.eventName,
        dateTime: eventData.dateTime,
        location: eventData.location,
        photo: photoUrl,
        description: eventData.description,
        price: parseFloat(eventData.price),
      });

      // Crear la subcolección de categorías dentro del evento
      const categoriesCollectionRef = collection(
        eventRef,
        this.categoriesSubCollectionName
      );
      for (const category of eventData.categories) {
        await addDoc(categoriesCollectionRef, {
          name: category, // Suponiendo que `category` es un objeto con el campo `name`
        });
      }
      // const participantsCollectionRef = collection(eventRef, "participants");
      console.log("Evento añadido!");
    } catch (error) {
      console.error("Error al crear evento:", error);
    }
  }

  async addParticipantToEvent(eventId, userId, categoryId) {
    try {
      const eventDocRef = doc(db, this.eventsCollectionName, eventId);

      const participantsCollectionRef = collection(eventDocRef, "participants");

      await addDoc(participantsCollectionRef, {
        userId,
        categoryId,
        joinedAt: new Date(), // Fecha de unión, por ejemplo
      });
    } catch (error) {
      console.error("Error al añadir participante al evento:", error);
    }
  }

  async removeParticipantFromEvent(eventId, userId) {
    try {
      const eventDocRef = doc(db, this.eventsCollectionName, eventId);
      const participantsCollectionRef = collection(eventDocRef, "participants");

      const participantQuery = query(
        participantsCollectionRef,
        where("userId", "==", userId)
      );

      const querySnapshot = await getDocs(participantQuery);

      querySnapshot.forEach(async (docSnapshot) => {
        await deleteDoc(docSnapshot.ref);
        console.log(
          `Participante ${userId} eliminado del evento ${eventId} correctamente.`
        );
      });
    } catch (error) {
      console.error("Error al eliminar participante del evento:", error);
    }
  }

  async updateEvent(eventId, updatedData) {
    try {
      console.log(1, updatedData);
      const docRef = doc(this.eventsCollectionRef, eventId);

      // If there's a new photo to upload
      if (updatedData.photo) {
        const storageRef = ref(
          storage,
          `event_images/${eventId}/${updatedData.photo.name}`
        );
        await uploadBytesResumable(storageRef, updatedData.photo);
        const photoUrl = await getDownloadURL(storageRef);
        updatedData.photo = photoUrl; // Update the photo URL in the updatedData
      } else {
        delete updatedData.photo; // Remove photo field if not being updated
      }

      console.log(2, updatedData);

      await updateDoc(docRef, updatedData);
      console.log("Event updated!");
    } catch (error) {
      console.error("Error updating event:", error);
    }
  }

  async getEventById(
    eventId,
    include = { creator: false, categories: false, participants: false }
  ) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        const eventData = { id: eventDoc.id, ...eventDoc.data() };

        if (include.creator) {
          const creatorUID = eventData.creatorUID;
          if (creatorUID) {
            const creatorData = await userApi.getUserByUid(creatorUID);
            eventData.creator = creatorData;
          }
        }

        if (include.categories) {
          const categoriesRef = collection(
            docRef,
            this.categoriesSubCollectionName
          );
          const categoriesSnapshot = await getDocs(categoriesRef);

          const categories = categoriesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          eventData.categories = categories;
        }

        if (include.participants) {
          const participantsRef = collection(
            docRef,
            this.participantsSubCollectionName
          );
          const participantsSnapshot = await getDocs(participantsRef);

          const participantsPromises = participantsSnapshot.docs.map(
            async (doc) => {
              const docData = doc.data();

              const participant = {
                id: doc.id,
                joinedAt: docData.joinedAt,
              };

              if (docData.userId) {
                const userData = await userApi.getUserByUid(docData.userId);
                participant.user = userData;
              }

              return participant;
            }
          );

          // Esperar a que todas las promesas se resuelvan
          const participants = await Promise.all(participantsPromises);

          eventData.participants = participants;
        }

        return eventData;
      } else {
        console.log("No such event!");
        return null;
      }
    } catch (error) {
      console.log("Error getting event:", error);
    }
  }

  async deleteEvent(eventId) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        if (eventData.photo) {
          const photoRef = ref(storage, eventData.photo);
          await deleteObject(photoRef);
        }

        await deleteDoc(docRef);
        console.log("Event deleted!");
      } else {
        console.log("No such event!");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  }
}

export const eventsApi = new Events();
