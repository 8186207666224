import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  CircularProgress,
  Button, // Importamos Button correctamente
} from "@mui/material";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
  getDocs,
} from "firebase/firestore"; // Importamos getDocs correctamente
import { db } from "../../../../firebase";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";

const ITEMS_PER_PAGE = 50;
const CSV_ITEMS = 1000;

const PingsConsole = ({ uid }) => {
  const [pingsData, setPingsData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const consoleRef = useRef(null); // Ref para el área de consola

  // Desplazarse al último log automáticamente sin mover la pantalla principal
  const scrollToBottom = () => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight; // Desplaza solo el área de logs
    }
  };

  useEffect(() => {
    const fetchPings = () => {
      const pingsCollection = collection(db, `Receivers/${uid}/Pings`);
      const pingsQuery = query(
        pingsCollection,
        orderBy("time", "desc"),
        limit(ITEMS_PER_PAGE)
      );

      const unsubscribe = onSnapshot(pingsQuery, (snapshot) => {
        const newPings = snapshot.docs.map((doc) => doc.data());
        setPingsData(newPings);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchPings();
  }, [uid]);

  useEffect(() => {
    scrollToBottom(); // Solo desplaza la consola de logs hacia abajo cuando hay nuevos pings
  }, [pingsData]);

  // Descargar los últimos 1000 pings en CSV
  const fetchCsvData = async () => {
    const pingsCollection = collection(db, `Receivers/${uid}/Pings`);
    const pingsQuery = query(
      pingsCollection,
      orderBy("time", "desc"),
      limit(CSV_ITEMS)
    );
    const snapshot = await getDocs(pingsQuery);
    const csvPings = snapshot.docs.map((doc) => doc.data());

    const csvFormattedData = csvPings.map((ping) => ({
      time: moment(ping.time).format("YYYY-MM-DD HH:mm:ss"),
      temperature: ping.temperature.toFixed(4),
      vsysVoltage: ping.vsysVoltage.toFixed(4),
    }));

    setCsvData(csvFormattedData);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ position: "relative" }}>
      {/* Descargar Log File */}
      <IconButton
        onClick={fetchCsvData}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          color: "#000",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        }}
      >
        <DownloadIcon />
      </IconButton>

      {/* Consola de Logs */}
      <Box
        ref={consoleRef}
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "16px",
          borderRadius: "10px",
          height: "300px",
          overflowY: "auto", // Habilitamos el scroll solo dentro de este componente
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          fontFamily: "monospace",
          mb: 2,
          border: "1px solid #000",
        }}
      >
        <List>
          {pingsData.map((ping, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 300, fontSize: "14px" }}
                >
                  [ {moment(ping.time).format("YYYY-MM-DD HH:mm:ss")} ] -{" "}
                  <strong>Temp:</strong> {ping.temperature.toFixed(4)}°C -{" "}
                  <strong>Volt:</strong> {ping.vsysVoltage.toFixed(4)} V
                </Typography>
              </ListItem>
              <Divider sx={{ backgroundColor: "#000" }} />
            </React.Fragment>
          ))}
        </List>
      </Box>

      {/* Botón para descargar el archivo CSV */}
      {csvData.length > 0 && (
        <CSVLink
          data={csvData}
          filename={`receiver_${uid}_logs.csv`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="contained" color="primary">
            Descargar Log File
          </Button>
        </CSVLink>
      )}
    </Box>
  );
};

export default PingsConsole;
