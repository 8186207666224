import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
} from "@mui/material";
import {
  MenuBook as BookIcon,
  Receipt as ReceiptIcon,
  NfcOutlined as NfcIcon,
  Event as EventIcon,
  DirectionsBike as BikeIcon,
  Group as GroupIcon,
  MonetizationOn as CoinIcon,
  School as SchoolIcon,
} from "@mui/icons-material";

// Importa los componentes de guías
import Introduccion from "./guias/Introduccion";
import Receptores from "./guias/Receptores";
import ComoAgregarTagNFC from "./guias/ComoAgregarTagNFC";
import OrganizacionEventos from "./guias/OrganizacionEventos";
import CreacionRodadas from "./guias/CreacionRodadas";
import CreacionEquipos from "./guias/CreacionEquipos";
import SpeedCoins from "./guias/SpeedCoins";
import CreacionWorkShops from "./guias/CreacionWorkShops";

const Documentation = () => {
  const [selectedGuide, setSelectedGuide] = useState("Introduccion");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleGuideSelection = (event, newValue) => {
    setSelectedGuide(newValue);
  };

  const guides = [
    { name: "Introduccion", label: "Introducción", icon: <BookIcon /> },
    { name: "Receptores", label: "Receptores", icon: <ReceiptIcon /> },
    {
      name: "Como Agregar un Tag NFC",
      label: "Como Agregar un Tag NFC",
      icon: <NfcIcon />,
    },
    {
      name: "Organizacion de Eventos",
      label: "Organización de Eventos",
      icon: <EventIcon />,
    },
    {
      name: "Creacion de Rodadas",
      label: "Creación de Rodadas",
      icon: <BikeIcon />,
    },
    {
      name: "Creacion de Equipos",
      label: "Creación de Equipos",
      icon: <GroupIcon />,
    },
    {
      name: "SpeedCoins",
      label: "¿Qué son los SpeedCoins?",
      icon: <CoinIcon />,
    },
    {
      name: "Creacion de WorkShops",
      label: "Creación de WorkShops",
      icon: <SchoolIcon />,
    },
  ];

  const renderSelectedGuide = () => {
    switch (selectedGuide) {
      case "Introduccion":
        return <Introduccion />;
      case "Receptores":
        return <Receptores />;
      case "Como Agregar un Tag NFC":
        return <ComoAgregarTagNFC />;
      case "Organizacion de Eventos":
        return <OrganizacionEventos />;
      case "Creacion de Rodadas":
        return <CreacionRodadas />;
      case "Creacion de Equipos":
        return <CreacionEquipos />;
      case "SpeedCoins":
        return <SpeedCoins />;
      case "Creacion de WorkShops":
        return <CreacionWorkShops />;
      default:
        return (
          <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
            Selecciona una guía del menú para comenzar.
          </Typography>
        );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Logo de Livespeed */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
          alt="Livespeed logo"
          style={{ width: "200px", height: "auto" }}
        />
      </Box>

      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontWeight: "bold",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          color: "text.primary",
        }}
      >
        Documentación
      </Typography>

      <Typography
        variant="body1"
        paragraph
        align="center"
        sx={{
          fontSize: "1.1rem",
          lineHeight: 1.6,
          maxWidth: "75%",
          mx: "auto",
          mb: 4,
          color: "text.secondary",
        }}
      >
        Aquí encontrarás guías detalladas para aprender a utilizar todas las
        funciones clave de Livespeed. Comienza seleccionando una de las guías en
        el menú de abajo.
      </Typography>

      {/* Menú de guías */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 3,
        }}
      >
        {/* Menú como Tabs en móviles y lista en pantallas grandes */}
        {isMobile ? (
          <Tabs
            value={selectedGuide}
            onChange={handleGuideSelection}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ mb: 3 }}
          >
            {guides.map((guide, index) => (
              <Tab
                key={index}
                label={guide.label}
                icon={guide.icon}
                value={guide.name}
                iconPosition="start"
              />
            ))}
          </Tabs>
        ) : (
          <Paper
            elevation={3}
            sx={{
              width: "25%",
              padding: 2,
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
            >
              Guías Disponibles
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {guides.map((guide) => (
                <ListItem
                  button
                  key={guide.name}
                  onClick={() => handleGuideSelection(null, guide.name)}
                  sx={{
                    "&:hover": {
                      bgcolor: "action.hover",
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: "40px",
                    },
                  }}
                >
                  <ListItemIcon>{guide.icon}</ListItemIcon>
                  <ListItemText primary={guide.label} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}

        <Paper
          elevation={3}
          sx={{
            flexGrow: 1,
            p: 3,
            minHeight: "400px",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          {renderSelectedGuide()}
        </Paper>
      </Box>
    </Container>
  );
};

export default Documentation;
