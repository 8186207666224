import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Paper,
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import {
  collection,
  getDocs,
  collectionGroup,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const Leaderboards = () => {
  const [parks, setParks] = useState([]);
  const [selectedPark, setSelectedPark] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [runs, setRuns] = useState([]);
  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParks = async () => {
      const parksCollectionRef = collection(db, "Parks");
      const parksSnapshot = await getDocs(parksCollectionRef);
      const parksData = parksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParks(parksData);
    };

    const fetchTags = async () => {
      const tagsCollectionRef = collection(db, "Tags");
      const tagsSnapshot = await getDocs(tagsCollectionRef);
      const tagsData = tagsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(tagsData);
    };

    const fetchUsers = async () => {
      const usersCollectionRef = collection(db, "Users");
      const usersSnapshot = await getDocs(usersCollectionRef);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    };

    fetchParks();
    fetchTags();
    fetchUsers();
  }, []);

  const handleParkClick = async (parkId) => {
    setSelectedPark(parkId);
    const tracksCollectionRef = collection(db, "Parks", parkId, "Tracks");
    const tracksSnapshot = await getDocs(tracksCollectionRef);
    const tracksData = tracksSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTracks(tracksData);
  };

  const handleTrackClick = async (trackId) => {
    setSelectedTrack(trackId);
    const runsCollectionRef = collectionGroup(db, "Runs");
    const q = query(runsCollectionRef, where("TrackID", "==", trackId));
    const runsSnapshot = await getDocs(q);
    const runsData = runsSnapshot.docs.map((doc) => {
      const runData = doc.data();
      if (runData.StartTime && runData.FinishTime) {
        const startTime = new Date(runData.StartTime.seconds * 1000);
        const finishTime = new Date(runData.FinishTime.seconds * 1000);
        const timeDifference = finishTime - startTime;
        return {
          id: doc.id,
          rider: findUserName(runData.NFCIdentifier),
          date: startTime.toLocaleString(),
          time: timeDifference,
        };
      } else {
        return {
          id: doc.id,
          rider: findUserName(runData.NFCIdentifier),
          date: "N/A",
          time: "N/A",
        };
      }
    });
    setRuns(runsData);
  };

  useEffect(() => {
    const findBestTimes = () => {
      const bestTimesMap = new Map();
      runs.forEach((run) => {
        if (!bestTimesMap.has(run.rider)) {
          bestTimesMap.set(run.rider, run);
        } else {
          if (run.time < bestTimesMap.get(run.rider).time) {
            bestTimesMap.set(run.rider, run);
          }
        }
      });
      const bestTimesArray = Array.from(bestTimesMap.values());
      const sortedBestTimes = bestTimesArray.sort((a, b) => a.time - b.time);
      setBestTimes(sortedBestTimes);
    };
    findBestTimes();
  }, [runs]);

  const findUserName = (nfcIdentifier) => {
    const tag = tags.find((tag) => tag.NFCID === nfcIdentifier);
    if (tag) {
      const user = users.find((user) => user.id === tag.Owner);
      return user ? user.name : nfcIdentifier;
    } else {
      return nfcIdentifier;
    }
  };

  const findUserPhoto = (nfcIdentifier) => {
    const tag = tags.find((tag) => tag.NFCID === nfcIdentifier);
    if (tag) {
      const user = users.find((user) => user.id === tag.Owner);
      return user && user.photoUrl ? user.photoUrl : "default_photo_url";
    }
    return "default_photo_url";
  };

  const handleRunClick = (runId) => {
    navigate(`/run/${runId}`);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        Leaderboards
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" align="center">
            Parques
          </Typography>
          <Grid container spacing={2}>
            {parks.map((park) => (
              <Grid item key={park.id} xs={6} sm={4} md={6}>
                <Card>
                  <CardActionArea onClick={() => handleParkClick(park.id)}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={park.Logo} // Asegúrate de tener esta propiedad en tus datos de parque
                      alt={park.ParkName}
                    />
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {park.ParkName}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {selectedPark && (
            <>
              <Typography variant="h5" align="center">
                Pistas
              </Typography>
              <List>
                {tracks.map((track) => (
                  <ListItem
                    key={track.id}
                    button
                    onClick={() => handleTrackClick(track.id)}
                    selected={selectedTrack === track.id}
                    style={{
                      backgroundColor:
                        track.Status === "Abierta" ? "#c8e6c9" : "#ffcdd2",
                    }}
                  >
                    <ListItemText
                      primary={track.TrackName}
                      secondary={`Status: ${track.Status}, Dificultad: ${track.Difficulty}`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {selectedTrack && (
            <>
              <Typography variant="h5" align="center">
                {`Tiempos de: ${
                  tracks.find((track) => track.id === selectedTrack)?.TrackName
                }`}
              </Typography>
              <List>
                {bestTimes.map((run, index) => (
                  <ListItem
                    key={run.id}
                    button
                    onClick={() => handleRunClick(run.id)}
                    style={{
                      backgroundColor:
                        index === 0
                          ? "gold"
                          : index === 1
                          ? "silver"
                          : index === 2
                          ? "#cd7f32"
                          : "none",
                    }}
                  >
                    <ListItemAvatar>
                      <Badge badgeContent={index + 1} color="primary">
                        <Avatar src={findUserPhoto(run.rider)} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={run.rider}
                      secondary={`Tiempo: ${Math.floor(
                        run.time / 60000
                      )} min ${Math.floor((run.time / 1000) % 60)} sec`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Leaderboards;
