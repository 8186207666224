import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  Button,
  Avatar,
  Alert,
} from "@mui/material";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";

const TransactionDetails = () => {
  const { uid } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [senderInfo, setSenderInfo] = useState(null);
  const [receiverInfo, setReceiverInfo] = useState(null);
  const transactionRef = useRef();

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const docRef = doc(db, "coinstransactions", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const transactionData = docSnap.data();
          setTransaction({ id: docSnap.id, ...transactionData });

          const senderDocRef = doc(db, "Users", transactionData.senderUID);
          const receiverDocRef = doc(db, "Users", transactionData.receiverUID);

          const [senderDocSnap, receiverDocSnap] = await Promise.all([
            getDoc(senderDocRef),
            getDoc(receiverDocRef),
          ]);

          if (senderDocSnap.exists()) {
            setSenderInfo(senderDocSnap.data());
          }

          if (receiverDocSnap.exists()) {
            setReceiverInfo(receiverDocSnap.data());
          }
        } else {
          setError("Transacción no encontrada");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la transacción:", error);
        setError("Error al obtener la transacción. Intente nuevamente.");
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [uid]);

  const handleShare = async () => {
    try {
      if (transactionRef.current) {
        const dataUrl = await toPng(transactionRef.current);
        saveAs(dataUrl, `transaction_${transaction.id}.png`);
      }
    } catch (error) {
      console.error("Error al compartir el comprobante:", error);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error" align="center" sx={{ mt: 5 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  if (!transaction) {
    return (
      <Container>
        <Alert severity="warning" align="center" sx={{ mt: 5 }}>
          Transacción no encontrada.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 2,
          position: "relative",
          boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
        }}
        ref={transactionRef}
      >
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: "bold", color: "#4CAF50" }}
        >
          Comprobante de Transacción
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            ID de la Transacción
          </Typography>
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {transaction.id}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          {senderInfo && (
            <Avatar src={senderInfo.photoUrl} alt={senderInfo.name} />
          )}
          <Box ml={2}>
            <Typography variant="body2" color="textSecondary">
              Emisor
            </Typography>
            <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
              {senderInfo
                ? `${senderInfo.name} (${transaction.senderUID})`
                : transaction.senderUID}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" alignItems="center">
          {receiverInfo && (
            <Avatar src={receiverInfo.photoUrl} alt={receiverInfo.name} />
          )}
          <Box ml={2}>
            <Typography variant="body2" color="textSecondary">
              Receptor
            </Typography>
            <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
              {receiverInfo
                ? `${receiverInfo.name} (${transaction.receiverUID})`
                : transaction.receiverUID}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="body2" color="textSecondary">
            Monto
          </Typography>
          <Typography variant="body1">
            ${parseFloat(transaction.amount).toFixed(2)}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="body2" color="textSecondary">
            Fecha
          </Typography>
          <Typography variant="body1">
            {new Date(transaction.date.seconds * 1000).toLocaleString("es-ES", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Box>
        {transaction.comment && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary">
                Comentario
              </Typography>
              <Typography variant="body1">{transaction.comment}</Typography>
            </Box>
          </>
        )}
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleShare}
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            Compartir Comprobante
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default TransactionDetails;
