import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import {
  List,
  Avatar,
  CircularProgress,
  Typography,
  Box,
  Paper,
  useTheme,
  ListItemButton,
  Divider,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const MyFriends = () => {
  const { uid } = useParams();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = subscribeToFriends(uid || auth.currentUser.uid);
    return () => unsubscribe();
  }, [uid]);

  const subscribeToFriends = (userId) => {
    if (userId) {
      const userDocRef = doc(db, "Users", userId);
      return onSnapshot(userDocRef, async (docSnapshot) => {
        const userData = docSnapshot.data();
        if (userData && userData.friends && userData.friends.length > 0) {
          const friendDetailsPromises = userData.friends.map(
            async (friendUid) => {
              const friendDocRef = doc(db, "Users", friendUid);
              const friendDocSnap = await getDoc(friendDocRef);
              if (friendDocSnap.exists()) {
                const friendData = friendDocSnap.data();
                return {
                  uid: friendUid,
                  name: friendData.name,
                  photoUrl: friendData.photoUrl,
                  nickName: friendData.nickName,
                };
              } else {
                console.error("Friend details not found for UID:", friendUid);
                return null;
              }
            }
          );
          Promise.all(friendDetailsPromises).then((resolvedFriendDetails) => {
            setFriends(resolvedFriendDetails.filter(Boolean));
            setLoading(false);
          });
        } else {
          setFriends([]);
          setLoading(false);
        }
      });
    }
    return () => {};
  };

  const handleFriendClick = (friendUid) => {
    navigate(`/user/${friendUid}`);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "100%", margin: "0 auto", p: 2 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Amigos
      </Typography>
      {friends.length > 0 ? (
        <Paper elevation={2} sx={{ borderRadius: 2, overflow: "hidden" }}>
          <List disablePadding>
            {friends.map((friend, index) => (
              <React.Fragment key={friend.uid}>
                <ListItemButton onClick={() => handleFriendClick(friend.uid)}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      py: 1,
                    }}
                  >
                    <Avatar
                      alt={friend.name}
                      src={friend.photoUrl}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                      <Typography variant="subtitle1" noWrap>
                        {friend.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        @{friend.nickName}
                      </Typography>
                    </Box>
                    <IconButton edge="end">
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                </ListItemButton>
                {index < friends.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        <Paper
          elevation={2}
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <PersonIcon
            sx={{ fontSize: 48, color: theme.palette.text.secondary, mb: 2 }}
          />
          <Typography variant="body1" color="text.secondary" align="center">
            No hay amigos añadidos.
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default MyFriends;
