import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Button, CircularProgress } from "@mui/material";
import * as toGeoJSON from "togeojson";
import tokml from "tokml";
import { storage } from "../../firebase";

const FileUploader = ({ uid, setTrackData }) => {
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const fileType = file.name.split(".").pop().toLowerCase();

      try {
        let geoJson = null;
        let kml = null;

        if (fileType === "geojson") {
          const reader = new FileReader();
          reader.onload = async (event) => {
            geoJson = JSON.parse(event.target.result);
            kml = tokml(geoJson);

            // Subir el archivo GeoJSON
            const geoJsonBlob = new Blob([JSON.stringify(geoJson)], {
              type: "application/json",
            });
            const geoJsonRef = ref(storage, `tracks/${uid}/${file.name}`);
            const geoJsonUploadTask = uploadBytesResumable(
              geoJsonRef,
              geoJsonBlob,
            );
            await geoJsonUploadTask;
            const geoJsonUrl = await getDownloadURL(geoJsonRef);

            // Subir el archivo KML
            const kmlBlob = new Blob([kml], {
              type: "application/vnd.google-earth.kml+xml",
            });
            const kmlRef = ref(
              storage,
              `tracks/${uid}/${file.name.replace(".geojson", ".kml")}`,
            );
            const kmlUploadTask = uploadBytesResumable(kmlRef, kmlBlob);
            await kmlUploadTask;
            const kmlUrl = await getDownloadURL(kmlRef);

            setTrackData((prevData) => ({
              ...prevData,
              GeoJson: geoJsonUrl,
              KML: kmlUrl,
            }));

            setUploading(false);
          };
          reader.readAsText(file);
        } else if (fileType === "kml") {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const kmlDom = new DOMParser().parseFromString(
              event.target.result,
              "application/xml",
            );
            geoJson = toGeoJSON.kml(kmlDom);

            // Subir el archivo KML
            const kmlBlob = new Blob([event.target.result], {
              type: "application/vnd.google-earth.kml+xml",
            });
            const kmlRef = ref(storage, `tracks/${uid}/${file.name}`);
            const kmlUploadTask = uploadBytesResumable(kmlRef, kmlBlob);
            await kmlUploadTask;
            const kmlUrl = await getDownloadURL(kmlRef);

            // Subir el archivo GeoJSON
            const geoJsonBlob = new Blob([JSON.stringify(geoJson)], {
              type: "application/json",
            });
            const geoJsonRef = ref(
              storage,
              `tracks/${uid}/${file.name.replace(".kml", ".geojson")}`,
            );
            const geoJsonUploadTask = uploadBytesResumable(
              geoJsonRef,
              geoJsonBlob,
            );
            await geoJsonUploadTask;
            const geoJsonUrl = await getDownloadURL(geoJsonRef);

            setTrackData((prevData) => ({
              ...prevData,
              GeoJson: geoJsonUrl,
              KML: kmlUrl,
            }));

            setUploading(false);
          };
          reader.readAsText(file);
        }
      } catch (error) {
        console.error("Error processing file: ", error);
        setUploading(false);
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        component="label"
        fullWidth
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        {uploading ? "Subiendo..." : "Subir Archivo GeoJson/KML"}
        <input
          type="file"
          accept=".geojson,.kml"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      {uploading && <CircularProgress size={24} sx={{ mt: 1 }} />}
    </>
  );
};

export default FileUploader;
