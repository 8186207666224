import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

const ContentBox = ({ title, text, videoSrc, zoom = false }) => (
  <Box
    component="article"
    sx={{
      p: { xs: 2, sm: 4 },
      backgroundColor: "#f0f0f0",
      borderRadius: "20px",
      boxShadow: "0px 6px 16px rgba(0,0,0,0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <Typography
      variant="h4"
      component="h3"
      sx={{
        fontWeight: "700",
        color: "#14192d",
        mb: 2,
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontSize: "1.1rem",
        lineHeight: "1.75",
        color: "#515151",
        mb: 2,
      }}
    >
      {text}
    </Typography>
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: "20px",
        boxShadow: "0px 6px 16px rgba(0,0,0,0.1)",
        width: "100%",
        height: 0,
        paddingTop: "56.25%", // 16:9 aspect ratio
        position: "relative",
        mt: 2,
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: zoom ? "110%" : "100%", // Aplicar zoom al video si es necesario
          height: "100%",
          objectFit: "cover",
          transform: zoom ? "scale(1.5)" : "none", // Aumenta el tamaño del video para aplicar un efecto de zoom
        }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  </Box>
);

const GetStarted = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      sx={{
        py: isMobile ? 5 : 10,
        px: isMobile ? 2 : 4,
        backgroundColor: "white",
      }}
    >
      <Grid item xs={12} sm={6}>
        <ContentBox
          title="Simplificamos lo complicado"
          text="Confía en Livespeed para la organización completa de tu evento. Ofrecemos un amplio rango de herramientas diseñadas para aliviar la carga de trabajo que conlleva la planificación y ejecución de un evento."
          videoSrc="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Screen%20Recording%202024-02-23%20at%200.26.10%20(1).mp4?alt=media&token=6c420160-ecf8-4124-ba35-17909219f471"
          zoom={true}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ContentBox
          title="Explora tu alrededor"
          text="Conecta con otros entusiastas, intercambia equipo y explora los rincones más emocionantes de tu ciudad para practicar tu pasión."
          videoSrc="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.mp4?alt=media&token=6dd53f67-9d9d-4920-8d7f-9c89d0c9d915"
        />
      </Grid>
    </Grid>
  );
};

export default GetStarted;
