import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, getDoc, collection, getDocs, addDoc } from "firebase/firestore";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Button,
  Box,
  CircularProgress,
  Grid,
  Divider,
  Chip,
  Fab,
} from "@mui/material";
import { Paid, FreeBreakfast, ShoppingCart } from "@mui/icons-material";
import AddonCard from "./CheckOut/AddonCard";

const PayEvent = () => {
  const { id } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonSizes, setAddonSizes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventDocRef = doc(db, "events", id);
        const eventDoc = await getDoc(eventDocRef);
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() });
        } else {
          setError("No se encontró el evento");
        }
      } catch (error) {
        setError("Error al obtener el evento");
        console.error("Error al obtener el evento:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAddons = async () => {
      try {
        const addonsCollectionRef = collection(db, "events", id, "addons");
        const addonsSnapshot = await getDocs(addonsCollectionRef);
        const addonsData = addonsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAddons(addonsData);
      } catch (error) {
        setError("Error al obtener los addons");
        console.error("Error al obtener los addons:", error);
      }
    };

    fetchEventData();
    fetchAddons();
  }, [id]);

  const handlePayment = async () => {
    const orderAddons = selectedAddons.map((addon) => ({
      type: addon.type,
      price: addon.price,
      size: addonSizes[addon.id] || null,
    }));

    // Agregar el precio del evento como un addon
    if (event.price > 0) {
      orderAddons.push({
        type: `Entrada: ${event.eventName}`,
        price: event.price,
        size: null,
      });
    }

    try {
      const orderData = {
        ItemUID: event.id,
        Total: getTotalPrice(),
        Status: "Failure",
        UserUID: user.uid,
        Addons: orderAddons,
        Type: "event",
      };
      const orderRef = await addDoc(collection(db, "orders"), orderData);
      navigate(`/checkout/${orderRef.id}`);
    } catch (error) {
      setError("Error al crear la orden");
      console.error("Error al crear la orden:", error);
    }
  };

  const handleAddonChange = (addon, isChecked) => {
    if (isChecked) {
      setSelectedAddons([...selectedAddons, addon]);
    } else {
      setSelectedAddons(selectedAddons.filter((a) => a.id !== addon.id));
      const updatedAddonSizes = { ...addonSizes };
      delete updatedAddonSizes[addon.id];
      setAddonSizes(updatedAddonSizes);
    }
  };

  const handleSizeChange = (addonId, size) => {
    setAddonSizes({ ...addonSizes, [addonId]: size });
  };

  const getTotalPrice = () => {
    const addonsTotal = selectedAddons.reduce(
      (total, addon) => total + addon.price,
      0
    );
    return event.price + addonsTotal;
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!event) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          Evento no encontrado
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Card sx={{ mb: 3, borderRadius: 2, overflow: "hidden" }}>
        <CardMedia
          component="img"
          height="500px"
          image={event.photo}
          alt={`Foto del evento ${event.id}`}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {event.eventName}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Fecha y Hora: </strong> {event.dateTime}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Nivel: </strong>
            {event.skillLevel}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Precio: </strong>
            {event.price > 0 ? (
              <Chip
                icon={<Paid />}
                label={`$${event.price}`}
                color="secondary"
              />
            ) : (
              <Chip icon={<FreeBreakfast />} label="Gratuito" color="primary" />
            )}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Add-ons:
          </Typography>
          <Grid container spacing={2}>
            {addons.map((addon) => (
              <Grid item xs={12} sm={6} md={4} key={addon.id}>
                <AddonCard
                  addon={addon}
                  selected={selectedAddons.some((a) => a.id === addon.id)}
                  onChange={handleAddonChange}
                  size={addonSizes[addon.id]}
                  onSizeChange={handleSizeChange}
                />
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ my: 2 }} />
        </CardContent>
      </Card>
      <Box position="fixed" bottom={80} right={16} zIndex={1000}>
        <Fab
          variant="extended"
          color="primary"
          onClick={handlePayment}
          size="large"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ShoppingCart sx={{ mr: 1 }} />
          Pagar ${getTotalPrice()}
        </Fab>
      </Box>
    </Container>
  );
};

export default PayEvent;
