import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Divider,
  Box,
  Stack,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import { storage } from "../../firebase";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const serviceTypesData = {
  Transmisión: [
    "Cadena",
    "Desviadores",
    "Shifters",
    "Cables",
    "Plato (Chainring)",
    "Cassette",
    "Eje de pedalier (Bottom Bracket)",
  ],
  Frenos: [
    "Pastillas de freno (Brake Pads)",
    "Discos de freno (Disc Rotors)",
    "Sistema de frenos (Brake System)",
  ],
  Suspensión: ["Horquilla (Fork)", "Amortiguador trasero (Rear shock)"],
  Cuadro: ["Reparación de Carbono", "Reparación de Alloy"],
  "Neumáticos y Ruedas": [
    "Presión de los neumáticos (Tire pressure)",
    "Desgaste de los neumáticos (Tire wear)",
    "Sellador de neumáticos (Tire sealant)",
    "Radios (Spokes)",
    "Rodamientos (Bearings)",
    "Ejes (Axles)",
  ],
  Dropper: ["Dropper"],
};

const CreateWorkShop = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { workshopId } = useParams();
  const firestore = getFirestore();
  const [workShopData, setWorkShopData] = useState({
    workshopName: "",
    location: { lat: 29.110262, lng: -110.940463 },
    workshopLogo: null,
    workshopPhoto: null,
    creationDate: "",
    phoneNumber: "",
    workshopAddress: "",
    workshopDescription: "",
    openingHours: {
      lunes: { open: "", close: "", closed: false },
      martes: { open: "", close: "", closed: false },
      miércoles: { open: "", close: "", closed: false },
      jueves: { open: "", close: "", closed: false },
      viernes: { open: "", close: "", closed: false },
      sábado: { open: "", close: "", closed: false },
      domingo: { open: "", close: "", closed: false },
    },
    serviceTypes: Object.fromEntries(
      Object.keys(serviceTypesData).map((serviceType) => [serviceType, false]),
    ),
    subServices: Object.fromEntries(
      Object.keys(serviceTypesData).map((serviceType) => [
        serviceType,
        Object.fromEntries(
          serviceTypesData[serviceType].map((subService) => [
            subService,
            false,
          ]),
        ),
      ]),
    ),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (workshopId) {
      const fetchWorkshop = async () => {
        const docRef = doc(firestore, "workshops", workshopId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setWorkShopData({ ...docSnap.data(), workshopId: docSnap.id });
        } else {
          console.log("No such workshop!");
        }
      };

      fetchWorkshop();
    }
  }, [workshopId, firestore]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkShopData({ ...workShopData, [name]: value });
  };

  const handleLocationChange = (e) => {
    const { latLng } = e;
    setWorkShopData((prevData) => ({
      ...prevData,
      location: { lat: latLng.lat(), lng: latLng.lng() },
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setWorkShopData((prevData) => ({ ...prevData, workshopLogo: file }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setWorkShopData((prevData) => ({ ...prevData, workshopPhoto: file }));
  };

  const handleOpeningHoursChange = (day, time, value) => {
    setWorkShopData((prevData) => ({
      ...prevData,
      openingHours: {
        ...prevData.openingHours,
        [day]: {
          ...prevData.openingHours[day],
          [time]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (section, service) => {
    if (section === "serviceTypes") {
      setWorkShopData((prevData) => ({
        ...prevData,
        serviceTypes: {
          ...prevData.serviceTypes,
          [service]: !prevData.serviceTypes[service],
        },
      }));
    } else {
      setWorkShopData((prevData) => ({
        ...prevData,
        subServices: {
          ...prevData.subServices,
          [section]: {
            ...prevData.subServices[section],
            [service]: !prevData.subServices[section][service],
          },
        },
      }));
    }
  };

  const handleDayCheckboxChange = (day) => {
    setWorkShopData((prevData) => ({
      ...prevData,
      openingHours: {
        ...prevData.openingHours,
        [day]: {
          ...prevData.openingHours[day],
          closed: !prevData.openingHours[day].closed,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let logoUrl = workShopData.workshopLogo;
      if (workShopData.workshopLogo instanceof File) {
        const logoRef = storageRef(
          storage,
          `workshop_logos/${user.uid}/${workShopData.workshopLogo.name}`,
        );
        const uploadLogoTaskSnapshot = await uploadBytesResumable(
          logoRef,
          workShopData.workshopLogo,
        );
        logoUrl = await getDownloadURL(uploadLogoTaskSnapshot.ref);
      }

      let photoUrl = workShopData.workshopPhoto;
      if (workShopData.workshopPhoto instanceof File) {
        const photoRef = storageRef(
          storage,
          `workshop_images/${user.uid}/${workShopData.workshopPhoto.name}`,
        );
        const uploadPhotoTaskSnapshot = await uploadBytesResumable(
          photoRef,
          workShopData.workshopPhoto,
        );
        photoUrl = await getDownloadURL(uploadPhotoTaskSnapshot.ref);
      }

      const workshopDataToSave = {
        ...workShopData,
        workshopLogo: logoUrl,
        workshopPhoto: photoUrl,
      };

      if (workshopId) {
        const workshopRef = doc(firestore, "workshops", workshopId);
        await updateDoc(workshopRef, workshopDataToSave);
        console.log("Workshop updated with ID: ", workshopId);
      } else {
        const workshopsCollectionRef = collection(firestore, "workshops");
        const newWorkshopRef = await addDoc(
          workshopsCollectionRef,
          workshopDataToSave,
        );
        console.log("New workshop created with ID: ", newWorkshopRef.id);
      }

      navigate("/home");
    } catch (error) {
      console.error("Error creating workshop:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          {workshopId ? "Editar Taller" : "Crear Taller"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Taller"
                type="text"
                name="workshopName"
                value={workShopData.workshopName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha de Creación"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="creationDate"
                value={workShopData.creationDate}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={workShopData.location}
                  zoom={10}
                  onClick={handleLocationChange}
                >
                  <Marker position={workShopData.location} />
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="workshopLogo">
                Agrega Logo del Taller:
              </InputLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <input
                  accept="image/*"
                  id="workshopLogo"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleLogoChange}
                />
                <label htmlFor="workshopLogo">
                  <IconButton color="primary" component="span">
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
                {workShopData.workshopLogo && (
                  <Box
                    component="img"
                    sx={{ width: 100, height: "auto" }}
                    alt="Workshop logo preview"
                    src={
                      workShopData.workshopLogo instanceof File
                        ? URL.createObjectURL(workShopData.workshopLogo)
                        : workShopData.workshopLogo
                    }
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="workshopPhoto">
                Agrega Fotos del Taller:
              </InputLabel>
              <Stack direction="row" spacing={2} alignItems="center">
                <input
                  accept="image/*"
                  id="workshopPhoto"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
                <label htmlFor="workshopPhoto">
                  <IconButton color="primary" component="span">
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
                {workShopData.workshopPhoto && (
                  <Box
                    component="img"
                    sx={{ width: 100, height: "auto" }}
                    alt="Workshop preview"
                    src={
                      workShopData.workshopPhoto instanceof File
                        ? URL.createObjectURL(workShopData.workshopPhoto)
                        : workShopData.workshopPhoto
                    }
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Número de Teléfono"
                type="tel"
                name="phoneNumber"
                value={workShopData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección del Taller"
                type="text"
                name="workshopAddress"
                value={workShopData.workshopAddress}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción del Taller"
                multiline
                rows={4}
                name="workshopDescription"
                value={workShopData.workshopDescription}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Horario de Apertura:
              </Typography>
              <Divider />
              {Object.entries(workShopData.openingHours).map(([day, hours]) => (
                <Grid container spacing={2} key={day} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      <strong>
                        {day.charAt(0).toUpperCase() + day.slice(1)}:
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hours.closed}
                          onChange={() => handleDayCheckboxChange(day)}
                        />
                      }
                      label="Cerrado"
                    />
                    {!hours.closed && (
                      <Stack direction="row" spacing={1}>
                        <TextField
                          type="time"
                          value={hours.open}
                          onChange={(e) =>
                            handleOpeningHoursChange(
                              day,
                              "open",
                              e.target.value,
                            )
                          }
                        />
                        <Typography variant="body1">a</Typography>
                        <TextField
                          type="time"
                          value={hours.close}
                          onChange={(e) =>
                            handleOpeningHoursChange(
                              day,
                              "close",
                              e.target.value,
                            )
                          }
                        />
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Tipo de Servicio:
              </Typography>
              <Divider />
              <FormControl component="fieldset">
                {Object.entries(workShopData.serviceTypes).map(
                  ([serviceType, checked]) => (
                    <React.Fragment key={serviceType}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={() =>
                              handleCheckboxChange("serviceTypes", serviceType)
                            }
                          />
                        }
                        label={serviceType}
                      />
                      {checked && (
                        <Grid container>
                          {serviceTypesData[serviceType].map((subService) => (
                            <Grid item key={subService} xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      workShopData.subServices[serviceType][
                                        subService
                                      ]
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        serviceType,
                                        subService,
                                      )
                                    }
                                  />
                                }
                                label={subService}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </React.Fragment>
                  ),
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : workshopId ? (
                  "Actualizar Taller"
                ) : (
                  "Crear Taller"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateWorkShop;
