import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import {
  Button,
  Card,
  CardMedia,
  Typography,
  Container,
  Grid,
  Box,
  CardContent,
  CardActionArea,
  useMediaQuery,
  useTheme,
  Avatar,
  TextField,
  InputAdornment,
  Chip,
  Rating,
  Skeleton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TerrainIcon from "@mui/icons-material/Terrain";
import { useUserAuth } from "../../context/userAuthContext";

const Parks = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [parks, setParks] = useState([]);
  const [filteredParks, setFilteredParks] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        try {
          const userDocRef = doc(db, "Users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setIsAdmin(!!userData.ParkAdmin);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();

    const unsubscribe = onSnapshot(
      collection(db, "Parks"),
      (snapshot) => {
        const parksData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParks(parksData);
        setFilteredParks(parksData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching parks data:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const redirectToPark = (parkId) => {
    navigate(`/park/${parkId}`);
  };

  const redirectToParkManager = () => {
    navigate("/myparks");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = parks.filter((park) =>
      park.ParkName?.toLowerCase().includes(value)
    );
    setFilteredParks(filtered);
  };

  const ParkCard = ({ park }) => (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        overflow: "hidden",
        boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 12px 24px rgba(0,0,0,0.15)",
        },
      }}
    >
      <CardActionArea onClick={() => redirectToPark(park.id)}>
        <CardMedia
          component="img"
          height={220}
          image={park.Cover || ""}
          alt={`Foto del parque ${park.ParkName || ""}`}
          sx={{ objectFit: "cover" }}
        />
        <CardContent sx={{ p: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6" fontWeight="bold" noWrap>
              {park.ParkName || "Parque sin nombre"}
            </Typography>
            <Avatar src={park.Logo || ""} alt={`${park.ParkName || ""} logo`} />
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOnIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary" noWrap>
              {park.city || ""}, {park.State || ""}, {park.country || ""}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <PeopleIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              {Array.isArray(park.Followers) ? park.Followers.length : 0}{" "}
              seguidores
            </Typography>
          </Box>
          {park.location &&
            typeof park.location.latitude === "number" &&
            typeof park.location.longitude === "number" && (
              <Box display="flex" alignItems="center" mb={1}>
                <TerrainIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                <Typography variant="body2" color="text.secondary" noWrap>
                  {park.location.latitude.toFixed(2)},{" "}
                  {park.location.longitude.toFixed(2)}
                </Typography>
              </Box>
            )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Rating
              name={`rating-${park.id}`}
              value={4.5} // Asume una calificación de 4.5, ajusta según tus datos
              precision={0.5}
              readOnly
              size="small"
            />
            <Chip
              icon={<FavoriteIcon />}
              label="Favorito"
              size="small"
              color="primary"
              sx={{ borderRadius: 10 }}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h4" fontWeight="bold" color="primary">
          Descubre Parques Increíbles
        </Typography>
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={redirectToParkManager}
            sx={{ borderRadius: 30, textTransform: "none" }}
          >
            Gestionar Parques
          </Button>
        )}
      </Box>
      <Box mb={4}>
        <TextField
          fullWidth
          placeholder="Busca tu próxima aventura..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
          sx={{
            maxWidth: 600,
            mx: "auto",
            backgroundColor: "#fff",
            borderRadius: 30,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: 30,
              },
            },
          }}
        />
      </Box>
      <Grid container spacing={3}>
        {loading
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton
                  variant="rectangular"
                  height={220}
                  sx={{ borderRadius: 4 }}
                />
                <Skeleton variant="text" sx={{ mt: 1 }} />
                <Skeleton variant="text" width="60%" />
              </Grid>
            ))
          : filteredParks.map((park) => (
              <Grid item xs={12} sm={6} md={4} key={park.id}>
                <ParkCard park={park} />
              </Grid>
            ))}
      </Grid>
      {!loading && filteredParks.length === 0 && (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="text.secondary">
            No se encontraron parques. Intenta con otra búsqueda.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Parks;
