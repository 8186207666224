import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
} from "@mui/material";
import scanSound from "../../../sounds/bip.mp3";

const OfflineStartReceiver = () => {
  const [error, setError] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState("");
  const [receivers, setReceivers] = useState([]);

  const playScanSound = () => {
    const audio = new Audio(scanSound);
    audio.play();
  };

  const writeDataToNFC = async (data) => {
    try {
      const ndef = new window.NDEFReader();

      await ndef.write(data);

      console.log("Data written to NFC tag successfully:", data);

      setScanResult(data);
    } catch (error) {
      console.error("Error writing data to NFC tag:", error);
      setError("Error writing data to NFC tag");
    }
  };

  useEffect(() => {
    // Obtener la lista de receptores disponibles (R1, R2, ..., R50)
    const availableReceivers = [];
    for (let i = 1; i <= 50; i++) {
      availableReceivers.push(`R${i}`);
    }
    setReceivers(availableReceivers);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Escribir datos en la pulsera NFC cuando se envía el formulario
    const currentTime = new Date().toISOString();
    const dataToWrite = {
      StartTime: currentTime,
      Receiver: selectedReceiver,
    };
    writeDataToNFC(dataToWrite);
    playScanSound();
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h4">Offline Start Receiver</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel>Select Receiver</InputLabel>
            <Select
              value={selectedReceiver}
              onChange={(e) => setSelectedReceiver(e.target.value)}
            >
              <MenuItem value="">Select a receiver</MenuItem>
              {receivers.map((receiver) => (
                <MenuItem key={receiver} value={receiver}>
                  {receiver}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button sx={{ mt: 2 }} variant="contained" type="submit">
            Start NFC Scan
          </Button>
        </form>
        {scanResult && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1">NFC Data Written:</Typography>
            <Typography variant="subtitle1">
              StartTime: {scanResult.StartTime}
            </Typography>
            <Typography variant="subtitle1">
              Receiver: {scanResult.Receiver}
            </Typography>
          </Box>
        )}
        {error && (
          <Typography variant="subtitle1" sx={{ mt: 2, color: "red" }}>
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default OfflineStartReceiver;
