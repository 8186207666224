import React from "react";
import { Typography, Box, Paper, Button, Divider, Link } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WifiIcon from "@mui/icons-material/Wifi";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const Receptores = () => {
  // Historial de versiones ficticias
  const firmwareVersions = [
    { version: "2.1.3", date: "2024-03-09", link: "#" },
    { version: "2.1.2", date: "2023-12-15", link: "#" },
    { version: "2.1.1", date: "2023-09-30", link: "#" },
    { version: "2.1.0", date: "2023-07-20", link: "#" },
  ];

  return (
    <Box>
      {/* Título de la Guía */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Guía de Receptores
      </Typography>

      {/* Introducción */}
      <Typography variant="body1" paragraph>
        Los receptores son dispositivos clave en Livespeed, permitiendo capturar
        la información de los ciclistas en diferentes puntos de una ruta o
        evento. Aquí aprenderás cómo configurar y utilizar los receptores en la
        plataforma.
      </Typography>

      {/* Sección: Acceso a la configuración de receptores */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: "black",
          color: "white",
          borderRadius: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <QrCodeScannerIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Habilita tu cuenta como EventManager
          </Typography>
        </Box>
        <Typography variant="body2" paragraph>
          Para dar de alta un receptor, es necesario tener una cuenta habilitada
          como EventManager. Si aún no tienes acceso, contacta con un
          administrador para solicitar la activación.
        </Typography>
        <Link
          href="/verificacion-cuentas"
          underline="hover"
          sx={{ color: "white" }}
        >
          Verificar mi cuenta
        </Link>
      </Paper>

      {/* Información sobre Receivers y descarga de firmware */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: "#f5f5f5",
          borderRadius: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <QrCodeScannerIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Receiver disponible: Versión 2.1.3
          </Typography>
        </Box>
        <Typography variant="body2" paragraph>
          Actualmente, está disponible públicamente el "Receiver Version 2.1.3"
          creado por LivespeedCorp, que se utiliza en los eventos de
          cronometraje.
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "black", color: "white", mt: 2 }}
          startIcon={<SystemUpdateAltIcon />}
        >
          Bajar última versión de Firmware (.bin)
        </Button>
      </Paper>
      <Divider sx={{ my: 3 }} />

      {/* Historial de versiones */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Historial de versiones de firmware
      </Typography>
      {firmwareVersions.map((version) => (
        <Paper
          key={version.version}
          elevation={1}
          sx={{
            p: 2,
            mb: 2,
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            <strong>Versión:</strong> {version.version} -{" "}
            <strong>Fecha:</strong> {version.date}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<SystemUpdateAltIcon />}
            href={version.link}
          >
            Descargar
          </Button>
        </Paper>
      ))}

      <Divider sx={{ my: 3 }} />

      {/* Nota sobre conexión a WiFi */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 4,
          backgroundColor: "#f5f5f5",
          borderRadius: "12px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <WifiIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Conexión a Internet mediante WiFi
          </Typography>
        </Box>
        <Typography variant="body2" paragraph>
          Recuerda que los receptores se conectan a Internet gracias al módulo
          de WiFi que tienen incorporados. Para actualizar tu receiver de forma
          automática, crea un hotspot con la red de tu celular:
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>Red:</strong> livespeed <br />
          <strong>Contraseña:</strong> 0987654321
        </Typography>
        <Typography variant="body2" paragraph>
          Los receivers por defecto tienen estos parámetros de WiFi. Una vez
          conectado el receiver a tu red WiFi del celular, podrás configurar más
          parámetros desde la aplicación móvil o web en el apartado{" "}
          <Link href="/misreceivers" underline="hover">
            Mis Receivers
          </Link>
          .
        </Typography>
        <Typography variant="body2" paragraph>
          En caso de no tener acceso a Internet y querer actualizar a la última
          versión, baja el archivo .bin correspondiente a la versión más
          reciente.
        </Typography>
      </Paper>

      {/* Sección: Configuración del primer receiver */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        ⚡️ Configuración del Receiver de Inicio
      </Typography>
      <Typography variant="body1" paragraph>
        ¡Vamos a comenzar! 🚀
      </Typography>
      <Typography variant="body1" paragraph>
        1. <strong>Escanea el Código QR</strong>: Ubicado en la parte inferior
        del dispositivo, este código te permitirá obtener el{" "}
        <strong>RUID</strong> (Número de Serie Único del Receiver). ¡Recuerda!
        Este código es único y no se puede repetir, ¡así que asegúrate de
        escanearlo correctamente! 📱
      </Typography>
      <Typography variant="body1" paragraph>
        2. <strong>Asigna un "Parque"</strong>: Selecciona el lugar donde se
        llevará a cabo el evento. Este será el espacio donde tu receiver se
        desplegará.
      </Typography>
      <Typography variant="body1" paragraph>
        3. <strong>Elige una "Pista"</strong>: Aquí es donde tu receiver
        comenzará a captar el paso de los ciclistas. Asigna cuidadosamente la
        pista de bicicletas donde se colocará.
      </Typography>
      <Typography variant="body1" paragraph>
        4. <strong>Marca como "Inicio"</strong>: Este paso es clave. Asegúrate
        de seleccionar la opción <strong>Receiver de Inicio</strong> 🏁 para
        indicar que este será el primer punto en el sistema de cronometraje.
      </Typography>

      {/* Sección: Configuración del segundo receiver */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        🏁 Configuración del Receiver de Llegada
      </Typography>
      <Typography variant="body1" paragraph>
        ¡Ya casi terminamos! 🎉
      </Typography>
      <Typography variant="body1" paragraph>
        1. <strong>Repite el Proceso</strong>: Una vez configurado el receiver
        de inicio, es hora de configurar el <strong>Receiver de Llegada</strong>
        . Nuevamente, escanea el código QR del segundo receiver para obtener su{" "}
        <strong>RUID</strong>.
      </Typography>
      <Typography variant="body1" paragraph>
        2. <strong>Selecciona el Mismo Parque y Pista</strong>: Para completar
        el circuito, asegúrate de asignar el mismo parque y pista que
        seleccionaste en el receiver de inicio.
      </Typography>
      <Typography variant="body1" paragraph>
        3. <strong>Marca como "Llegada"</strong>: ¡Y este es el último paso!
        Marca este receiver como <strong>Receiver de Llegada</strong> 🏁,
        cerrando así el ciclo del sistema de cronometraje. Este dispositivo
        registrará los tiempos finales de los ciclistas al cruzar la meta.
      </Typography>
    </Box>
  );
};

export default Receptores;
