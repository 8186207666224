import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Avatar,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Input = styled("input")({
  display: "none",
});

const containerStyle = {
  width: "100%",
  height: "400px",
};

const EditPark = () => {
  const { uid } = useParams();
  const [parkData, setParkData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const fetchPark = async () => {
      try {
        const parkDocRef = doc(db, "Parks", uid);
        const parkDocSnap = await getDoc(parkDocRef);

        if (parkDocSnap.exists()) {
          const parkData = parkDocSnap.data();
          setParkData(parkData);
          setLogoPreview(parkData.Logo);
          setCoverPreview(parkData.Cover);
          setLocation({
            lat: parkData.Location?.latitude || 0,
            lng: parkData.Location?.longitude || 0,
          });
        } else {
          console.error("No such park!");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching park:", error);
        setLoading(false);
      }
    };

    fetchPark();
  }, [uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParkData((prevPark) => ({
      ...prevPark,
      [name]: value,
    }));
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCoverChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCoverFile(e.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deletePreviousFile = async (url) => {
    if (url) {
      const fileRef = ref(storage, url);
      await deleteObject(fileRef).catch((error) => {
        console.error("Error deleting previous file:", error);
      });
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      let logoUrl = parkData.Logo;
      let coverUrl = parkData.Cover;

      if (logoFile) {
        await deletePreviousFile(logoUrl);
        const logoRef = ref(storage, `park_logos/${uid}/${logoFile.name}`);
        const uploadTask = uploadBytesResumable(logoRef, logoFile);
        logoUrl = await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
          });
        });
      }

      if (coverFile) {
        await deletePreviousFile(coverUrl);
        const coverRef = ref(storage, `park_covers/${uid}/${coverFile.name}`);
        const uploadTask = uploadBytesResumable(coverRef, coverFile);
        coverUrl = await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
          });
        });
      }

      const parkRef = doc(db, "Parks", uid);
      await updateDoc(parkRef, {
        ...parkData,
        Logo: logoUrl,
        Cover: coverUrl,
        Location: {
          latitude: location.lat,
          longitude: location.lng,
        },
      });

      setParkData((prevPark) => ({
        ...prevPark,
        Logo: logoUrl,
        Cover: coverUrl,
        Location: {
          latitude: location.lat,
          longitude: location.lng,
        },
      }));

      setSaving(false);
    } catch (error) {
      console.error("Error saving park data:", error);
      setSaving(false);
    }
  };

  const onMapClick = (e) => {
    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!parkData) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Parque no encontrado
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 2,
          borderRadius: 3,
          backgroundColor: "#ffffff",
          color: "#000000",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Editar Parque
        </Typography>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Parque"
                type="text"
                name="ParkName"
                value={parkData.ParkName}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Box position="relative">
                  <Avatar
                    alt={parkData.ParkName}
                    src={logoPreview}
                    sx={{ width: 120, height: 120, mb: 2 }}
                  />
                  <Input
                    accept="image/*"
                    id="icon-button-file-logo"
                    type="file"
                    onChange={handleLogoChange}
                  />
                  <label htmlFor="icon-button-file-logo">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Box>
                <Box position="relative">
                  <Avatar
                    variant="square"
                    alt={parkData.ParkName}
                    src={coverPreview}
                    sx={{ width: 120, height: 120, mb: 2 }}
                  />
                  <Input
                    accept="image/*"
                    id="icon-button-file-cover"
                    type="file"
                    onChange={handleCoverChange}
                  />
                  <label htmlFor="icon-button-file-cover">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#ffffff",
                        color: "#000000",
                      }}
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="País"
                type="text"
                name="country"
                value={parkData.country}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Estado"
                type="text"
                name="state"
                value={parkData.state}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Ciudad"
                type="text"
                name="city"
                value={parkData.city}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={location}
                  zoom={15}
                  onClick={onMapClick}
                >
                  <Marker position={location} />
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={saving}
                fullWidth
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#333333",
                  },
                }}
              >
                {saving ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Guardar Cambios"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default EditPark;
