import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Box,
} from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AddBike = () => {
  const { user } = useUserAuth();
  const [bikeData, setBikeData] = useState({
    bikeType: "",
    bikePic: "",
    bikeBrand: "",
    bikeSize: "",
    wheelSize: "",
  });
  const [bikeTypes, setBikeTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBikeTypes = async () => {
      try {
        const firestore = getFirestore();
        const querySnapshot = await getDocs(collection(firestore, "BikeTypes"));
        const types = querySnapshot.docs.map((doc) => doc.data().bikeType);
        setBikeTypes(types);
      } catch (error) {
        console.error("Error al cargar los tipos de bicicleta:", error);
        setError("Error al cargar los tipos de bicicleta.");
      }
    };

    fetchBikeTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBikeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);

      const storage = getStorage();
      const storageRef = ref(storage, `bikes/${user.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setBikeData((prevData) => ({ ...prevData, bikePic: url }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const firestore = getFirestore();
      await addDoc(collection(firestore, "Bikes"), {
        ...bikeData,
        userid: user.uid,
        bikeid: doc(collection(firestore, "Bikes")).id,
      });

      setBikeData({
        bikeType: "",
        bikePic: "",
        bikeBrand: "",
        bikeSize: "",
        wheelSize: "",
      });
      setImagePreview("");
      setError("");
      setLoading(false);
    } catch (error) {
      console.error("Error al agregar bicicleta:", error);
      setError("Error al agregar la bicicleta.");
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" mb={3}>
        Agregar Bicicleta
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Tipo de Bicicleta</InputLabel>
              <Select
                name="bikeType"
                value={bikeData.bikeType}
                onChange={handleChange}
                required
                label="Tipo de Bicicleta"
              >
                <MenuItem value="">Selecciona un tipo...</MenuItem>
                {bikeTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Marca de la Bicicleta"
              type="text"
              name="bikeBrand"
              value={bikeData.bikeBrand}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Tamaño del Cuadro</InputLabel>
              <Select
                name="bikeSize"
                value={bikeData.bikeSize}
                onChange={handleChange}
                required
                label="Tamaño del Cuadro"
              >
                <MenuItem value="">Selecciona un tamaño...</MenuItem>
                <MenuItem value="XXLarge">XXLarge</MenuItem>
                <MenuItem value="XLarge">XLarge</MenuItem>
                <MenuItem value="Large">Large</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Small">Small</MenuItem>
                <MenuItem value="XSmall">XSmall</MenuItem>
                <MenuItem value="XXSmall">XXSmall</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Tamaño de la Rueda</InputLabel>
              <Select
                name="wheelSize"
                value={bikeData.wheelSize}
                onChange={handleChange}
                required
                label="Tamaño de la Rueda (pulgadas)"
              >
                <MenuItem value="">Selecciona un tamaño...</MenuItem>
                {[...Array(19)].map((_, i) => (
                  <MenuItem key={i} value={12 + i}>
                    {12 + i} pulgadas
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label" fullWidth>
              Cargar Foto de la Bicicleta
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {imagePreview && (
              <Box mt={2} textAlign="center">
                <img
                  src={imagePreview}
                  alt="Bike Preview"
                  style={{
                    width: "100%",
                    maxHeight: "300px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : "Agregar Bicicleta"}
            </Button>
          </Grid>
        </Grid>
      </form>

      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default AddBike;
