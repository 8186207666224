import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  Typography,
  Box,
  Avatar,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Brightness1 } from "@mui/icons-material";

const ManualLiveRider = () => {
  const [activeRuns, setActiveRuns] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLedOn, setIsLedOn] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      setIsLedOn((prevIsLedOn) => !prevIsLedOn);
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "Runs"), where("ActiveRun", "==", true)),
      (snapshot) => {
        const activeRunsData = [];
        snapshot.forEach(async (doc) => {
          const runData = doc.data();
          console.log("runData", runData);
          // const tagData = await getTagData(runData.tag);
          const tagData = await getTagData(runData.NFCIdentifier);
          if (tagData) {
            runData.Owner = tagData.Owner;
            runData.Category = tagData.Category;
            // runData.ParkName = await getParkName(runData.ParkID);
            // runData.TrackName = await getTrackName(
            //   runData.ParkID,
            //   runData.TrackID,
            // );
            // runData.photoUrl = ""; // No se utiliza aquí, puedes eliminarlo si lo deseas
            activeRunsData.push(runData);
          }
        });
        setActiveRuns(activeRunsData);
      },
      (error) => console.error("Error fetching active runs:", error),
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const getTagData = async (NFCIdentifier) => {
    try {
      console.log(123, NFCIdentifier);
      const tagsCollectionRef = collection(db, "Tags");
      const q = query(tagsCollectionRef, where("NFCID", "==", NFCIdentifier));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const tagDoc = querySnapshot.docs[0];
        return tagDoc.data();
      } else {
        console.log("No tag found for NFCIdentifier:", NFCIdentifier);
        return null;
      }
    } catch (error) {
      console.error("Error getting tag data:", error);
      return null;
    }
  };

  const getParkName = async (parkID) => {
    try {
      const parkDoc = await getDoc(doc(db, "Parks", parkID));
      if (parkDoc.exists()) {
        return parkDoc.data().ParkName;
      } else {
        return "Unknown Park";
      }
    } catch (error) {
      console.error("Error getting park data:", error);
      return "Unknown Park";
    }
  };

  const getTrackName = async (parkID, trackID) => {
    try {
      const trackDoc = await getDoc(
        doc(db, "Parks", parkID, "Tracks", trackID),
      );
      if (trackDoc.exists()) {
        return trackDoc.data().TrackName;
      } else {
        return "Unknown Track";
      }
    } catch (error) {
      console.error("Error getting track data:", error);
      return "Unknown Track";
    }
  };

  const calculateElapsedTime = (startTime) => {
    const startTimestamp = new Date(startTime).getTime();
    const currentTimestamp = currentTime.getTime();
    const elapsedMilliseconds = currentTimestamp - startTimestamp;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        style={{ color: "black" }}
      >
        Active Riders
      </Typography>
      <Grid container spacing={3}>
        {activeRuns.length === 0 ? (
          <Typography variant="subtitle1" style={{ color: "white" }}>
            No active runs found.
          </Typography>
        ) : (
          activeRuns.map((run, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{ backgroundColor: "rgba(0,0,0,0.8)", borderRadius: "5%" }}
              >
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      <Box sx={{ color: "white" }}>
                        <Typography variant="body1">
                          <strong>Rider:</strong>{" "}
                          {run.Owner ? run.Owner : "Unknown"}
                        </Typography>
                        <strong>Categoria:</strong>{" "}
                        {run.Category ? run.Category : "Unknown"}
                        <Typography variant="body1">
                          <strong>Salida:</strong>{" "}
                          {new Date(run.StartTime).toLocaleString()}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{ color: "white" }}
                        >
                          Tiempo transcurrido:
                          <br />
                          {calculateElapsedTime(run.StartTime)}
                          <Brightness1
                            style={{
                              verticalAlign: "middle",
                              animation: isLedOn
                                ? "blink 1.5s infinite"
                                : "none",
                              opacity: isLedOn ? 1 : 0.5,
                              color: "green",
                            }}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default ManualLiveRider;
