import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Paper,
  Modal,
} from "@mui/material";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateAddon from "./CreateAddon";

const ViewAddons = ({ eventId }) => {
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addonId, setAddonId] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [addonToDelete, setAddonToDelete] = useState(null);

  const fetchAddons = async () => {
    const addonsCollectionRef = collection(db, `events/${eventId}/addons`);
    const addonsSnapshot = await getDocs(addonsCollectionRef);
    const addonsData = addonsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAddons(addonsData);
    setLoading(false);
  };

  useEffect(() => {
    fetchAddons();
  }, [eventId]);

  const handleDeleteAddon = async () => {
    try {
      const addonDocRef = doc(db, `events/${eventId}/addons`, addonToDelete);
      await deleteDoc(addonDocRef);
      setAddons(addons.filter((addon) => addon.id !== addonToDelete));
      setOpenDeleteConfirmation(false);
      setAddonToDelete(null);
    } catch (error) {
      console.error("Error deleting addon: ", error);
    }
  };

  if (loading) return <Box>Loading...</Box>;

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Addons
      </Typography>
      <CreateAddon
        eventId={eventId}
        addonId={addonId}
        setAddonId={setAddonId}
      />
      <Box mt={4}>
        {addons.map((addon) => (
          <Paper key={addon.id} sx={{ p: 2, mb: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              {addon.pic && (
                <img
                  src={addon.pic}
                  alt={addon.type}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              )}
              <Box>
                <Typography variant="h6">{addon.type}</Typography>
                <Typography variant="body1">Precio: ${addon.price}</Typography>
                <Typography variant="body1">Stock: {addon.stock}</Typography>
                {addon.description && (
                  <Typography variant="body1">
                    Descripción: {addon.description}
                  </Typography>
                )}
                {addon.size && addon.size.length > 0 && (
                  <Typography variant="body1">
                    Tamaños: {addon.size.join(", ")}
                  </Typography>
                )}
              </Box>
              <IconButton color="primary" onClick={() => setAddonId(addon.id)}>
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  setAddonToDelete(addon.id);
                  setOpenDeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Paper>
        ))}
      </Box>
      <Modal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">
            ¿Estás seguro de eliminar este addon?
          </Typography>
          <Stack direction="row" spacing={1} mt={2}>
            <Button
              color="secondary"
              onClick={() => setOpenDeleteConfirmation(false)}
            >
              Cancelar
            </Button>
            <Button color="error" onClick={handleDeleteAddon}>
              Eliminar
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </Box>
  );
};

export default ViewAddons;
