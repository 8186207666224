import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  Stepper,
  StepLabel,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import DirectionsIcon from "@mui/icons-material/Directions";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import BuildIcon from "@mui/icons-material/Build";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import StepConnector from "@mui/material/StepConnector";
import { stepConnectorClasses } from "@mui/material/StepConnector";

const steps = [
  { label: "pendiente", icon: <HourglassEmptyIcon /> },
  { label: "aceptado", icon: <DirectionsIcon /> },
  { label: "trabajando", icon: <BuildIcon /> },
  { label: "completado", icon: <TaskAltIcon /> },
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, icon } = props;
  const theme = useTheme();

  // Asegúrate de que el tamaño del Avatar coincida con el tamaño de los iconos
  return (
    <Avatar
      sx={{
        bgcolor: completed
          ? theme.palette.primary.main
          : active
            ? theme.palette.secondary.main
            : theme.palette.action.disabled,
        color: theme.palette.getContrastText(
          completed
            ? theme.palette.primary.main
            : active
              ? theme.palette.secondary.main
              : theme.palette.action.disabled,
        ),
        width: 40, // Ajusta según sea necesario para coincidir con el tamaño de tus iconos
        height: 40, // Ajusta según sea necesario para coincidir con el tamaño de tus iconos
        fontSize: "small", // Ajusta el tamaño de la fuente del icono si es necesario
      }}
    >
      {icon}
    </Avatar>
  );
};

const OnService = () => {
  const [userServices, setUserServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [workshopNames, setWorkshopNames] = useState({});
  const [loading, setLoading] = useState(true);
  const [workshopLocation, setWorkshopLocation] = useState(null); // State para almacenar la ubicación del taller seleccionado
  const auth = getAuth();
  const firestore = getFirestore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      console.log("No user logged in.");
      return;
    }

    const uid = auth.currentUser.uid;
    const servicesQuery = query(
      collection(firestore, "bikeService"),
      where("userId", "==", uid),
    );

    const unsubscribe = onSnapshot(servicesQuery, async (querySnapshot) => {
      const services = [];
      const workshopIds = new Set();

      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        services.push({ id: docSnapshot.id, ...data });
        workshopIds.add(data.workshopId);
      });

      setUserServices(services);

      const workshopNamesObj = {};
      for (const id of workshopIds) {
        const workshopDoc = await getDoc(doc(firestore, "workshops", id));
        if (workshopDoc.exists()) {
          workshopNamesObj[id] = workshopDoc.data().workshopName;
        }
      }

      setWorkshopNames(workshopNamesObj);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth.currentUser, firestore]);

  const handleConfirmCancel = async () => {
    if (selectedService) {
      const serviceRef = doc(firestore, "bikeService", selectedService);
      await updateDoc(serviceRef, { status: "cancelado" });
      setDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setSelectedService(null);
    setDialogOpen(false);
  };

  const handleGoToWorkshop = () => {
    if (workshopLocation) {
      const { lat, lng } = workshopLocation;
      console.log("Latitud:", lat);
      console.log("Longitud:", lng);
      const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(googleMapsLink, "_blank");
    }
  };

  useEffect(() => {
    const fetchWorkshopLocation = async () => {
      if (selectedService && userServices.length > 0) {
        const service = userServices.find(
          (item) => item.id === selectedService,
        );
        if (service && service.status === "aceptado") {
          const workshopId = service.workshopId;
          const workshopDoc = await getDoc(
            doc(firestore, "workshops", workshopId),
          );
          if (workshopDoc.exists()) {
            const { location } = workshopDoc.data();
            setWorkshopLocation(location);
          }
        }
      }
    };

    fetchWorkshopLocation();
  }, [selectedService, userServices, firestore]);

  return (
    <>
      {!auth.currentUser ? (
        <Dialog open={true}>
          <DialogTitle>No has iniciado sesión</DialogTitle>
          <DialogContent>
            <Typography>
              Por favor, inicia sesión o regístrate para acceder a tus
              servicios.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => navigate("/signup")} color="primary">
              Regístrate
            </Button>
            <Button
              onClick={() => navigate("/login")}
              color="primary"
              autoFocus
            >
              Inicia Sesión
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Grid container spacing={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            userServices.map((service) => (
              <Grid item key={service.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    bgcolor:
                      service.status === "cancelado" ? "#ffcccc" : "inherit",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">
                      <strong>Servicio en: </strong>{" "}
                      {workshopNames[service.workshopId]}
                    </Typography>
                    <Typography>
                      <strong>Bike ID:</strong> {service.bikeId}
                    </Typography>
                    <Typography>
                      <strong>Detalles del Servicio:</strong>{" "}
                      {service.serviceDetails}
                    </Typography>
                    <Typography>
                      <strong>Service ID:</strong> {service.id}
                    </Typography>
                    <Typography>
                      <strong>Status:</strong> {service.status}
                    </Typography>
                    <Typography>
                      <strong>Fecha de Creación:</strong>{" "}
                      {service.createdAt.toDate().toLocaleString()}
                    </Typography>
                    <Box sx={{ marginTop: theme.spacing(2) }}>
                      {service.status === "aceptado" && (
                        <>
                          <Typography variant="h6">
                            Lleva tu bicicleta al taller:
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={handleGoToWorkshop}
                            sx={{ mb: 1 }}
                          >
                            Ir al taller
                          </Button>
                        </>
                      )}
                      <Stepper
                        activeStep={steps.findIndex(
                          (step) => step.label === service.status,
                        )}
                        alternativeLabel
                        connector={<QontoConnector />}
                      >
                        {steps.map((step) => (
                          <Step key={step.label}>
                            <StepLabel
                              StepIconComponent={(props) => (
                                <QontoStepIcon {...props} icon={step.icon} />
                              )}
                            >
                              {step.label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                    {service.status !== "cancelado" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <QRCode
                          value={service.id}
                          size={isMobile ? 180 : 300}
                        />
                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            bgcolor: "lightcoral",
                            color: "white",
                            "&:hover": { bgcolor: "coral" },
                          }}
                          onClick={() =>
                            setSelectedService(service.id) ||
                            setDialogOpen(true)
                          }
                        >
                          Cancelar Servicio
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro que deseas cancelar este servicio? Cancelar un
            servicio aceptado puede afectar tu reputacion al momento de reservar
            nuevos servicios.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="error">
            Sí, cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OnService;
