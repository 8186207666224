import React from "react";
import {
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const ParticipantsList = ({ riders }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <List sx={{ width: "100%", maxWidth: 600, margin: "auto" }}>
      {riders.map((rider, index) => (
        <ListItem
          key={rider.id || index}
          component={Link}
          to={`/user/${rider.id}`}
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            "&:hover": {
              backgroundColor: theme.palette.grey[200],
            },
            padding: isMobile ? 1 : 2,
            transition: "background-color 0.3s ease",
          }}
        >
          <ListItemAvatar>
            <Avatar
              src={rider.photoUrl}
              alt={rider.name}
              sx={{
                width: isMobile ? 40 : 50,
                height: isMobile ? 40 : 50,
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant={isMobile ? "body1" : "h6"}
                component="span"
                sx={{ color: "black", fontWeight: "bold" }}
              >
                {rider.name}
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="text.secondary">
                @{rider.nickName}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ParticipantsList;
