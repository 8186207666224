import React from "react";
import { Typography, Box, Paper, Avatar, Chip, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SellIcon from "@mui/icons-material/Sell";

const ProductDetails = ({
  name,
  price,
  city,
  state,
  country,
  categoryName,
  subCategoryName,
  seller,
  description,
  handleUserClick,
}) => {
  const theme = useTheme();

  const feedbackPercentage = seller.totalFeedbacks
    ? ((seller.positiveFeedbacks / seller.totalFeedbacks) * 100).toFixed(1)
    : 90;

  const feedbackColor = () => {
    if (feedbackPercentage >= 99) return "green";
    if (feedbackPercentage >= 95) return "blue";
    if (feedbackPercentage >= 90) return "orange";
    return "red";
  };

  const formatPrice = (price) => {
    const options = {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: price % 1 === 0 ? 0 : 2,
    };
    return new Intl.NumberFormat("es-MX", options).format(price);
  };

  return (
    <Box mb={3}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          whiteSpace: "pre-line",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="h3"
        color="primary"
        gutterBottom
        sx={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {formatPrice(price)}
      </Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <Chip
          icon={<LocationOnIcon />}
          label={`${city}, ${state}, ${country}`}
          variant="outlined"
          sx={{ mr: 1 }}
        />
        <Chip
          icon={<SellIcon />}
          label={`${categoryName} > ${subCategoryName}`}
          variant="outlined"
        />
      </Box>

      {seller && (
        <Box
          display="flex"
          alignItems="center"
          mb={3}
          component={Paper}
          elevation={2}
          p={2}
          borderRadius={2}
          sx={{
            backgroundColor: theme.palette.background.default,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Avatar
            src={seller.photoUrl || ""}
            alt={seller.name}
            sx={{ width: 60, height: 60, mr: 2 }}
          />
          <Box>
            <Typography
              variant="subtitle1"
              onClick={handleUserClick}
              sx={{
                cursor: "pointer",
                "&:hover": { color: theme.palette.primary.main },
              }}
            >
              {seller.name || "Unknown User"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {seller.nickname || ""}
            </Typography>
            <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: feedbackColor(),
                  borderRadius: "50%",
                  mr: 1,
                }}
              />
              <Typography variant="body2" color="text.secondary">
                {feedbackPercentage}% (10 reviews)
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Typography
        variant="body1"
        paragraph
        sx={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default ProductDetails;
