import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";

const MapCam = () => {
  const [captures, setCaptures] = useState([]);

  useEffect(() => {
    const fetchCaptures = async () => {
      try {
        const capturesCollectionRef = collection(db, "CamReceiver");
        const capturesSnapshot = await getDocs(capturesCollectionRef);
        const capturesData = capturesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordenando los datos de más reciente a más antiguo
        capturesData.sort((a, b) => {
          const dateA = new Date(a.CaptureTime);
          const dateB = new Date(b.CaptureTime);
          return dateB - dateA; // Compara las fechas en formato de fecha de JavaScript
        });

        setCaptures(capturesData);
      } catch (error) {
        console.error("Error fetching captures:", error);
      }
    };

    fetchCaptures();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Cam Receiver Captures
      </Typography>
      <Grid container spacing={3}>
        {captures.map((capture) => (
          <Grid item key={capture.id} xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ backgroundColor: capture.Linked ? "green" : "red" }}>
              <CardMedia
                component="img"
                height="200"
                image={capture.Capture}
                alt={capture.ParkName}
              />
              <CardContent>
                <Typography variant="subtitle1">
                  Park: {capture.ParkName}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Capture Time: {new Date(capture.CaptureTime).toLocaleString()}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Linked: {capture.Linked ? "True" : "False"}
                </Typography>
                {capture.Linked && (
                  <Typography variant="subtitle2" color="text.secondary">
                    NFC Identifier: {capture.NFCIdentifier}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MapCam;
