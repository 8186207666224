import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db, storage } from "../../firebase";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
  arrayRemove,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import ProductDetailsForm from "./CreateProduct/ProductDetailsForm";
import ProductImagesForm from "./CreateProduct/ProductImagesForm";
import ProductLocationForm from "./CreateProduct/ProductLocationForm";

const CreateProduct = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const editProductId = new URLSearchParams(location.search).get("edit");

  const [activeStep, setActiveStep] = useState(0);
  const [productData, setProductData] = useState({
    name: "",
    category: "",
    subCategory: "",
    description: "",
    location: "",
    latitude: null,
    longitude: null,
    city: "",
    state: "",
    country: "",
    price: 0,
    mainImageUrl: "",
    moreImages: [],
    rating: [],
    postDate: new Date().toISOString(),
    uid: user ? user.uid : null,
  });
  const [mainImage, setMainImage] = useState(null);
  const [moreImages, setMoreImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialMoreImages, setInitialMoreImages] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCategories();
    if (editProductId) {
      fetchProductData(editProductId);
    }
  }, [editProductId]);

  const fetchCategories = async () => {
    const categoriesRef = collection(db, "Categories");
    const unsubscribe = onSnapshot(categoriesRef, (snapshot) => {
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    });
    return () => unsubscribe();
  };

  const fetchSubCategories = async (categoryId) => {
    const subCategoriesRef = collection(db, "Categories", categoryId, "sub");
    const unsubscribe = onSnapshot(subCategoriesRef, (snapshot) => {
      const subCategoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubCategories(subCategoriesList);
    });
    return () => unsubscribe();
  };

  const fetchProductData = async (productId) => {
    const productRef = doc(db, "Products", productId);
    const productDoc = await getDoc(productRef);
    if (productDoc.exists()) {
      const productData = productDoc.data();
      setProductData(productData);
      setInitialMoreImages(productData.moreImages || []);
      fetchSubCategories(productData.category);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "category") {
      fetchSubCategories(value);
    }
  };

  const handleMainImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setMainImage(e.target.files[0]);
    }
  };

  const handleMoreImagesChange = (e) => {
    if (e.target.files) {
      setMoreImages(Array.from(e.target.files));
    }
  };

  const handleDeleteImage = async (url, isMain = false) => {
    try {
      if (editProductId) {
        const productRef = doc(db, "Products", editProductId);
        await updateDoc(productRef, {
          moreImages: arrayRemove(url),
        });
      }

      if (isMain) {
        setProductData((prevData) => ({
          ...prevData,
          mainImageUrl: "",
        }));
      } else {
        setProductData((prevData) => ({
          ...prevData,
          moreImages: prevData.moreImages.filter((imgUrl) => imgUrl !== url),
        }));
      }
    } catch (error) {
      console.error("Error deleting image URL from Firestore: ", error);
    }
  };

  const validateStep = () => {
    let newErrors = {};

    if (activeStep === 0) {
      if (!productData.name) newErrors.name = "El nombre es obligatorio";
      if (!productData.category)
        newErrors.category = "La categoría es obligatoria";
      if (!productData.subCategory)
        newErrors.subCategory = "La subcategoría es obligatoria";
      if (!productData.description)
        newErrors.description = "La descripción es obligatoria";
      if (!productData.price) newErrors.price = "El precio es obligatorio";
    } else if (activeStep === 1) {
      if (!mainImage && !productData.mainImageUrl)
        newErrors.mainImage = "La imagen principal es obligatoria";
      if (moreImages.length === 0 && productData.moreImages.length === 0)
        newErrors.moreImages = "Se requieren imágenes adicionales";
    } else if (activeStep === 2) {
      if (!productData.latitude)
        newErrors.latitude = "La latitud es obligatoria";
      if (!productData.longitude)
        newErrors.longitude = "La longitud es obligatoria";
      if (!productData.city) newErrors.city = "La ciudad es obligatoria";
      if (!productData.state) newErrors.state = "El estado es obligatorio";
      if (!productData.country) newErrors.country = "El país es obligatorio";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert("Por favor, inicie sesión para crear o editar un anuncio.");
      return;
    }

    setLoading(true);

    try {
      let mainImageUrl = productData.mainImageUrl;
      if (mainImage) {
        const storageRef = ref(storage, `products/${mainImage.name}`);
        const uploadTask = uploadBytesResumable(storageRef, mainImage);
        mainImageUrl = await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
          });
        });
      }

      let moreImagesUrls = productData.moreImages;
      if (moreImages.length > 0) {
        moreImagesUrls = await Promise.all(
          moreImages.map((image) => {
            const storageRef = ref(
              storage,
              `products/moreImages/${image.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, image);
            return new Promise((resolve, reject) => {
              uploadTask.on("state_changed", null, reject, () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then(resolve)
                  .catch(reject);
              });
            });
          })
        );
      }

      if (editProductId) {
        const productRef = doc(db, "Products", editProductId);
        await updateDoc(productRef, {
          ...productData,
          mainImageUrl,
          moreImages: moreImagesUrls,
          postDate: productData.postDate,
        });
        navigate(`/product/${editProductId}`);
      } else {
        const productRef = await addDoc(collection(db, "Products"), {
          ...productData,
          mainImageUrl,
          moreImages: moreImagesUrls,
          postDate: new Date().toISOString(),
        });
        navigate(`/product/${productRef.id}`);
      }
    } catch (error) {
      console.error("Error creating or updating product: ", error);
      alert(
        "Error creando o actualizando el producto. Por favor, inténtelo de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    "Detalles del Producto",
    "Imágenes del Producto",
    "Ubicación del Producto",
    "Revisión y Envío",
  ];

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          {editProductId ? "Editar Producto" : "Crear Nuevo Producto"}
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <ProductDetailsForm
              productData={productData}
              handleChange={handleChange}
              categories={categories}
              subCategories={subCategories}
              errors={errors}
            />
          )}
          {activeStep === 1 && (
            <ProductImagesForm
              mainImage={mainImage}
              handleMainImageChange={handleMainImageChange}
              moreImages={moreImages}
              handleMoreImagesChange={handleMoreImagesChange}
              productData={productData}
              errors={errors}
              setMainImage={setMainImage}
              setMoreImages={setMoreImages}
              initialMoreImages={initialMoreImages}
              setInitialMoreImages={setInitialMoreImages}
              onDeleteImage={handleDeleteImage}
            />
          )}
          {activeStep === 2 && (
            <ProductLocationForm
              productData={productData}
              setProductData={setProductData}
              errors={errors}
            />
          )}
          {activeStep === 3 && (
            <Box>
              <Typography variant="h6">Revisa los detalles y envía</Typography>
              <Box mt={2}>
                <Typography variant="body1">
                  <strong>Nombre:</strong> {productData.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Categoría:</strong>{" "}
                  {categories.find((c) => c.id === productData.category)?.type}
                </Typography>
                <Typography variant="body1">
                  <strong>Subcategoría:</strong>{" "}
                  {
                    subCategories.find((s) => s.id === productData.subCategory)
                      ?.type
                  }
                </Typography>
                <Typography variant="body1">
                  <strong>Ciudad:</strong> {productData.city}
                </Typography>
                <Typography variant="body1">
                  <strong>Estado:</strong> {productData.state}
                </Typography>
                <Typography variant="body1">
                  <strong>País:</strong> {productData.country}
                </Typography>
                <Typography variant="body1">
                  <strong>Latitud:</strong> {productData.latitude}
                </Typography>
                <Typography variant="body1">
                  <strong>Longitud:</strong> {productData.longitude}
                </Typography>
                <Typography variant="body1">
                  <strong>Descripción:</strong> {productData.description}
                </Typography>
                <Typography variant="body1">
                  <strong>Precio:</strong> ${productData.price}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                {mainImage ? (
                  <img
                    src={URL.createObjectURL(mainImage)}
                    alt="Main"
                    style={{ width: "200px", height: "auto" }}
                  />
                ) : productData.mainImageUrl ? (
                  <img
                    src={productData.mainImageUrl}
                    alt="Main"
                    style={{ width: "200px", height: "auto" }}
                  />
                ) : null}
              </Box>
              <Box
                mt={2}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
              >
                {moreImages.length > 0
                  ? moreImages.map((image, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`More ${index}`}
                        style={{
                          width: "100px",
                          height: "auto",
                          margin: "5px",
                        }}
                      />
                    ))
                  : productData.moreImages.map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt={`More ${index}`}
                        style={{
                          width: "100px",
                          height: "auto",
                          margin: "5px",
                        }}
                      />
                    ))}
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Atrás
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : editProductId ? (
                  "Actualizar Producto"
                ) : (
                  "Crear Producto"
                )}
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Siguiente
              </Button>
            )}
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateProduct;
