import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  arrayUnion,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

const StateCreator = () => {
  const navigate = useNavigate();
  const [stateData, setStateData] = useState({
    state: "",
    cities: [],
    existingState: "",
    newCity: "",
  });
  const [loading, setLoading] = useState(false);
  const [existingStates, setExistingStates] = useState([]);

  useEffect(() => {
    const fetchExistingStates = async () => {
      try {
        const firestore = getFirestore();
        const statesCollectionRef = collection(firestore, "states");
        const snapshot = await getDocs(statesCollectionRef);
        const existingStatesData = snapshot.docs.map((doc) => doc.data().state);
        setExistingStates(existingStatesData);
      } catch (error) {
        console.error("Error fetching existing states:", error.message);
      }
    };

    fetchExistingStates();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    setStateData((prevState) => ({
      ...prevState,
      newCity: value,
    }));
  };

  const handleAddCity = () => {
    setStateData((prevState) => ({
      ...prevState,
      cities: [...prevState.cities, prevState.newCity],
      newCity: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const firestore = getFirestore();
      let stateId = "";

      if (stateData.existingState === "") {
        const docRef = await addDoc(collection(firestore, "states"), {
          state: stateData.state,
          cities: stateData.cities,
        });
        stateId = docRef.id;
      } else {
        stateId = stateData.existingState;
        const stateDocRef = doc(firestore, "states", stateId);
        const stateDocSnap = await getDoc(stateDocRef);
        if (stateDocSnap.exists()) {
          const existingCities = stateDocSnap.data().cities;
          const updatedCities = [...existingCities, ...stateData.cities];
          await updateDoc(stateDocRef, { cities: updatedCities });
        }
      }

      navigate("/home");
    } catch (error) {
      console.error("Error creating state:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
        <Typography variant="h5" gutterBottom>
          Crear un Nuevo Estado
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  value={stateData.existingState}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Select existing state" }}
                >
                  <MenuItem value="" disabled>
                    Selecciona un estado existente o crea uno nuevo
                  </MenuItem>
                  {existingStates.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {stateData.existingState === "" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nombre del Estado"
                  type="text"
                  name="state"
                  value={stateData.state}
                  onChange={handleChange}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
              <Typography variant="h6" gutterBottom>
                Asignar Ciudades al Estado
              </Typography>
              <TextField
                fullWidth
                label="Nombre de la Ciudad"
                type="text"
                name="newCity"
                value={stateData.newCity}
                onChange={handleCityChange}
                required
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCity}
                disabled={!stateData.newCity}
              >
                Agregar Ciudad
              </Button>
            </Grid>
            {stateData.cities.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Ciudades Asignadas:
                </Typography>
                <ul>
                  {stateData.cities.map((city, index) => (
                    <li key={index}>{city}</li>
                  ))}
                </ul>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Crear Estado"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default StateCreator;
