import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage, db } from "../../firebase";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const CreateTeam = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState({
    name: "",
    logo: null,
    coverPhoto: null,
    nationality: "",
    state: "",
    municipality: "",
    privacy: "public",
    instagram: "",
    facebook: "",
  });
  const [existingTeamName, setExistingTeamName] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);

  useEffect(() => {
    const checkExistingTeam = async () => {
      try {
        const teamQuery = query(
          collection(db, "Teams"),
          where("admin", "array-contains", user.uid)
        );
        const querySnapshot = await getDocs(teamQuery);
        setExistingTeamName(!querySnapshot.empty);
      } catch (error) {
        console.error("Error checking existing team:", error);
      }
    };

    checkExistingTeam();
  }, [user.uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    setTeamData({ ...teamData, logo: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCoverPhotoChange = (e) => {
    setTeamData({ ...teamData, coverPhoto: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverPhotoPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!teamData.logo) {
        alert("Por favor, seleccione un logo para su equipo.");
        setLoading(false);
        return;
      }

      const teamQuery = query(
        collection(db, "Teams"),
        where("name", "==", teamData.name)
      );
      const querySnapshot = await getDocs(teamQuery);
      if (!querySnapshot.empty) {
        alert("El nombre del equipo ya está en uso.");
        setLoading(false);
        return;
      }

      if (existingTeamName) {
        alert("Ya tienes un equipo creado.");
        setLoading(false);
        return;
      }

      const storageRef = ref(
        storage,
        `team_logos/${user.uid}/${teamData.logo.name}`
      );
      const coverPhotoRef = ref(
        storage,
        `team_cover_photos/${user.uid}/${teamData.coverPhoto.name}`
      );

      const uploadLogoTask = uploadBytesResumable(storageRef, teamData.logo);
      const uploadCoverPhotoTask = uploadBytesResumable(
        coverPhotoRef,
        teamData.coverPhoto
      );

      const [logoURL, coverPhotoURL] = await Promise.all([
        new Promise((resolve, reject) => {
          uploadLogoTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadLogoTask.snapshot.ref).then(resolve);
          });
        }),
        new Promise((resolve, reject) => {
          uploadCoverPhotoTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadCoverPhotoTask.snapshot.ref).then(resolve);
          });
        }),
      ]);

      await addDoc(collection(db, "Teams"), {
        admin: [user.uid],
        name: teamData.name,
        logo: logoURL,
        coverPhoto: coverPhotoURL,
        members: [user.uid],
        requests: [],
        nationality: teamData.nationality,
        state: teamData.state,
        municipality: teamData.municipality,
        private: teamData.privacy === "private",
        instagram:
          teamData.instagram &&
          `https://www.instagram.com/${teamData.instagram}/`,
        facebook:
          teamData.facebook && `https://www.facebook.com/${teamData.facebook}/`,
        createdAt: serverTimestamp(),
      });

      setLoading(false);
      navigate(`/teams`);
    } catch (error) {
      console.error("Error creating team:", error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Crea tu Equipo
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Equipo"
                type="text"
                name="name"
                value={teamData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                type="file"
                onChange={handleLogoChange}
                style={{ display: "none" }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Subir Logo
                </Button>
              </label>
              {logoPreview && (
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                type="file"
                onChange={handleCoverPhotoChange}
                style={{ display: "none" }}
                id="raised-button-file-cover"
              />
              <label htmlFor="raised-button-file-cover">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Subir Foto de Portada
                </Button>
              </label>
              {coverPhotoPreview && (
                <img
                  src={coverPhotoPreview}
                  alt="Cover Photo Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nacionalidad"
                type="text"
                name="nationality"
                value={teamData.nationality}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Estado"
                type="text"
                name="state"
                value={teamData.state}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Municipio"
                type="text"
                name="municipality"
                value={teamData.municipality}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Cuenta de Instagram"
                type="text"
                name="instagram"
                value={teamData.instagram}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Cuenta de Facebook"
                type="text"
                name="facebook"
                value={teamData.facebook}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="privacy"
                  name="privacy"
                  value={teamData.privacy}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Público"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Privado"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{ backgroundColor: "black", color: "white" }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Crear Equipo"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateTeam;
