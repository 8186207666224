import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

const UsersControl = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [team, setTeam] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => doc.data());
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.name &&
          user.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, users]);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setName(user.name);
    setNickName(user.nickName);
    setPhoneNumber(user.phoneNumber);
    setTeam(user.team);
    setOpenEditDialog(true);
  };

  const handleEditConfirm = async () => {
    try {
      const userDocRef = doc(db, "Users", selectedUser.uid);
      await updateDoc(userDocRef, {
        name: name,
        nickName: nickName,
        phoneNumber: phoneNumber,
        team: team,
      });
      const updatedUser = {
        ...selectedUser,
        name: name,
        nickName: nickName,
        phoneNumber: phoneNumber,
        team: team,
      };
      setUsers(
        users.map((user) =>
          user.uid === updatedUser.uid ? updatedUser : user,
        ),
      );
      console.log("User edited successfully:", updatedUser);
      setSelectedUser(null);
      setName("");
      setNickName("");
      setPhoneNumber("");
      setTeam("");
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleEditCancel = () => {
    setSelectedUser(null);
    setName("");
    setNickName("");
    setPhoneNumber("");
    setTeam("");
    setOpenEditDialog(false);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const userDocRef = doc(db, "Users", selectedUser.uid);
      await deleteDoc(userDocRef);
      setUsers(users.filter((u) => u.uid !== selectedUser.uid));
      console.log("User deleted successfully:", selectedUser);
      setSelectedUser(null);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedUser(null);
    setOpenDeleteDialog(false);
  };

  return (
    <div>
      <h2>Lista de Usuarios</h2>
      <TextField
        label="Buscar por nombre"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Equipo</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.uid}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.nickName}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{user.team}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleEditClick(user)}
                  >
                    Editar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteClick(user)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Eliminar Usuario</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que quieres eliminar a{" "}
          {selectedUser && selectedUser.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleEditCancel}>
        <DialogTitle>Editar Usuario</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nickname"
            value={nickName}
            onChange={(e) => setNickName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Teléfono"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Equipo"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditConfirm} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersControl;
