import React from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Comments from "../Comments";
import { WhatsApp, Phone } from "@mui/icons-material";

const ProductActions = ({
  productId,
  handleLike,
  likes,
  user,
  seller,
  productName,
  productPrice,
}) => {
  const theme = useTheme();

  const handleWhatsApp = () => {
    if (seller && seller.phoneNumber) {
      const url = `https://wa.me/${seller.phoneNumber}?text=Estoy%20interesado%20en%20su%20producto%20en%20venta:%20${productName}%20por%20$${productPrice}.%20${window.location.href}`;
      window.open(url, "_blank");
    }
  };

  const handleCall = () => {
    if (seller && seller.phoneNumber) {
      window.location.href = `tel:${seller.phoneNumber}`;
    }
  };

  return (
    <Box mb={3}>
      <Box display="flex" alignItems="center" mb={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleWhatsApp}
          fullWidth
          sx={{ mr: 2, py: 1.5 }}
          startIcon={<WhatsApp />}
        >
          WhatsApp
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleCall}
          fullWidth
          sx={{ py: 1.5 }}
          startIcon={<Phone />}
        >
          Llamar
        </Button>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IconButton
          onClick={handleLike}
          sx={{ backgroundColor: theme.palette.grey[200], p: 2 }}
        >
          <FavoriteIcon
            color={likes.includes(user?.uid) ? "secondary" : "action"}
          />
        </IconButton>
        <Typography variant="subtitle2" color="text.secondary">
          {likes.length}{" "}
          {likes.length === 1 ? "persona está" : "personas están"} interesadas
        </Typography>
      </Box>

      <Comments productId={productId} />
    </Box>
  );
};

export default ProductActions;
