import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { Typography } from "@mui/material";
import scanSound from "../../../sounds/bip.mp3";

const NfcCam = () => {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [scanResult, setScanResult] = useState(null);

  const playScanSound = () => {
    const audio = new Audio(scanSound);
    audio.play();
  };

  const handleReadingError = () => {
    const errorMessage =
      "Argh! Cannot read data from the NFC tag. Try another one?";
    console.error(errorMessage);
    setError(errorMessage);
  };

  const handleReading = async ({ serialNumber }) => {
    console.log("> Serial Number: ", serialNumber);
    setScanResult(serialNumber);
    playScanSound();
    setError(null);
    await handleFinish(serialNumber);
  };

  const handleNFCScan = async () => {
    try {
      const ndef = new window.NDEFReader();

      ndef.addEventListener("readingerror", handleReadingError);

      ndef.addEventListener("reading", async (event) => {
        await handleReading(event);
      });

      await ndef.scan();
      console.log("> Scan started");
    } catch (error) {
      const errorMessage = "Error during NFC scanning: " + error.message;
      console.error(errorMessage);
      setError(errorMessage);
    }
  };

  const handleFinish = async (NFCIdentifier) => {
    try {
      // Buscar la última captura no vinculada en CamReceiver
      const camReceiverCollectionRef = collection(db, "CamReceiver");
      const q = query(
        camReceiverCollectionRef,
        orderBy("CaptureTime", "desc"),
        limit(1),
      );
      const querySnapshot = await getDocs(q);

      let captureTime = null;
      let camReceiverId = null;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.Linked) {
          captureTime = data.CaptureTime;
          camReceiverId = doc.id;
        }
      });

      if (captureTime && camReceiverId) {
        // Actualizar CamReceiver a Linked: true y agregar NFCIdentifier
        const camReceiverDocRef = doc(db, "CamReceiver", camReceiverId);
        await updateDoc(camReceiverDocRef, {
          Linked: true,
          NFCIdentifier,
        });
        setSuccessMessage("CamReceiver updated successfully.");

        // Buscar una carrera activa con el mismo NFCIdentifier en Runs
        const runsCollectionRef = collection(db, "Runs");
        const runsQuery = query(
          runsCollectionRef,
          where("ActiveRun", "==", true),
          where("NFCIdentifier", "==", NFCIdentifier),
          limit(1),
        );
        const runsSnapshot = await getDocs(runsQuery);
        runsSnapshot.forEach(async (runDoc) => {
          // Actualizar la carrera encontrada a ActiveRun: false y agregar FinishTime
          const runRef = doc(db, "Runs", runDoc.id);
          await updateDoc(runRef, {
            FinishTime: captureTime,
            ActiveRun: false,
          });
        });
      } else {
        setError(
          "No se encontró ninguna captura reciente sin vincular en CamReceiver.",
        );
      }
    } catch (error) {
      console.error("Error updating CamReceiver or Runs:", error);
      setError("Error updating CamReceiver or Runs: " + error.message);
    }
  };

  useEffect(() => {
    handleNFCScan();
  }, []);

  return (
    <div>
      <Typography variant="h4">NFC Receiver</Typography>
      {scanResult && (
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          NFC Identifier: {scanResult}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="subtitle1" sx={{ mt: 2, color: "green" }}>
          {successMessage}
        </Typography>
      )}
      {error && (
        <Typography variant="subtitle1" sx={{ mt: 2, color: "red" }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default NfcCam;
