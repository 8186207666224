import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Skeleton,
} from "@mui/material";

const KingHill = () => {
  const [track, setTrack] = useState(null);
  const [runs, setRuns] = useState([]);
  const [users, setUsers] = useState([]);
  const [userRuns, setUserRuns] = useState({});
  const [loading, setLoading] = useState(true);

  const specificParkId = "a1tGkXljSiNf4Z7blsE0";
  const specificTrackId = "mB5B2PeabinuwogCGKtL";

  useEffect(() => {
    const fetchTrackAndData = async () => {
      try {
        // Obtener los datos del track
        const trackRef = doc(db, "tracks", specificTrackId);
        const trackSnap = await getDoc(trackRef);
        if (trackSnap.exists()) {
          setTrack(trackSnap.data());
        } else {
          console.log("Track not found");
        }

        // Obtener runs y users
        const runsQuery = query(
          collection(db, "runs"),
          where("trackId", "==", specificTrackId),
        );
        const usersQuery = query(collection(db, "users"));

        const [runsSnap, usersSnap] = await Promise.all([
          getDocs(runsQuery),
          getDocs(usersQuery),
        ]);

        setRuns(runsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setUsers(usersSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchTrackAndData();
  }, []);

  useEffect(() => {
    const countUserRuns = () => {
      const runCounts = {};
      runs.forEach((run) => {
        runCounts[run.userId] = (runCounts[run.userId] || 0) + 1;
      });
      setUserRuns(runCounts);
    };

    if (runs.length > 0) {
      countUserRuns();
    }
  }, [runs]);

  const sortedUserRuns = Object.entries(userRuns)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 3);

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        King Of The Hill - {track ? track.TrackName : "Loading..."}
      </Typography>
      {loading ? (
        <Skeleton variant="rectangular" width={210} height={118} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center">
                  Cantidad de Runs por Usuario
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Usuario</TableCell>
                      <TableCell align="right">Runs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedUserRuns.map(([userId, count]) => (
                      <TableRow key={userId}>
                        <TableCell component="th" scope="row">
                          {users.find((user) => user.id === userId)?.name ||
                            "Unknown User"}
                        </TableCell>
                        <TableCell align="right">{count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default KingHill;
