import React from "react";
import { Typography, Box } from "@mui/material";

const ComoAgregarTagNFC = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Cómo Agregar un Tag NFC
      </Typography>
      <Typography variant="body1" paragraph>
        En esta guía aprenderás a agregar un Tag NFC a un ciclista para que
        pueda ser identificado durante las rodadas.
      </Typography>
      {/* Aquí puedes agregar más contenido detallado sobre cómo agregar un Tag NFC */}
    </Box>
  );
};

export default ComoAgregarTagNFC;
