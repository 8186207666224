import React from "react";
import { Grid, Button, Box, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { getStorage, ref, deleteObject } from "firebase/storage";

const storage = getStorage();

const ProductImagesForm = ({
  mainImage,
  handleMainImageChange,
  moreImages,
  handleMoreImagesChange,
  productData,
  errors,
  setMainImage,
  setMoreImages,
  initialMoreImages,
  setInitialMoreImages,
  onDeleteImage,
}) => {
  const handleDeleteImage = async (image, index, isMain = false) => {
    try {
      if (image.url) {
        // Delete from Firebase Storage
        const imageRef = ref(storage, image.url);
        await deleteObject(imageRef);
      }

      if (isMain) {
        setMainImage(null);
        onDeleteImage(image.url, true);
      } else {
        const updatedMoreImages = moreImages.filter((_, i) => i !== index);
        setMoreImages(updatedMoreImages);

        if (initialMoreImages.some((img) => img === image.url)) {
          const updatedInitialImages = initialMoreImages.filter(
            (img) => img !== image.url
          );
          setInitialMoreImages(updatedInitialImages);
        }

        onDeleteImage(image.url, false);
      }
    } catch (error) {
      console.error("Error deleting image: ", error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="main-image-upload"
          type="file"
          onChange={handleMainImageChange}
        />
        <label htmlFor="main-image-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            fullWidth
          >
            Subir Imagen Principal
          </Button>
        </label>
        {errors.mainImage && (
          <Box mt={1} color="error.main">
            {errors.mainImage}
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" position="relative">
          {mainImage ? (
            <>
              <img
                src={URL.createObjectURL(mainImage)}
                alt="Main"
                style={{ width: "200px", height: "auto" }}
              />
              <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleDeleteImage(mainImage, null, true)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          ) : productData.mainImageUrl ? (
            <>
              <img
                src={productData.mainImageUrl}
                alt="Main"
                style={{ width: "200px", height: "auto" }}
              />
              <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={() =>
                  handleDeleteImage(
                    { url: productData.mainImageUrl },
                    null,
                    true
                  )
                }
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="more-images-upload"
          type="file"
          multiple
          onChange={handleMoreImagesChange}
        />
        <label htmlFor="more-images-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            fullWidth
          >
            Subir Imágenes Adicionales
          </Button>
        </label>
        {errors.moreImages && (
          <Box mt={1} color="error.main">
            {errors.moreImages}
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {moreImages.length > 0
            ? moreImages.map((image, index) => (
                <Box key={index} position="relative" m={1}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`More ${index}`}
                    style={{ width: "100px", height: "auto", margin: "5px" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => handleDeleteImage(image, index)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              ))
            : initialMoreImages.map((url, index) => (
                <Box key={index} position="relative" m={1}>
                  <img
                    src={url}
                    alt={`More ${index}`}
                    style={{ width: "100px", height: "auto", margin: "5px" }}
                  />
                  <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => handleDeleteImage({ url }, index)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductImagesForm;
