import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  InputLabel,
  IconButton,
  Stack,
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { eventsApi } from "../../lib/client";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const CreateEvent = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  if (!user) navigate(-1);

  const [categoryInput, setCategoryInput] = useState("");
  const [eventData, setEventData] = useState({
    creatorUID: user.uid,
    eventName: "",
    dateTime: "",
    location: { lat: 29.110262, lng: -110.940463 },
    photo: null,
    description: "",
    skillLevel: "",
    distance: "",
    price: "",
    categories: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "categoryInput") {
      setCategoryInput(value);
    }
    setEventData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddCategory = () => {
    if (!eventData.categories.includes(categoryInput) && categoryInput !== "") {
      setEventData((prevEventData) => ({
        ...prevEventData,
        categories: [...prevEventData.categories, categoryInput],
      }));
      setCategoryInput("");
    }
  };

  const handleRemoveCategory = (category) => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      categories: prevEventData.categories.filter((cat) => cat !== category),
    }));
  };

  const handleLocationChange = (e) => {
    const { latLng } = e;
    setEventData((prevData) => ({
      ...prevData,
      location: { lat: latLng.lat(), lng: latLng.lng() },
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setEventData((prevData) => ({ ...prevData, photo: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await eventsApi.createEvent(user, eventData);
    navigate("/my-events");
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Stack direction="row" alignItems="center" pb={2} spacing={2}>
          <IconButton aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">Crea tu evento</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Evento"
                type="text"
                name="eventName"
                value={eventData.eventName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Fecha y Hora"
                type="datetime-local"
                name="dateTime"
                value={eventData.dateTime}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={eventData.location}
                  zoom={15}
                  onClick={(e) => handleLocationChange(e)}
                >
                  <Marker position={eventData.location} />
                </GoogleMap>
              </LoadScript>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="photo">Foto del Spot:</InputLabel>
              <input
                type="file"
                accept="image/*"
                id="photo"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
              />
              <label htmlFor="photo">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
              {eventData.photo && (
                <img
                  src={URL.createObjectURL(eventData.photo)}
                  alt="Vista Previa del Evento"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                multiline
                rows={6}
                name="description"
                value={eventData.description}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Precio"
                type="number"
                name="price"
                value={eventData.price}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <TextField
                  fullWidth
                  label="Categorías"
                  type="text"
                  name="categoryInput"
                  value={categoryInput}
                  onChange={handleChange}
                />
                <IconButton onClick={handleAddCategory}>
                  <AddCircleIcon />
                </IconButton>
              </Stack>
              <List dense>
                {eventData.categories.map((category) => (
                  <ListItem
                    key={category}
                    secondaryAction={
                      <IconButton
                        onClick={() => handleRemoveCategory(category)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={category} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
              >
                Crear Evento
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateEvent;
