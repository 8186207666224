import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Paper,
} from "@mui/material";
import axios from "axios";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

const ReceiverSimulator = () => {
  const [ruid, setRuid] = useState("");
  const [uid, setUid] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Obtener los RUID de la colección "Receivers" en Firestore
    const fetchReceivers = async () => {
      try {
        const receiversCollection = collection(db, "Receivers");
        const receiversSnapshot = await getDocs(receiversCollection);
        const receiversData = receiversSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReceivers(receiversData);
      } catch (error) {
        console.error("Error fetching receivers: ", error);
      }
    };
    fetchReceivers();
  }, []);

  const getCurrentTime = () => {
    // Obtener la fecha y hora actual en formato ISO con milisegundos
    return new Date().toISOString();
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const logData = {
      RUID: ruid,
      time: getCurrentTime(),
      uid,
    };

    try {
      const response = await axios.post(
        "https://us-central1-mtbking-aa657.cloudfunctions.net/logToFirestore",
        logData
      );
      if (response.status === 200) {
        setSuccess("Datos enviados correctamente.");
      } else {
        setError("Hubo un problema enviando los datos.");
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper
        sx={{
          p: 4,
          borderRadius: "12px",
          boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Simulador de Receiver
        </Typography>
        <Typography variant="body1" paragraph>
          Envía datos simulados a la función de log en Firestore. Selecciona un
          RUID, agrega un UID manualmente y el sistema generará la fecha y hora
          actual.
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {/* Selector de RUID */}
          <TextField
            select
            label="Seleccionar RUID"
            value={ruid}
            onChange={(e) => setRuid(e.target.value)}
            fullWidth
            required
          >
            {receivers.map((receiver) => (
              <MenuItem key={receiver.id} value={receiver.id}>
                {receiver.id}
              </MenuItem>
            ))}
          </TextField>

          {/* Input para UID */}
          <TextField
            label="UID Manual"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            fullWidth
            required
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !ruid || !uid}
            sx={{ backgroundColor: "black", color: "white", mt: 2 }}
          >
            {loading ? "Enviando..." : "Enviar Datos"}
          </Button>

          {success && (
            <Typography color="success.main" sx={{ mt: 2 }}>
              {success}
            </Typography>
          )}
          {error && (
            <Typography color="error.main" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ReceiverSimulator;
