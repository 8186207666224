import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import {
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import FileUploader from "./FileUploader"; // Asegúrate de importar el componente correctamente

const CreateTrack = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [trackData, setTrackData] = useState({
    Description: "",
    Difficulty: "Intermedio",
    GeoJson: "",
    KML: "",
    Status: "Abierta",
    TrackName: "",
  });
  const [loading, setLoading] = useState(false);

  const difficulties = ["Fácil", "Intermedio", "Avanzada"];
  const statuses = ["Abierta", "Mantenimiento", "Cerrada"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrackData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, "Parks", uid, "Tracks"), trackData);
      navigate(`/parkdashboard/${uid}`);
    } catch (error) {
      console.error("Error adding track: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Crear Nueva Pista
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre de la Pista"
                type="text"
                name="TrackName"
                value={trackData.TrackName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                type="text"
                name="Description"
                value={trackData.Description}
                onChange={handleChange}
                required
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Dificultad"
                name="Difficulty"
                value={trackData.Difficulty}
                onChange={handleChange}
                required
              >
                {difficulties.map((difficulty) => (
                  <MenuItem key={difficulty} value={difficulty}>
                    {difficulty}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FileUploader uid={uid} setTrackData={setTrackData} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL de GeoJson"
                type="text"
                name="GeoJson"
                value={trackData.GeoJson}
                onChange={handleChange}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL de KML"
                type="text"
                name="KML"
                value={trackData.KML}
                onChange={handleChange}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Estado"
                name="Status"
                value={trackData.Status}
                onChange={handleChange}
                required
              >
                {statuses.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                fullWidth
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#333",
                  },
                }}
              >
                {loading ? "Guardando..." : "Crear Pista"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateTrack;
