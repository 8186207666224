import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import WorkShopCard from "./WorkShopCard"; // Asegúrate de que la ruta de importación sea correcta
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import StoreIcon from "@mui/icons-material/Store";

const services = [
  {
    name: "Transmisión",
    subServices: [
      "Cadena",
      "Desviadores",
      "Shifters",
      "Cables",
      "Plato",
      "Cassette",
      "Eje de pedalier",
    ],
  },
  {
    name: "Frenos",
    subServices: ["Pastillas de freno", "Discos de freno", "Sistema de frenos"],
  },
  {
    name: "Suspensión",
    subServices: ["Horquilla", "Amortiguador trasero"],
  },
  {
    name: "Cuadro",
    subServices: ["Reparacion de Carbono", "Reparacion de Alloy"],
  },
  {
    name: "Neumáticos y Ruedas",
    subServices: [
      "Presión de los neumáticos",
      "Desgaste de los neumáticos",
      "Sellador de neumáticos",
      "Radios",
      "Rodamientos",
      "Ejes",
    ],
  },
  {
    name: "Dropper",
    subServices: ["Dropper"],
  },
];

const AllWorkShops = () => {
  // const { user } = useUserAuth();
  const navigate = useNavigate();
  const [workShops, setWorkShops] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredWorkShops, setFilteredWorkShops] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedSubService, setSelectedSubService] = useState("");

  useEffect(() => {
    const fetchWorkshops = async () => {
      const firestore = getFirestore();
      const q = query(collection(firestore, "workshops"));
      const querySnapshot = await getDocs(q);
      const workshopsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWorkShops(workshopsData);
      setFilteredWorkShops(workshopsData);
    };
    fetchWorkshops();
  }, []);

  const handleClick = (id) => {
    navigate(`/workshop/${id}`);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterWorkShops(term, selectedService, selectedSubService);
  };

  const handleServiceChange = (e) => {
    const service = e.target.value;
    setSelectedService(service);
    setSelectedSubService("");
    filterWorkShops(searchTerm, service, "");
  };

  const handleSubServiceChange = (e) => {
    const subService = e.target.value;
    setSelectedSubService(subService);
    filterWorkShops(searchTerm, selectedService, subService);
  };

  const filterWorkShops = (term, service, subService) => {
    let filtered = workShops;
    if (term) {
      filtered = filtered.filter((workshop) =>
        workshop.workshopName.toLowerCase().includes(term.toLowerCase())
      );
    }
    if (service) {
      filtered = filtered.filter(
        (workshop) => workshop.serviceTypes && workshop.serviceTypes[service]
      );
    }
    if (subService) {
      filtered = filtered.filter(
        (workshop) =>
          workshop.subServices &&
          workshop.subServices[selectedService] &&
          workshop.subServices[selectedService][subService]
      );
    }
    setFilteredWorkShops(filtered);
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/service")}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        startIcon={<HomeRepairServiceIcon />}
      >
        Bicicletas en Servicio
      </Button>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/myworkshop")}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        startIcon={<StoreIcon />}
      >
        Mis WorkShops
      </Button>
      <br />
      <Typography variant="h5" align="center" gutterBottom>
        <strong> WorkShops </strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Buscar por nombre"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Servicio</InputLabel>
            <Select
              value={selectedService}
              onChange={handleServiceChange}
              label="Servicio"
            >
              <MenuItem value="">Todos</MenuItem>
              {services.map((service) => (
                <MenuItem key={service.name} value={service.name}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {selectedService && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Sub-Servicio</InputLabel>
              <Select
                value={selectedSubService}
                onChange={handleSubServiceChange}
                label="Sub-Servicio"
              >
                <MenuItem value="">Todos</MenuItem>
                {services
                  .find((service) => service.name === selectedService)
                  ?.subServices.map((subService) => (
                    <MenuItem key={subService} value={subService}>
                      {subService}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 20 }}>
        {filteredWorkShops.map((workshop) => (
          <Grid item key={workshop.id} xs={12} sm={6} md={4}>
            <WorkShopCard workshop={workshop} onClick={handleClick} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AllWorkShops;
