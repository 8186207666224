import React, { useEffect, useState, useCallback } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import QRCode from "qrcode.react";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  Button,
  Alert,
  Collapse,
  Fade,
  useTheme,
  useMediaQuery,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendSpeedCoins from "./SendSpeedCoins";
import SpeedCoinHistory from "./SpeedCoinHistory";

const Wallet = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(null);
  const [sendCoinsOpen, setSendCoinsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchBalance = useCallback(() => {
    if (!user) return;
    setLoading(true);
    const userDocRef = doc(db, "Users", user.uid);
    return onSnapshot(
      userDocRef,
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          if (userData.balance !== undefined) {
            setBalance(Number(userData.balance) || 0); // Ensure balance is a number
          } else {
            updateDoc(userDocRef, { balance: 0 });
            setBalance(0);
          }
        } else {
          setError("No se encontró el documento del usuario.");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error al obtener el balance del usuario:", error);
        setError(
          "Error al obtener el balance del usuario. Intente nuevamente."
        );
        setLoading(false);
      }
    );
  }, [user]);

  useEffect(() => {
    let unsubscribe;
    if (user?.uid) {
      unsubscribe = fetchBalance();
    }
    return () => unsubscribe && unsubscribe();
  }, [user, fetchBalance]);

  const handleSendCoinsToggle = () => setSendCoinsOpen((prev) => !prev);
  const handleInfoToggle = () => setInfoOpen((prev) => !prev);
  const handleQrOpen = () => setQrOpen(true);
  const handleQrClose = () => setQrOpen(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.uid);
    alert("UID copiado al portapapeles");
  };

  if (!user) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 5 }}>
          Usuario no autenticado. Por favor, inicia sesión.
        </Alert>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 5 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Fade in={true}>
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            borderRadius: 4,
            background: theme.palette.background.default,
            mt: 5,
          }}
        >
          <Typography variant="h4" gutterBottom align="center" color="primary">
            Mi Billetera
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h6" color="textSecondary">
              Balance
            </Typography>
            <Typography
              variant="h3"
              color="primary"
              sx={{ fontWeight: "bold" }}
            >
              ${balance.toFixed(2)}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" mb={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={handleSendCoinsToggle}
              fullWidth={isMobile}
            >
              Enviar SpeedCoins
            </Button>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Typography
            variant="h6"
            gutterBottom
            align="center"
            color="textSecondary"
          >
            Mi Código QR
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={3}
            onClick={handleQrOpen}
            sx={{ cursor: "pointer" }}
          >
            <Tooltip title="Haz clic para agrandar">
              <QRCode value={user.uid} size={128} level="H" />
            </Tooltip>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                userSelect: "all",
                color: theme.palette.text.secondary,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={copyToClipboard}
            >
              UID: {user.uid}
              <ContentCopyIcon sx={{ ml: 1 }} />
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Button
            variant="outlined"
            color="info"
            startIcon={<InfoOutlinedIcon />}
            onClick={handleInfoToggle}
            fullWidth
          >
            ¿Qué son Los SpeedCoins?
          </Button>

          <Collapse in={infoOpen}>
            <Box
              mt={2}
              p={2}
              bgcolor={theme.palette.background.paper}
              borderRadius={2}
            >
              <Typography variant="body2" paragraph>
                Los SpeedCoins son una moneda única desarrollada por Livespeed,
                diseñada para mejorar la experiencia del usuario proporcionando
                una forma de recompensa y medio de transacción dentro de la
                plataforma. Con cada inscripción a una carrera, el 10% de la
                cuota de inscripción se devuelve al participante en forma de
                SpeedCoins.
              </Typography>
              <Typography variant="body2">
                Esta moneda puede utilizarse para diversos fines, incluyendo la
                inscripción en carreras adicionales, la compra de artículos en
                el mercado de Livespeed, o la realización de apuestas contra
                otros competidores. Los usuarios obtienen SpeedCoins
                exclusivamente a través de su participación en las carreras.
              </Typography>
            </Box>
          </Collapse>
        </Paper>
      </Fade>

      {sendCoinsOpen && (
        <SendSpeedCoins onClose={handleSendCoinsToggle} user={user} />
      )}

      <SpeedCoinHistory user={user} />

      <Modal open={qrOpen} onClose={handleQrClose}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 3,
              borderRadius: 2,
              boxShadow: 24,
            }}
          >
            <QRCode value={user.uid} size={256} level="H" />
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="contained" onClick={handleQrClose}>
                Cerrar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default Wallet;
