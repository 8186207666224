import React, { useState, useEffect } from "react";
import { Container, Box, Typography, CircularProgress } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const InstagramFeed = ({ uid }) => {
  const [instagramUrl, setInstagramUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInstagramUrl = async () => {
      try {
        const teamDocRef = doc(db, "Teams", uid);
        const docSnapshot = await getDoc(teamDocRef);
        if (docSnapshot.exists()) {
          const teamData = docSnapshot.data();
          setInstagramUrl(teamData.instagram);
        } else {
          setError("Equipo no encontrado");
        }
      } catch (err) {
        console.error("Error obteniendo la URL de Instagram:", err);
        setError("Error al cargar las publicaciones de Instagram");
      } finally {
        setLoading(false);
      }
    };

    fetchInstagramUrl();
  }, [uid]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
          {error}
        </Typography>
      </Container>
    );
  }

  if (!instagramUrl) {
    return (
      <Container>
        <Typography variant="h6" align="center" sx={{ mt: 4 }}>
          Este equipo no tiene una cuenta de Instagram vinculada.
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Publicaciones de Instagram
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <iframe
          src={`${instagramUrl}embed`}
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          allow="encrypted-media"
          title="Instagram Feed"
          style={{ borderRadius: "12px", maxWidth: "1000px" }}
        ></iframe>
      </Box>
    </Container>
  );
};

export default InstagramFeed;
