import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayRemove,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const TeamRequests = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const teamDocRef = doc(db, "Teams", uid);

    const unsubscribe = onSnapshot(teamDocRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const teamData = docSnapshot.data();
        if (teamData.requests && teamData.requests.length > 0) {
          const requestPromises = teamData.requests.map(async (userUid) => {
            const userDocRef = doc(db, "Users", userUid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              return { uid: userUid, ...userDocSnap.data() };
            } else {
              console.error("No such user:", userUid);
              return null;
            }
          });
          const requestsData = await Promise.all(requestPromises);
          setRequests(requestsData.filter((request) => request !== null));
        } else {
          setRequests([]);
        }
      } else {
        console.error("No such team!");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  const handleAcceptRequest = async (userUid) => {
    try {
      const teamRef = doc(db, "Teams", uid);
      await updateDoc(teamRef, {
        requests: arrayRemove(userUid),
        members: arrayUnion(userUid),
      });
      setRequests((prevRequests) =>
        prevRequests.filter((request) => request.uid !== userUid)
      );
    } catch (error) {
      console.error("Error accepting request:", error);
    }
  };

  const handleRejectRequest = async (userUid) => {
    try {
      const teamRef = doc(db, "Teams", uid);
      await updateDoc(teamRef, {
        requests: arrayRemove(userUid),
      });
      setRequests((prevRequests) =>
        prevRequests.filter((request) => request.uid !== userUid)
      );
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
  };

  const handleUserClick = (userUid) => {
    navigate(`/user/${userUid}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Solicitudes:
        </Typography>
        <List>
          {requests.length > 0 ? (
            requests.map((request) => (
              <ListItem
                key={request.uid}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f9f9f9",
                  borderRadius: 2,
                  mb: 1,
                  boxShadow: 1,
                }}
              >
                <ListItemAvatar>
                  <Tooltip title="Ver perfil">
                    <IconButton onClick={() => handleUserClick(request.uid)}>
                      <Avatar alt={request.name} src={request.photoUrl} />
                    </IconButton>
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText
                  primary={request.name}
                  secondary={request.nickName}
                  sx={{ mr: 2 }}
                />
                <Box>
                  <Tooltip title="Aceptar">
                    <IconButton
                      color="primary"
                      onClick={() => handleAcceptRequest(request.uid)}
                      sx={{ mr: 1 }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rechazar">
                    <IconButton
                      color="secondary"
                      onClick={() => handleRejectRequest(request.uid)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">
              No hay solicitudes pendientes.
            </Typography>
          )}
        </List>
      </Paper>
    </Container>
  );
};

export default TeamRequests;
