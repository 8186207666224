import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  collectionGroup,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Paper,
  CircularProgress,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const TagControl = () => {
  const [runs, setRuns] = useState([]);
  const [filteredRuns, setFilteredRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const tagsSnapshot = await getDocs(collection(db, "Tags"));
    const usersSnapshot = await getDocs(collection(db, "Users"));
    const runsSnapshot = await getDocs(query(collectionGroup(db, "Runs")));

    const tagsData = tagsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const usersData = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const runsData = runsSnapshot.docs.map((doc) => {
      const runData = doc.data();
      const startTime = runData.StartTime
        ? new Date(runData.StartTime.seconds * 1000)
        : null;
      const finishTime = runData.FinishTime
        ? new Date(runData.FinishTime.seconds * 1000)
        : null;
      const timeDifference = finishTime ? finishTime - startTime : "N/A";
      const riderTag = tagsData.find(
        (tag) => tag.NFCID === runData.NFCIdentifier,
      );
      const rider = riderTag
        ? usersData.find((user) => user.id === riderTag.Owner)
        : null;

      return {
        id: doc.id,
        riderName: rider ? rider.name : "Unknown",
        photoUrl:
          rider && rider.photoUrl ? rider.photoUrl : "default_photo_url",
        NFCIdentifier: runData.NFCIdentifier,
        date: startTime ? startTime.toLocaleString() : "N/A",
        time: timeDifference,
      };
    });

    setRuns(runsData);
    setFilteredRuns(runsData);
    setLoading(false);
  };

  useEffect(() => {
    const filtered = runs.filter(
      (run) =>
        run.riderName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        run.NFCIdentifier.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredRuns(filtered);
  }, [searchTerm, runs]);

  const handleDelete = async (id) => {
    // Placeholder for delete functionality
    alert(`Eliminar la carrera con ID: ${id}`);
    // await deleteDoc(doc(db, "Runs", id)); // Asumiendo "Runs" es tu colección
    // fetchData(); // Recargar los datos
  };

  const handleEdit = (id) => {
    // Placeholder for edit functionality
    alert(`Editar la carrera con ID: ${id}`);
    // Logica para editar, posiblemente estableciendo un estado para el ID a editar y mostrando un modal o formulario de edición.
  };

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        Leaderboard
      </Typography>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Buscar por Nombre o NFC"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <Typography variant="h5" align="center">
                Todos los Tiempos
              </Typography>
              <List>
                {filteredRuns.map((run) => (
                  <ListItem
                    key={run.id}
                    secondaryAction={
                      <>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEdit(run.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(run.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={run.photoUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${run.riderName} - NFC: ${run.NFCIdentifier}`}
                      secondary={`Tiempo: ${typeof run.time === "number" ? `${Math.floor(run.time / 60000)} min ${Math.floor((run.time / 1000) % 60)} sec` : run.time}, Fecha: ${run.date}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TagControl;
