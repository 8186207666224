import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Divider,
  Paper,
  Button,
  Fade,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PrintIcon from "@mui/icons-material/Print";

const Success = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const payment_id = query.get("payment_id");

    const fetchOrderAndUpdate = async () => {
      setLoading(true);
      try {
        const orderDocRef = doc(db, "orders", orderId);
        const orderDoc = await getDoc(orderDocRef);
        if (orderDoc.exists()) {
          const orderData = { id: orderDoc.id, ...orderDoc.data() };
          setOrder(orderData);

          const paymentStatus = await verifyPaymentStatus(payment_id);
          setPaymentStatus(paymentStatus);

          if (paymentStatus === "approved") {
            await updateDoc(orderDocRef, {
              payment_id: payment_id,
            });

            if (orderData.Type === "event") {
              const eventDocRef = doc(db, "events", orderData.ItemUID);
              await updateDoc(eventDocRef, {
                participants: arrayUnion(orderData.UserUID),
              });
            }
          }
        } else {
          console.log("No se encontró la orden");
          setError("Orden no encontrada");
        }
      } catch (error) {
        console.error("Error al obtener la orden:", error);
        setError("Error al obtener la orden. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    const verifyPaymentStatus = async (payment_id) => {
      try {
        const response = await fetch(
          `https://api.mercadopago.com/v1/payments/${payment_id}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Bearer TEST-2746090563234645-032921-2ac3092e97a9cf7047e57a1f54012780-175078923",
            },
          }
        );
        const data = await response.json();
        if (data && data.status) {
          return data.status;
        } else {
          throw new Error("No se recibió el estado del pago del servidor");
        }
      } catch (err) {
        console.error("Error verificando el estado del pago:", err);
        setError("Error verificando el estado del pago. Intente nuevamente.");
      }
    };

    fetchOrderAndUpdate();
  }, [orderId, location.search]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Fade in={true} timeout={1000}>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Paper
          elevation={6}
          sx={{
            padding: 4,
            borderRadius: 4,
            background: "linear-gradient(45deg, #212121 30%, #424242 90%)",
            color: "white",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handlePrint}
            sx={{ position: "absolute", top: 16, right: 16, color: "white" }}
          >
            <PrintIcon />
          </IconButton>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 60, mr: 2 }} />
            <Typography variant="h3" fontWeight="bold">
              ¡Pago Exitoso!
            </Typography>
          </Box>
          <Divider sx={{ mb: 4, bgcolor: "rgba(255,255,255,0.2)" }} />
          <Card
            sx={{
              mb: 4,
              borderRadius: 2,
              boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
              bgcolor: "white",
              color: "black",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                color="primary"
                fontWeight="bold"
              >
                Detalles del Ticket
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Orden ID:</strong> {order.id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Total Pagado:</strong> ${order.Total}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Estado del Pago:</strong>{" "}
                    <span
                      style={{
                        color:
                          paymentStatus === "approved" ? "green" : "orange",
                      }}
                    >
                      {paymentStatus || "Desconocido"}
                    </span>
                  </Typography>
                </Grid>
                {order.Type === "event" && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <strong>Tipo de Orden:</strong> Evento
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Box textAlign="center" mb={4}>
            <Typography variant="h6" gutterBottom>
              Gracias por tu compra
            </Typography>
            <Typography variant="body1">
              Tu pago ha sido registrado exitosamente.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                navigate("/tickets");
                setShowSnackbar(true);
              }}
              startIcon={<ConfirmationNumberIcon />}
              endIcon={<ArrowForwardIcon />}
              sx={{
                fontWeight: "bold",
                px: 4,
                py: 1.5,
                borderRadius: 8,
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                bgcolor: "#212121",
                color: "white",
                "&:hover": {
                  boxShadow: "0 6px 8px rgba(0,0,0,0.2)",
                  bgcolor: "#424242",
                },
              }}
            >
              Ver mis Tickets
            </Button>
          </Box>
        </Paper>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setShowSnackbar(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            ¡Ticket comprado con éxito!
          </Alert>
        </Snackbar>
      </Container>
    </Fade>
  );
};

export default Success;
