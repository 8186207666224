import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collectionGroup,
  query,
  where,
  getDocs,
  collection,
  onSnapshot,
} from "firebase/firestore";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Paper,
  Badge,
  CircularProgress,
  Button,
} from "@mui/material";

const CapitanLeaderboard = () => {
  const [track, setTrack] = useState(null);
  const [runs, setRuns] = useState([]);
  const [tags, setTags] = useState([]);
  const [bestTimes, setBestTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  const specificParkId = "DlMVVtjrhAnsdRZIiGpF";
  const specificTrackId = "ffJseYsYV3xNKIGfembs";

  useEffect(() => {
    const fetchTrackAndData = async () => {
      const trackDocRef = doc(
        db,
        "Parks",
        specificParkId,
        "Tracks",
        specificTrackId,
      );
      const trackSnapshot = await getDoc(trackDocRef);
      if (trackSnapshot.exists()) {
        setTrack({ id: trackSnapshot.id, ...trackSnapshot.data() });

        const tagsCollectionRef = collection(db, "Tags");
        const tagsSnapshot = await getDocs(tagsCollectionRef);
        const tagsData = tagsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(tagsData);

        const runsCollectionRef = collectionGroup(db, "Runs");
        const q = query(
          runsCollectionRef,
          where("TrackID", "==", specificTrackId),
        );

        // Suscribirse a cambios en tiempo real
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const runsData = snapshot.docs.map((doc) => {
            const runData = doc.data();
            // console.log("runData", runData);
            if (runData.StartTime && runData.FinishTime) {
              const startTime = new Date(runData.StartTime).getTime();
              const finishTime = new Date(runData.FinishTime).getTime();
              const timeDifference = finishTime - startTime;

              // tagsData.map((tag) => {
              //   console.log("runData.NFCIdentifier", runData.NFCIdentifier);
              //   console.log("tag", tag);
              // });

              const riderTag = tagsData.find(
                (tag) => tag.NFCID === runData.NFCIdentifier,
              );

              // console.log("riderTag", riderTag);
              const rider = riderTag
                ? {
                    owner: riderTag.Owner,
                    category: riderTag.Category,
                    team: riderTag.Team,
                  }
                : null;
              return {
                id: doc.id,
                riderName: rider ? rider.owner : "Unknown",
                category: rider ? rider.category : "Unknown",
                team: rider ? rider.team : "Unknown",
                date: new Date(runData.StartTime).toLocaleString(),
                time: timeDifference,
              };
            } else {
              return {
                id: doc.id,
                riderName: "Unknown",
                category: "Unknown",
                team: "Unknown",
                date: "N/A",
                time: "N/A",
              };
            }
          });
          setRuns(runsData);
          setLoading(false);
        });

        // Devolver la función de limpieza para cancelar la suscripción
        return () => unsubscribe();
      }
    };

    fetchTrackAndData();
  }, []);

  useEffect(() => {
    const findBestTimes = () => {
      const bestTimesMap = new Map();
      runs.forEach((run) => {
        if (!isNaN(run.time)) {
          // Verificar si el tiempo es un número
          if (!bestTimesMap.has(run.riderName)) {
            bestTimesMap.set(run.riderName, run);
          } else {
            if (run.time < bestTimesMap.get(run.riderName).time) {
              bestTimesMap.set(run.riderName, run);
            }
          }
        }
      });
      const bestTimesArray = Array.from(bestTimesMap.values());
      const sortedBestTimes = bestTimesArray.sort((a, b) => a.time - b.time);
      setBestTimes(sortedBestTimes);
    };
    findBestTimes();
  }, [runs]);

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        {track ? track.TrackName : "Loading..."}
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo negro con opacidad
                color: "white", // Texto en blanco para mejor legibilidad
                padding: "20px", // Añadir algo de espacio dentro del Paper
              }}
            >
              <Typography variant="h5" align="center">
                Mejores Tiempos
              </Typography>
              <List>
                {bestTimes.map((run, index) => (
                  <ListItem
                    key={run.id}
                    style={{
                      backgroundColor:
                        index === 0
                          ? "rgba(225, 206, 0, 0.7)"
                          : index === 1
                            ? "rgba(160, 160, 160, 0.7)"
                            : index === 2
                              ? "rgba(197, 106, 11, 0.7)"
                              : "none",
                    }}
                  >
                    <ListItemText
                      primary={run.riderName}
                      secondary={`Categoría: ${run.category}, Equipo: ${run.team}, Tiempo: ${Math.floor(
                        run.time / 60000,
                      )} min ${Math.floor((run.time / 1000) % 60)} sec, Fecha: ${
                        run.date
                      }`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CapitanLeaderboard;
