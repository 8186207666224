import React from "react";
import { Typography, Box } from "@mui/material";

const CreacionEquipos = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Creación de Equipos
      </Typography>
      <Typography variant="body1" paragraph>
        Aprende a crear y gestionar equipos en Livespeed.
      </Typography>
      {/* Aquí puedes agregar más contenido detallado sobre la creación de equipos */}
    </Box>
  );
};

export default CreacionEquipos;
