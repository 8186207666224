import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Discount } from "@mui/icons-material";

const CheckOut = () => {
  const { uid } = useParams();
  const [preferenceId, setPreferenceId] = useState(null);
  const [order, setOrder] = useState(null);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    initMercadoPago("TEST-c8377fe0-6c57-454b-8d84-d3ac4a3bff44");

    const fetchOrder = async () => {
      setLoading(true);
      try {
        const orderDocRef = doc(db, "orders", uid);
        const orderDoc = await getDoc(orderDocRef);
        if (orderDoc.exists()) {
          const orderData = { id: orderDoc.id, ...orderDoc.data() };
          setOrder(orderData);
          if (orderData.ItemUID) {
            await fetchItem(orderData.ItemUID);
          } else {
            console.error("ItemUID missing from order data");
            setError("Datos del ítem no disponibles.");
          }
          createPreference(orderData.Total, orderDoc.id);
        } else {
          console.log("No se encontró la orden");
          setError("Orden no encontrada");
        }
      } catch (error) {
        console.error("Error al obtener la orden:", error);
        setError("Error al obtener la orden. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [uid]);

  const fetchItem = async (itemUID) => {
    if (!itemUID) {
      console.error("Error: itemUID is undefined");
      setError("Error en la carga de datos del ítem");
      return;
    }

    try {
      const itemDocRef = doc(db, "events", itemUID);
      const itemDoc = await getDoc(itemDocRef);
      if (itemDoc.exists()) {
        setEvent({ id: itemDoc.id, ...itemDoc.data() });
      } else {
        console.log("No se encontró el ítem");
        setError("Ítem no encontrado");
      }
    } catch (error) {
      console.error("Error al obtener el ítem:", error);
      setError("Error al obtener el ítem. Intente nuevamente.");
    }
  };

  const createPreference = async (total, orderId) => {
    try {
      const response = await fetch(
        "https://api.mercadopago.com/checkout/preferences",
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer TEST-2746090563234645-032921-2ac3092e97a9cf7047e57a1f54012780-175078923",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: [
              {
                title: "Pago de Evento",
                quantity: 1,
                currency_id: "MXN",
                unit_price: total,
              },
            ],
            back_urls: {
              success: `https://www.livespeed.mx/success/${orderId}`,
              failure: `https://www.livespeed.mx/failure/${orderId}`,
              pending: `https://www.livespeed.mx/pending/${orderId}`,
            },
            auto_return: "approved",
            external_reference: orderId,
          }),
        }
      );
      const data = await response.json();
      if (data && data.id) {
        setPreferenceId(data.id);
      } else {
        throw new Error("No se recibió ID de preferencia del servidor");
      }
    } catch (err) {
      console.error("Error creando la preferencia:", err);
      setError("Error al crear la preferencia de pago. Intente nuevamente.");
    }
  };

  const handleApplyCoupon = async () => {
    if (!coupon) {
      return;
    }

    try {
      const couponDocRef = doc(db, "coupons", coupon);
      const couponDoc = await getDoc(couponDocRef);
      if (couponDoc.exists()) {
        const couponData = couponDoc.data();
        let discountValue = 0;

        if (couponData.amount) {
          discountValue = couponData.amount;
        } else if (couponData.percent) {
          discountValue = (order.Total * couponData.percent) / 100;
        }

        setDiscount(discountValue);
        setCouponValid(true);
        setError(null);

        // Update preferenceId after applying the coupon
        createPreference(order.Total - discountValue, uid);
      } else {
        setDiscount(0);
        setCouponValid(false);
        setError("Cupón no válido");
      }
    } catch (error) {
      console.error("Error al verificar el cupón:", error);
      setError("Error al verificar el cupón. Intente nuevamente.");
      setDiscount(0);
      setCouponValid(false);
    }
  };

  const getTotalWithDiscount = () => {
    return order.Total - discount;
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!order || !event) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          Orden no encontrada
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Pagar Orden: {event.eventName}
      </Typography>
      <Card sx={{ mb: 3, borderRadius: 2, overflow: "hidden" }}>
        <CardMedia
          component="img"
          height="550px"
          image={event.photo}
          alt={`Foto del evento ${event.id}`}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Ticket:</strong> {event.eventName}
          </Typography>

          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Resumen:
          </Typography>
          <List>
            {order.Addons?.map((addon, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={addon.type}
                  secondary={
                    <>
                      {addon.price && <span>Precio: ${addon.price}</span>}
                      {addon.size && <span> - Tamaño: {addon.size}</span>}
                      {addon.description && (
                        <span> - Descripción: {addon.description}</span>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                label="Cupón"
                variant="outlined"
                fullWidth
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Discount />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyCoupon}
                fullWidth
              >
                Aplicar
              </Button>
            </Grid>
          </Grid>
          {couponValid && (
            <Typography variant="body2" color="green" sx={{ mt: 2 }}>
              Cupón válido. Descuento aplicado: ${discount.toFixed(2)}
            </Typography>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Total:</strong> ${getTotalWithDiscount().toFixed(2)}
          </Typography>
          <Box mt={2} display="flex" justifyContent="center">
            {preferenceId && (
              <Wallet
                initialization={{ preferenceId }}
                customization={{
                  texts: {
                    valueProp: "smart_option",
                  },
                }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CheckOut;
