import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  TextField,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

const Teams = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [showLoginWarning, setShowLoginWarning] = useState(false);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsCollection = collection(db, "Teams");
        const teamsSnapshot = await getDocs(teamsCollection);
        const teamsData = teamsSnapshot.docs.map((doc) => ({
          id: doc.id,
          logo: doc.data().logo || "",
          coverPhoto: doc.data().coverPhoto || "",
          ...doc.data(),
        }));
        setTeams(teamsData);
        setFilteredTeams(teamsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching teams:", error);
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    setFilteredTeams(
      teams.filter((team) =>
        team.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, teams]);

  const handleCardClick = (teamId) => {
    navigate(`/team/${teamId}`);
  };

  const handleCreateTeamClick = () => {
    if (user) {
      navigate("/createteam");
    } else {
      setShowLoginWarning(true);
    }
  };

  const handleCloseLoginWarning = () => {
    setShowLoginWarning(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Teams
        </Typography>
        <IconButton
          color="primary"
          onClick={handleCreateTeamClick}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": { backgroundColor: "primary.dark" },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        placeholder="Buscar equipos"
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{
          mb: 3,
          "& .MuiOutlinedInput-root": {
            borderRadius: "50px",
            backgroundColor: "background.paper",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
        }}
      />

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredTeams.map((team) => (
            <Grid item xs={12} sm={6} md={4} key={team.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 3,
                  overflow: "hidden",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  height: "100%",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    transition: "transform 0.3s ease-in-out",
                  },
                }}
                onClick={() => handleCardClick(team.id)}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 150, sm: 200 },
                    objectFit: "cover",
                  }}
                  image={team.coverPhoto}
                  alt={`${team.name} cover`}
                />
                <CardContent
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Avatar
                      src={team.logo}
                      alt={team.name}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      {team.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {team.nationality}, {team.state}
                  </Typography>
                  <Box sx={{ display: "flex", mt: 1, gap: 1 }}>
                    <Chip
                      icon={
                        team.private ? (
                          <LockIcon fontSize="small" />
                        ) : (
                          <PublicIcon fontSize="small" />
                        )
                      }
                      label={team.private ? "Privado" : "Público"}
                      size="small"
                      color={team.private ? "secondary" : "primary"}
                    />
                    <Chip
                      icon={<PeopleIcon fontSize="small" />}
                      label={`${team.members.length} miembros`}
                      size="small"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog
        open={showLoginWarning}
        onClose={handleCloseLoginWarning}
        PaperProps={{
          style: { borderRadius: 16 },
        }}
      >
        <DialogTitle>{"¡Alerta!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Debes estar registrado para crear un equipo. ¿Deseas iniciar sesión
            ahora?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseLoginWarning();
              navigate("/login");
            }}
            color="primary"
          >
            Iniciar Sesión
          </Button>
          <Button
            onClick={() => {
              handleCloseLoginWarning();
              navigate("/signup");
            }}
            color="primary"
            variant="contained"
          >
            Registrarse
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Teams;
