import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  KmlLayer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "700px",
  borderRadius: "15px", // Bordes redondeados
  overflow: "hidden",
};

const center = {
  lat: 29.11580571,
  lng: -110.93319296,
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const CustomMarker = ({ position, photoUrl, name, team, timestamp, speed }) => {
  // Convertir la velocidad de m/s a km/h
  const speedKmph = (speed || 0) * 3.6;

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div style={markerContainerStyle}>
        <div style={imageContainerStyle}>
          <img src={photoUrl} alt={name} style={imageStyle} />
        </div>
        <div style={textStyle}>
          <div>{name}</div>
          <div>Team: {team}</div>
          <div>Speed: {speedKmph.toFixed(2)} km/h</div>
        </div>
      </div>
    </OverlayView>
  );
};

const markerContainerStyle = {
  textAlign: "center",
  width: "150px",
};

const imageContainerStyle = {
  background: "#66ff66",
  border: "1px solid #ccc",
  borderRadius: "50%",
  padding: "4px",
  width: "33px",
  height: "33px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "5px",
};

const imageStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
};

const textStyle = {
  background: "#fff",
  color: "#333",
  fontSize: "10px",
  padding: "5px",
  borderRadius: "5px",
};

const LiveMap = () => {
  const [riders, setRiders] = useState([]);
  const [mapType, setMapType] = useState("terrain"); // Tipo de mapa por defecto

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Establecer tiempo en 00:00:00 del día actual

      const updatedRiders = snapshot.docs
        .map((doc) => {
          const userData = doc.data();
          const locationDate = userData.location?.timestamp?.toDate(); // Obtener la fecha de ubicación

          if (userData.location && locationDate >= today) {
            return {
              id: doc.id,
              position: {
                lat: userData.location.latitude,
                lng: userData.location.longitude,
              },
              name: userData.name,
              team: userData.team,
              photoUrl: userData.photoUrl,
              timestamp: userData.location.timestamp,
              speed: userData.location.speed,
            };
          }
          return null;
        })
        .filter((rider) => rider !== null);
      setRiders(updatedRiders);
    });

    return () => unsubscribe();
  }, []);

  const topographyStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { hue: "#00aaff" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { color: "#f2f2f2" }],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#e8e8e8",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#dae8e4",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { color: "#e8e8e8" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { color: "#e0ffcc" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }, { color: "#a0d6d1" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }, { color: "#f99f1c" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }, { color: "#f3f3f3" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }, { color: "#ffffff" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: "#f2f2f2" }],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9e1ff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#a0d6d1" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffdd00",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ff0000",
        },
      ],
    },
  ];

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <button onClick={() => setMapType("terrain")}>Mapa Relieve</button>
        <button onClick={() => setMapType("satellite")}>Mapa Satelital</button>
        <button onClick={() => setMapType("roadmap")}>Mapa Básico</button>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        options={{
          mapTypeId: mapType, // Cambiar tipo de mapa
          styles: topographyStyles, // Estilos del mapa topográfico
        }}
      >
        {riders.map((rider) => (
          <CustomMarker
            key={rider.id}
            position={rider.position}
            photoUrl={rider.photoUrl}
            name={rider.name}
            team={rider.team}
            timestamp={rider.timestamp}
            speed={rider.speed}
          />
        ))}

        {/* Capa KML */}
        <KmlLayer
          url="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Enduro.kml?alt=media&token=cfb193ce-69b7-4739-8165-c5f492020891"
          options={{ preserveViewport: true }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default LiveMap;
