import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Avatar,
  Chip,
  IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import FavoriteIcon from "@mui/icons-material/Favorite";
import moment from "moment";

const Product = ({ product, handleCategoryChange, handleFavorite }) => {
  const navigate = useNavigate();

  const formatPrice = (price) => {
    const options = {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: price % 1 === 0 ? 0 : 2,
    };
    return new Intl.NumberFormat("es-MX", options).format(price);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: "0 8px 24px rgba(0, 0, 0, 0.12)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 12px 32px rgba(0, 0, 0, 0.18)",
        },
      }}
    >
      {product.sponsored && (
        <Chip
          icon={<RocketLaunchIcon fontSize="small" />}
          label="Sponsored"
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            color: "#1976d2",
            fontWeight: "bold",
            zIndex: 1,
          }}
        />
      )}
      <CardActionArea onClick={() => navigate(`/product/${product.id}`)}>
        <CardMedia
          component="img"
          height="220"
          image={product.mainImageUrl}
          alt={product.name}
          sx={{ objectFit: "cover" }}
        />
        <CardContent sx={{ p: 2.5 }}>
          {product.user && (
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar
                src={product.user.photoUrl || ""}
                alt={product.user.nickname || ""}
                sx={{ mr: 1.5, width: 36, height: 36 }}
              />
              <Box>
                <Typography variant="subtitle2" fontWeight="bold">
                  {product.user.name || "Unknown User"}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {product.user.nickname || ""}
                </Typography>
              </Box>
            </Box>
          )}
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            {product.name}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            color="text.secondary"
            mb={1.5}
          >
            <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2">
              {product.city}, {product.state}, {product.country}
            </Typography>
          </Box>
          <Chip
            label={product.categoryName}
            onClick={() => handleCategoryChange(product.category)}
            sx={{
              mb: 2,
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption" color="text.secondary">
              Posted {moment(product.postDate).fromNow()}
            </Typography>
            <Typography variant="h6" fontWeight="bold" color="primary">
              {formatPrice(product.price)}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <IconButton
        onClick={() => handleFavorite(product.id)}
        sx={{
          position: "absolute",
          top: 8,
          left: 8,
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
          },
        }}
      >
        <FavoriteIcon color={product.isFavorite ? "error" : "action"} />
      </IconButton>
    </Card>
  );
};

export default Product;
