import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, Grid, Typography, Alert, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import GoogleIcon from "../assets/google-icon.png"; // Importa el logo de Google
import { useUserAuth } from "../context/userAuthContext";

const GoogleButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#000",
  borderColor: "#000",
  textTransform: "none",
  fontSize: "16px",
  padding: "10px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#f1f1f1",
    borderColor: "#000",
  },
});

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { signUp, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        padding: "0 20px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            border: "1px solid #ddd",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Crea tu cuenta!
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label="Correo Electrónico"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label="Contraseña"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{
                mt: 3,
                py: 1.5,
                backgroundColor: "#000",
                color: "#fff",
                fontSize: "16px",
                borderRadius: "8px",
              }}
            >
              Crear cuenta
            </Button>
          </form>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <GoogleButton
                variant="outlined"
                onClick={handleGoogleSignIn}
                fullWidth
                startIcon={<img src={GoogleIcon} alt="Google" width="20" />}
              >
                Crea tu cuenta con Google
              </GoogleButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="body2">
                ¿Ya tienes una cuenta?{" "}
                <Link
                  to="/login"
                  style={{ color: "#000", textDecoration: "underline" }}
                >
                  Inicia Sesión
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
