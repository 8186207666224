import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Paper,
} from "@mui/material";

const RunStats = () => {
  const { uid } = useParams();
  const [run, setRun] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rider, setRider] = useState(null);

  useEffect(() => {
    const fetchRunData = async () => {
      try {
        console.log("Fetching run data for UID:", uid);
        const runRef = doc(db, "Runs", uid);

        const runSnapshot = await getDoc(runRef);

        if (runSnapshot.exists()) {
          const runData = runSnapshot.data();
          setRun(runData);

          const tagsRef = collection(db, "Tags");
          const tagsQuery = query(
            tagsRef,
            where("NFCID", "==", runData.NFCIdentifier)
          );
          const tagsSnapshot = await getDocs(tagsQuery);

          if (!tagsSnapshot.empty) {
            const tagData = tagsSnapshot.docs[0].data();
            const ownerUid = tagData.Owner;
            const riderRef = doc(db, "Users", ownerUid);
            const riderSnapshot = await getDoc(riderRef);
            if (riderSnapshot.exists()) {
              setRider(riderSnapshot.data());
            } else {
              console.log("Rider document not found.");
            }
          } else {
            console.log("Tag document not found.");
          }
        } else {
          console.log("Run document not found.");
        }
      } catch (error) {
        console.error("Error fetching run data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRunData();
  }, [uid]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!run) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h5">Run not found.</Typography>
      </Box>
    );
  }

  const { StartTime, FinishTime, TrackID, NFCIdentifier } = run;
  const startTime = StartTime
    ? new Date(StartTime.seconds * 1000).toLocaleString()
    : "N/A";
  const finishTime = FinishTime
    ? new Date(FinishTime.seconds * 1000).toLocaleString()
    : "N/A";
  const timeDifference =
    FinishTime && StartTime
      ? FinishTime.seconds * 1000 - StartTime.seconds * 1000
      : "N/A";

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Run Stats
        </Typography>
        <Box display="flex" alignItems="center" mb={3}>
          <Avatar
            src={rider?.photoUrl || ""}
            alt={rider?.name || NFCIdentifier}
            sx={{ width: 60, height: 60, mr: 2 }}
          />
          <Box>
            <Typography variant="subtitle1">
              {rider?.name || "Unknown Rider"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {rider?.nickname || ""}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" paragraph>
          <strong>Track ID:</strong> {TrackID}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Start Time:</strong> {startTime}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Finish Time:</strong> {finishTime}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Total Time:</strong>{" "}
          {timeDifference !== "N/A"
            ? `${Math.floor(timeDifference / 60000)} min ${Math.floor(
                (timeDifference / 1000) % 60
              )} sec`
            : "N/A"}
        </Typography>
      </Paper>
    </Container>
  );
};

export default RunStats;
