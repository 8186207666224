import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";
import ServiceNotes from "./AdminWorkShop/ServiceNotes";
import ServiceNotesMapper from "./AdminWorkShop/ServiceNotesMapper";

const Service = () => {
  const [service, setService] = useState(null);
  const [status, setStatus] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [showQRReader, setShowQRReader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { serviceId } = useParams();
  const db = getFirestore();

  useEffect(() => {
    const fetchServiceAndUser = async () => {
      const docRef = doc(db, "bikeService", serviceId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const serviceData = docSnap.data();
        setService({ id: docSnap.id, ...serviceData });
        setStatus(serviceData.status);

        // Obtener el número de teléfono del usuario
        const userDocRef = doc(db, "Users", serviceData.userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserPhoneNumber(userDocSnap.data().phoneNumber);
        } else {
          console.log("User document not found.");
        }
      } else {
        console.log("No such service!");
      }
    };

    fetchServiceAndUser();
  }, [serviceId, db]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const updateServiceStatus = async (newStatus) => {
    const serviceRef = doc(db, "bikeService", serviceId);
    await updateDoc(serviceRef, { status: newStatus });
    setStatus(newStatus);
    setShowQRReader(false);
    sendStatusUpdateMessage(newStatus);
    setSnackbarMessage("Estado del servicio actualizado.");
    setSnackbarOpen(true);
  };

  const sendStatusUpdateMessage = async (newStatus) => {
    if (!userPhoneNumber) {
      console.log("User's phone number is not available.");
      return;
    }

    // Agregar "521" al principio si el número no lo tiene
    const completePhoneNumber = userPhoneNumber.startsWith("521")
      ? userPhoneNumber
      : `521${userPhoneNumber}`;

    const message = `El estado del servicio ha cambiado. \n*Folio:* ${service?.id}\n*Estado:* ${newStatus}`;

    try {
      const response = await fetch("https://pd87xz-3001.csb.app/send-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number: completePhoneNumber,
          message: message,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Response from server:", result);
    } catch (error) {
      console.error("Error sending to WhatsApp:", error);
    }
  };

  const startQRScan = () => {
    setShowQRReader(true);

    setTimeout(() => {
      const html5QrCode = new Html5Qrcode("qr-reader");
      const config = { fps: 10, qrbox: { width: 250, height: 250 } };

      html5QrCode
        .start(
          { facingMode: "environment" },
          config,
          (decodedText, decodedResult) => {
            if (decodedText === serviceId) {
              html5QrCode.stop().then(() => {
                setShowQRReader(false);
                updateServiceStatus("completado");
                setSnackbarMessage("Servicio completado.");
                setSnackbarOpen(true);
              });
            } else {
              setSnackbarMessage(
                "QR incorrecto, el servicio no puede ser completado.",
              );
              setSnackbarOpen(true);
            }
          },
        )
        .catch((err) => {
          console.log("QR scanning error:", err);
        });
    }, 100); // Delay to ensure the QR reader element is rendered
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h6">Detalles del Servicio</Typography>
              {service ? (
                <>
                  <Typography>
                    <strong>Bicicleta ID:</strong> {service.bikeId}
                  </Typography>
                  <Typography>
                    <strong>Detalles del Servicio:</strong>{" "}
                    {service.serviceDetails}
                  </Typography>
                  <Typography>
                    <strong>Servicio ID:</strong> {service.id}
                  </Typography>
                  <Typography>
                    <strong>Fecha de Creación:</strong>{" "}
                    {service.createdAt?.toDate().toLocaleString()}
                  </Typography>
                </>
              ) : (
                <Typography>No se encontró el servicio</Typography>
              )}
              <FormControl fullWidth>
                <InputLabel id="status-select-label">Estado</InputLabel>
                <Select
                  labelId="status-select-label"
                  value={status}
                  label="Estado"
                  onChange={handleStatusChange}
                >
                  <MenuItem value="pendiente">Pendiente</MenuItem>
                  <MenuItem value="aceptado">Aceptado</MenuItem>
                  <MenuItem value="cancelado">Cancelado</MenuItem>
                  <MenuItem value="trabajando">Trabajando</MenuItem>
                  <MenuItem value="completado">Completado</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" onClick={startQRScan}>
                Escanear QR
              </Button>
              {showQRReader && (
                <Box id="qr-reader" sx={{ width: "100%", height: 300 }} />
              )}
              <Typography>
                Escanea el QR de la bicicleta para entregar
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateServiceStatus(status)}
              >
                Actualizar Estado del Servicio
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={status === "Completado" ? "success" : "error"}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <ServiceNotes serviceId={service?.id} />
        <ServiceNotesMapper serviceId={service?.id} />
      </Grid>
    </Grid>
  );
};

export default Service;
