import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { db } from "../../../../firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import moment from "moment";
import BatteryChart from "./BatteryChart";
import TemperatureChart from "./TemperatureChart";

const convertVoltage = (vsysVoltage) => {
  return (vsysVoltage * 4.13) / 0.5820207;
};

const calculateBatteryPercentage = (voltage) => {
  const minVoltage = 3.0;
  const maxVoltage = 4.2;
  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  }
};

const MonitoringCharts = ({ uid }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const pingsCollection = collection(db, `Receivers/${uid}/Pings`);
      const allPingsQuery = query(pingsCollection, orderBy("time", "desc"));
      const allPingsSnapshot = await getDocs(allPingsQuery);
      const pingsData = allPingsSnapshot.docs.map((doc) => doc.data());

      const chartData = {
        battery: {
          labels: pingsData.map((ping) =>
            moment(ping.time).format("MM-DD HH:mm")
          ),
          datasets: [
            {
              label: "Battery Percentage",
              data: pingsData.map((ping) =>
                calculateBatteryPercentage(convertVoltage(ping.vsysVoltage))
              ),
              fill: true,
              borderColor: "#4caf50",
              backgroundColor: "rgba(76, 175, 80, 0.1)",
              tension: 0.4,
            },
          ],
        },
        temperature: {
          labels: pingsData.map((ping) =>
            moment(ping.time).format("MM-DD HH:mm")
          ),
          datasets: [
            {
              label: "Temperature (°C)",
              data: pingsData.map((ping) => ping.temperature),
              fill: true,
              borderColor: "#f44336",
              backgroundColor: "rgba(244, 67, 54, 0.1)",
              tension: 0.4,
            },
          ],
        },
      };

      setChartData(chartData);
      setLoading(false);
    };

    fetchData();
  }, [uid]);

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="body2">Cargando gráficos...</Typography>
      </Box>
    );
  }

  if (!chartData) {
    return (
      <Typography variant="body2" sx={{ opacity: 0.8 }}>
        No hay datos disponibles.
      </Typography>
    );
  }

  return (
    <Card
      sx={{
        backgroundColor: "#f5f5f5",
        color: "#000",
        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(4px)",
        borderRadius: "16px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        height: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Gráficas de Monitoreo
        </Typography>
        <Box sx={{ p: 2 }}>
          {chartData.battery ? (
            <BatteryChart chartData={chartData.battery} />
          ) : (
            <Typography>No hay datos de batería disponibles.</Typography>
          )}
          {chartData.temperature ? (
            <TemperatureChart chartData={chartData.temperature} />
          ) : (
            <Typography>No hay datos de temperatura disponibles.</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MonitoringCharts;
