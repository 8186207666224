import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ParkIcon from "@mui/icons-material/Park"; // Icono para Parques

const LeaderboardMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        margin: "auto",
        padding: isMobile ? 2 : 4,
        backgroundColor: theme.palette.background.default,
        textAlign: "center",
      }}
    >
      {/* Título */}
      <Typography
        variant={isMobile ? "h4" : "h3"}
        sx={{
          fontWeight: "bold",
          color: theme.palette.primary.contrastText,
          marginBottom: theme.spacing(4),
        }}
      >
        Leaderboards
      </Typography>

      {/* Botones de Parques y Eventos */}
      <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            component={Link}
            to="/parksleaderboards"
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: theme.spacing(3),
              textDecoration: "none",
              color: "inherit",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: theme.shadows[10],
              },
              borderRadius: theme.shape.borderRadius,
              backgroundColor: "black",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "50%",
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <ParkIcon
                fontSize="large"
                sx={{ color: theme.palette.primary.contrastText }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.contrastText,
              }}
            >
              Parques
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper
            component={Link}
            to="/events"
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: theme.spacing(3),
              textDecoration: "none",
              color: "inherit",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: theme.shadows[10],
              },
              borderRadius: theme.shape.borderRadius,
              backgroundColor: "black",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "50%",
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <EventIcon
                fontSize="large"
                sx={{ color: theme.palette.primary.contrastText }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.contrastText,
              }}
            >
              Eventos
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeaderboardMenu;
