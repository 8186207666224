import React, { useState, useEffect } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  CircularProgress,
  Typography,
  Box,
  Fade,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const SearchFriends = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersRef = collection(db, "Users");
        const snapshot = await getDocs(usersRef);
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllUsers(usersData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setResults([]);
      return;
    }
    const filteredUsers = allUsers.filter((user) => {
      const searchLower = searchQuery.toLowerCase();
      const nameMatch = user.name?.toLowerCase().includes(searchLower);
      const nickNameMatch = user.nickName?.toLowerCase().includes(searchLower);
      return nameMatch || nickNameMatch;
    });
    setResults(filteredUsers);
  }, [searchQuery, allUsers]);

  const handleSelectUser = (uid) => {
    navigate(`/user/${uid}`);
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: 4, textAlign: "center" }}>
      <Typography
        variant="h4"
        sx={{ marginBottom: 2, fontWeight: "bold", color: "#333" }}
      >
        Buscar Amigos
      </Typography>
      <TextField
        fullWidth
        label="Buscar amigos..."
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ marginBottom: 3 }}
      />
      {loading ? (
        <Box sx={{ mt: 4 }}>
          {/* Skeletons for loading state */}
          {[...Array(5)].map((_, index) => (
            <ListItem key={index} sx={{ mb: 2 }}>
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <Box sx={{ width: "100%" }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
              </Box>
            </ListItem>
          ))}
        </Box>
      ) : (
        <>
          {results.length === 0 && searchQuery.trim() === "" && (
            <Fade in={true} timeout={800}>
              <Box
                sx={{
                  marginTop: 4,
                  padding: 2,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  ¡Busca a tus amigos y conéctate!
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Escribe el nombre o apodo de tus amigos en la barra de
                  búsqueda para encontrarlos.
                </Typography>
              </Box>
            </Fade>
          )}
          <List>
            {results.length === 0 && searchQuery.trim() !== "" && (
              <ListItem>
                <ListItemText primary="No se encontraron resultados" />
              </ListItem>
            )}
            {results.map((user) => (
              <ListItem
                key={user.id}
                button
                onClick={() => handleSelectUser(user.id)}
                sx={{
                  borderRadius: 2,
                  mb: 2,
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s ease-in-out, box-shadow 0.2s",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    transform: "translateY(-4px)",
                    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={user.name}
                    src={user.photoUrl || "https://via.placeholder.com/150"}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  secondary={user.nickName}
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    color: "#333333",
                  }}
                  secondaryTypographyProps={{
                    color: "textSecondary",
                    mt: 0.5,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Container>
  );
};

export default SearchFriends;
