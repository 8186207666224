import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  Button,
  Slide,
  Fade,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

const ProductLocationForm = ({ productData, setProductData, errors }) => {
  const [marker, setMarker] = useState({
    lat: productData.latitude || 0,
    lng: productData.longitude || 0,
  });
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!productData.latitude || !productData.longitude) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setMarker({ lat: latitude, lng: longitude });
            setLoading(false);
            updateLocationData(latitude, longitude);
          },
          (error) => {
            console.error("Error getting user location: ", error);
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      updateLocationData(productData.latitude, productData.longitude);
    }
  }, [productData.latitude, productData.longitude]);

  const handleMapClick = useCallback(
    async (event) => {
      const newMarker = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      setMarker(newMarker);
      await updateLocationData(newMarker.lat, newMarker.lng);
    },
    [setProductData]
  );

  const updateLocationData = async (lat, lng) => {
    const locationData = await fetchLocationName(lat, lng);
    setProductData((prevData) => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
      city: locationData.city || "",
      state: locationData.state || "",
      country: locationData.country || "",
    }));
    setAddress(
      `${locationData.city}, ${locationData.state}, ${locationData.country}`
    );
  };

  const fetchLocationName = async (lat, lng) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      const addressComponents = data.results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      )?.long_name;
      return { city, state, country };
    }
    return { city: "", state: "", country: "" };
  };

  const handleAddressSearch = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      setMarker({ lat, lng });
      await updateLocationData(lat, lng);
    }
  };

  return (
    <Box p={isMobile ? 2 : 3}>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        Ubicación del Producto
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        gutterBottom
        mb={isMobile ? 2 : 3}
      >
        Selecciona la ubicación de tu producto para que los compradores cercanos
        puedan encontrarlo fácilmente.
      </Typography>
      <Paper elevation={3} sx={{ p: isMobile ? 2 : 3, mb: isMobile ? 2 : 3 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <LocationOnIcon color="primary" sx={{ mr: 1 }} />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Busca una dirección"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputProps={{
              endAdornment: isMobile ? (
                <IconButton color="primary" onClick={handleAddressSearch}>
                  <SearchIcon />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddressSearch}
                  startIcon={<SearchIcon />}
                >
                  Buscar
                </Button>
              ),
            }}
          />
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Fade in={!loading} timeout={1000}>
            <Box
              sx={{
                position: "relative",
                height: "400px",
                overflow: "hidden",
                borderRadius: 2,
              }}
            >
              <LoadScript
                googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28"
                onLoad={() => setMapLoaded(true)}
              >
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  center={marker}
                  zoom={12}
                  onClick={handleMapClick}
                  options={{
                    styles: [
                      {
                        featureType: "all",
                        elementType: "geometry.fill",
                        stylers: [{ weight: "2.00" }],
                      },
                      {
                        featureType: "all",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#9c9c9c" }],
                      },
                      {
                        featureType: "all",
                        elementType: "labels.text",
                        stylers: [{ visibility: "on" }],
                      },
                      {
                        featureType: "landscape",
                        elementType: "all",
                        stylers: [{ color: "#f2f2f2" }],
                      },
                      {
                        featureType: "landscape",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ffffff" }],
                      },
                      {
                        featureType: "landscape.man_made",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#ffffff" }],
                      },
                      {
                        featureType: "poi",
                        elementType: "all",
                        stylers: [{ visibility: "off" }],
                      },
                      {
                        featureType: "road",
                        elementType: "all",
                        stylers: [{ saturation: -100 }, { lightness: 45 }],
                      },
                      {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#eeeeee" }],
                      },
                      {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#7b7b7b" }],
                      },
                      {
                        featureType: "road",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#ffffff" }],
                      },
                      {
                        featureType: "road.highway",
                        elementType: "all",
                        stylers: [{ visibility: "simplified" }],
                      },
                      {
                        featureType: "road.arterial",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                      },
                      {
                        featureType: "transit",
                        elementType: "all",
                        stylers: [{ visibility: "off" }],
                      },
                      {
                        featureType: "water",
                        elementType: "all",
                        stylers: [{ color: "#46bcec" }, { visibility: "on" }],
                      },
                      {
                        featureType: "water",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#c8d7d4" }],
                      },
                      {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#070707" }],
                      },
                      {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#ffffff" }],
                      },
                    ],
                  }}
                >
                  <Marker position={marker} />
                </GoogleMap>
              </LoadScript>
              <Slide direction="up" in={mapLoaded} mountOnEnter unmountOnExit>
                <Paper
                  elevation={3}
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    left: 16,
                    right: 60,
                    p: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  <Typography variant="body2" fontWeight="bold" gutterBottom>
                    Ubicación seleccionada:
                  </Typography>
                  <Typography variant="body2">
                    {address ||
                      "Haz clic en el mapa para seleccionar una ubicación"}
                  </Typography>
                </Paper>
              </Slide>
            </Box>
          </Fade>
        )}
      </Paper>
      {errors.location && (
        <Typography color="error" variant="body2">
          {errors.location}
        </Typography>
      )}
    </Box>
  );
};

export default ProductLocationForm;
