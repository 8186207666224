import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../context/userAuthContext";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  Container,
  Box,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Facebook, Twitter, WhatsApp } from "@mui/icons-material";
import ProductHeader from "./ProductHeader";
import ProductDetails from "./ProductDetails";
import ProductActions from "./ProductActions";

const Product = () => {
  const { user } = useUserAuth();
  const { uid } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [likes, setLikes] = useState([]);
  const [seller, setSeller] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const productRef = doc(db, "Products", uid);
        const productSnapshot = await getDoc(productRef);

        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          setProduct(productData);
          setLikes(productData.likes || []);

          const userRef = doc(db, "Users", productData.uid);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            setSeller(userSnapshot.data());
          }

          const categoryRef = doc(db, "Categories", productData.category);
          const categorySnapshot = await getDoc(categoryRef);
          if (categorySnapshot.exists()) {
            setCategoryName(categorySnapshot.data().type);

            const subCategoryRef = doc(
              db,
              "Categories",
              productData.category,
              "sub",
              productData.subCategory
            );
            const subCategorySnapshot = await getDoc(subCategoryRef);
            if (subCategorySnapshot.exists()) {
              setSubCategoryName(subCategorySnapshot.data().type);
            }
          }
        } else {
          console.error("No product found with the specified UID.");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    getProduct();
  }, [uid]);

  const handleLike = async () => {
    if (!user) return;

    const productRef = doc(db, "Products", uid);
    const userLiked = likes.includes(user.uid);

    try {
      if (userLiked) {
        await updateDoc(productRef, {
          likes: arrayRemove(user.uid),
        });
        setLikes((prevLikes) => prevLikes.filter((like) => like !== user.uid));
      } else {
        await updateDoc(productRef, {
          likes: arrayUnion(user.uid),
        });
        setLikes((prevLikes) => [...prevLikes, user.uid]);
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleUserClick = () => {
    if (seller && seller.uid) {
      navigate(`/user/${seller.uid}`);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const shareText = "Comparte en tus Redes Sociales";

  const shareProduct = (platform) => {
    const shareUrl = window.location.href;
    const text = `*En venta:* ${product.name} - ${shareUrl}`;

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `https://wa.me/?text=${encodeURIComponent(text)}`,
          "_blank"
        );
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h5">Producto no encontrado.</Typography>
      </Box>
    );
  }

  const {
    name,
    price,
    mainImageUrl,
    description,
    city,
    state,
    country,
    moreImages,
  } = product;
  const allImages = [mainImageUrl, ...moreImages];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body2" align="center" gutterBottom>
            {shareText}
          </Typography>
          <Box display="flex" justifyContent="center" mb={2} gap={1}>
            <IconButton
              onClick={() => shareProduct("facebook")}
              sx={{
                backgroundColor: "#3b5998",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#3b5998",
                  opacity: 0.8,
                },
                borderRadius: "50%",
                p: 1,
              }}
            >
              <Facebook />
            </IconButton>
            <IconButton
              onClick={() => shareProduct("twitter")}
              sx={{
                backgroundColor: "#1da1f2",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1da1f2",
                  opacity: 0.8,
                },
                borderRadius: "50%",
                p: 1,
              }}
            >
              <Twitter />
            </IconButton>
            <IconButton
              onClick={() => shareProduct("whatsapp")}
              sx={{
                backgroundColor: "#25d366",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#25d366",
                  opacity: 0.8,
                },
                borderRadius: "50%",
                p: 1,
              }}
            >
              <WhatsApp />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <ProductHeader
            allImages={allImages}
            selectedImage={selectedImage}
            handleImageClick={handleImageClick}
            open={open}
            handleClose={handleClose}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <ProductDetails
            name={name}
            price={price}
            city={city}
            state={state}
            country={country}
            categoryName={categoryName}
            subCategoryName={subCategoryName}
            seller={seller}
            description={description}
            handleUserClick={handleUserClick}
          />
          <ProductActions
            productId={uid}
            handleLike={handleLike}
            likes={likes}
            user={user}
            seller={seller}
            productName={name}
            productPrice={price}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Product;
