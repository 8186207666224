import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  Alert,
  Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./BikeGarage.css"; // Asegúrate de tener este archivo CSS para estilos adicionales

const BikeGarage = () => {
  const { user } = useUserAuth();
  const [bikes, setBikes] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.uid) {
      const firestore = getFirestore();
      const q = query(
        collection(firestore, "Bikes"),
        where("userid", "==", user.uid),
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const bikesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBikes(bikesData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const handleDeleteBike = async (bikeId) => {
    try {
      const firestore = getFirestore();
      await deleteDoc(doc(firestore, "Bikes", bikeId));
    } catch (error) {
      console.error("Error al eliminar bicicleta:", error);
      setError("Error al eliminar la bicicleta.");
    }
  };

  const handleAddBike = () => {
    navigate("/addBike");
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Mi Garage de Bicicletas
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddBike}
      >
        Agregar Nueva Bicicleta
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={3}>
        {bikes.map((bike) => (
          <Grid item xs={12} sm={6} md={4} key={bike.id}>
            <Card>
              <CardMedia
                component="img"
                height="100%" // Altura fija para todas las imágenes
                image={bike.bikePic}
                alt={bike.bikeBrand}
                style={{ objectFit: "cover" }} // Ajusta la imagen para cubrir el área definida
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {bike.bikeBrand}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tipo: {bike.bikeType}
                  <br />
                  Tamaño del Cuadro: {bike.bikeSize}
                  <br />
                  Tamaño de la Rueda: {bike.wheelSize}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDeleteBike(bike.id)}
                >
                  Eliminar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BikeGarage;
