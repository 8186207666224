import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  Typography,
  Container,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  CardMedia,
  Switch,
  Paper,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useUserAuth } from "../../context/userAuthContext";
import { motion, AnimatePresence } from "framer-motion";
import {
  ArrowBack,
  ArrowForward,
  AddAPhoto,
  Delete,
} from "@mui/icons-material";
import "./CreateRide.css";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "16px",
  overflow: "hidden",
  marginBottom: "16px",
};

const steps = [
  "Detalles de la rodada",
  "Fecha y privacidad",
  "Ubicación e imagen",
];

const CreateRide = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const [bikeType, setBikeType] = useState("");
  const [bikeTypes, setBikeTypes] = useState([]);
  const [distance, setDistance] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [name, setName] = useState("");
  const [postdate, setPostdate] = useState(new Date().toISOString());
  const [isPrivate, setIsPrivate] = useState(false);
  const [riders, setRiders] = useState([]);
  const [time, setTime] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  });

  useEffect(() => {
    const fetchBikeTypes = async () => {
      const bikeTypesRef = collection(db, "BikeTypes");
      const bikeTypesSnapshot = await getDocs(bikeTypesRef);
      const bikeTypesList = bikeTypesSnapshot.docs.map(
        (doc) => doc.data().bikeType
      );
      setBikeTypes(bikeTypesList);
    };

    fetchBikeTypes();
  }, []);

  useEffect(() => {
    if (uid) {
      const fetchRide = async () => {
        const rideRef = doc(db, "Rides", uid);
        const rideSnap = await getDoc(rideRef);
        if (rideSnap.exists()) {
          const rideData = rideSnap.data();
          setBikeType(rideData.bikeType);
          setDistance(rideData.distance);
          setImageUrl(rideData.imageUrl);
          setLocation(rideData.location);
          setName(rideData.name);
          setPostdate(rideData.postdate);
          setIsPrivate(rideData.private);
          setRiders(rideData.riders);
          setTime(new Date(rideData.time).toISOString().slice(0, -1));
        } else {
          console.log("No such document!");
        }
      };

      fetchRide();
    }
  }, [uid]);

  useEffect(() => {
    if (user) {
      setRiders([user.uid]);
    }
  }, [user]);

  const pageVariants = {
    initial: (direction) => ({
      opacity: 0,
      x: direction === "next" ? "100%" : "-100%",
    }),
    in: { opacity: 1, x: 0 },
    out: (direction) => ({
      opacity: 0,
      x: direction === "next" ? "-100%" : "100%",
    }),
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleImageRemove = () => {
    setImage(null);
    setImageUrl("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    let uploadedImageUrl = imageUrl;

    if (image) {
      const imageRef = ref(storage, `rides/${image.name}`);
      const snapshot = await uploadBytes(imageRef, image);
      uploadedImageUrl = await getDownloadURL(snapshot.ref);
    }

    const rideData = {
      bikeType,
      creator: user.uid,
      distance,
      imageUrl: uploadedImageUrl,
      location,
      name,
      postdate,
      private: isPrivate,
      riders,
      time: new Date(time).toISOString(),
    };

    try {
      if (uid) {
        const rideRef = doc(db, "Rides", uid);
        await updateDoc(rideRef, rideData);
      } else {
        await addDoc(collection(db, "Rides"), rideData);
      }
      navigate("/");
    } catch (error) {
      console.error("Error adding/updating ride: ", error);
    }
  };

  const handleMapClick = (event) => {
    setLocation({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  const handleNext = () => {
    const currentErrors = {};
    if (activeStep === 0) {
      if (!name) currentErrors.name = "El nombre es obligatorio";
      if (!bikeType)
        currentErrors.bikeType = "El tipo de bicicleta es obligatorio";
    } else if (activeStep === 1) {
      if (!time) currentErrors.time = "La hora es obligatoria";
    }
    if (Object.keys(currentErrors).length === 0) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrors(currentErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: "24px",
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" gutterBottom align="center">
          {uid ? "Editar Rodada" : "Nueva Rodada"}
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <AnimatePresence mode="wait" custom={activeStep}>
          <motion.div
            key={activeStep}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            custom={activeStep > 0 ? "next" : "back"}
          >
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Detalles de la rodada
                  </Typography>
                  <TextField
                    label="Nombre de la Rodada"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.bikeType}
                  >
                    <InputLabel>Tipo de Bicicleta</InputLabel>
                    <Select
                      value={bikeType}
                      onChange={(e) => setBikeType(e.target.value)}
                      label="Tipo de Bicicleta"
                    >
                      {bikeTypes.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.bikeType && (
                      <Typography variant="caption" color="error">
                        {errors.bikeType}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
              )}
              {activeStep === 1 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Fecha y privacidad
                  </Typography>
                  <TextField
                    label="Hora"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="datetime-local"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    error={!!errors.time}
                    helperText={errors.time}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                      />
                    }
                    label="Privada"
                  />
                </Box>
              )}
              {activeStep === 2 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Ubicación e imagen
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Haz clic en el mapa para establecer la ubicación
                  </Typography>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{
                      lat: location.latitude || 29.109680351364556,
                      lng: location.longitude || -110.94029814004898,
                    }}
                    zoom={8}
                    onClick={handleMapClick}
                  >
                    {location.latitude && location.longitude && (
                      <Marker
                        position={{
                          lat: location.latitude,
                          lng: location.longitude,
                        }}
                      />
                    )}
                  </GoogleMap>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Sube una fotografía del lugar para ayudar a los
                    participantes a llegar a tu spot. ¡No es obligatoria!
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "16px",
                      border: "2px solid black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      marginTop: 2,
                      marginBottom: 2,
                      overflow: "hidden",
                    }}
                  >
                    {imageUrl ? (
                      <>
                        <CardMedia
                          component="img"
                          image={imageUrl}
                          alt="Preview"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <IconButton
                          color="error"
                          aria-label="remove picture"
                          onClick={handleImageRemove}
                          sx={{ position: "absolute", top: 8, right: 8 }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: "64px",
                          height: "64px",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          border: "2px solid black",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="upload picture"
                          component="label"
                          sx={{ color: "black" }}
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                          />
                          <AddAPhoto />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  startIcon={<ArrowBack />}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="contained"
                  sx={{ backgroundColor: "black", color: "white" }}
                >
                  Atrás
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ backgroundColor: "black", color: "white" }}
                  >
                    {uid ? "Actualizar Rodada" : "Crear Rodada"}
                  </Button>
                ) : (
                  <Button
                    endIcon={<ArrowForward />}
                    onClick={handleNext}
                    variant="contained"
                    sx={{ backgroundColor: "black", color: "white" }}
                  >
                    Siguiente
                  </Button>
                )}
              </Box>
            </form>
          </motion.div>
        </AnimatePresence>
      </Paper>
    </Container>
  );
};

export default CreateRide;
