import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  addDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
  Stack,
  TextField,
} from "@mui/material";
import scanSound from "../../../sounds/bip.mp3";

const ManualStart = () => {
  const [error, setError] = useState(null);
  // const [scanResult, setScanResult] = useState(null);
  const [selectedPark, setSelectedPark] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const [riderNumber, setRiderNumber] = useState("");
  const [parkID, setParkID] = useState("");
  const [trackID, setTrackID] = useState("");
  const [parks, setParks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [images, setImages] = useState([]);
  // const [isScanningBlocked, setIsScanningBlocked] = useState(false);

  const fetchParks = async () => {
    try {
      const parksCollectionRef = collection(db, "Parks");
      const querySnapshot = await getDocs(parksCollectionRef);
      const parkList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().ParkName,
      }));
      setParks(parkList);
    } catch (error) {
      console.error("Error getting parks list:", error);
    }
  };

  const fetchTracks = async () => {
    try {
      const tracksCollectionRef = collection(
        db,
        "Parks",
        selectedPark,
        "Tracks",
      );
      const querySnapshot = await getDocs(tracksCollectionRef);
      const trackList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().TrackName,
      }));
      setTracks(trackList);
    } catch (error) {
      console.error("Error getting tracks list:", error);
    }
  };

  const fetchCamReceiverImages = async () => {
    try {
      const camReceiverCollectionRef = collection(db, "CamReceiver");
      const querySnapshot = await getDocs(camReceiverCollectionRef);
      const imagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        capture: doc.data().Capture,
      }));
      setImages(imagesList);
    } catch (error) {
      console.error("Error getting tracks list:", error);
    }
  };

  // useEffect(() => {
  //   fetchCamReceiverImages();
  // }, []);

  useEffect(() => {
    fetchParks();
  }, []);

  useEffect(() => {
    if (selectedPark) {
      fetchTracks();
    } else {
      setTracks([]); // Limpia los tracks cuando no hay un parque seleccionado
    }
  }, [selectedPark]);

  const handleStartManualTime = async () => {
    try {
      const runsCollectionRef = collection(db, "Runs");
      const runData = {
        ActiveRun: true,
        NFCIdentifier: riderNumber,
        ParkID: parkID,
        TrackID: trackID,
        StartTime: new Date().toISOString(),
      };
      await addDoc(runsCollectionRef, runData);
      console.log("Run registered successfully.");
    } catch (error) {
      const errorMessage = "Error registering run: " + error.message;
      console.error(errorMessage);
      setError(errorMessage);
    }
    console.log("Rider #", riderNumber, "started!");
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h4">Manual Start</Typography>

        <form>
          <Stack>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel>Select Park</InputLabel>
              <Select
                value={selectedPark}
                onChange={(e) => {
                  setSelectedPark(e.target.value);
                  setParkID(e.target.value);
                }}
              >
                <MenuItem value="">Select a park</MenuItem>
                {parks.map((park) => (
                  <MenuItem key={park.id} value={park.id}>
                    {park.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedPark && (
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <InputLabel>Select Track</InputLabel>
                <Select
                  value={selectedTrack}
                  onChange={(e) => {
                    setSelectedTrack(e.target.value);
                    setTrackID(e.target.value);
                  }}
                >
                  <MenuItem value="">Select a track</MenuItem>
                  {tracks.map((track) => (
                    <MenuItem key={track.id} value={track.id}>
                      {track.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          {selectedTrack ? (
            <Stack spacing={1} mt={2} my={2}>
              <Typography>Select Rider Number</Typography>
              <TextField
                id="riderNumber"
                label="Rider Number"
                variant="outlined"
                value={riderNumber}
                onChange={(event) => {
                  setRiderNumber(event.target.value);
                }}
              />
            </Stack>
          ) : null}
        </form>
        {riderNumber ? (
          <Button variant="contained" onClick={handleStartManualTime}>
            Start Time
          </Button>
        ) : null}
        <Box>
          <Button onClick={fetchCamReceiverImages}>show images</Button>
          {images.map((e) => (
            <Box
              component={"img"}
              src={e.capture}
              width={100}
              height={100}
              sx={{ m: 1 }}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ManualStart;
