import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  LinearProgress,
} from "@mui/material";
import {
  Battery20 as Battery20Icon,
  Battery30 as Battery30Icon,
  Battery50 as Battery50Icon,
  Battery60 as Battery60Icon,
  Battery80 as Battery80Icon,
  Battery90 as Battery90Icon,
  BatteryFull as BatteryFullIcon,
  BatteryAlert as BatteryAlertIcon,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import RouterIcon from "@mui/icons-material/Router";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { db } from "../../../firebase";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReceiverMapLocation from "./ReceiverMapLocation";

const convertVoltage = (vsysVoltage) => {
  return (vsysVoltage * 4.13) / 0.5820207;
};

const calculateBatteryPercentage = (voltage) => {
  const minVoltage = 3.0;
  const maxVoltage = 4.2;
  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  }
};

const getBatteryIcon = (percentage) => {
  if (percentage >= 90) return <BatteryFullIcon />;
  if (percentage >= 80) return <Battery90Icon />;
  if (percentage >= 60) return <Battery80Icon />;
  if (percentage >= 50) return <Battery60Icon />;
  if (percentage >= 30) return <Battery50Icon />;
  if (percentage >= 20) return <Battery30Icon />;
  if (percentage >= 10) return <Battery20Icon />;
  return <BatteryAlertIcon />;
};

const ReceiverManager = () => {
  const [receivers, setReceivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReceivers = () => {
      const receiversCollection = collection(db, "Receivers");
      const q = query(receiversCollection);

      const unsubscribe = onSnapshot(q, async (receiverSnapshot) => {
        const receiverDataPromises = receiverSnapshot.docs.map(
          async (receiverDoc) => {
            const receiver = receiverDoc.data();
            const pingsCollection = collection(
              db,
              `Receivers/${receiverDoc.id}/Pings`
            );
            const latestPingQuery = query(
              pingsCollection,
              orderBy("time", "desc"),
              limit(1)
            );
            const latestPingSnapshot = await getDocs(latestPingQuery);

            if (!latestPingSnapshot.empty) {
              const latestPing = latestPingSnapshot.docs[0].data();
              latestPing.vsysVoltage = convertVoltage(latestPing.vsysVoltage);
              latestPing.batteryPercentage = calculateBatteryPercentage(
                latestPing.vsysVoltage
              );
              receiver.latestPing = latestPing;
              receiver.isConnected = true;
              receiver.secondsSinceLastPing = 0;
            } else {
              receiver.isConnected = false;
              receiver.secondsSinceLastPing = null;
            }

            receiver.RUID = receiverDoc.id;

            return receiver;
          }
        );

        const receiverData = await Promise.all(receiverDataPromises);
        setReceivers(receiverData);
      });

      return unsubscribe;
    };

    fetchReceivers();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setReceivers((prevReceivers) =>
        prevReceivers.map((receiver) => {
          if (receiver.latestPing) {
            const now = moment();
            const pingTime = moment(receiver.latestPing.time);
            const diffSeconds = now.diff(pingTime, "seconds");

            return {
              ...receiver,
              isConnected: diffSeconds <= 20,
              secondsSinceLastPing: diffSeconds,
            };
          }
          return receiver;
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleCardClick = (id) => {
    navigate(`/receiver/${id}`);
  };

  const filteredReceivers = receivers.filter((receiver) => {
    return (
      receiver.RUID.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter === "all" ||
        (filter === "connected" && receiver.isConnected) ||
        (filter === "disconnected" && !receiver.isConnected))
    );
  });

  const getReceiverType = (type) => {
    return type === "0" ? "Start" : "Finish";
  };

  return (
    <Box
      p={4}
      sx={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#000",
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          textAlign: "center",
        }}
      >
        Receiver Manager
      </Typography>

      <Box sx={{ height: "60vh", borderRadius: "16px", overflow: "hidden" }}>
        <ReceiverMapLocation />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <TextField
          label="Buscar Receivers por RUID"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            width: "60%",
            "& .MuiOutlinedInput-root": {
              color: "#000",
              "& fieldset": {
                borderColor: "#000",
              },
              "&:hover fieldset": {
                borderColor: "#000",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#000",
            },
          }}
        />
        <FormControl sx={{ width: "35%" }}>
          <InputLabel sx={{ color: "#000" }}>Filtrar por Estado</InputLabel>
          <Select
            value={filter}
            onChange={handleFilterChange}
            label="Filtrar por Estado"
            sx={{
              color: "#000",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
            }}
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="connected">Conectados</MenuItem>
            <MenuItem value="disconnected">Desconectados</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={4}>
        {filteredReceivers.map((receiver) => (
          <Grid key={receiver.RUID} item xs={12} sm={6} md={4}>
            <Card
              onClick={() => handleCardClick(receiver.RUID)}
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                height: "100%",
                position: "relative",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
                },
                cursor: "pointer",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 3,
                  }}
                >
                  <RouterIcon fontSize="large" sx={{ color: "#000" }} />
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", letterSpacing: "0.05em" }}
                  >
                    {receiver.RUID}
                  </Typography>
                  <FiberManualRecordIcon
                    sx={{ color: receiver.isConnected ? "#4caf50" : "#f44336" }}
                  />
                </Box>

                {receiver.latestPing && (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      {getBatteryIcon(receiver.latestPing.batteryPercentage)}
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        Batería:{" "}
                        {Math.round(receiver.latestPing.batteryPercentage)}%
                      </Typography>
                    </Box>
                    <Box sx={{ width: "100%", mb: 3 }}>
                      <LinearProgress
                        variant="determinate"
                        value={receiver.latestPing.batteryPercentage}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: "#e0e0e0",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor:
                              receiver.latestPing.batteryPercentage > 50
                                ? "#4caf50"
                                : receiver.latestPing.batteryPercentage > 20
                                ? "#ff9800"
                                : "#f44336",
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <SignalWifi4BarIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        {" "}
                        {receiver.isConnected ? "Conectado" : "Desconectado"}
                      </Typography>
                      <Tooltip
                        title={
                          receiver.isConnected ? "Conectado" : "Desconectado"
                        }
                      ></Tooltip>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <LocationOnIcon sx={{ mr: 1, color: "#000" }} />
                      <Typography variant="body2">
                        Coordenadas:{" "}
                        {receiver.Location
                          ? `${receiver.Location.lat}, ${receiver.Location.lng}`
                          : "No disponible"}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Último ping:{" "}
                      {moment(receiver.latestPing.time).format("MM-DD HH:mm")}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Voltaje: {receiver.latestPing.vsysVoltage.toFixed(2)} V
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Segundos desde el último ping:{" "}
                      {receiver.secondsSinceLastPing}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", letterSpacing: "0.05em" }}
                      >
                        {getReceiverType(receiver.Type)}
                      </Typography>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReceiverManager;
