import React, { useState, useEffect, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Typography, Stack, Avatar, useMediaQuery, Box } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import GroupIcon from "@mui/icons-material/Group";
import LoopIcon from "@mui/icons-material/Loop";
import RouteIcon from "@mui/icons-material/Route";
import CountUp from "react-countup";

const StatItem = ({ icon, count, label, start }) => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Avatar sx={{ mb: 1, backgroundColor: "black", color: "white" }}>
      {icon}
    </Avatar>
    <Typography variant="h6">
      <CountUp start={start ? 0 : count} end={count} duration={2} /> {label}
    </Typography>
  </Box>
);

const LiveStats = () => {
  const [parksCount, setParksCount] = useState(0);
  const [runsCount, setRunsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [tracksCount, setTracksCount] = useState(0);
  const [startCount, setStartCount] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const statsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parksCollectionRef = collection(db, "Parks");
        const parksSnapshot = await getDocs(parksCollectionRef);
        setParksCount(parksSnapshot.size);

        let totalTracksCount = 0;
        for (const parkDoc of parksSnapshot.docs) {
          const tracksCollectionRef = collection(parkDoc.ref, "Tracks");
          const tracksSnapshot = await getDocs(tracksCollectionRef);
          totalTracksCount += tracksSnapshot.size;
        }
        setTracksCount(totalTracksCount);

        const runsCollectionRef = collection(db, "Runs");
        const runsSnapshot = await getDocs(runsCollectionRef);
        setRunsCount(runsSnapshot.size);

        const usersCollectionRef = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        setUsersCount(usersSnapshot.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } // El 10% del elemento debe ser visible
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={statsRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        pt: 0,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#333",
          mb: 3,
          textAlign: "center",
        }}
      >
        Creciendo en comunidad.
      </Typography>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 2 : 4}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: isMobile ? "90%" : "70%",
          m: "auto",
          backgroundColor: "#f0f0f0", // Fondo gris claro
          p: isMobile ? 2 : 4,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <StatItem
          icon={<ParkIcon />}
          count={parksCount}
          label="Parques"
          start={startCount}
        />
        <StatItem
          icon={<RouteIcon />}
          count={tracksCount}
          label="Pistas"
          start={startCount}
        />
        <StatItem
          icon={<LoopIcon />}
          count={runsCount}
          label="Vueltas"
          start={startCount}
        />
        <StatItem
          icon={<GroupIcon />}
          count={usersCount}
          label="Usuarios"
          start={startCount}
        />
      </Stack>
      <Typography
        variant="caption"
        sx={{
          color: "#999",
          mt: 2,
          textAlign: "center",
          fontSize: "0.75rem", // Letras super chiquitas
        }}
      >
        Datos actualizados en tiempo real
      </Typography>
    </Box>
  );
};

export default LiveStats;
