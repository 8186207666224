import React from "react";
import { Box, Typography } from "@mui/material";
import Select from "react-select";
import { countries, states, cities } from "../CountryStateOptions";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
    border: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #aaa",
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
  }),
};

const LocationForm = ({
  country,
  state,
  city,
  handleCountryChange,
  handleStateChange,
  handleCityChange,
}) => {
  const stateOptions = country ? states[country.value] || [] : [];
  const cityOptions = state ? cities[state.value] || [] : [];

  return (
    <Box sx={{ width: "100%", maxWidth: 400, margin: "0 auto" }}>
      <Typography variant="h5" gutterBottom>
        Nacionalidad:
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Select
          options={countries}
          value={country}
          onChange={handleCountryChange}
          placeholder="Selecciona un país"
          isClearable
          styles={customStyles}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Select
          options={stateOptions}
          value={state}
          onChange={handleStateChange}
          placeholder="Selecciona un estado"
          isClearable
          isDisabled={!country}
          styles={customStyles}
        />
      </Box>
      <Box>
        <Select
          options={cityOptions.map((city) => ({ value: city, label: city }))}
          value={city}
          onChange={handleCityChange}
          placeholder="Selecciona una ciudad"
          isClearable
          isDisabled={!state}
          styles={customStyles}
        />
      </Box>
    </Box>
  );
};

export default LocationForm;
