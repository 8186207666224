import React from "react";
import { Box, Stack, Typography, Link } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const StackColumn = ({ children }) => (
    <Stack
      style={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: 1,
        gap: 8,
        textAlign: "center",
      }}
    >
      {children}
    </Stack>
  );

  const BoxRow = ({ children }) => (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#ffffff",
        flex: 1,
        gap: "30px",
        "@media (max-width:600px)": {
          flexDirection: "column",
          gap: "30px",
        },
      }}
    >
      {children}
    </Box>
  );

  const FooterTitle = ({ text }) => (
    <Typography
      variant="h6"
      component="h6"
      sx={{
        fontWeight: "700",
        textTransform: "capitalize",
        pb: 1,
      }}
    >
      {text}
    </Typography>
  );

  const FooterLink = ({ text }) => (
    <Link
      href="#"
      variant="body2"
      sx={{
        fontSize: "0.9rem",
        fontWeight: "400",
        textDecoration: "none",
        color: "#414141",
      }}
    >
      {text}
    </Link>
  );

  return (
    <BoxRow>
      <StackColumn>
        <FooterTitle text={"dirección"} />
        <FooterLink
          text={
            " Yañez #27, esquina con Nuevo León , Col San Benito, 83190 Hermosillo, Son."
          }
        />
        <FooterLink text={"662 217 2654"} />
        <FooterLink text="livespeedmx@gmail.com" />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={"nuestros servicios"} />
        <FooterLink text={"Rentar Livespeed"} />
        <FooterLink text={"Comprar Livespeed"} />
        <FooterLink text={"Unirme al Equipo"} />
        <FooterLink text={"Asesoria"} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={"Nuestra empresa"} />
        <FooterLink text={"Informes"} />
        <FooterLink text={"Contacto"} />
        <FooterLink text={"Gestión"} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={"Livespeed Mexico"} />
        <Stack
          direction="row"
          width="70px"
          maxWidth="100%"
          justifyContent="space-between"
        >
          <Link
            href="https://www.instagram.com/livespeed.mx"
            variant="body2"
            sx={{
              color: "#414141",
              "&:hover": {
                color: "#1c2859",
              },
            }}
          >
            <InstagramIcon />
          </Link>
          <Link
            href="#"
            variant="body2"
            sx={{
              color: "#414141",
              "&:hover": {
                color: "#1c2859",
              },
            }}
          >
            <FacebookIcon />
          </Link>
        </Stack>
        <Typography variant="caption" component="p">
          &copy; 2024 Livespeed Inc.
        </Typography>
      </StackColumn>
    </BoxRow>
  );
};

export default Footer;
