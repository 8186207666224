import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  addDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
} from "@mui/material";
import scanSound from "../../../sounds/bip.mp3";

const Receiver = () => {
  const [error, setError] = useState(null);
  const [scanResult, setScanResult] = useState(null);
  const [selectedPark, setSelectedPark] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const [parkID, setParkID] = useState("");
  const [trackID, setTrackID] = useState("");
  const [parks, setParks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [isScanningBlocked, setIsScanningBlocked] = useState(false);

  const playScanSound = () => {
    new Audio(scanSound).play();
  };

  const handleReadingError = () => {
    const errorMessage =
      "Argh! Cannot read data from the NFC tag. Try another one?";
    console.error(errorMessage);
    setError(errorMessage);
  };

  const handleReading = async ({ serialNumber }) => {
    if (isScanningBlocked) return;
    setIsScanningBlocked(true);
    setTimeout(() => setIsScanningBlocked(false), 3000);

    console.log("> Serial Number: ", serialNumber);

    const currentTime = new Date().toISOString();
    const runData = {
      NFCIdentifier: serialNumber,
      ParkID: parkID,
      TrackID: trackID,
      StartTime: currentTime,
      ActiveRun: true,
    };

    setScanResult(runData);
    playScanSound();

    await registerRun(runData);
  };

  const handleNFCScan = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      ndef.addEventListener("readingerror", handleReadingError);
      ndef.addEventListener("reading", handleReading);

      setError(null);
      console.log("> Scan started");
    } catch (error) {
      const errorMessage = "Error during NFC scanning: " + error.message;
      console.error(errorMessage);
      setError(errorMessage);
    }
  };

  const registerRun = async (runData) => {
    setIsScanningBlocked(true);

    try {
      const runsCollectionRef = collection(db, "Runs");
      const q = query(
        runsCollectionRef,
        where("NFCIdentifier", "==", runData.NFCIdentifier),
        where("ActiveRun", "==", true),
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, {
          ...runData,
          StartTime: new Date().toISOString(),
        });
      } else {
        await addDoc(runsCollectionRef, runData);
      }

      console.log("Run registered successfully.");
    } catch (error) {
      const errorMessage = "Error registering run: " + error.message;
      console.error(errorMessage);
      setError(errorMessage);
    } finally {
      setIsScanningBlocked(false);
    }
  };

  const fetchParks = async () => {
    try {
      const parksCollectionRef = collection(db, "Parks");
      const querySnapshot = await getDocs(parksCollectionRef);
      const parkList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().ParkName,
      }));
      setParks(parkList);
    } catch (error) {
      console.error("Error getting parks list:", error);
    }
  };

  const fetchTracks = async () => {
    try {
      const tracksCollectionRef = collection(
        db,
        "Parks",
        selectedPark,
        "Tracks",
      );
      const querySnapshot = await getDocs(tracksCollectionRef);
      const trackList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().TrackName,
      }));
      setTracks(trackList);
    } catch (error) {
      console.error("Error getting tracks list:", error);
    }
  };

  useEffect(() => {
    fetchParks();
  }, []);

  useEffect(() => {
    if (selectedPark) {
      fetchTracks();
    } else {
      setTracks([]); // Limpia los tracks cuando no hay un parque seleccionado
    }
  }, [selectedPark]);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNFCScan();
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h4">Receptor Inicial</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel>Select Park</InputLabel>
            <Select
              value={selectedPark}
              onChange={(e) => {
                setSelectedPark(e.target.value);
                setParkID(e.target.value);
              }}
            >
              <MenuItem value="">Select a park</MenuItem>
              {parks.map((park) => (
                <MenuItem key={park.id} value={park.id}>
                  {park.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedPark && (
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel>Select Track</InputLabel>
              <Select
                value={selectedTrack}
                onChange={(e) => {
                  setSelectedTrack(e.target.value);
                  setTrackID(e.target.value);
                }}
              >
                <MenuItem value="">Select a track</MenuItem>
                {tracks.map((track) => (
                  <MenuItem key={track.id} value={track.id}>
                    {track.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button sx={{ mt: 2 }} variant="contained" type="submit">
            Start NFC Scan
          </Button>
        </form>
        {scanResult && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1">
              NFC Identifier: {scanResult.NFCIdentifier}
            </Typography>
            <Typography variant="subtitle1">
              Park ID: {scanResult.ParkID}
            </Typography>
            <Typography variant="subtitle1">
              Track ID: {scanResult.TrackID}
            </Typography>
            <Typography variant="subtitle1">
              Start Time: {new Date(scanResult.StartTime).toLocaleString()}{" "}
              {/* Mostrar la marca de tiempo */}
            </Typography>
          </Box>
        )}
        {error && (
          <Typography variant="subtitle1" sx={{ mt: 2, color: "red" }}>
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Receiver;
