import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage, db } from "../../firebase";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const CreatePark = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [parkData, setParkData] = useState({
    ParkName: "",
    Logo: null,
    Cover: null,
    country: "",
    state: "",
    city: "",
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParkData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    setParkData({ ...parkData, Logo: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCoverChange = (e) => {
    setParkData({ ...parkData, Cover: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!parkData.Logo || !parkData.Cover) {
        alert(
          "Por favor, seleccione un logo y una foto de portada para el parque."
        );
        setLoading(false);
        return;
      }

      const logoRef = ref(
        storage,
        `park_logos/${user.uid}/${parkData.Logo.name}`
      );
      const coverRef = ref(
        storage,
        `park_covers/${user.uid}/${parkData.Cover.name}`
      );

      const uploadLogoTask = uploadBytesResumable(logoRef, parkData.Logo);
      const uploadCoverTask = uploadBytesResumable(coverRef, parkData.Cover);

      const logoUrl = await new Promise((resolve, reject) => {
        uploadLogoTask.on("state_changed", null, reject, () => {
          getDownloadURL(uploadLogoTask.snapshot.ref)
            .then(resolve)
            .catch(reject);
        });
      });

      const coverUrl = await new Promise((resolve, reject) => {
        uploadCoverTask.on("state_changed", null, reject, () => {
          getDownloadURL(uploadCoverTask.snapshot.ref)
            .then(resolve)
            .catch(reject);
        });
      });

      await addDoc(collection(db, "Parks"), {
        ParkName: parkData.ParkName,
        Logo: logoUrl,
        Cover: coverUrl,
        country: parkData.country,
        state: parkData.state,
        city: parkData.city,
        Owners: [user.uid],
        Followers: [],
        Guards: [],
        History: [],
        createdAt: serverTimestamp(),
      });

      setLoading(false);
      navigate(`/parks`);
    } catch (error) {
      console.error("Error creating park:", error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Crear Parque
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Parque"
                type="text"
                name="ParkName"
                value={parkData.ParkName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                type="file"
                onChange={handleLogoChange}
                style={{ display: "none" }}
                id="raised-button-file-logo"
              />
              <label htmlFor="raised-button-file-logo">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Subir Logo
                </Button>
              </label>
              {logoPreview && (
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                type="file"
                onChange={handleCoverChange}
                style={{ display: "none" }}
                id="raised-button-file-cover"
              />
              <label htmlFor="raised-button-file-cover">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Subir Portada
                </Button>
              </label>
              {coverPreview && (
                <img
                  src={coverPreview}
                  alt="Cover Preview"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="País"
                type="text"
                name="country"
                value={parkData.country}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Estado"
                type="text"
                name="state"
                value={parkData.state}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Ciudad"
                type="text"
                name="city"
                value={parkData.city}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{ backgroundColor: "black", color: "white" }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Crear Parque"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreatePark;
