import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Box,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "./firebase";
import { useUserAuth } from "./context/userAuthContext";

const Notificaciones = () => {
  const { user } = useUserAuth();
  const [friendRequests, setFriendRequests] = useState([]);
  const [friendRequestsData, setFriendRequestsData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user?.uid) {
      const userDocRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setFriendRequests(userData.friendRequests || []);
        } else {
          console.log("No such document!");
          setFriendRequests([]);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const fetchFriendRequestsData = async () => {
      const requestsData = await Promise.all(
        friendRequests.map(async (requestUid) => {
          const requestUserDocRef = doc(db, "Users", requestUid);
          const requestUserDocSnap = await getDoc(requestUserDocRef);
          if (requestUserDocSnap.exists()) {
            const requestUserData = requestUserDocSnap.data();
            return {
              uid: requestUid,
              name: requestUserData.name || "Usuario",
              photoUrl: requestUserData.photoUrl || "",
            };
          } else {
            return {
              uid: requestUid,
              name: "Usuario desconocido",
              photoUrl: "",
            };
          }
        })
      );
      setFriendRequestsData(requestsData);
    };

    if (friendRequests.length > 0) {
      fetchFriendRequestsData();
    } else {
      setFriendRequestsData([]);
    }
  }, [friendRequests]);

  const handleFriendRequest = async (requestUid, action) => {
    try {
      const userDocRef = doc(db, "Users", user.uid);
      const requesterDocRef = doc(db, "Users", requestUid);

      if (action === "accept") {
        await updateDoc(userDocRef, {
          friends: arrayUnion(requestUid),
          friendRequests: arrayRemove(requestUid),
        });

        await updateDoc(requesterDocRef, {
          friends: arrayUnion(user.uid),
        });

        setSnackbarMessage("¡Amigo agregado!");
      } else {
        await updateDoc(userDocRef, {
          friendRequests: arrayRemove(requestUid),
        });

        setSnackbarMessage("Solicitud rechazada");
      }

      setSnackbarOpen(true);

      // No eliminamos la solicitud del estado local aquí para mantener la notificación visible
    } catch (error) {
      console.error(
        `Error al ${
          action === "accept" ? "aceptar" : "rechazar"
        } la solicitud de amistad:`,
        error
      );
      setSnackbarMessage("Ocurrió un error. Por favor, intenta de nuevo.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : 300,
        maxHeight: isMobile ? "100vh" : 400,
        overflow: "auto",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: isMobile ? 0 : theme.shape.borderRadius,
        boxShadow: isMobile ? "none" : theme.shadows[3],
      }}
    >
      <List>
        {friendRequestsData.length > 0 ? (
          friendRequestsData.map((request) => (
            <React.Fragment key={request.uid}>
              <ListItem alignItems="flex-start" sx={{ paddingY: 2 }}>
                <ListItemAvatar>
                  <Avatar src={request.photoUrl} alt={request.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      {request.name}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        Quiere ser tu amigo
                      </Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            mr: 1,
                            backgroundColor: "black",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.dark,
                            },
                          }}
                          onClick={() =>
                            handleFriendRequest(request.uid, "accept")
                          }
                        >
                          Aceptar
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            borderColor: theme.palette.grey[500],
                            color: theme.palette.text.primary,
                            "&:hover": {
                              borderColor: theme.palette.grey[700],
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                          onClick={() =>
                            handleFriendRequest(request.uid, "reject")
                          }
                        >
                          Rechazar
                        </Button>
                      </Box>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText
              primary="No hay solicitudes de amistad"
              sx={{ textAlign: "center", color: theme.palette.text.secondary }}
            />
          </ListItem>
        )}
      </List>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Notificaciones;
