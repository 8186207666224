import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const AdminWorkShop = () => {
  const [workshops, setWorkshops] = useState([]);
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchWorkshops = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const workshopsRef = collection(firestore, "workshops");
      const q = query(workshopsRef, where("creatorId", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const userWorkshops = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setWorkshops(userWorkshops);
    };

    fetchWorkshops();
  }, [auth, firestore]);

  return (
    <Grid container spacing={2}>
      <Typography variant="h4" gutterBottom>
        Mis talleres
      </Typography>
      {workshops.map((workshop) => (
        <Grid item xs={12} sm={6} md={4} key={workshop.id}>
          <Link
            to={`/myworkshop/${workshop.id}`}
            style={{ textDecoration: "none" }}
          >
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={workshop.workshopLogo}
                alt={workshop.workshopName}
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {workshop.workshopName}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminWorkShop;
