import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import RouterIcon from "@mui/icons-material/Router";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import ReceiverLocation from "./ReceiverCharts/ReceiverLocation";
import Pings from "./ReceiverCharts/Pings";
import MonitoringCharts from "./ReceiverCharts/MonitoringCharts";

const convertVoltage = (vsysVoltage) => {
  return (vsysVoltage * 4.13) / 0.5820207;
};

const calculateBatteryPercentage = (voltage) => {
  const minVoltage = 3.0;
  const maxVoltage = 4.2;
  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  }
};

const ReceiverInfo = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCharts, setShowCharts] = useState(false);

  useEffect(() => {
    const fetchReceiverInfo = async () => {
      const receiverDoc = await getDoc(doc(db, "Receivers", uid));

      if (receiverDoc.exists()) {
        const receiverData = receiverDoc.data();

        const pingsCollection = collection(db, `Receivers/${uid}/Pings`);
        const latestPingQuery = query(
          pingsCollection,
          orderBy("time", "desc"),
          limit(1)
        );
        const latestPingSnapshot = await getDocs(latestPingQuery);

        if (!latestPingSnapshot.empty) {
          const latestPing = latestPingSnapshot.docs[0].data();
          latestPing.vsysVoltage = convertVoltage(latestPing.vsysVoltage);
          latestPing.batteryPercentage = calculateBatteryPercentage(
            latestPing.vsysVoltage
          );
          receiverData.latestPing = latestPing;

          const pingTime =
            latestPing.time instanceof Date
              ? latestPing.time
              : new Date(latestPing.time);

          const now = moment();
          const diffSeconds = now.diff(moment(pingTime), "seconds");

          receiverData.isConnected = diffSeconds <= 20;
          receiverData.secondsSinceLastPing = diffSeconds;

          setReceiver(receiverData);
        } else {
          receiverData.isConnected = false;
          receiverData.secondsSinceLastPing = null;
        }
      } else {
        setReceiver(null);
      }
      setLoading(false);
    };

    fetchReceiverInfo();
  }, [uid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (receiver?.latestPing) {
        const pingTime =
          receiver.latestPing.time instanceof Date
            ? receiver.latestPing.time
            : new Date(receiver.latestPing.time);

        const now = moment();
        const diffSeconds = now.diff(moment(pingTime), "seconds");

        if (diffSeconds !== receiver.secondsSinceLastPing) {
          setReceiver((prevReceiver) => ({
            ...prevReceiver,
            isConnected: diffSeconds <= 20,
            secondsSinceLastPing: diffSeconds,
          }));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [receiver]);

  const handleRestart = () => {
    console.log("Reiniciando receptor...");
  };

  const handleShutdown = () => {
    console.log("Apagando receptor...");
  };

  const handleSettingsClick = () => {
    navigate(`/receiverconfig/${uid}`);
  };

  const handleShowCharts = () => {
    setShowCharts(!showCharts);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!receiver) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">Receiver no encontrado</Typography>
      </Box>
    );
  }

  return (
    <Box
      p={4}
      sx={{ backgroundColor: "#ffffff", minHeight: "100vh", color: "#000" }}
    >
      {/* Botones de control */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
          mb: 4,
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={handleRestart}
          sx={{
            backgroundColor: "#007BFF",
            color: "#fff",
            "&:hover": { backgroundColor: "#0056b3" },
            boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
            width: "100%",
            "@media (min-width: 600px)": { width: "30%" },
          }}
        >
          Reiniciar
        </Button>
        <Button
          variant="contained"
          startIcon={<PowerSettingsNewIcon />}
          onClick={handleShutdown}
          sx={{
            backgroundColor: "#DC3545",
            color: "#fff",
            "&:hover": { backgroundColor: "#c82333" },
            boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
            width: "100%",
            "@media (min-width: 600px)": { width: "30%" },
          }}
        >
          Apagar
        </Button>
        <Button
          variant="contained"
          startIcon={<SettingsIcon />}
          onClick={handleSettingsClick}
          sx={{
            backgroundColor: "#28a745",
            color: "#fff",
            "&:hover": { backgroundColor: "#218838" },
            boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
            width: "100%",
            "@media (min-width: 600px)": { width: "30%" },
          }}
        >
          Configuración
        </Button>
        <Button
          variant="contained"
          startIcon={<ShowChartIcon />}
          onClick={handleShowCharts}
          sx={{
            backgroundColor: "#17a2b8",
            color: "#fff",
            "&:hover": { backgroundColor: "#117a8b" },
            boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
            width: "100%",
            "@media (min-width: 600px)": { width: "30%" },
          }}
        >
          {showCharts ? "Ocultar Gráficas" : "Ver Gráficas"}
        </Button>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <RouterIcon fontSize="large" sx={{ color: "#000" }} />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", letterSpacing: "0.05em" }}
                >
                  {receiver.stage === "0" ? "Start" : "Finish"}
                </Typography>
                <FiberManualRecordIcon
                  sx={{ color: receiver.isConnected ? "#4caf50" : "#f44336" }}
                />
              </Box>

              {receiver.latestPing ? (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <BatteryChargingFullIcon sx={{ mr: 1, color: "#4caf50" }} />
                    <Typography variant="body2">
                      Batería:{" "}
                      {Math.round(receiver.latestPing.batteryPercentage)}%
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%", mb: 3 }}>
                    <LinearProgress
                      variant="determinate"
                      value={receiver.latestPing.batteryPercentage}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: "#e0e0e0",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor:
                            receiver.latestPing.batteryPercentage > 50
                              ? "#4caf50"
                              : receiver.latestPing.batteryPercentage > 20
                              ? "#ff9800"
                              : "#f44336",
                        },
                      }}
                    />
                  </Box>
                  {receiver.solarPanel && (
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <SolarPowerIcon sx={{ mr: 1, color: "#ffeb3b" }} />
                      <Typography variant="body2">
                        Voltaje Solar:{" "}
                        {receiver.latestPing.vsysVoltage.toFixed(1)}V
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <SignalWifi4BarIcon sx={{ mr: 1, color: "#000" }} />
                    <Typography variant="body2">
                      Conexión:{" "}
                      {receiver.isConnected ? "Conectado" : "Desconectado"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <LocationOnIcon sx={{ mr: 1, color: "#000" }} />
                    <Typography variant="body2">
                      Coordenadas:{" "}
                      {receiver.Location
                        ? `${receiver.Location.lat}, ${receiver.Location.lng}`
                        : "No disponible"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <ThermostatIcon
                      sx={{
                        mr: 1,
                        color:
                          receiver.latestPing.temperature < 30
                            ? "#4caf50"
                            : receiver.latestPing.temperature < 50
                            ? "#ff9800"
                            : "#f44336",
                      }}
                    />
                    <Typography variant="body2">
                      Temperatura: {receiver.latestPing.temperature} °C
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Último ping:{" "}
                    {moment(receiver.latestPing.time).format("MM-DD HH:mm")}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Voltaje: {receiver.latestPing.vsysVoltage.toFixed(2)} V
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Segundos desde el último ping:{" "}
                    {receiver.secondsSinceLastPing}
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" sx={{ opacity: 0.8 }}>
                  No hay datos disponibles.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Pings uid={uid} />
        </Grid>

        {showCharts && (
          <Grid item xs={12} md={6}>
            <MonitoringCharts uid={uid} />
          </Grid>
        )}

        <Grid item xs={12}>
          <ReceiverLocation location={receiver.Location} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReceiverInfo;
