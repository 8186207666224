import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Avatar,
  Card,
  CardContent,
  Grid,
  Chip,
  IconButton,
  Tabs,
  Tab,
  useTheme,
  Fade,
  Button,
  Tooltip,
  SwipeableDrawer,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import ShareIcon from "@mui/icons-material/Share";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import TeamMembers from "./TeamMembers";
import TeamActionButton from "./TeamActionButton";
import InstagramFeed from "./InstagramFeed";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 180,
  height: 180,
  border: `5px solid ${theme.palette.background.paper}`,
  boxShadow: theme.shadows[10],
  marginTop: -90,
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

const TeamPage = () => {
  const { uid } = useParams();
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const teamDocRef = doc(db, "Teams", uid);
    const unsubscribe = onSnapshot(teamDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setTeam(docSnapshot.data());
        setLoading(false);
      } else {
        console.error("No such team!");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [uid]);

  const handleShare = (platform) => {
    const text = `Conoce al equipo ${team.name} y únete a nosotros para compartir experiencias increíbles.`;
    const url = window.location.href;

    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}&quote=${encodeURIComponent(text)}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text + " " + url
      )}`,
    };

    if (shareUrls[platform]) {
      window.open(shareUrls[platform], "_blank");
      setShareDrawerOpen(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!team) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Equipo no encontrado
        </Typography>
      </Container>
    );
  }

  const creationDate = team.createdAt
    ? new Date(team.createdAt.seconds * 1000).toLocaleDateString()
    : "Fecha desconocida";

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.default,
        minHeight: "100vh",
        pb: 4,
      }}
    >
      <Box
        sx={{
          height: { xs: 300, md: 400 },
          position: "relative",
          overflow: "hidden",
          borderBottomLeftRadius: { xs: 40, md: 80 },
          borderBottomRightRadius: { xs: 40, md: 80 },
          boxShadow: theme.shadows[5],
        }}
      >
        <Box
          component="img"
          src={
            team.coverPhoto || "https://source.unsplash.com/random/?team,sports"
          }
          alt="Cover Photo"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "brightness(0.7)",
          }}
        />
      </Box>

      <Container
        maxWidth="lg"
        sx={{ mt: -10, position: "relative", zIndex: 1 }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              elevation={5}
              sx={{ borderRadius: 4, overflow: "visible", mb: 3 }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <StyledAvatar alt={team.name} src={team.logo} />
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ mt: 2, mb: 1 }}
                >
                  {team.name}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                  <Chip
                    icon={team.private ? <LockIcon /> : <PublicIcon />}
                    label={team.private ? "Privado" : "Público"}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<PeopleIcon />}
                    label={`${team.members.length} miembros`}
                    size="small"
                    color="secondary"
                    variant="outlined"
                  />
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                  {`${team.municipality}, ${team.state}, ${team.nationality}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ display: "flex", alignItems: "center", mb: 2 }}
                >
                  <CalendarTodayIcon fontSize="small" sx={{ mr: 0.5 }} />
                  {`Creado el ${creationDate}`}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  {team.instagram && (
                    <SocialButton
                      href={team.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </SocialButton>
                  )}
                  {team.facebook && (
                    <SocialButton
                      href={team.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </SocialButton>
                  )}
                  {team.twitter && (
                    <SocialButton
                      href={team.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </SocialButton>
                  )}
                </Box>
                <TeamActionButton teamId={uid} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card elevation={5} sx={{ borderRadius: 4, overflow: "hidden" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab icon={<DescriptionIcon />} label="Información" />
                <Tab icon={<PeopleIcon />} label="Miembros" />
                <Tab icon={<InstagramIcon />} label="Instagram" />
              </Tabs>
              <CardContent>
                <Fade in={true} timeout={500}>
                  <Box sx={{ minHeight: 300 }}>
                    {tabValue === 0 && (
                      <Typography variant="body1" paragraph>
                        {team.description ||
                          "Este equipo aún no tiene una descripción."}
                      </Typography>
                    )}
                    {tabValue === 1 && <TeamMembers />}
                    {tabValue === 2 && <InstagramFeed uid={uid} />}
                  </Box>
                </Fade>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Tooltip title="Compartir" placement="left">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ShareIcon />}
          onClick={() => setShareDrawerOpen(true)}
          sx={{
            position: "fixed",
            bottom: theme.spacing(3),
            right: theme.spacing(3),
            borderRadius: 30,
            boxShadow: theme.shadows[5],
          }}
        >
          Compartir
        </Button>
      </Tooltip>

      <SwipeableDrawer
        anchor="bottom"
        open={shareDrawerOpen}
        onClose={() => setShareDrawerOpen(false)}
        onOpen={() => setShareDrawerOpen(true)}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Compartir en redes sociales
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              mt: 2,
            }}
          >
            {["facebook", "twitter", "whatsapp"].map((platform) => (
              <IconButton
                key={platform}
                onClick={() => handleShare(platform)}
                color="primary"
                size="large"
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  "&:hover": { backgroundColor: theme.palette.primary.main },
                }}
              >
                {platform === "facebook" && <FacebookIcon fontSize="large" />}
                {platform === "twitter" && <TwitterIcon fontSize="large" />}
                {platform === "whatsapp" && <WhatsAppIcon fontSize="large" />}
              </IconButton>
            ))}
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default TeamPage;
