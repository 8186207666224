import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";

const ProfileInfo = ({ nfcIdentifier }) => {
  const [profileData, setProfileData] = useState({});
  const [bestTimes, setBestTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (!nfcIdentifier) {
          // Si nfcIdentifier no está definido, salir de la función
          setLoading(false);
          return;
        }

        // Obtener datos del perfil
        const profilesCollectionRef = collection(db, "profiles");
        const profileQuerySnapshot = await getDocs(
          query(
            profilesCollectionRef,
            where("nfcIdentifier", "==", nfcIdentifier),
          ),
        );

        if (profileQuerySnapshot.docs.length > 0) {
          setProfileData(profileQuerySnapshot.docs[0].data());
        }

        // Obtener mejores tiempos
        const timesCollectionRef = collection(db, "times");
        const timesQuerySnapshot = await getDocs(
          query(
            timesCollectionRef,
            where("nfcIdentifier", "==", nfcIdentifier),
            orderBy("tiempoTotal"),
          ),
        );

        setBestTimes(timesQuerySnapshot.docs.map((doc) => doc.data()));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [nfcIdentifier]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      <h1>{profileData.fullName || "Anonimo"}</h1>
      <p>NFC Identifier: {profileData.nfcIdentifier}</p>
      <p>Número de Teléfono: {profileData.phoneNumber || "No disponible"}</p>
      <p>Número de Rides: {profileData.rideCount || 0}</p>
      <p>Nivel de Habilidad: {profileData.skillLevel || "No especificado"}</p>
      <p>Tipo de Bicicleta: {profileData.bikeType || "No especificado"}</p>
      <p>Mejor Tiempo: {profileData.bestTime || "No disponible"}</p>
      <img
        src={
          profileData.profileImageUrl ||
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/noprof.png?alt=media&token=07ef4dde-51d9-4f35-b2b6-fe732aba5b1b"
        }
        alt={`Profile of ${profileData.fullName}`}
      />

      <h2>Mejores Tiempos</h2>
      <ul>
        {bestTimes.map((time, index) => (
          <li key={index}>
            Fecha: {time.scanTime.toDate().toLocaleString()} - Tiempo:{" "}
            {time.tiempoTotal} ms
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProfileInfo;
