import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  List,
  ListItem,
  useTheme,
  Chip,
  Tooltip,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { ArrowDownward, ArrowUpward, SwapHoriz } from "@mui/icons-material";
import { green, red, blue } from "@mui/material/colors";

const SpeedCoinHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const q = query(
          collection(db, "coinstransactions"),
          orderBy("date", "desc")
        );
        return onSnapshot(
          q,
          (querySnapshot) => {
            const transactionsData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setTransactions(transactionsData);
            setLoading(false);
          },
          (error) => {
            console.error("Error al obtener las transacciones:", error);
            setError("Error al obtener las transacciones. Intente nuevamente.");
            setLoading(false);
          }
        );
      } catch (error) {
        console.error("Error al obtener las transacciones:", error);
        setError("Error al obtener las transacciones. Intente nuevamente.");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const getTransactionIcon = (senderUID, receiverUID) => {
    if (senderUID === "SYSTEM")
      return <ArrowDownward style={{ color: green[500] }} />;
    if (receiverUID === "SYSTEM")
      return <ArrowUpward style={{ color: red[500] }} />;
    return <SwapHoriz style={{ color: blue[500] }} />;
  };

  const getTransactionColor = (senderUID, receiverUID) => {
    if (senderUID === "SYSTEM") return green[50];
    if (receiverUID === "SYSTEM") return red[50];
    return blue[50];
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatAmount = (amount) => {
    if (typeof amount === "number") {
      return amount.toFixed(2);
    } else if (typeof amount === "string") {
      return parseFloat(amount).toFixed(2);
    }
    return "0.00";
  };

  const memoizedTransactions = useMemo(() => transactions, [transactions]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" color="error" align="center" mt={5}>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ padding: isMobile ? 1 : 3 }}>
      <Paper
        elevation={3}
        sx={{
          padding: isMobile ? 2 : 3,
          borderRadius: 4,
          background: theme.palette.background.paper,
          mt: 5,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          color="primary"
          fontWeight="bold"
        >
          Historial de SpeedCoins
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List>
          {memoizedTransactions.map((transaction) => (
            <ListItem
              key={transaction.id}
              sx={{
                mb: 2,
                borderRadius: 2,
                backgroundColor: getTransactionColor(
                  transaction.senderUID,
                  transaction.receiverUID
                ),
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  cursor: "pointer",
                },
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 2,
              }}
              onClick={() => navigate(`/transaction/${transaction.id}`)}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} display="flex" alignItems="center" mb={1}>
                  {getTransactionIcon(
                    transaction.senderUID,
                    transaction.receiverUID
                  )}
                  <Chip
                    label={`$${formatAmount(transaction.amount)}`}
                    color={
                      transaction.senderUID === "SYSTEM" ? "success" : "primary"
                    }
                    sx={{ fontWeight: "bold", ml: 1 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {formatDate(transaction.date.seconds)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="ID de Emisor" placement="top">
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      Emisor: {transaction.senderUID}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="ID de Receptor" placement="top">
                    <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                      Receptor: {transaction.receiverUID}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default SpeedCoinHistory;
