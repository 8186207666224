import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Modal,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const ServiceNotesMapper = ({ serviceId }) => {
  const [serviceNotes, setServiceNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const db = getFirestore();
  const storage = getStorage();

  const handleOpen = (media) => {
    setSelectedMedia(media);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteId) {
      const noteToDelete = serviceNotes.find((note) => note.id === deleteId);
      if (noteToDelete) {
        const fileRef = storageRef(storage, noteToDelete.url);

        await deleteObject(fileRef).catch((error) => {
          console.error("Error deleting file from storage:", error);
        });

        await deleteDoc(
          doc(db, `bikeService/${serviceId}/ServiceNotes`, deleteId),
        );
      }
      setDeleteId(null);
      setConfirmOpen(false);
    }
  };

  useEffect(() => {
    if (serviceId) {
      const serviceDocRef = doc(db, `bikeService/${serviceId}`);
      getDoc(serviceDocRef)
        .then((serviceDocSnap) => {
          if (serviceDocSnap.exists()) {
            const notesQuery = query(collection(serviceDocRef, "ServiceNotes"));
            return onSnapshot(notesQuery, (querySnapshot) => {
              const notesData = [];
              querySnapshot.forEach((doc) => {
                notesData.push({
                  id: doc.id,
                  url: doc.data().url || "",
                  comments: doc.data().comments || "",
                  timestamp: doc.data().timestamp || "",
                  isVideo: doc.data().url?.includes(".mp4"),
                });
              });
              setServiceNotes(notesData);
            });
          } else {
            console.log("No such service document exists!");
          }
        })
        .catch((error) => {
          console.error("Error fetching service notes:", error);
        });
    }
  }, [serviceId, db]);

  return (
    <>
      <Grid container spacing={2}>
        {serviceNotes.length > 0 ? (
          serviceNotes.map((note) => (
            <Grid item key={note.id} xs={12} sm={6} md={6}>
              <Card>
                <CardContent>
                  <CardMedia
                    component={note.isVideo ? "video" : "img"}
                    src={note.url}
                    alt="ServiceNote"
                    onClick={() => handleOpen(note)}
                    sx={{ height: 250, cursor: "pointer" }}
                  />
                  <Typography variant="body2">
                    <strong>{note.timestamp}</strong>
                  </Typography>
                  <Typography variant="body1">{note.comments}</Typography>
                  <Button
                    color="error"
                    onClick={() => handleDeleteClick(note.id)}
                  >
                    Eliminar
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1">
              No hay notas de servicio disponibles.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedMedia?.isVideo ? (
            <video controls style={{ width: "100%", height: "100%" }}>
              <source src={selectedMedia?.url} type="video/mp4" />
            </video>
          ) : (
            <img
              src={selectedMedia?.url}
              style={{ width: "100%", height: "100%" }}
              alt="Service Note"
            />
          )}
        </Box>
      </Modal>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estás seguro de querer eliminar esta nota?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} autoFocus color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceNotesMapper;
