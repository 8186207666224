import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Avatar,
  Paper,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import TeamRequests from "./TeamRequests";
import MemberManager from "./MemberManager";

const TeamAdmin = () => {
  const { uid } = useParams();
  const [team, setTeam] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [logoFile, setLogoFile] = useState(null);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const teamDocRef = doc(db, "Teams", uid);
        const teamDocSnap = await getDoc(teamDocRef);

        if (teamDocSnap.exists()) {
          setTeam(teamDocSnap.data());
          fetchAdmins(teamDocSnap.data().admin);
        } else {
          console.error("No such team!");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching team:", error);
        setLoading(false);
      }
    };

    const fetchAdmins = async (adminUids) => {
      try {
        const adminPromises = adminUids.map(async (adminUid) => {
          const userDocRef = doc(db, "Users", adminUid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            return { uid: adminUid, ...userDocSnap.data() };
          } else {
            console.error("No such user:", adminUid);
            return null;
          }
        });
        const adminsData = await Promise.all(adminPromises);
        setAdmins(adminsData.filter((admin) => admin !== null));
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    };

    fetchTeam();
  }, [uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeam((prevTeam) => ({
      ...prevTeam,
      [name]: value,
    }));
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      let logoUrl = team.logo;
      if (logoFile) {
        const storageRef = ref(storage, `team_logos/${uid}/${logoFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, logoFile);
        await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              logoUrl = downloadURL;
              resolve();
            });
          });
        });
      }

      const teamRef = doc(db, "Teams", uid);
      await updateDoc(teamRef, {
        ...team,
        logo: logoUrl,
      });

      setTeam((prevTeam) => ({
        ...prevTeam,
        logo: logoUrl,
      }));

      setSaving(false);
    } catch (error) {
      console.error("Error saving team data:", error);
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!team) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
          Equipo no encontrado
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 3, mt: 3 }}>
        <form onSubmit={handleSave}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
            <Box position="relative">
              <Avatar
                alt={team.name}
                src={team.logo}
                sx={{ width: 120, height: 120 }}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="icon-button-file"
                type="file"
                onChange={handleLogoChange}
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Box>
            <TextField
              name="name"
              label="Nombre del Equipo"
              value={team.name}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
          </Box>
          <TextField
            name="nationality"
            label="Nacionalidad"
            value={team.nationality}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            name="state"
            label="Estado"
            value={team.state}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            name="municipality"
            label="Municipio"
            value={team.municipality}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Administradores
          </Typography>
          <List>
            {admins.map((admin) => (
              <ListItem key={admin.uid}>
                <ListItemAvatar>
                  <Avatar alt={admin.name} src={admin.photoUrl} />
                </ListItemAvatar>
                <ListItemText primary={admin.name} />
              </ListItem>
            ))}
          </List>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Privacidad:
            </Typography>
            {team.private ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LockIcon sx={{ mr: 1 }} /> Privado
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PublicIcon sx={{ mr: 1 }} /> Público
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Miembros:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PeopleIcon sx={{ mr: 1 }} /> {team.members.length}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={saving}
          >
            {saving ? <CircularProgress size={24} /> : "Guardar Cambios"}
          </Button>
        </form>
      </Paper>
      <MemberManager />
      <TeamRequests />
    </Container>
  );
};

export default TeamAdmin;
