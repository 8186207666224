import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PeopleIcon from "@mui/icons-material/People";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const features = [
  {
    title: "Marketplace",
    description:
      "Compra y vende equipo, encuentra ofertas exclusivas y todo lo necesario para tu próxima aventura en ciclismo.",
    icon: <ShoppingCartIcon sx={{ fontSize: 50, color: "#3f51b5" }} />,
  },
  {
    title: "Leaderboard",
    description:
      "Compite con otros y escala en el tablero de líderes, mostrando tus logros y tiempos récord.",
    icon: <LeaderboardIcon sx={{ fontSize: 50, color: "#f50057" }} />,
  },
  {
    title: "Comunidades",
    description:
      "Únete a comunidades de ciclistas, participa en eventos y conéctate con otros que comparten tu pasión.",
    icon: <PeopleIcon sx={{ fontSize: 50, color: "#4caf50" }} />,
  },
  {
    title: "Control de Acceso",
    description:
      "Gestiona tus entradas y salidas de eventos de manera segura, con tecnología de control de acceso eficiente y confiable.",
    icon: <LockOpenIcon sx={{ fontSize: 50, color: "#ff9800" }} />,
  },
];

const Features = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 3, md: 4 }}
      sx={{ py: 8, px: 2, backgroundColor: "white" }}
    >
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 2,
              borderRadius: "15px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardMedia sx={{ my: 2 }}>{feature.icon}</CardMedia>
            <CardContent>
              <Typography
                variant="h6"
                component="h3"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: "#666" }}>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Features;
