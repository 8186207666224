import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/userAuthContext";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import { Typography, Container, Box, Button } from "@mui/material";
import NewRides from "./Rides/NewRides";

import LogoutButton from "./LogoutButton";

const Home = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (user) {
        setEmail(user.email);
        await checkAdmin(user.uid);
        await checkProfile(user.uid);
        setLoading(false);
      }
    };

    init();
  }, [user]);

  const checkAdmin = async (uid) => {
    try {
      const userDocRef = doc(db, "Users", uid);
      const userSnapshot = await getDoc(userDocRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (userData && userData.type === "admin") {
          setIsAdmin(true);
        }
      }
    } catch (error) {
      console.log("Error checking admin status:", error);
    }
  };

  const checkProfile = async (uid) => {
    try {
      const userDocRef = doc(db, "Users", uid);
      const userSnapshot = await getDoc(userDocRef);
      if (!userSnapshot.exists()) {
        navigate("/addProfile");
      }
    } catch (error) {
      console.log("Error checking profile:", error);
    }
  };

  const handleAdminPanel = () => {
    navigate("/1098");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <NewRides />
    </Container>
  );
};

export default Home;
