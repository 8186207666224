import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, onSnapshot } from "firebase/firestore";
import {
  Typography,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Container,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  LocationOn as LocationOnIcon,
  Info as InfoIcon,
} from "@mui/icons-material";

const getStatusColor = (status) => {
  switch (status) {
    case "Abierta":
      return "#c8e6c9";
    case "Mantenimiento":
      return "#fff9c4";
    case "Cerrada":
      return "#ffcdd2";
    default:
      return "#ffffff";
  }
};

const ParkTracks = () => {
  const { uid } = useParams();
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tracksRef = collection(db, `Parks/${uid}/Tracks`);
    const q = query(tracksRef);
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const tracksData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTracks(tracksData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching tracks:", error);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [uid]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {tracks.map((track) => (
          <Grid item xs={12} sm={6} md={4} key={track.id}>
            <Card
              elevation={3}
              style={{
                backgroundColor: getStatusColor(track.Status),
                marginBottom: "16px",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" gutterBottom>
                    {track.TrackName}
                  </Typography>
                  <Chip
                    label={track.Status}
                    color={
                      track.Status === "Abierta"
                        ? "success"
                        : track.Status === "Mantenimiento"
                          ? "warning"
                          : "error"
                    }
                    size="small"
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Dificultad: {track.Difficulty}
                </Typography>

                <Box mt={1}>
                  <Tooltip title={track.Description} placement="top">
                    <Typography variant="body2" color="textSecondary" noWrap>
                      <InfoIcon fontSize="small" color="action" />{" "}
                      {track.Description}
                    </Typography>
                  </Tooltip>
                </Box>
              </CardContent>
              <CardActions>
                {track.location && (
                  <IconButton
                    aria-label="Ver ubicación"
                    color="primary"
                    href={`https://www.google.com/maps/search/?api=1&query=${track.location.latitude},${track.location.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "auto" }}
                  >
                    <LocationOnIcon />
                  </IconButton>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ParkTracks;
