import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Avatar,
  useMediaQuery,
  useTheme,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  styled,
} from "@mui/material";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "16px",
  overflow: "hidden",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 28px rgba(0, 0, 0, 0.2)",
  },
  cursor: "pointer",
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));

const TimeChip = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  color: "#fff",
  fontSize: "1.25rem",
  padding: theme.spacing(1, 2),
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
  borderRadius: "8px 8px 0 0",
  marginBottom: theme.spacing(2),
}));

const formatDateTime = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    return timestamp.toDate().toLocaleString();
  } else if (timestamp instanceof Date) {
    return timestamp.toLocaleString();
  } else {
    return "Fecha inválida";
  }
};

const calculateTotalTime = (startTime, finishTime) => {
  if (!startTime || !finishTime) return "Tiempo inválido";
  const start = startTime.toDate ? startTime.toDate() : new Date(startTime);
  const finish = finishTime.toDate ? finishTime.toDate() : new Date(finishTime);
  const diffMs = finish - start;
  const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const diffSecs = Math.floor((diffMs % (1000 * 60)) / 1000);

  return `${diffHrs}h ${diffMins}m ${diffSecs}s`;
};

const RiderStats = ({ uid }) => {
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [selectedPark, setSelectedPark] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRunsAndTags = async () => {
      setLoading(true);
      try {
        const runsRef = collection(db, "Runs");
        const runsQuerySnapshot = await getDocs(runsRef);

        const fetchedRuns = await Promise.all(
          runsQuerySnapshot.docs.map(async (runDoc) => {
            const runData = runDoc.data();
            const tagsRef = collection(db, "Tags");
            const tagsQuery = query(
              tagsRef,
              where("NFCID", "==", runData.NFCIdentifier)
            );
            const tagsQuerySnapshot = await getDocs(tagsQuery);

            if (!tagsQuerySnapshot.empty) {
              const tagDoc = tagsQuerySnapshot.docs[0];
              const tagData = tagDoc.data();

              if (tagData.Owner === uid) {
                const riderDocRef = doc(db, "Users", tagData.Owner);
                const riderDoc = await getDoc(riderDocRef);
                const riderData = riderDoc.exists() ? riderDoc.data() : {};

                const parkDocRef = doc(db, "Parks", runData.ParkID);
                const parkDoc = await getDoc(parkDocRef);
                const parkName = parkDoc.exists()
                  ? parkDoc.data().ParkName
                  : "Desconocido";

                const trackDocRef = doc(
                  collection(parkDocRef, "Tracks"),
                  runData.TrackID
                );
                const trackDoc = await getDoc(trackDocRef);
                const trackName = trackDoc.exists()
                  ? trackDoc.data().TrackName
                  : "Desconocido";

                const totalTimeMs = calculateTotalTime(
                  runData.StartTime,
                  runData.FinishTime
                );

                return {
                  id: runDoc.id,
                  ...runData,
                  tagInfo: tagData,
                  rider: riderData,
                  parkName,
                  trackName,
                  totalTimeMs,
                };
              }
            }

            return null;
          })
        );

        const filteredRuns = fetchedRuns.filter((run) => run !== null);
        setRuns(filteredRuns);

        // Extraer parques únicos de las runs
        const uniqueParks = [...new Set(filteredRuns.map((run) => run.ParkID))];
        const parksData = await Promise.all(
          uniqueParks.map(async (parkId) => {
            const parkDocRef = doc(db, "Parks", parkId);
            const parkDoc = await getDoc(parkDocRef);
            return { id: parkId, name: parkDoc.data().ParkName };
          })
        );
        setParks(parksData);
      } catch (error) {
        console.error("Error fetching runs and tags:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRunsAndTags();
  }, [uid]);

  useEffect(() => {
    const fetchTracks = async () => {
      if (selectedPark) {
        try {
          const tracksRef = collection(db, "Parks", selectedPark, "Tracks");
          const tracksSnapshot = await getDocs(tracksRef);
          const tracksData = tracksSnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().TrackName,
          }));
          setTracks(tracksData);
        } catch (error) {
          console.error("Error fetching tracks:", error);
        }
      } else {
        setTracks([]);
      }
    };

    fetchTracks();
  }, [selectedPark]);

  const filteredRuns = useMemo(() => {
    return runs
      .filter(
        (run) =>
          (selectedPark === "" || run.ParkID === selectedPark) &&
          (selectedTrack === "" || run.TrackID === selectedTrack)
      )
      .sort((a, b) => a.totalTimeMs.localeCompare(b.totalTimeMs));
  }, [runs, selectedPark, selectedTrack]);

  const handleClearFilters = () => {
    setSelectedPark("");
    setSelectedTrack("");
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (runs.length === 0) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", mt: 4, color: "text.secondary" }}
      >
        No se encontraron runs.
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: isMobile ? 2 : 4 }}>
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: "bold", color: theme.palette.primary.main }}
      >
        Tus Estadísticas de Carreras
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          marginBottom: 4,
          alignItems: "center",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-park-label">Seleccionar Parque</InputLabel>
          <Select
            labelId="select-park-label"
            value={selectedPark}
            label="Seleccionar Parque"
            onChange={(e) => {
              setSelectedPark(e.target.value);
              setSelectedTrack("");
            }}
            startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
          >
            <MenuItem value="">
              <em>Todos los Parques</em>
            </MenuItem>
            {parks.map((park) => (
              <MenuItem key={park.id} value={park.id}>
                {park.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!selectedPark}>
          <InputLabel id="select-track-label">Seleccionar Track</InputLabel>
          <Select
            labelId="select-track-label"
            value={selectedTrack}
            label="Seleccionar Track"
            onChange={(e) => setSelectedTrack(e.target.value)}
            startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
          >
            <MenuItem value="">
              <em>Todos los Tracks</em>
            </MenuItem>
            {tracks.map((track) => (
              <MenuItem key={track.id} value={track.id}>
                {track.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          startIcon={<ClearIcon />}
          onClick={handleClearFilters}
          sx={{ minWidth: 120 }}
        >
          Limpiar Filtros
        </Button>
      </Box>
      <Grid container spacing={isMobile ? 2 : 3}>
        {filteredRuns.map((run) => (
          <Grid item xs={12} sm={6} md={4} key={run.id}>
            <StyledCard onClick={() => navigate(`/run/${run.id}`)}>
              <TimeChip>
                <AccessTimeIcon />
                {calculateTotalTime(run.StartTime, run.FinishTime)}
              </TimeChip>
              <CardContent sx={{ padding: isMobile ? "16px" : "24px" }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <StyledChip
                    label={run.parkName}
                    color="secondary"
                    size="small"
                    icon={<LocationOnIcon />}
                  />
                  <StyledChip
                    label={run.trackName}
                    color="info"
                    size="small"
                    icon={<DirectionsBikeIcon />}
                  />
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Inicio:</strong> {formatDateTime(run.StartTime)}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  <strong>Fin:</strong> {formatDateTime(run.FinishTime)}
                </Typography>
                <Divider sx={{ my: 2 }} />
                {run.rider.name ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar
                      src={run.rider.photoUrl || ""}
                      alt={run.rider.name || "Unknown Rider"}
                      sx={{ width: 48, height: 48 }}
                    />
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {run.rider.name || "Desconocido"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        @{run.rider.nickName || "Unknown"}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Información del Rider no disponible.
                  </Typography>
                )}
                <Divider sx={{ my: 2 }} />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ display: "block", textAlign: "right" }}
                >
                  Run ID: {run.id}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RiderStats;
