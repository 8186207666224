import React from "react";
import { Typography, Box } from "@mui/material";

const Introduccion = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Bienvenido a la Documentación de Livespeed
      </Typography>
      <Typography variant="body1" paragraph>
        Esta documentación está diseñada para guiarte a través de las funciones
        clave de Livespeed. Aquí aprenderás a manejar receptores, agregar
        etiquetas NFC, organizar eventos, crear rodadas y mucho más.
      </Typography>
      <Typography variant="body1" paragraph>
        Livespeed es una plataforma avanzada para ciclistas que te ayuda a
        organizar eventos, gestionar equipos y hacer un seguimiento de tus
        actividades. Navega por las guías para aprender más sobre cada
        funcionalidad.
      </Typography>
    </Box>
  );
};

export default Introduccion;
